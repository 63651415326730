import React from "react";
import GreyAlert from '../../../assets/images/svg/GreyAlert.svg';
import NotSupported from '../../../assets/images/svg/NotSupported.svg'
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
const ProductTypeNotSupported = (props) => {
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            closePopUp();
        }
    }
    const closePopUp = () => {
        props.toggleProductTypeNotSupported && props.toggleProductTypeNotSupported()
    }
    return (
        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className={props.isShow === true ? "subwindow product-unsupported current" : "subwindow product-unsupported"}>
                <div className="subwindow-body">
                    <img src={NotSupported} alt="" />
                    <p className="style1">{LocalizedLanguage.Productnotsupported}</p>
                    <p className="style2">
                        {LocalizedLanguage.productNotSupport} <br/>
                        {LocalizedLanguage.youmaybe} <br />
                        {LocalizedLanguage.introduceyour} <br className="mobile" />
                        {LocalizedLanguage.hubsettings}
                    </p>
                    <button id="prodNotSupportedExit" onClick={(() => closePopUp())}>{LocalizedLanguage.goBack}</button>
                </div>
            </div></div>)
}

export default ProductTypeNotSupported 