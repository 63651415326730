
exports.key = {
    // ---dev setting-------
    //  OP_API_URL:  'https://dev1.api.oliverpos.com/api/' ,
    // RECIEPT_IMAGE_DOMAIN: "https://dev1.api.oliverpos.com",
    // OP_IMAGE_URL:  'https://dev1.api.oliverpos.com',
   
     // ---live setting-------
    OP_API_URL: process.env.NODE_ENV === 'development' ? 'https://app.oliverpos.com/api/' : 'https://app.oliverpos.com/api/',
    OP_IMAGE_URL: process.env.NODE_ENV === 'development' ? 'https://app.oliverpos.com' : 'https://app.oliverpos.com',
    RECIEPT_IMAGE_DOMAIN: "https://app.oliverpos.com",
   
    AUTH_KEY: localStorage.getItem("AUTH_KEY"), 
    ENV: process.env.NODE_ENV,
    PPRODUCT_PAGE_SIZE: 100,
    CUSTOMER_PAGE_SIZE: 1000,
    ACTIVITY_PAGE_SIZE: 20,
    CASHLOG_PAGE_SIZE: 100,
    FETCH_PRODUCTS_PAGESIZE: 700,
    ONLY_TIME: 'h:mm a',
    MONTH_DAY_FORMAT: 'MMMM,DD YYYY ,h:mm a',
    DATE_FORMAT: 'DD, MMMM YYYY',
    DATE_FORMAT_CASH_DETAIL: 'MMMM DD, YYYY',
    DATETIME_FORMAT: 'DD, MMMM YYYY, h:mm a',
    DATE_FORMAT_SAFARI: 'YYYY-MM-DD',
    DATETIME_FORMAT_SAFARI: 'YYYY-MM-DDTHH:mm:ss',
    TIMEDATE_FORMAT: 'h:mm A DD, MMMM YYYY',
    
    NOTIFICATION_LIMIT: 100,
    NOTIFICATION_FORMAT: 'DD/MM/YY, h:mm a',
    SYNC_COUNT_LIMIT: 100,
    BUGSNAG_KEY: process.env.NODE_ENV == 'production' ? '1b6822fe1472fcdb1089ae3dbbc58d1c' : process.env.NODE_ENV == 'development' ? '36d2896ab825e64ceeffda1d5840cb4d' : '44dbab7bfa5373855271ad1de88669dc',
    PRODUCT_SEARCH_LENGHT: 2,
    DEMO_USER_PING_INTERVAL: 30000,
    GOOGLE_CLIENT_ID: '868968713299-g32dlt8ib214vh0ukc88bj909cp9cjp9.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: "1066839183753303",
    DEMO_USER_NAME: "Demo User",
    OLIVERPOS_CONTACT_LINK: 'https://oliverpos.com/contact-oliver-pos/',
    OLIVERPOS_TUTORIAL_LINK: 'https://youtu.be/EoCdEwfIZww',
    OLIVERPOS_GETTING_STARTED: 'https://oliverpos.com/getting-started/',
    SEGMENT_ANALYTIC_KEY: 'xnSztkKDSndS5XpNN7rrj8sJBvfABbvh',
    FIREBASE_NOTIFICATION_COUNT: 5,
    ALTERNATIVE_PRODUCT_SEARCH_START: 20000, // starting the product searching on alternative input character by this limit
    APPLE_LOGIN_RETURN_URL: "https://"+ window.location.hostname + "/login",
    REGISTER_TAKE_OVER_LOGOUT_TIME: 10000,
    IDLE_SCREEN_TIME_OUT:1000 * 60 * 10,
    //New google tag script has been placed in live branch
    // GA_KEY                  : process.env.NODE_ENV=='production'? 'UA-114926859-8':'UA-141287502-1'
    GTM_ID:'GTM-TQBJ7K8',
    SEARCH_START_LENGTH:3,
    DEMO_USER_URL: 'try.oliverpos.com',
    DEMO_USER_EMAIL: 'cafe@oliver.com',
    DEMO_USER_PASSWORD: '123456',
    DEMO_POS_URL:"https://onboarding.oliverpos.com/?utm_source=demo&utm_medium=banner&utm_campaign=get_started",
    INTERCOM_APP_ID:'xntoxr3q',
    TOUR_HOME_KEY:471949,
    TOUR_PIN_KEY:471971
}

exports.key_AssigneeType = { Category: 0, SubCategory: 1, Attribute: 2, SubAttribute: 3, Product: 4, Tag: 5 }
exports.key_InputTypes = { TextField: 0, NumberField: 1, RadioButton: 2, CheckBox: 3 }

