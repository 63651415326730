import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import avatar from '../../assets/images/svg/avatar.svg';
import avatar_2 from '../../assets/images/svg/avatar-2.svg';
import Arrow_Left from '../../assets/images/svg/Arrow_Left.svg';
import Oliver_Icon_BaseBlue from '../../assets/images/svg/Oliver-Icon-BaseBlue.svg';
import Ellipsis_Icon_DarkBlue from '../../assets/images/svg/Ellipsis-Icon-DarkBlue.svg';
import AddNote_Icon from '../../assets/images/svg/AddNote-Icon.svg';
import ClearCart_Icon from '../../assets/images/svg/ClearCart-Icon.svg';

import Clock from '../../assets/images/svg/Clock.svg';
import UserInfo from "../common/commonComponents/UserInfo";
import SwitchUser from "../common/commonComponents/SwitchUser";
import EndSession from "../common/commonComponents/EndSession";
import OrderNote from "../common/commonComponents/OrderNote";
import { useNavigate, useLocation } from 'react-router-dom';
import AppLauncher from "../common/commonComponents/AppLauncher";
import IframeWindow from "../dashboard/IframeWindow";
// import ParkSale from "./ParkSale";
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { get_UDid, get_userName_Initial } from "../common/localSettings";
// import { checkTempOrderSync, orderToVoid } from "./checkoutSlice";
// import ActiveUser from '../../settings/ActiveUser';
// import Config from '../../Config';
// import { removeCheckOutList } from '../dashboard/product/productLogic';
// import { product } from "../dashboard/product/productSlice";
import { orderToVoid } from "./checkoutSlice";
import RegisterTakeOver from "../common/commonComponents/RegisterTakeOver";
import STATUSES from "../../constants/apiStatus";
import VoidSaleConfirmationPopup from "../common/commonComponents/VoidSaleConfirmationPopup";
import { LoadingModal } from "../common/commonComponents/LoadingModal";
import { paymentAmount } from "./checkoutSlice";
import { NavigateComponent } from "../../settings";
const Header = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isShowOrderNote, setisShowOrderNote] = useState(false);
    const [isShowUserProfile, setisShowUserProfile] = useState(false);
    const [isShowSwitchUser, setisShowSwitchUser] = useState(false);
    const [isShowEndSession, setisShowEndSession] = useState(false);
    // const [isShowParkSale, setisShowParkSale] = useState(false);
    const [isShowAppLauncher, setisShowAppLauncher] = useState(false);
    const [isShowiFrameWindow, setisShowiFrameWindow] = useState(false);
    const [isShowOptionPage, setisShowOptionPage] = useState(false);
    const [isShowVoidSaleConfirmation, setisVoidSaleConfirmation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toggleOrderNote = () => {
        setisShowOrderNote(!isShowOrderNote)
        if (isShowOrderNote === true) {
            props.refreshCart && props.refreshCart()
        } else {
            props.falseState && props.falseState()
        }
    }
    const toggleUserProfile = () => {
        setisShowUserProfile(!isShowUserProfile)
    }
    const toggleShowEndSession = () => {
        setisShowEndSession(!isShowEndSession)
    }
    const toggleSwitchUser = () => {
        setisShowSwitchUser(!isShowSwitchUser)
    }
    // const toggleParkSale = () => {
    //     setisShowParkSale(!isShowParkSale)
    // }
    const toggleAppLauncher = () => {
        setisShowAppLauncher(!isShowAppLauncher)
        setisShowOptionPage(false)
    }
    const toggleiFrameWindow = () => {
        setisShowiFrameWindow(!isShowiFrameWindow)
    }
    const toggleOptionPage = () => {
        setisShowOptionPage(!isShowOptionPage)
    }
    const toggleVoidSaleConfirmation = () => {
        setisVoidSaleConfirmation(!isShowVoidSaleConfirmation)
    }
    const addNote = (e) => {
        //console.log("----order note-----" + e);
        toggleOrderNote();
        // if(e==="park_sale")
        // {
        //     props.placeParkLayAwayOrder && props.placeParkLayAwayOrder('park_sale');
        // }
    }
    const goBack = () => {
        if (props.title) { localStorage.removeItem("oliver_refund_order_payments"); navigate('/transactions'); }
        else {
            sessionStorage.setItem("addcutomertosale", true)
            localStorage.removeItem("oliver_refund_order_payments");
            localStorage.removeItem('paybyproduct');
            localStorage.removeItem('paybyproduct_unpaid');
            localStorage.removeItem("payType");
            //NavigateComponent(navigate,"home");

            var checklist = localStorage.getItem('CHECKLIST') && JSON.parse(localStorage.getItem('CHECKLIST'))
            if (checklist && (checklist.status == "pending" || checklist.status == "park_sale" || checklist.status == "lay_away" || checklist.status == "on-hold")) {
                localStorage.setItem('PENDING_PAYMENTS', JSON.stringify(checklist))
            }
            localStorage.removeItem('extensionUpdateCart');
            NavigateComponent(navigate,"home");
        }
    }
    const voidSale = () => {
        dispatch(paymentAmount(null));
        var checkList = JSON.parse(localStorage.getItem("CHECKLIST"));
        localStorage.removeItem('extensionUpdateCart');
        localStorage.removeItem('PRODUCTX_DATA');
        localStorage.removeItem("couponApplied");
        localStorage.removeItem("shippingAddress");
        localStorage.removeItem('paybyproduct');
        localStorage.removeItem('paybyproduct_unpaid');
        localStorage.removeItem("payType");
        localStorage.removeItem("AdCusDetail");
        var order_id = (checkList != null && typeof checkList.order_id !== "undefined") ? checkList.order_id : 0;
        if (order_id == 0) {
            localStorage.removeItem('PRODUCT');
            localStorage.removeItem("CART");
            localStorage.removeItem('CHECKLIST');
            localStorage.removeItem('AdCusDetail');
            localStorage.removeItem('CARD_PRODUCT_LIST');
            localStorage.removeItem('oliver_order_payments');
            toggleVoidSaleConfirmation();
            NavigateComponent(navigate,"home");
        }
        else {
            var data = {
                order_id: order_id,
                udid: get_UDid(),
                WarehouseId: localStorage.getItem("WarehouseId") ? parseInt(localStorage.getItem("WarehouseId")) : 0
            }
            dispatch(orderToVoid(data));
            setIsLoading(true);
        }
    }
    const [resOrderToVoid] = useSelector((state) => [state.orderToVoid])
    useEffect(() => {
        if ((resOrderToVoid && resOrderToVoid.status == STATUSES.IDLE && resOrderToVoid.is_success && isLoading == true)) {
            setIsLoading(false);
            toggleVoidSaleConfirmation();
            navigate('/transactions');
            // dispatch(orderToVoid("clear"));
        }
    }, [resOrderToVoid]);

    // const clearCart = () => {
    //     removeCheckOutList();
    //     dispatch(product());
    //     NavigateComponent(navigate,"home");
    // }
    var isBack = true;

    if ((localStorage.getItem("oliver_order_payments") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : []).length > 0
        || ((localStorage.getItem("oliver_refund_order_payments") ? JSON.parse(localStorage.getItem("oliver_refund_order_payments")) : []).length > 0) == true
        // || localStorage.getItem("VOID_SALE")
        // props.Orderstatus == "processing"
    ) {
        isBack = false;
    }
    if (localStorage.getItem("CHECKLIST")) {
        var checklist = JSON.parse(localStorage.getItem("CHECKLIST"));
        if (checklist && checklist.hasOwnProperty('status') == true && checklist.status == "processing") {
            isBack = false;
        }
    }

    return (<React.Fragment>
        {isLoading == true ? <LoadingModal></LoadingModal> : ""}
        <div className="header">
            {
                // (/*isBack == false 
                //     ||*/ (localStorage.getItem("oliver_order_payments") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : []).length > 0
                //     || (localStorage.getItem("oliver_refund_order_payments") ? JSON.parse(localStorage.getItem("oliver_refund_order_payments")) : []).length > 0) == true
                //     ?
                //     null : 
                <button id="exitCheckoutButton" onClick={() => isBack == true ? goBack() : toggleVoidSaleConfirmation()}>
                    <img src={Arrow_Left} alt="" />
                </button>}
            <p>{(props.title == null || typeof props.title === "undefined") ? LocalizedLanguage.checkout : props.title}</p>
            {/* Page Options */}

            <div id="pageOptions" className={isShowOptionPage || window.location.pathname == "/checkout" || window.location.pathname == "/refund"? "page-options-wrapper checkout_page" : "page-options-wrapper hidden"}>
            <div className="page-options">
                <p>{LocalizedLanguage.optionsmenu}</p>
                {/* <button id="clearCartButton" onClick={() => clearCart()}>
                        <div className="img-container">
                            <img src={ClearCart_Icon} alt="" />
                        </div>
                        <p>Clear Cart</p>
                    </button> */}
                {props.title == null ?
                    localStorage.getItem("oliver_order_payments") && (JSON.parse(localStorage.getItem("oliver_order_payments"))).length > 0 ? null :
                        <button id="parkSaleButton" onClick={() => props.toggleParkSale('park_sale')}>
                            <div className="img-container">
                                <img src={Clock} alt="" />
                            </div>
                            <p>{LocalizedLanguage.parkSale}</p>
                        </button>
                    : null}
                <button id="addNoteButton" onClick={() => toggleOrderNote()}>
                    <div className="img-container">
                        <img src={AddNote_Icon} alt="" />
                    </div>
                    <p>{LocalizedLanguage.addordernote}</p>
                </button>
            </div>
            </div>
            <button id="userInfoButton" /*onClick={() => toggleUserProfile()}*/ disabled>
                {/* <img src={avatar_2} alt="" /> */}
                {get_userName_Initial() != "" ? <div className="avatar">{get_userName_Initial()}</div> : <img src={avatar_2} alt="" />}
                {/* {get_userName_Initial()!=""?<div class="avatar">{get_userName_Initial()}</div>: <img src={avatar_2} alt="" />} */}
            </button>
            <button id="mobileOptionsButton" onClick={() => toggleOptionPage()} className={isShowOptionPage ? "filter" : ""}>
                <img src={Ellipsis_Icon_DarkBlue} alt="" />
            </button>
            <button id="mobileAppsButton">
                <img src={Oliver_Icon_BaseBlue} alt="" onClick={() => toggleAppLauncher()} />
            </button>
        </div>

        
        <OrderNote isShow={isShowOrderNote} toggleOrderNote={toggleOrderNote} addNote={addNote}></OrderNote>
        <UserInfo isShow={isShowUserProfile} toggleSwitchUser={toggleSwitchUser} toggleUserProfile={toggleUserProfile} toggleShowEndSession={toggleShowEndSession}></UserInfo>
        <SwitchUser toggleSwitchUser={toggleSwitchUser} isShow={isShowSwitchUser}></SwitchUser>
        <EndSession toggleShowEndSession={toggleShowEndSession} isShow={isShowEndSession}></EndSession>
        {/* <ParkSale toggleParkSale={toggleParkSale} isShow={isShowParkSale} addNote={addNote}></ParkSale> */}
        <AppLauncher isShow={isShowAppLauncher} toggleAppLauncher={toggleAppLauncher} toggleiFrameWindow={toggleiFrameWindow}></AppLauncher>
        {isShowiFrameWindow ? <IframeWindow isShow={isShowiFrameWindow} toggleiFrameWindow={toggleiFrameWindow}></IframeWindow> : null}
        <RegisterTakeOver></RegisterTakeOver>
        <VoidSaleConfirmationPopup isShow={isShowVoidSaleConfirmation} toggleVoidSaleConfirmation={toggleVoidSaleConfirmation} voidSale={voidSale}></VoidSaleConfirmationPopup>
    </React.Fragment>)
}

export default Header 