import React, { useState, useEffect, useLayoutEffect } from "react";
import avatar from '../../../assets/images/svg/avatar.svg';
import Avatar_Blue_Background from '../../../assets/images/svg/Avatar-Blue_Background.svg';
import avatar_2 from '../../../assets/images/svg/avatar-2.svg';
import knowledgeBase_Icon from '../../../assets/images/svg/knowledgeBase-Icon.svg';
import SwitchUser_Icon from '../../../assets/images/svg/SwitchUser-Icon.svg';
import star from '../../../assets/images/svg/star.svg';
import LogOut_Icon from '../../../assets/images/svg/LogOut-Icon.svg';
import { changeLanguage } from "../../../settings/LocalizedLanguage";
import ClockIn_Icon from '../../../assets/images/Temp/ClockIn_Icon.png';
import DownArrowGrey from '../../../assets/images/svg/DownArrowGrey.svg';
import { get_regName, get_userName, get_userName_Initial } from "../../common/localSettings";
import { CheckAppDisplayInView } from '../commonFunctions/AppDisplayFunction';
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import NoImageAvailable from '../../../assets/images/svg/NoImageAvailable.svg';
import NoAppsMessage from '../../../assets/images/svg/NoApps-Message.svg';
const UserInfo = (props) => {
    const [isSelectLangugage, setisSelectLangugage] = useState(false);
    const [selLangugage, setSelLangugage] = useState('');
    const toggleSelectLangugage = () => {
        setisSelectLangugage(!isSelectLangugage)
    }
  // Display app render
  var allAppList = JSON.parse(localStorage.getItem("GET_EXTENTION_FIELD")) ? JSON.parse(localStorage.getItem("GET_EXTENTION_FIELD")) : [];


    useEffect(() => {
        setSelLangugage(localStorage.getItem("LANG") ? getLangNameByCode(localStorage.getItem("LANG")) : "English")
    }, [selLangugage]);
    const getLangNameByCode = (lang) => {
        var _lang = "English";
        switch (lang) {
            case "fr":
                _lang = "French"
                break;
            case "en":
                _lang = "English"
                break;
            case "es":
                _lang = "Spanish"
                break;
            case "de":
                _lang = "German"
                break;
            case "da":
                _lang = "Danish"
                break;
            case "hi":
                _lang = "Hindi"
                break;
            case "nl":
                _lang = "Dutch"
                break;
            case "zh":
                _lang = "Chinese (Mandarin)"
                break;
            case "no":
                _lang = "Norwegian"
                break;
            case "fi":
                _lang = "Finland"
                break;
            case "ro":
                _lang = "Romanian"
                break;
                case "it":
                    _lang = "Italian"
                    break;
            default:
                break;
        }
        return _lang;
    }
    const SetLangugage = (lang) => {
        changeLanguage(lang);
        localStorage.setItem("LANG", lang);
        setSelLangugage(lang)
        setisSelectLangugage(false);
        props.toggleUserProfile();
        window.location.reload(false);
    }
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "user-info-wrapper") {
            props.toggleUserProfile();
            toggleSelectLangugage();
        }
    }
    const outerClickLanguage = (e) => {
        if (e && e.target && e.target.className && (e.target.className === "header"||e.target.className === "body"||e.target.className === "footer" || e.target.className=="language-select")) {
            setisSelectLangugage(false)
        }
    }

    useEffect(() => {
        if (props && props.hasOwnProperty("isShow") && props.isShow === false) {
            setisSelectLangugage(false);
        }
    }, [props]);
    var MyAppExist=false;
    allAppList && allAppList.length > 0  && allAppList.map((Items, index) => {
        { 
        if(Items.viewManagement && Items.viewManagement !== [] && (Items.viewManagement.find(item=> item.ViewSlug=="User View")))
        { 
            MyAppExist=true;
        }
    }
    })

    var user_list= localStorage.getItem('user_List') && JSON.parse(localStorage.getItem('user_List'));
    return (
        <div id="userInfoWrapper" className={props.isShow === true ? "user-info-wrapper" : "user-info-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className="user-info" onClick={(e) => outerClickLanguage(e)}>
                <div className="header">
                    {/* <img src={avatar} alt="" /> */}
                    {/* <img src={Avatar_Blue_Background} alt="" className="default" /> */}
                    {/* <img src={avatar_2} alt="" /> */}
                    {get_userName_Initial() !== "" ? <div className="avatar">{get_userName_Initial()}</div> : <img src={avatar_2} alt="" />}
                    <div className="col">
                        <p className="style1">{get_userName()}</p>
                        <p className="style2">{get_regName()}</p>
                    </div>
                </div>
                <div className={allAppList && allAppList.length==0?"body pb0":"body"}>
                    <div className="language-select">
                        <p>{LocalizedLanguage.Language}:</p>
                        <div id="languageDropdownWrapper" className={isSelectLangugage === true ? "dropdown-input-wrapper open" : "dropdown-input-wrapper"}>
                            <img src={DownArrowGrey} alt="" onClick={() => toggleSelectLangugage()} />
                            <input type="text" id="language" value={selLangugage} onClick={() => toggleSelectLangugage()} readOnly />
                            <div className="option-container">
                                <div className="language-option" onClick={() => SetLangugage("nl")}><p>Dutch</p></div>
                                <div className="language-option" onClick={() => SetLangugage("de")}><p>German</p></div>
                                <div className="language-option" onClick={() => SetLangugage("fr")}><p>French</p></div>
                                <div className="language-option" onClick={() => SetLangugage("es")}><p>Spanish</p></div>
                                <div className="language-option" onClick={() => SetLangugage("hi")}><p>Hindi</p></div>
                                <div className="language-option" onClick={() => SetLangugage("zh")}><p>Chinese (Mandarin)</p></div>
                                <div className="language-option" onClick={() => SetLangugage("en")}> <p>English</p></div>
                                <div className="language-option" onClick={() => SetLangugage("no")}><p>Norwegian</p></div>
                                <div className="language-option" onClick={() => SetLangugage("da")}><p>Danish</p></div>
                                <div className="language-option" onClick={() => SetLangugage("fi")}><p>Finland</p></div>
                                <div className="language-option" onClick={() => SetLangugage("ro")}><p>Romanian</p></div>
                                <div className="language-option" onClick={() => SetLangugage("it")}><p>Italian</p></div>
                            </div>
                        </div>
                    </div>
                    {/* <button id="knowledgeBaseButton" onClick={() => props.toggleKnowledgeBase()}>
                        <div className="img-container">
                            <img src={knowledgeBase_Icon} alt="" />
                        </div>
                        {LocalizedLanguage.KnowledgeBase}
                    </button> */}
                    {user_list && user_list !== null && user_list.length > 0 ?
                        <button className={user_list && user_list !== null && user_list.length === 1 ? "btn-disable":''} id="switchUserButton" onClick={ user_list.length > 1 ? () => props.toggleSwitchUser():null} disabled={user_list && user_list !== null && user_list.length === 1 ? true : false}>
                            <div className="img-container">
                                <img src={SwitchUser_Icon} alt="" />
                            </div>
                            {LocalizedLanguage.switchusers}
                        </button> : null}
                    <button id="endSessionButton" onClick={() => props.toggleShowEndSession()}>
                        <div className="img-container">
                            <img src={LogOut_Icon} alt="" />
                        </div>
                        {LocalizedLanguage.endsession}
                    </button>
                </div>
                {MyAppExist == true ? 
                 <div className="footer">
                    <div className="row">
                    <p>{LocalizedLanguage.myapps}</p>
                    </div>
                    <div className="button-row">
                        {allAppList && allAppList.length > 0 ? allAppList.map((Items, index) => {
                            { return (Items.viewManagement && Items.viewManagement !== [] && (Items.viewManagement.find(item=> item.ViewSlug=="User View"))) ?
                                    <button onClick={() => props.ToggleiFrameWindow(Items)} key={Items.Id}>
                                            {Items.logo !== null ? <img src={Items.logo}  alt="" onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = NoImageAvailable;
                                            }}/> : <img src={NoImageAvailable} alt="" />}
                                    </button>
                                    : null
                            }
                        }) : <button> <img src={NoAppsMessage} alt="" /></button>}
                    </div>
                </div>
                : ""}
            </div>
        </div>)
}

export default UserInfo 