import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
import { product } from "../../dashboard/product/productSlice";
import { addtoCartProduct } from "../../dashboard/product/productLogic";
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { isAutoFocus } from "../../../settings/AndroidIOSConnect";
const OrderNote = (props) => {
    const refTextArea = useRef();
    const [note, setNote] = useState((props.hasNote && props.hasNote.item && props.hasNote.item.Title != "") ? props.hasNote.item.Title : '');
    const dispatch = useDispatch();
    const location = useLocation();
    const handleNote = () => {
        if (location.pathname !== "/refund" && note != "") {
            var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];//this.state.cartproductlist;
            cartlist = cartlist == null ? [] : cartlist;

            if (props.hasNote && props.hasNote.item && props.hasNote.item.Title != "" && props.hasNote.hasOwnProperty("index")) {
                var tempItem = cartlist[props.hasNote.index];
                if (tempItem && typeof tempItem !== "undefined") {
                    var indexPos = cartlist.indexOf(tempItem);
                    if (indexPos > -1) {
                        tempItem["Title"] = note;
                        cartlist[indexPos] = tempItem;
                    }
                }
            }
            else {
                cartlist.push({ "Title": note, "IsTicket": false })
            }

            addtoCartProduct(cartlist);
            var list = localStorage.getItem('CHECKLIST') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null;
            if (list != null) {
                const CheckoutList = {
                    ListItem: cartlist,
                    customerDetail: list.customerDetail,
                    totalPrice: list.totalPrice,
                    discountCalculated: list.discountCalculated,
                    tax: list.tax,
                    subTotal: list.subTotal,
                    TaxId: list.TaxId,
                    order_id: list.order_id !== 0 ? list.order_id : 0,
                    showTaxStaus: list.showTaxStaus,
                    _wc_points_redeemed: list._wc_points_redeemed,
                    _wc_amount_redeemed: list._wc_amount_redeemed,
                    _wc_points_logged_redemption: list._wc_points_logged_redemption
                }
                localStorage.setItem('CHECKLIST', JSON.stringify(CheckoutList))
            }
            dispatch(product());
            setNote('');
            props.toggleOrderNote();

        }
        else if (location.pathname === "/refund" && note != "") {
            var refundOrder = localStorage.getItem('getorder') ? JSON.parse(localStorage.getItem('getorder')) : '';
            var refundNote = { "Title": note, "refundnote": true }
            var refundItem = refundOrder.line_items
            var refundOrderNote = refundOrder.order_notes
            refundOrderNote.push(refundNote)
            refundItem.push(refundNote)
            if (refundItem != null) {
                const orderDetails = {
                    order_id: refundOrder.order_id,
                    order_status: refundOrder.order_status,
                    customer_note: refundOrder.customer_note,
                    customer_id: refundOrder.customer_id,
                    total_tax: refundOrder.total_tax,
                    total_amount: refundOrder.total_amount,
                    tax_refunded: refundOrder.tax_refunded,
                    refunded_amount: refundOrder.refunded_amount,
                    discount: refundOrder.discount,
                    order_include_tax: refundOrder.order_include_tax,
                    cash_rounding_amount: refundOrder.cash_rounding_amount,
                    refund_cash_rounding_amount: refundOrder.refund_cash_rounding_amount,
                    quantity_refunded: refundOrder.quantity_refunded,
                    RegisterName: refundOrder.RegisterName,
                    LocationName: refundOrder.LocationName,
                    ServedBy: refundOrder.ServedBy,
                    ShopName: refundOrder.ShopName,
                    RefundedNotes: refundOrder.RefundedNotes,
                    ManagerId: refundOrder.manager_id,
                    ManagerName: refundOrder.ManagerName,
                    OrderDateTime: refundOrder.OrderDateTime,
                    date_time: refundOrder.date_time,
                    create_date: refundOrder.create_date,
                    complete_date: refundOrder.complete_date,
                    time_zone_type: refundOrder.time_zone_type,
                    time_zone: refundOrder.time_zone,
                    orderCustomerInfo: refundOrder.orderCustomerInfo,
                    order_notes: refundOrderNote,
                    // Notes
                    line_items: refundItem,

                    order_taxes: refundOrder.order_taxes,
                    billing_address: refundOrder.billing_address,
                    shipping_address: refundOrder.shipping_address,
                    order_custom_fee: refundOrder.order_custom_fee,
                    order_payments: refundOrder.order_payments,
                    order_Refund_payments: refundOrder.order_Refund_payments,
                    order_meta: refundOrder.order_meta,
                    TicketDetails: refundOrder.TicketDetails,
                    OliverReciptId: refundOrder.OliverReciptId,
                    meta_datas: refundOrder.meta_datas
                }
                localStorage.setItem('getorder', JSON.stringify(orderDetails))
            }
            setNote('');
            props.toggleOrderNote();
        }
    }




    useEffect(() => {
        if (refTextArea) {
            if (isAutoFocus() == true)
            refTextArea && refTextArea.current && refTextArea.current.focus();
        }
    }, [props])
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleOrderNote();
        }
    }
    return (
        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className={props.isShow === true ? "subwindow add-order-note current" : "subwindow add-order-note"}>
                <div className="subwindow-header">
                    <p>{LocalizedLanguage.addordernote}</p>
                    <button className="close-subwindow" onClick={() => props.toggleOrderNote()}>
                        <img src={X_Icon_DarkBlue} alt="" />
                    </button>
                </div>
                <div className="subwindow-body">
                    <div className="auto-margin-top"></div>
                    <label htmlFor="orderNote">{LocalizedLanguage.Enteranoteforthisorder}:</label>
                    <textarea ref={refTextArea} name="order-note" id="orderNote" placeholder={LocalizedLanguage.addnotetoorder} defaultValue={note} onChange={(e) => setNote(e.target.value)}></textarea>
                    <button onClick={() => handleNote()}>{LocalizedLanguage.addNote}</button>
                    <div className="auto-margin-bottom"></div>
                </div>
            </div>
        </div>)
}

export default OrderNote 