import React from "react";
import LockedIcon from '../../../assets/images/svg/LockedIcon.svg';
const PermissionPopup = (props) => {
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            closePopUp();
        }
    }
    const closePopUp = () => {
        props.togglePermissionPopup && props.togglePermissionPopup()
    }
    return (
        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className={props.isShow === true ? "subwindow no-permissions current" : "subwindow no-permissions"}>
                <div className="subwindow-body">
                    <img src={LockedIcon} alt="" />
                    <p className="style1">
                        You do not have permission <br />
                        to access this feature
                    </p>
                    <p className="style2">
                        It looks like you do not have permission to <br />
                        access this feature. Role permissions can <br className="mobile" />
                        be <br className="landscape" />
                        managed through the HUB settings.
                    </p>
                    <button id="noPermissionExit" onClick={()=>closePopUp()}>Go Back</button>
                </div>
            </div>
        </div>)
}

export default PermissionPopup 