import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
import Pencil_Blue from '../../../assets/images/svg/Pencil-Blue.svg';
import { useDispatch, useSelector } from "react-redux";
import { getInventory, updateInventory } from '../slices/inventorySlice'
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { isAutoFocus } from "../../../settings/AndroidIOSConnect";

const AdjustInventory = (props) => {
    const inputInventory = useRef(null)
    const [inventory, setInventory] = useState(props.productStockQuantity)
    const [isAllowUpdate, setIsAllowUpdate] = useState(false);
    const [inventoryStatus] = useSelector((state) => [state.inventories])
 
    const dispatch = useDispatch()
       
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            console.log("AdjustInventory")

            props.toggleAdjustInventory(false);
        }
    }

    var warehouseDetail = inventoryStatus && inventoryStatus.inventoryGet && inventoryStatus.inventoryGet.data && inventoryStatus.inventoryGet.data.content
    var CurrentWarehouseId = localStorage.getItem("WarehouseId");
    var currentWareHouseDetail = "";
    if (warehouseDetail && warehouseDetail.length > 0) {
        currentWareHouseDetail = warehouseDetail.find(item => item.warehouseId == CurrentWarehouseId)

    }
    let useCancelled = false;
    useEffect(() => {
        if (useCancelled == false) {
           //update index DB........................
            if (inventoryStatus && inventoryStatus.inventoryUpdate && inventoryStatus.inventoryUpdate.is_success == true) {
                dispatch(getInventory(props.product.WPID));
                props.fatchUpdateInventory && props.fatchUpdateInventory();
                props.toggleAdjustInventory && props.toggleAdjustInventory(false); //close inventory popup
                dispatch(updateInventory("clear"));
                return;
            }
            return () => {
                useCancelled = true;
            }
        }
    }, [inventoryStatus])
    //function for call api request to upddate inventory into warehouse. 
    const handleUpdateInventory = () => {       
        var data = {
            quantity: inventory == '' ? 0 : inventory,
            wpid: (props.product) ? props.product.WPID : props.product ? props.product.WPID : '',
            WarehouseId: CurrentWarehouseId
        }       
        dispatch(updateInventory(data));
      
    }

    const handleInventoryChange = (e) => {
        const { value } = e.target;
        const re = /^[0-9]+$/
        if (value == '' || re.test(value)) {
            setInventory(value)
        }
    }
    const allowUpdate = () => {
        setIsAllowUpdate(true);
        setTimeout(() => { //delay for status update and sure that input is not disable.
            if (isAutoFocus() == true)
            inputInventory && inputInventory.current && inputInventory.current.focus();
        }, 100);

    }

    var isOutOfStock = currentWareHouseDetail && currentWareHouseDetail.Quantity > 0 ? false : true;
    return (
        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className={props.isShow === true ? "subwindow adjust-inventory current" : "subwindow adjust-inventory"}>
                <div className="subwindow-header">
                    <p>{LocalizedLanguage.adjustInven}</p>
                    <button className="close-subwindow" onClick={() => props.toggleAdjustInventory()}>
                        <img src={X_Icon_DarkBlue} alt="" />
                    </button>
                </div>
                <div className="subwindow-body">
                    <div className="auto-margin-top"></div>
                    <p>{LocalizedLanguage.currentwarehouse}</p>
                    <label htmlFor="productStock">{LocalizedLanguage.currently} {isOutOfStock == true ? <>{LocalizedLanguage.outof}</> : <>{LocalizedLanguage.in}</>} {LocalizedLanguage.stock}:</label>
                    <input type="text" min="0" id="productStock"  ref={inputInventory} value={inventory!==-1?inventory:0} disabled={isAllowUpdate == true ? false : true} onChange={(e) => handleInventoryChange(e)} />
                    <button id="editStockButton" onClick={() => allowUpdate()}>
                        <img src={Pencil_Blue} alt="" />
                        {LocalizedLanguage.clicktoedit}
                    </button>
                    <button id="updateStockButton" onClick={() => handleUpdateInventory()}>{LocalizedLanguage.inventoryUpdate}</button>
                    <p>{LocalizedLanguage.otherwarehouses}</p>
                    {warehouseDetail && warehouseDetail.length > 0 &&
                        warehouseDetail.map(item => {
                            if (item.warehouseId !== parseInt(CurrentWarehouseId)) {
                                return <div className="text-row" key={item.warehouseId}>
                                    <p><b>{item.warehouseName}</b></p>
                                    <p><b>{item.Quantity}</b> {LocalizedLanguage.instock}</p>
                                </div>
                            }
                        })
                    }
                    <div className="auto-margin-bottom"></div>
                </div>
            </div></div >)
}

export default AdjustInventory 