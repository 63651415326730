import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import STATUSES from "../../constants/apiStatus";
import { showSubTitle, showTitle, getInclusiveTaxType } from "../../settings/CommonModuleJS";
import { FormateDateAndTime } from '../../settings/FormateDateAndTime';
import { useIndexedDB } from 'react-indexed-db';
// import { NumericFormat } from 'react-number-format';
import { useNavigate } from "react-router-dom";
import OnlineSale from '../../assets/images/svg/OnlineSale.svg'
import InStoreSale from '../../assets/images/svg/InStoreSale.svg'
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { v4 as uniqueKey } from 'uuid';
import { addModiferTaxToLineItemTax, decodeHTML, getChangeAmount, getCustomFee, getDiscountPerItem, getModifierFromCustomFee, getOrderNotes, getProductNotes, getTaxesPerItem, getWCDiscount, get_order_oliverpos_product_discount_amount_meta_data } from "../../settings/CommonWork";
import RefundDetail from "./RefundDetail";
import { currecyFunction } from "../common/commonFunctions/currencyFunction";
import KeysDisplayOrderStatus from "../../settings/KeysDisplayOrderStatus"
import Pencil_Outline_Blue from "../../assets/images/svg/Pencil-Outline-Blue.svg";
import UpdateOrderStatus from "../common/commonComponents/UpdateOrderStatus";
import { get_UDid } from "../common/localSettings";
import { updateOrderStatus } from "../common/commonAPIs/updateOrderStatusSlice";
import { LoadingModal } from "../common/commonComponents/LoadingModal";
import NoImageAvailable from '../../assets/images/svg/NoImageAvailable.svg';
import CommonJs from "../../settings/CommonJS";
const ActivityOrderDetail = (props) => {
    const { getByID } = useIndexedDB("products");
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [activityOrderDetails, setActivityOrderDetails] = useState([])
    const [pImages, setpImages] = useState([])
    // Getting Response from activitygetDetail Api
    const [cashChangeDetail, setCashChangeDetail] = useState({})
    const [activitygetdetails] = useSelector((state) => [state.activityGetDetail]);
    const [isShowUpdateOrderStatus, setIsShowUpdateOrderStatus] = useState(false);
    const [tempOrderStatus, setTempOrderStatus] = useState('completed');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const toggleUpdateOrderStatus = () => {
        setIsShowUpdateOrderStatus(!isShowUpdateOrderStatus);
    }
    const updateStatus = (_orderStatus) => {
        setError("")
        setTempOrderStatus(_orderStatus);
        if (props.updateActivityId) {
            var option = { "udid": get_UDid(), "orderId": props.updateActivityId, "status": _orderStatus }
            setIsLoading(true);
            dispatch(updateOrderStatus(option));
        }
    }
    const [respupdateOrderStatus] = useSelector((state) => [state.updateOrderStatus])
    useEffect(() => {
        if ((respupdateOrderStatus && respupdateOrderStatus.status == STATUSES.IDLE && respupdateOrderStatus.is_success && isLoading == true)) {
            //console.log("--respupdateOrderStatus--" + JSON.stringify(respupdateOrderStatus));
            toggleUpdateOrderStatus();
            setIsLoading(false);
            props.applyServerFilter && props.applyServerFilter(true);
        }
        else if ((respupdateOrderStatus && respupdateOrderStatus.status == STATUSES.ERROR && respupdateOrderStatus.is_success == false && isLoading == true)) {
            setError(respupdateOrderStatus.error)
            setIsLoading(false);
        }
    }, [respupdateOrderStatus]);
    useEffect(() => {
        if (activitygetdetails && activitygetdetails.status == STATUSES.IDLE && activitygetdetails.is_success && activitygetdetails.data && activitygetdetails.data.content && activitygetdetails.data.content.line_items) {

            var lineitems = activitygetdetails.data.content.line_items;
            var images = [];
            var count = 0;
            lineitems.map(item => {
                const id = item.product_id ? item.product_id : item.WPID ? item.WPID : item.Product_Id;
                id && getByID(id).then(a => {
                    count++;
                    if (typeof a != "undefined" && a != null && a.hasOwnProperty('ProductImage')) {
                        images.push({ id: id, image:  a.ProductImage != "False" && a.ProductImage != "" ? a.ProductImage: NoImageAvailable});
                    }else{
                        images.push({ id: id,NoImageAvailable})
                    }
                    if (count == lineitems.length) {
                        setpImages(images);
                    }
                });
            })
            setActivityOrderDetails(activitygetdetails && activitygetdetails.data.content);
            var change_amount = getChangeAmount(activitygetdetails && activitygetdetails.data.content.meta_datas);
            setCashChangeDetail(change_amount);
        }
    }, [activitygetdetails]);
    let data = activityOrderDetails && activityOrderDetails !== '' ? activityOrderDetails : ''

    var DateTime = activityOrderDetails
    var gmtDateTime = "";
    if (DateTime && DateTime.OrderDateTime && DateTime.time_zone) {
        gmtDateTime = FormateDateAndTime.formatDateAndTime(DateTime.OrderDateTime, DateTime.time_zone)
    }
    var Customerdata = activityOrderDetails && activityOrderDetails.orderCustomerInfo ? activityOrderDetails.orderCustomerInfo : ''

    const OpenCustomer = (data) => {
        if (data.orderCustomerInfo.customer_email !== '') {
            sessionStorage.setItem("customerredirect", data.orderCustomerInfo.customer_email ? data.orderCustomerInfo.customer_email : "");
            navigate('/customers')
        }
    }

    var _totalProductIndividualDiscount = 0;
    var _indivisualProductDiscountArray = [];
    var _indivisualProductCartDiscountArray = [];
    var _tempTotalProductIndividualDiscount = [];
    var _tempIndivisualProductCartDiscountArray = [];
    var _tempProductIndividualDiscount = 0;
    const reCalculateDiscount = (item, LineItem = []) => {

        var price = 0;
        //var LineItem = activityOrderDetails && activityOrderDetails.line_items;
        //var lineitems = activityOrderDetails && activityOrderDetails.line_items ? activityOrderDetails.line_items : [];
        //if (LineItem.length > 0 && typeof activityOrderDetails.meta_datas != "undefined") {
        //   LineItem = getModifierFromCustomFee(LineItem, activityOrderDetails.meta_datas, activityOrderDetails.order_custom_fee);
        //}
        LineItem && LineItem.map(x => {
            if ((x.product_id === item.product_id || x.parent_id === item.product_id) /*&& (x.quantity - Math.abs(x.quantity_refunded)) > 0*/
                && (x.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? x.modifiers_key == item.modifiers_key : true)
            ) {
                price = item.Price;
                if (item.product_discount_amount) {  //getting the product discount amount
                    _tempProductIndividualDiscount = (item.product_discount_amount * (item.discount_type == "Percentage" ? x.quantity/*(x.quantity + (x.quantity_refunded ? x.quantity_refunded : 0))*/ : 1))
                    price = item.old_price * x.quantity;//(x.quantity + (x.quantity_refunded ? x.quantity_refunded : 0));
                    if (item.discount_type == "Number") {
                        _tempProductIndividualDiscount = (_tempProductIndividualDiscount / item.quantity) * x.quantity;//(x.quantity + (x.quantity_refunded ? x.quantity_refunded : 0));
                    }
                    price = price - _tempProductIndividualDiscount;
                    _totalProductIndividualDiscount += _tempProductIndividualDiscount;

                    _indivisualProductDiscountArray.push({ "ProductId": item.variation_id && item.variation_id !== 0 ? item.variation_id : item.product_id, "discountAmount": _tempProductIndividualDiscount, "discountType": item.discount_type, "modifiers_key": item.hasOwnProperty("modifiers_key") ? x.modifiers_key : '' })
                }
                let cart_discount = 0;
                if (item.discountCart && item.discountCart.discountType) {
                    if (item.discountCart && item.discountCart.discountType && item.discountCart.discountType == "Percentage") {
                        if (!item.product_discount_amount) {
                            price = item.old_price * x.quantity;//(x.quantity + (x.quantity_refunded ? x.quantity_refunded : 0));
                        }
                        cart_discount = (price * item.discountCart.discount_amount) / 100;
                        _indivisualProductCartDiscountArray.push({ "ProductId": item.variation_id && item.variation_id !== 0 ? item.variation_id : item.product_id, "discountAmount": cart_discount, "discountType": "Percentage", "modifiers_key": item.hasOwnProperty("modifiers_key") ? x.modifiers_key : '' })
                    }
                    else {
                        cart_discount = ((item.cart_discount_amount) / x.quantity) * x.quantity;//(x.quantity + (x.quantity_refunded ? x.quantity_refunded : 0));
                        _indivisualProductCartDiscountArray.push({ "ProductId": item.variation_id && item.variation_id !== 0 ? item.variation_id : item.product_id, "discountAmount": cart_discount, "discountType": "Number", "modifiers_key": item.hasOwnProperty("modifiers_key") ? x.modifiers_key : '' })
                        //item.cart_discount_amount

                    }
                }
            }
        });

    }


    var lineitems = activityOrderDetails && activityOrderDetails.line_items ? activityOrderDetails.line_items : [];
    if (lineitems.length > 0 && typeof activityOrderDetails.meta_datas != "undefined") {
        lineitems = getModifierFromCustomFee(lineitems, activityOrderDetails.meta_datas, activityOrderDetails.order_custom_fee);
    }


    var order_custom_fee = getCustomFee(activityOrderDetails.meta_datas, activityOrderDetails.order_custom_fee);

    if (order_custom_fee && order_custom_fee.length == 0) {
        order_custom_fee = activityOrderDetails && activityOrderDetails.order_custom_fee ? activityOrderDetails.order_custom_fee : [];
    }
    var fee_ids = lineitems && lineitems.map(m => { return m.fee_id });
    order_custom_fee = order_custom_fee && order_custom_fee.filter((item, index) => {
        return (item.hasOwnProperty("fee_id") == true && !fee_ids.includes(item.fee_id))
    })

    var order_notes = activityOrderDetails && activityOrderDetails.order_notes ? activityOrderDetails.order_notes : [];

    var subtotal = 0.0;
    // var getorderlist = activityOrderDetails && activityOrderDetails.meta_datas && activityOrderDetails.meta_datas !== null ? activityOrderDetails.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount') : null;
    // if (getorderlist !== null) {
    //     getorderlist = getorderlist && getorderlist.ItemValue && JSON.parse(getorderlist.ItemValue);
    // }
    var getorderlist = get_order_oliverpos_product_discount_amount_meta_data(activityOrderDetails.meta_datas, true)
    var taxInclusiveName = "";
    if (activityOrderDetails && activityOrderDetails && activityOrderDetails.meta_datas) {
        taxInclusiveName = getInclusiveTaxType(activityOrderDetails.meta_datas);
    }

    //----------------------------------
    _indivisualProductDiscountArray = [];
    _indivisualProductCartDiscountArray = [];

    if (getorderlist != null) {
        getorderlist.map((item, index) => {
            if ((item.product_discount_amount && item.product_discount_amount != 0) || (item.cart_discount_amount && item.cart_discount_amount != 0)) {
                reCalculateDiscount(item, lineitems);
            }
        });
    }

    var taxType = "";
    if (lineitems !== null && lineitems !== "" && getorderlist !== null) {
        getorderlist && getorderlist.map((item, index) => {
            if (item.taxType != null) {
                taxType = item.taxType;
            }
        });
    }
    var _discount = 0;
    if (
        _indivisualProductCartDiscountArray &&
        _indivisualProductCartDiscountArray.length > 0
    ) {
        _indivisualProductCartDiscountArray &&
            _indivisualProductCartDiscountArray.map((x) => {
                _discount += x.discountAmount;
            });
    } else {
        if (
            _indivisualProductDiscountArray &&
            _indivisualProductDiscountArray.length > 0
        ) {
            _discount = 0;
        } else {
            _discount =
                data && data !== "" && data.discount
                    ? data.discount
                    : 0;
        }
    }

    var TotalIndividualProductDiscount = _totalProductIndividualDiscount;
    var totalDiscount = _discount + TotalIndividualProductDiscount;

    if (props.isWebShopOrder == true) {
        totalDiscount = getWCDiscount(activityOrderDetails, totalDiscount, taxType);
    }

    var balance = 0;
    if (props.OrderPayment && props.OrderPayment.length > 0) {
        props.OrderPayment.map(item => {
            balance += parseFloat(item.amount)
        })
    }
    var remaining_balance = (parseFloat(props.Subtotal) + parseFloat(props.TotalTax)) - parseFloat(balance);

    //WHEN CASH_ROUND AMOUNT IS MORE THAN 1 THEN ADJUSTING THE CALCULATION 28/08/23
    var _total_amount = props.TotalAmount;
    if (props.cash_round && props.cash_round > 1 && activityOrderDetails.order_status === "completed") {
        if (remaining_balance > 0 && parseFloat(remaining_balance).toFixed(2) == parseFloat(props.cash_round).toFixed(2)) {
            remaining_balance = remaining_balance - props.cash_round;
            _total_amount = _total_amount - props.cash_round;
        }
    }
    //END
    var gmtDateTime = "";
    if (activityOrderDetails && activityOrderDetails.OrderDateTime && activityOrderDetails.time_zone) {
        gmtDateTime = FormateDateAndTime.formatDateAndTime(activityOrderDetails.OrderDateTime, activityOrderDetails.time_zone)
    }
    var CreateDate = activityOrderDetails.CreatedDate && activityOrderDetails.CreatedDate !== 'Invalid date' ? activityOrderDetails.CreatedDate : gmtDateTime == "" ? "" : gmtDateTime;

    function Capitalize(val) {
        var str = val ? val.replace(/_/g, "\u00a0") : "";
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    var _cartDiscount = 0.0;
    var Order_cartDiscount;

    var subtotal = 0;
    order_custom_fee && order_custom_fee.map(m => {
        if (m.hasOwnProperty("pid") == false || (m.hasOwnProperty("pid") == true && typeof m.pid == "undefined")) {
            subtotal += m.amount;
        }
    })
    var order_reciept = localStorage.getItem('orderreciept') && localStorage.getItem('orderreciept') !== 'undefined' ? JSON.parse(localStorage.getItem('orderreciept')) : "";
    var orderStatus = activityOrderDetails && activityOrderDetails.order_status ? (
        activityOrderDetails.refunded_amount !== 0 && activityOrderDetails.total_amount !== activityOrderDetails.refunded_amount ? 'partial refund' : KeysDisplayOrderStatus.key[activityOrderDetails.order_status]) : null
    var lineItem_cartDiscountType = '';

    var shippingTotal = 0
    // props.shippingLines && props.shippingLines.map(value =>{
    //     if(value.total_in_decimal && value.total_in_decimal !== null){
    //         shippingTotal += value.total_in_decimal
    //     }
    // })

    if (props.shipping_tax || props.total_shipping) {
        shippingTotal = (props.shipping_tax ? props.shipping_tax : 0) + (props.total_shipping ? props.total_shipping : 0);
    }
    //GETTING old_price FROM META 07JULY2023
    var logLineItemData = [];
    if (getorderlist) {
        getorderlist.map(prd => {
            if ((prd.product_id && prd.product_id != 0) || prd.variation_id) {
                logLineItemData.push(prd)
            }
        })
    }
    //------
    return (
        <>
            {isLoading == true && props.isFreePlan==false  ? <LoadingModal></LoadingModal> : null}
            <div className="scrollable">
                <div className={orderStatus && orderStatus.toLowerCase()?.includes("refund") ? "quick-info refund" : "quick-info"}>
                    <div className="row">
                        <div className="group">
                            <img src={activityOrderDetails != "" && activityOrderDetails.OliverReciptId !== '' ? InStoreSale : OnlineSale} alt="" />
                            <p>{LocalizedLanguage.Order} #{activityOrderDetails && activityOrderDetails.order_id}</p>
                        </div>
                        <div className="group" onClick={() => toggleUpdateOrderStatus()}>
                            <p className={orderStatus && orderStatus.toLowerCase()?.includes("refund") ? "style1 red textcapital" : "style1 textcapital"} >{orderStatus}</p>
                            <img src={Pencil_Outline_Blue} id="edit-order-status" alt="" />
                        </div>
                    </div>
                    <div className="row">
                        {activityOrderDetails.ServedBy ? <p className="style2">{LocalizedLanguage.servedby}: {activityOrderDetails.ServedBy}</p> : <p className="style2"></p>}
                        <p className="style2">{CreateDate}</p>
                    </div>
                    <div className="row">
                        <p className="style3">
                            {LocalizedLanguage.total}: <b>{
                                (activityOrderDetails && activityOrderDetails.refunded_amount > 0) ? <>{currecyFunction(parseFloat(activityOrderDetails.total_amount - activityOrderDetails.refunded_amount).toFixed(2))} <del>{currecyFunction(parseFloat(activityOrderDetails && activityOrderDetails.total_amount).toFixed(2))}</del></> : currecyFunction(parseFloat(activityOrderDetails && activityOrderDetails.total_amount).toFixed(2))
                            }</b>
                        </p>
                    </div>
                </div>
                {activityOrderDetails.orderCustomerInfo !== '' && activityOrderDetails.orderCustomerInfo !== null &&
                    <div className="customer-info">
                        <div className="col">
                            {activityOrderDetails.orderCustomerInfo !== '' && activityOrderDetails.orderCustomerInfo !== null ? <>  <p className="style1">{LocalizedLanguage.customerInfo}</p></> : null}
                            <p className="style2">{Customerdata && Customerdata.customer_name ? Customerdata.customer_name : Customerdata && Customerdata.customer_first_name}</p>
                            <p className="style2">{Customerdata && Customerdata.customer_email ? Customerdata.customer_email : ''}</p>
                            <p className="style2">{Customerdata && Customerdata.customer_phone ? Customerdata.customer_phone : ''}</p>
                        </div>
                        {activityOrderDetails.orderCustomerInfo !== '' && activityOrderDetails.orderCustomerInfo !== null ? <button id="openCustomerButton" onClick={() => OpenCustomer(activityOrderDetails)}>{LocalizedLanguage.opencustomer}</button> : ""}
                    </div>}

                <div className="order-details">
                    <p>{LocalizedLanguage.orderDetails}</p>
                    {
                        lineitems && lineitems.map((item, index) => {
                            var modifers = lineitems.filter(a =>
                                (a.hasOwnProperty("pid") && a.pid == (item.product_id ? item.product_id : item.WPID ? item.WPID : item.Product_Id) && a.hasOwnProperty("is_modi") && a.is_modi == true)
                                && ((a.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? a.modifiers_key == item.modifiers_key : true) || (!a.hasOwnProperty("modifiers_Id") && !item.hasOwnProperty("modifiers_Id")))
                            );
                            //  if (item.hasOwnProperty("product_id") && item.hasOwnProperty("variation_id")) {
                            var Total_IndividualProductDiscount = 0.0;
                            var lineItem_DiscountDetail = getDiscountPerItem(activityOrderDetails, 'activity', item.product_id, item)
                            var lineitem_cartDiscount = 0;

                            var lineitem_Discount = lineItem_DiscountDetail && lineItem_DiscountDetail.discountAmount ? lineItem_DiscountDetail.discountAmount : 0;
                            Total_IndividualProductDiscount += lineitem_Discount;
                            if (lineItem_DiscountDetail && lineItem_DiscountDetail.discountCart) {
                                lineitem_cartDiscount = parseFloat(lineItem_DiscountDetail.cartItemDiscount);
                                _cartDiscount += lineitem_cartDiscount;
                                Order_cartDiscount = lineItem_DiscountDetail.discountCart;
                                var _discountType = Order_cartDiscount ? (Order_cartDiscount.discountType == "Number" ? "" : "%") : "%";
                                lineItem_cartDiscountType = lineItem_DiscountDetail.discountCart && _discountType == "%" ? '(' + parseFloat(lineItem_DiscountDetail.discountCart.discount_amount) + _discountType + ')' : ''
                                // lineItem_cartDiscountType = lineItem_DiscountDetail.discountCart ? '(' + parseFloat(lineItem_DiscountDetail.discountCart.discount_amount) + '%)' : ''
                            }
                            var lineitem_Dis_Percent = lineItem_DiscountDetail && lineItem_DiscountDetail.discountApply && lineItem_DiscountDetail.discounttype == "Number" ? "" : lineItem_DiscountDetail && lineItem_DiscountDetail.discountApply && "(" + lineItem_DiscountDetail.discountApply + "%)";
                            var DisplayTotalSplitTax = getTaxesPerItem(order_reciept, 'activity', activityOrderDetails, item);

                            var productImage = NoImageAvailable;
                            var IndexImage = pImages.find(a => a.id == (item.product_id ? item.product_id : item.WPID ? item.WPID : item.Product_Id));
                            if (typeof IndexImage != "undefined" && IndexImage != null) {
                                productImage = IndexImage.image;

                            }
                            var sku = item.sku ? item.sku : ''
                            var varDetail = item.ProductSummery && item.ProductSummery.toString();
                            ///Composit Child product------------------------
                            if ((item.composite_parent_key && item.composite_parent_key !== "") || (item.bundled_parent_key && item.bundled_parent_key !== "")) { //remove child composit product
                                return <React.Fragment></React.Fragment>
                            } else if (item.composite_product_key && item.composite_parent_key == "") {
                                var compositChield = []
                                lineitems && lineitems.map(compositItem => {
                                    if (compositItem.composite_parent_key && compositItem.composite_parent_key == item.composite_product_key) {
                                        compositChield.push(compositItem.name)
                                    }
                                })
                                varDetail = compositChield.join(', ')
                            } else if (item.bundle_product_key && item.bundled_parent_key == "") { //for bundle Product
                                var compositChield = []
                                lineitems && lineitems.map(compositItem => {
                                    if (compositItem.bundled_parent_key && compositItem.bundled_parent_key == item.bundle_product_key) {
                                        compositChield.push(compositItem.name)
                                    }
                                })
                                varDetail = compositChield.join(', ')
                            }
                            //-----------------------------------------------
                            //var _cart_discount_type = "";
                            var _paroduct_discount_type = "";
                            var _productCartDiscountAmount = 0;
                            if (_indivisualProductCartDiscountArray && _indivisualProductCartDiscountArray.length > 0) {
                                _indivisualProductCartDiscountArray && _indivisualProductCartDiscountArray.map(x => {
                                    if (x.ProductId === item.product_id
                                        && (x.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? x.modifiers_key == item.modifiers_key : true)
                                    ) {
                                        _productCartDiscountAmount = x.discountAmount;
                                    }
                                    // else {
                                    //     _productCartDiscountAmount = 0;
                                    // }
                                    //_cart_discount_type = x.discountType;
                                });
                            }
                            var _indivisualDiscount = 0;
                            if (_indivisualProductDiscountArray && _indivisualProductDiscountArray.length > 0) {
                                _indivisualProductDiscountArray && _indivisualProductDiscountArray.map(x => {
                                    if ((x.ProductId === item.product_id || x.parent_id === item.product_id) &&
                                        (x.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? x.modifiers_key == item.modifiers_key : true)
                                    ) {
                                        _indivisualDiscount = x.discountAmount;
                                        _paroduct_discount_type = x.discountType;
                                    }
                                    // else
                                    // {
                                    //     _indivisualDiscount = 0;
                                    //     _paroduct_discount_type = 0;
                                    // }

                                });
                            }
                            //logitem.old_price * item.quantity : (item.total + ((taxInclusiveName == "incl") ? item.total_tax : 0));

                            var qty = item.hasOwnProperty("is_modi") == false ? item.quantity : 1;//+ item.quantity_refunded;
                            var _single_amount = ((
                                (item.hasOwnProperty("is_modi") == false ? (item.subtotal + (taxType == "incl" ? _indivisualDiscount : 0)) : item.amount)
                                + (taxType == "incl" && item.hasOwnProperty("is_modi") == false ? _indivisualDiscount == 0 ? (taxType == "incl" ? item.subtotal_tax : 0) : 0 : 0)) / qty);
                            if (_productCartDiscountAmount == 0 && _indivisualDiscount > 0) {
                                _single_amount = ((item.subtotal + (taxType == "incl" ? item.subtotal_tax : 0)) / qty);
                            }
                            if (_productCartDiscountAmount !== 0 && _indivisualDiscount !== 0) {
                                _single_amount = ((item.subtotal + (taxType == "incl" ? item.subtotal_tax : 0)) / qty);// + lineitem_Discount + lineitem_cartDiscount;
                            }
                            var _amount = _single_amount * qty;
                            var product_amount = _single_amount * qty;
                            var product_line_total = (_single_amount * qty) - _productCartDiscountAmount - _indivisualDiscount;
                            _amount += _productCartDiscountAmount - _indivisualDiscount;
                            //subtotal += _single_amount * qty;
                            //CALULATING product_amount BASIS OF old_price FROM META 07JULY2023   
                            if (logLineItemData && logLineItemData.length > 0) {
                                var logitem = logLineItemData && logLineItemData.find(litem => { return ((litem.variation_id ? litem.variation_id : litem.product_id) == item.product_id) && ( !litem.hasOwnProperty("modifiers_Id") || (litem.hasOwnProperty("modifiers_Id") && item.hasOwnProperty("modifiers_Id") && JSON.stringify(litem.modifiers_Id) == JSON.stringify(item.modifiers_Id)) ) 
                            })
                                if (typeof logitem != "undefined" && logitem != null) {
                                    product_amount = logitem ? (taxInclusiveName == "incl" ? logitem.old_price * item.quantity : logitem.Price) : (item.total + ((taxInclusiveName == "incl") ? item.total_tax : 0));
                                    product_line_total = product_amount - _productCartDiscountAmount - _indivisualDiscount;
                                    subtotal += product_amount;
                                }
                                else {
                                    subtotal += _single_amount * qty;
                                }

                            }
                            else {
                                subtotal += _single_amount * qty;
                            }
                            if (isNaN(subtotal)) {
                                subtotal = parseFloat(parseFloat(activityOrderDetails.discount) + parseFloat(activityOrderDetails.total_amount) - parseFloat(taxInclusiveName == '' ? activityOrderDetails.total_tax : 0)).toFixed(2);
                            }
                            //}
                            //----
                            //var product_line_total = (_single_amount * qty);
                            // - _productCartDiscountAmount - _indivisualDiscount;

                            // if (_cart_discount_type == "Percentage") {
                            //     product_line_total = (_single_amount - _productCartDiscountAmount) * qty - _indivisualDiscount;
                            // }
                            // else 
                            // {
                            //product_line_total = (_single_amount * qty) - _productCartDiscountAmount - _indivisualDiscount;
                            // }

                            var total_tax = 0;
                            taxInclusiveName == "" && DisplayTotalSplitTax && DisplayTotalSplitTax.map(t => {
                                total_tax += parseFloat(t.value);
                            })
                            if (taxType !== "incl") {
                                product_line_total = product_line_total + total_tax;
                            }
                            _amount = parseFloat(_amount) + parseFloat(total_tax);
                            modifers && modifers.map(t => {
                                _amount += parseFloat(t.amount);
                                product_line_total += parseFloat(t.amount);
                                if (taxType !== "incl") {
                                    _amount += t.total_tax;
                                    product_line_total += t.total_tax;
                                }
                                if (t.hasOwnProperty("cart_discount_amount") && t.cart_discount_amount != 0) {
                                    lineitem_cartDiscount += t.cart_discount_amount;
                                    product_line_total -= t.cart_discount_amount;
                                    totalDiscount += t.cart_discount_amount;
                                }
                                if (t.hasOwnProperty("product_discount_amount") && t.product_discount_amount != 0) {
                                    lineitem_Discount += t.product_discount_amount;
                                    product_line_total -= t.product_discount_amount;
                                    totalDiscount += t.product_discount_amount;
                                }
                            })
                            var _result = addModiferTaxToLineItemTax(DisplayTotalSplitTax, modifers)
                            return (
                                item.hasOwnProperty("is_modi") == false ? <>
                                    <div className="item" key={uniqueKey()}>
                                        <div className="img-container">
                                            <div className="quantity">
                                                <p> {
                                                    item.quantity
                                                }</p>
                                            </div>
                                            <img src={productImage ? productImage : NoImageAvailable} alt="" />
                                        </div>
                                        <div className="col">
                                            <div className="main-row">
                                                <p>{showTitle(item) !== "" ? item.name : item.note}
                                                    {showSubTitle(item) !== "" ? <p>{item.name} </p> : null}
                                                    {varDetail ? <p >{varDetail} </p> : null}
                                                </p>
                                                <p>
                                                    {/* {item.quantity > 1 ? <small>{"(" + item.quantity + " x " + parseFloat(_single_amount).toFixed(2) + ")"}</small> : ""}
                                                    <b> {LocalizedLanguage.price}&nbsp;:&nbsp;
                                                        <NumericFormat
                                                            prefix="$"
                                                            value={(product_amount)}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                        />
                                                    </b> */}
                                                    {item.quantity > 1 ? <small>{"(" + item.quantity + " x " + parseFloat(product_amount / item.quantity).toFixed(2) + ")"}</small> : ""}
                                                    <b> {LocalizedLanguage.price}:&nbsp;
                                                        {currecyFunction(product_amount)}
                                                    </b>
                                                </p>
                                            </div>
                                                                                        <div className="item-fields">
                                                {sku ? <p>SKU: {sku.toString()}</p> : ''}
                                            </div>
                                            {modifers && typeof modifers !== "undefined" && modifers.length > 0 &&
                                                <div className="modifier-fields">
                                                    {
                                                        modifers.map(item => {
                                                            return <div className="row">
                                                                {/* <p>{item.note ? item.note : ""}</p> */}
                                                                <p>{item.note ? item.note.replace(/_/g, " ") : ""}</p>
                                                                <p> &nbsp; {currecyFunction(item.amount)}</p>
                                                            </div>
                                                        })
                                                    }
                                                </div>}
                                            {order_notes && typeof order_notes !== "undefined" &&
                                                <div className="modifier-fields">
                                                    {order_notes &&
                                                        getProductNotes(activityOrderDetails.meta_datas, item).map(_item => {
                                                            return <div className="row">
                                                                <p>{LocalizedLanguage.note}</p>
                                                                <p>{_item.note}</p>
                                                            </div>
                                                        })}
                                                </div>
                                            }

                                            <div className="product-total-fields" >
                                                {
                                                    // order_reciept.PercentageDiscountPerItem == true ?
                                                    <div className="discounts">
                                                        {lineitem_Discount != "0.00" && lineitem_Discount > 0 ? <div className="row">
                                                            <p>{LocalizedLanguage.itemDiscount} {lineitem_Dis_Percent}</p>
                                                            <p>{currecyFunction(-lineitem_Discount)} </p>
                                                        </div> : null}
                                                        {lineitem_cartDiscount != "0.00" && lineitem_cartDiscount > 0 ? <div className="row">
                                                            <p>{LocalizedLanguage.cartdiscount} {lineItem_cartDiscountType}</p>
                                                            <p>{currecyFunction(-lineitem_cartDiscount)} </p>
                                                        </div> : null}
                                                    </div>
                                                    // : null
                                                }
                                                {DisplayTotalSplitTax && DisplayTotalSplitTax.length > 0 ? <div className="taxes">
                                                    {
                                                        _result && _result.map(tx => {
                                                            return (
                                                                <div className="row">
                                                                    <p>{tx.tax} {(taxType != "Tax" || taxType != "") ? "(" + taxType + ".)" : ""}</p>
                                                                    <p>
                                                                        {currecyFunction(tx.value)}
                                                                    </p>
                                                                </div>)
                                                        })
                                                    }

                                                </div> : null}
                                                <div className="total">
                                                    <div className="row">
                                                        <p><b>{LocalizedLanguage.producttotal}</b></p>
                                                        <p><b>{currecyFunction(product_line_total)} </b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : ""
                            )
                        })
                    }
                    {<div className="item">
                        <div className="img-container" style={{ height: "unset " }}>
                        </div>
                        <div className="col">
                            {order_custom_fee && order_custom_fee.map(item => {
                                return (item.hasOwnProperty("pid") == false || (item.hasOwnProperty("pid") == true && typeof item.pid == "undefined")) ? <><div className="main-row" key={uniqueKey()}>
                                    <p>{decodeHTML(item.note ? item.note : "")} :</p>
                                    <p>{currecyFunction(item.amount)}</p>
                                </div> <div className="main-row">
                                        <p>{"Tax"} {(taxType != "Tax" || taxType != "") ? "(" + taxType + ".)" : ""}</p>
                                        <p>
                                            {currecyFunction(item.total_tax)}
                                        </p>
                                    </div></> : ""
                            })}

                        </div></div>}
                    {getOrderNotes(activityOrderDetails.meta_datas).map((item, index) => {
                        return (
                            <div className="custom-fields" key={uniqueKey()}>
                                {item.note ? <p className="style2">{LocalizedLanguage.notes}: {item.note ? item.note : ""}</p> : ''}
                            </div>
                        )
                    })}

                    <div className="transaction-totals-wrapper">
                        <div className="transaction-totals">
                            <div className="row">
                                <p>{LocalizedLanguage.subTotal}</p>
                                <p>
                                    {currecyFunction(subtotal)}
                                </p>
                            </div>
                            {shippingTotal && shippingTotal > 0 ? <div className="row">
                                <p>{LocalizedLanguage.shippingAmount}</p>
                                <p>{shippingTotal && currecyFunction(shippingTotal)}</p>
                            </div> : ""}
                            {totalDiscount !== 0 ?
                                <div className="row">
                                    <p>{LocalizedLanguage.totalDiscount /*+ lineItem_cartDiscountType*/}</p>
                                    <p>
                                        {currecyFunction(totalDiscount == 0 ? 0.00 : (-totalDiscount))}
                                    </p>
                                </div> : null}
                            <div className="row" key={uniqueKey()}>
                                <p>{LocalizedLanguage.totalTax + taxInclusiveName} </p>
                                <p><b>

                                    {currecyFunction(activityOrderDetails && (activityOrderDetails.total_tax - (taxType !== "incl" ? props.shipping_tax : 0)))}
                                </b></p>
                            </div>

                            {props.cash_round !== 0 && props.cash_round <= 1 ?
                                <div className="row border-bottom">
                                    <p><b>{LocalizedLanguage.cashRounding}</b></p>
                                    <p>
                                        {currecyFunction(props.cash_round)}
                                    </p>
                                </div> : null}


                            {shippingTotal && shippingTotal > 0 ? <div className="row">
                                <p>Shipping Total</p>
                                <p>{shippingTotal && currecyFunction(shippingTotal)}</p>
                            </div> : ""}

                            <div className="row border-bottom">
                                <p><b className="bold2">{LocalizedLanguage.total} </b></p>

                                <p><b className="bold2">
                                    {currecyFunction(_total_amount)}
                                </b></p>
                            </div>
                            <div className="row">
                                <p className="style2">{LocalizedLanguage.payments}</p>
                            </div>

                            {props.OrderPayment ? props.OrderPayment.map((item, index) => {
                                if (item.type !== null) {
                                    return (
                                        <div className="row" key={uniqueKey()} >
                                            <p>{`${Capitalize(item.type)} (${item.payment_date != null ? FormateDateAndTime.formatDateAndTime(item.payment_date, props.TimeZone) : CreateDate}) `} </p>
                                            <p><b>
                                                {currecyFunction(item.amount)}
                                            </b></p>
                                        </div>
                                    )
                                }
                            }) : null}

                            {cashChangeDetail !== '' && cashChangeDetail.cashPayment != 0 ? (
                                <><div className="row border-bottom">
                                    <p>{`${Capitalize('change')}`} </p>
                                    <p><b>{currecyFunction(cashChangeDetail.change)}</b></p>
                                </div>
                                    <div className="row border-bottom">
                                        <p>{`${Capitalize('cash payment')}`}</p>
                                        <p>{currecyFunction(cashChangeDetail.cashPayment)}</p>
                                    </div></>) : null}
                            <div className="row border-top">
                                <p><b className="bold2">{LocalizedLanguage.balance}</b></p>
                                <p><b className="bold2">{currecyFunction(remaining_balance >= 0 ? parseFloat(remaining_balance).toFixed(2) : parseFloat(props.balence).toFixed(2))} </b></p>
                            </div>
                        </div>
                    </div>
                    <RefundDetail shippingTotal={shippingTotal} activityOrderDetails={activitygetdetails} pImages={pImages} taxType={taxType}></RefundDetail>
                </div>
            </div>
            {isShowUpdateOrderStatus ? <UpdateOrderStatus isShow={isShowUpdateOrderStatus} toggleUpdateOrderStatus={toggleUpdateOrderStatus} updateStatus={updateStatus} orderstatus={orderStatus} page="transaction" error={error}></UpdateOrderStatus> : null}
        </>
    )
}

export default ActivityOrderDetail






