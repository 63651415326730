
exports.key = {  
    completed:"Completed",  
    lay_away:"On Hold",
    pending:"Pending",
    park_sale:"Park",
    void_sale:"Void", // cancelled_sale:"",
    refunded:"Refunded",
    processing:"Processing",
    cancelled_sale:"Cancelled",
}   

// var _orderstatus=[{key:"",value:"All"},
// {key:"pending",value:"Parked"},{key:"on-hold",value:"Lay-Away"},
// {key:"cancelled",value:"Voided"},{key:"refunded",value:"Refunded"},
// {key:"completed",value:"Closed"}];