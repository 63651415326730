import React from "react";
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import GreyAlert from '../../../assets/images/svg/GreyAlert.svg';
import OutOfStock from '../../../assets/images/svg/OutOfStock.svg';
import { useNavigate } from 'react-router-dom';
import { NavigateComponent } from "../../../settings";

const PaymentNotSupportedPopup = (props) => {
    const navigate = useNavigate();
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            if (props.isBack && props.isBack == true) {
                //NavigateComponent(navigate,"home")
            }
            else {
                props.togglePaymentNotSupportedPopup() && props.togglePaymentNotSupportedPopup();
            }

        }
    }
    const goBack=()=>
    {
        if (props.isBack && props.isBack == true) {
            NavigateComponent(navigate,"home")
        }
        else {
            props.togglePaymentNotSupportedPopup() && props.togglePaymentNotSupportedPopup();
        }

    }
    //console.log(props.isShowPaymentNotSupported)
    return (
        <div className={props.isShowPaymentNotSupported === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className={props.isShowPaymentNotSupported === true ? "subwindow upgrade-to-unlock current" : "subwindow upgrade-to-unlock"}>
                <div className="subwindow-body" onClick={(e) => outerClick(e)}>
                    <div className="auto-margin-top"></div>
                    <img src={GreyAlert} alt="" />
                    {/* {props && props.hasOwnProperty("img") == false ? <img src={GreyAlert} alt="" /> : null}
                    {props && props.img == "alert" ? <img src={GreyAlert} alt="" /> : null}
                    {props && props.img == "stock" ? <img src={OutOfStock} alt="" /> : null} */}
                        <p className="style1" style={{ marginBottom:'12px' }}>{LocalizedLanguage.paymentNotSupported}</p>
                        <p className="style2" style={{ paddingLeft: 20, paddingRight: 20 }}>
                          {LocalizedLanguage.thisFeatureIsNotAvailableForDemoUser}
                        </p>
                    
                    <button onClick={() => goBack()}>
                        {LocalizedLanguage.back}
                    </button>
                    <div className="auto-margin-bottom"></div>
                </div>
            </div>
            {/* <div className={props.isShow === true ? "subwindow upgrade-to-unlock current" : "subwindow upgrade-to-unlock"}>
                <div className="subwindow-body">
                    <div className="auto-margin-top"></div>
                    <p className="style1">{props.msgTitle && props.msgTitle}</p>
                    <p className="style2">
                       {props.msgBody && props.msgBody}<br />
                    </p>
                    <button  onClick={() => props.toggleMsgPopup() && props.toggleMsgPopup()}>Go Back</button>
                    <div className="auto-margin-bottom"></div>
                </div>
            </div> */}
        </div>)
}

export default PaymentNotSupportedPopup 