import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addRemoveCash, getDetails } from './CashmanagementSlice'
import moment from 'moment';
import IconDarkBlue from '../../assets/images/svg/X-Icon-DarkBlue.svg'
import { openCashBox } from "../../settings/AndroidIOSConnect";
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { popupMessage } from "../common/commonAPIs/messageSlice";
import MsgPopup from "../common/commonComponents/MsgPopup";
import { currecyFunction } from "../common/commonFunctions/currencyFunction";
function AddRemoveCashPopup(props) {
    const dispatch = useDispatch();
    const [Amount, setAmount] = useState('')
    const [removeAmount, setremoveAmount] = useState('')
    const [Notes, setNotes] = useState('')
    const [isShowMsg, setisShowMsg] = useState(false);
    const [msgTitle, setmsgTitle] = useState('');
    const [msgBody, setmsgBody] = useState('');
    const [warningImg, setWarningImg] = useState('');
    
    const toggleMsgPopup = () => {
        setisShowMsg(!isShowMsg)
    }

      let useCancelled = false;
      useEffect(() => {
        if (useCancelled == false) {
        setNotes("")
        }
        return () => {
          useCancelled = true;
        }
      },[props]);

    const validateHundle = (e,type) => {
        const { value } = e.target;
        if (value === '' ||  value.match(/^\d{1,}(\.\d{0,4})?$/)) {
            if (type == 'add') {
                setAmount(value)
                setremoveAmount("")
                setNotes("")

            }
            if (type == 'remove') {
                setremoveAmount(value)
                setAmount("")
                setNotes("")
            }
        }
        if (type == 'note') {
            setNotes(value)
        }
    }

    

    const handleSubmit = () => {
        var msg = "";
        var title = "";
        if (Amount || removeAmount) {
            if (props.drawerBalance < removeAmount) {

                setmsgTitle(LocalizedLanguage.messageTitle)
                setmsgBody(LocalizedLanguage.cashAmountExceed)
                setisShowMsg(true)
                // title = LocalizedLanguage.messageTitle;
                // msg = LocalizedLanguage.cashAmountExceed;
                // var data = { title: title, msg: msg, is_success: true }
                // dispatch(popupMessage(data));
                return;
            }
        }

        if (Amount > 0 || removeAmount > 0 ) {
            // this.setState({ isloading: true });
            var cashManagementID = localStorage.getItem('Cash_Management_ID');
            var d = new Date();
            var dateStringWithTime = moment(d).format('YYYY-MM-DD HH:mm:ss Z');
            var localTimeZoneType = moment.tz.guess(true);
            var user = JSON.parse(localStorage.getItem("user"));
            var addRemoveParm = {
                "CashManagementId": cashManagementID,
                "AmountIn": Amount,
                "AmountOut": removeAmount,
                "LocalDateTime": dateStringWithTime,
                "LocalTimeZoneType": localTimeZoneType,
                "SalePersonId": user && user.user_id ? user.user_id : '',
                "SalePersonName": user && user.display_name ? user.display_name : '',
                "SalePersonEmail": user && user.user_email ? user.user_email : '',
                "OliverPOSReciptId": '0',
                "Notes": Notes
            }
            //  console.log("addRemoveParm", addRemoveParm)
            dispatch(addRemoveCash(addRemoveParm));
            openCashBox(); //cash drawer opening while add/remove cash
            setTimeout(() => {
                callApi()
            }, 100);
            setAmount("")
            setremoveAmount("")
            setNotes('')
            setTimeout(() => {
                props.HundlePOpupClose()
            }, 300);

        }
    }




    const callApi = () => {
        var Cash_Management_ID = localStorage.getItem('Cash_Management_ID');
        dispatch(getDetails(Cash_Management_ID));
    }


    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleCreateCustomer && props.toggleCreateCustomer();
        }
        else {
            e.stopPropagation();
        }
    }



    useEffect(() =>{
        setAmount('')
        setremoveAmount('')
        document.getElementById('addCashAmount') && document.getElementById('addCashAmount').focus()
        document.getElementById('removeCashAmount') && document.getElementById('removeCashAmount').focus()
    },[props.isShow])
    return (

        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className={props.isShow === true ? "subwindow add-cash current" : "subwindow add-cash"}>
                <div className="subwindow-header">
                    <p>{props.popupstatus.toLowerCase() == 'add' ? <>{LocalizedLanguage.addCash}</> : <>{LocalizedLanguage.removeCash}</>}</p>
                    <button className="close-subwindow" onClick={props.HundlePOpupClose}>
                        <img src={IconDarkBlue} alt="" />
                    </button>
                </div>
                <div className="subwindow-body">
                    <div className="auto-margin-top" />
                    <div className="text-row">
                        <p className="style1">{LocalizedLanguage.currentBalance}</p>
                        {/* <p className="style2">$<NumericFormat value={props.drawerBalance} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></p> */}
                        <p className="style2">{currecyFunction(props.drawerBalance)}</p>
                    </div>
                    <div className="input-row">
                        <label htmlFor="addCashAmount">{props.popupstatus == 'add' ? <>{LocalizedLanguage.addCash}</> : <>{LocalizedLanguage.removeCash}</>}:</label>
                        {props.popupstatus == 'add' ? <input type="text" id="addCashAmount" placeholder={LocalizedLanguage.EnterAmount} onChange={(e) => validateHundle(e,"add")} value={Amount} /> : <input type="text" id="removeCashAmount" placeholder={LocalizedLanguage.EnterAmount} onChange={(e) => validateHundle(e,"remove")} value={removeAmount} />}
                    </div>
                    <label htmlFor="addCashNote">{LocalizedLanguage.addanote}:</label>
                    <textarea id="addCashNote" placeholder={LocalizedLanguage.pleaseaddanotehere} onChange={(e) => validateHundle(e,"note")} defaultValue={""} />
                    <button onClick={() => handleSubmit()}> {LocalizedLanguage.updateBalance}</button>
                    <div className="auto-margin-bottom" />
                </div>
            </div>
            <MsgPopup isShow={isShowMsg} toggleMsgPopup={toggleMsgPopup} msgTitle={msgTitle} msgBody={msgBody} img={warningImg}></MsgPopup>
        </div>

    )
} 

export default AddRemoveCashPopup