import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { useLoginMutation,useGetAllRegisterQuery } from '../../../components/login/loginService';
import { getMenuAPI } from './ameliaAPI';
import STATUSES from '../../../constants/apiStatus';


const initialState = {
  "status": STATUSES.IDLE,
  "data": "",
  "error": '',
  "is_success": false
};


export const getMenu = createAsyncThunk(
  'amelia/getMenuAPI',
  async (parameter, { rejectWithValue }) => {
    // const response =  loginAPI(parameter);
    // // The value we return becomes the `fulfilled` action payload
    // console.log("test",response.json())
    // return response.json();

    try {
     
      const response = await getMenuAPI(parameter);
      // The value we return becomes the `fulfilled` action payload
      return response;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data)
    }

  }
);
export const ameliaSlice = createSlice({
  name: 'getMenu',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // loginPanding:(state)=>{
    //   state.status=STATUSES.LOADING;
    //   state.data=""
    // },
    // loginSuccess:(state,action)=>{
    //   state.status=STATUSES.IDLE;
    //   state.error='';
    //   state.data=action.payload
    // },
    // loginFail:(state,action)=>{
    //   state.status=STATUSES.ERROR;    
    //   state.data="";
    //   state.error=action.payload;
    // }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: () => {}
  extraReducers: (builder) => {
    builder
      .addCase(getMenu.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(getMenu.fulfilled, (state, action) => {
        state.status = action.payload && action.payload.IsSuccess === true ? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload.IsSuccess === true ? action.payload : "");
        state.error = action.payload && action.payload.IsSuccess === false ? (action.payload.hasOwnProperty("message") ? action.payload.message : action.payload.error) : action.payload ? "Fail to fetch" : "";;
        state.is_success = action.payload && action.payload.IsSuccess === true ? true : false;
      })
      .addCase(getMenu.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      })
     
      ;
  },
});



export default { ameliaSlice};