import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getDetails, closeRegister } from './CashmanagementSlice'
import LogOut_Icon_White from '../../assets/images/svg/LogOut-Icon-White.svg'
import AngledBracket_left from '../../assets/images/svg/AngledBracket-Left-BaseBlue.svg'
import AngledBracket_Left_White from '../../assets/images/svg/AngledBracket-Left-White.svg'
import OpenSign from '../../assets/images/svg/OpenSign.svg'
import PinPad from '../pinPage/PinPad'
import moment from 'moment';
import Closeregistertwo from "./Closeregistertwo";
import { get_locName, get_regName, get_userName, getShopName } from "../common/localSettings"
import { useNavigate } from 'react-router-dom';
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { validatePin } from "../pinPage/pinSlice";
import { openCashBox } from "../../settings/AndroidIOSConnect";
import { NavigateComponent } from "../../settings";
import LogoutConfirm from "../common/commonComponents/LogoutConfirm";
import { LoadingModal } from "../common/commonComponents/LoadingModal";
import STATUSES from "../../constants/apiStatus";
const Closeregister = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [enteredCashAmount, setCashAmount] = useState('')
    const [enteredCardAmount, setCardAmount] = useState('')
    const [enteredOthersAmount, setOtherPayment] = useState('')
    const [allOtherPayment,setAllOtherpayment]= useState('')
    const [isSaveCount, setisSaveCount] = useState(false)
    const [isUserExist, setIsUserExist] = useState(false)
    const [secondtoggle, setsecondtoggle] = useState(false)
    const [count, setCount] = useState(0)
    const [isShowLogoutConfirm, setisShowLogoutConfirm] = useState(false)
    const [isloading, setIsLoading] = useState(false);
    const toggleLogoutConfirm = () => {
        setisShowLogoutConfirm(!isShowLogoutConfirm)
    }
    var otherPaymentID = ''
    var cardPaymentID = ''
    dispatch(validatePin(null));


    // -----received data fro Api  
    const { statusgetdetail, getdetail, errorgetdetail, is_successgetdetail } = useSelector((state) => state.cashmanagementgetdetail)

    var CashDrawerPaymentDetail = getdetail && getdetail.content
    CashDrawerPaymentDetail && CashDrawerPaymentDetail.PaymentSummery.map(item => {

        return item.Slug == 'other' ?
            otherPaymentID = item.Id

            :
            item.Slug == 'card' ?
                cardPaymentID = item.Id
                : ''
    })
    
    const validateEnteredCashAmount = (e) => {
        const { value } = e.target;
        const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
        if (value == '' || re.test(value)) {
            setCashAmount(value)
        }
    }
    const validateEnteredCardAmount = (e) => {
        const { value } = e.target;
        const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
        if (value == '' || re.test(value)) {
            setCardAmount(value)
        }
    }
    const validateEnteredOthersAmount = (e) => {
        const { value } = e.target;
        const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
        if (value == '' || re.test(value)) {
            setOtherPayment(value)
        }
    }
    const validateEntereAllOthersAmount = (e,id) => {
        const { value } = e.target;
        const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
        var _allOtherpayment=allOtherPayment;
        if (value == '' || re.test(value)) {  
            _allOtherpayment && _allOtherpayment.map(item=>{
                if(id==item.id){
                    item.value= parseInt(value);
                }
            })           
            setAllOtherpayment(_allOtherpayment)
            
            setCount(count+1);
        }
    }
useEffect(()=>{
    if( !localStorage.getItem("register") || !localStorage.getItem('Location')  ){
        navigate('/location')
    }
},[])
// set prefilled payment Value 
useEffect(() => {    
    if(getdetail && getdetail.content){            
        let cardAmt=0;
        let otherAmt=0
        var _allOtherpayment=[]
        getdetail && getdetail.content.PaymentSummery.map(item => {
            if( item.Slug == 'card' ){
            cardAmt += item.Expected;
            }
            else if( item.Slug == 'other' ){
                otherAmt += item.Expected; 
            }  
            else{
                _allOtherpayment.push({"id":item.Id,"name":item.Name, "value":item.Expected})
            }
        })
        setCardAmount(cardAmt)
        setOtherPayment(otherAmt)
        setCashAmount(getdetail.content.Expected)        
        setAllOtherpayment(_allOtherpayment)
    }

}, [getdetail])

    const saveCount = (event) => {
        if (enteredCashAmount || enteredCardAmount || enteredOthersAmount) {
            event.currentTarget.disabled = true
            setIsLoading(true)
            setisSaveCount(false)
            var last_login_register_id = (localStorage.getItem("register"));
            var d = new Date();
            var dateStringWithTime = moment(d).format('YYYY-MM-DD HH:mm:ss Z');
            var localTimeZoneType = moment.tz.guess(true);
            var user = JSON.parse(localStorage.getItem("user"));
            var cashManagementID = localStorage.getItem('Cash_Management_ID');
            var otherPaymentTypes = '[';
            var otherPaymentAmount = enteredOthersAmount && enteredOthersAmount !== "" ? enteredOthersAmount : 0
            var cardPaymentAmount = enteredCardAmount && enteredCardAmount !== "" ? enteredCardAmount : 0
            if (otherPaymentID && otherPaymentID !== "") {
                otherPaymentTypes += '{"Id": "' + otherPaymentID + '","Slug": "other","ActualCloingBalance": "' + otherPaymentAmount + '"}';
            }
            if (cardPaymentID && cardPaymentID !== '') {
                otherPaymentTypes += (otherPaymentTypes == '[' ? '' : ',') + '{"Id": "' + cardPaymentID + '","Slug": "card","ActualCloingBalance": "' + cardPaymentAmount + '"}'
            }

            //'[{"Id": "' + this.state.otherPaymentID + '","Slug": "other","ActualCloingBalance": "' + this.state.enteredOthersAmount + '"},{"Id": "' + this.state.cardPaymentID + '","Slug": "card","ActualCloingBalance": "' + this.state.enteredCardAmount + '"}';
            if (CashDrawerPaymentDetail) {
                var _cashDetails = CashDrawerPaymentDetail && CashDrawerPaymentDetail
                _cashDetails && _cashDetails.PaymentSummery && _cashDetails.PaymentSummery.map((item, index) => {
                    if (item.Slug.toLowerCase() !== 'card' && item.Slug.toLowerCase() !== 'other') {
                        var ele = document.getElementById("_txtpayment" + item.Id);
                        if (ele) {
                            var _val = ele.value;
                            if (!_val || _val == '') {
                                _val = 0;
                            }
                            var _otherPayType = '{ "Id": "' + item.Id + '","Slug": "' + item.Slug + '","ActualCloingBalance":"' + _val + '"}';
                            otherPaymentTypes = (otherPaymentTypes !== '' && otherPaymentTypes !== '[' ? otherPaymentTypes + ',' : otherPaymentTypes) + _otherPayType;
                        }
                    }
                })
            }
            otherPaymentTypes = otherPaymentTypes + ']';
            var saveCountParm = {
                "Id": cashManagementID,
                "RegisterId": last_login_register_id,
                "LocalDateTime": dateStringWithTime,
                "LocalTimeZoneType": localTimeZoneType,
                "SalePersonId": user && user.user_id ? user.user_id : '',
                "ActualClosingBalance": enteredCashAmount,
                "ClosingNote": '',
                "PaymentSummeryClosing": JSON.parse(otherPaymentTypes)
            }
            sessionStorage.setItem("Cash_Management_ID",cashManagementID)
            dispatch(closeRegister(saveCountParm));
            //  $('.form-control').val('');          
        }
        else {
            setisSaveCount(true)
        }
    }


    // -----received data fro Api  
    var toggleCloseRegisterSuccess = false
    const { statuscloseRegister, closeRegisterdetail, errorcloseRegister, is_successcloseRegister } = useSelector((state) => state.cashmanagementCloseRegister)
    
    if (is_successcloseRegister === true) {
        toggleCloseRegisterSuccess = true;
        localStorage.removeItem("Cash_Management_ID");

    }
    var closeregisterPaymentDetail = closeRegisterdetail && closeRegisterdetail.content
    useEffect(() => {
        if(closeRegisterdetail){
            setIsLoading(false);
        }
    },[closeRegisterdetail])
    useEffect(() => {
        if (localStorage.getItem('user')) {
            setIsUserExist(true)
        }
        if (!localStorage.getItem("selectedRegister")) {
            navigate('/register')
        }
        var registerId = localStorage.getItem('register');
        var user = JSON.parse(localStorage.getItem("user"));
        var LoggenInUserId = user && user.user_id ? user.user_id : '';
        var cashManagementID = localStorage.getItem('Cash_Management_ID');
        if (cashManagementID) {
            dispatch(getDetails(cashManagementID));
        }

    }, [localStorage.getItem('user')])

    const doAction = () => {
        setIsUserExist(true)
    }
    var IsCashDrawerOpen = localStorage.getItem("IsCashDrawerOpen") ? localStorage.getItem("IsCashDrawerOpen") : false;

    return (
        <>
        {(closeRegisterdetail && closeRegisterdetail.status == STATUSES.LOADING)||isloading==true  ?<LoadingModal></LoadingModal> : null}
    
            <div className="close-register-wrapper">
                {/* <button id="cancelButton" onClick={() => NavigateComponent(navigate,'pin')}>
                    <img src={AngledBracket_Left_White} alt="" />                   
                   {LocalizedLanguage.cancel}
                </button> */}
                
                 {<button id="cancelcloseregister"  onClick={() => toggleLogoutConfirm()} >
                    <img src={LogOut_Icon_White} alt="" className="close_register_logout" />
                    {LocalizedLanguage.logout}
                </button>}
                <header>``
                    <img src={OpenSign} alt="" />
                    <div className="col">
                        <p className="style1">{getShopName()}</p>
                        <div className="divider" />
                        <p className="style2">{get_regName()}</p>
                        <p className="style3">{get_locName()}</p>
                    </div>
                </header>
                <main>
                    {isUserExist == false ? <div className="step1 ">
                        <div className="auto-margin-top" />
                        <p className="style1">{LocalizedLanguage.closeRegister}</p>
                        <div className="divider" />

                        <PinPad doAction={doAction} />

                        <div className="auto-margin-bottom" />
                    </div>
                        :

                        IsCashDrawerOpen == "true" && toggleCloseRegisterSuccess == false ? <div className="step2">
                            <p className="style1">{LocalizedLanguage.closeRegister}</p>
                            <div className="divider" />
                            <p className="style2">
                                <b>{LocalizedLanguage.loggedUser}</b> {get_userName()}
                            </p>
                            <button id="openCashDrawer" onClick={() => openCashBox()}>{LocalizedLanguage.opencashdrawer}</button>

                            <div className="input-column close_register_div">
                                {isSaveCount === true ? <>
                                    <label className="custom-error-message">{LocalizedLanguage.AmountShouldntbeblank}
                                    </label>
                                </> : null}
                                <div className="input-row">
                                    <label htmlFor="cashInTill">{LocalizedLanguage.cashInTill}:</label>
                                    <input type="number" title="" id="cashInTill" placeholder={LocalizedLanguage.EnterAmount} value={enteredCashAmount} onChange={(e) => validateEnteredCashAmount(e)} />
                                </div>

                                {CashDrawerPaymentDetail && CashDrawerPaymentDetail.PaymentSummery && CashDrawerPaymentDetail.PaymentSummery.map((item, index) => {
                                     return (item.Slug.toLowerCase() == 'card' ? <>
                                        <div className="input-row" key={index}>
                                            <label htmlFor="debitCredit">{item.Name}:</label>
                                            <input type="number" title="" id={"_txtpayment"+item.Id} placeholder={LocalizedLanguage.EnterAmount} value={enteredCardAmount} onChange={(e) => validateEnteredCardAmount(e)} />
                                        </div>
                                    </> : item.Slug.toLowerCase() == 'other' ? <>
                                        <div className="input-row" key={index}>
                                            <label htmlFor="debitCredit">{item.Name}:</label>
                                            <input type="number" title="" id={"_txtpayment"+item.Id} placeholder={LocalizedLanguage.EnterAmount} value={enteredOthersAmount} onChange={(e) => validateEnteredOthersAmount(e)} />
                                        </div>
                                    </> :null
                                      
                                    )
                                    
                                })
                                }
                                {allOtherPayment && allOtherPayment.map((item,index)=>{
                                        return   <div className="input-row" key={item.id+index}>
                                        <label htmlFor="debitCredit">{item.name}:</label>
                                        <input type="number" title="" id={"_txtpayment"+item.id} placeholder={LocalizedLanguage.EnterAmount} value={item.value}  onChange={(e) => validateEntereAllOthersAmount(e,item.id)} />
                                    </div>
                                    })
                                }
                            </div>
                            <button id="saveCount" onClick={(e)=>saveCount(e)}>{LocalizedLanguage.closeRegister}</button>
                        </div> : null}

                    {toggleCloseRegisterSuccess == true ? <div className="step3  ">
                        <Closeregistertwo closeregisterPaymentDetail={closeregisterPaymentDetail} />
                    </div> : null}



                </main>
            </div>
            {isShowLogoutConfirm === true ? <LogoutConfirm isShow={isShowLogoutConfirm} toggleLogoutConfirm={toggleLogoutConfirm}></LogoutConfirm> : null}
        </>
    )
}

export default Closeregister