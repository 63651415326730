import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ViewportList } from "react-viewport-list";
import LeftNavBar from "../common/commonComponents/LeftNavBar";
// import ClearCart from '../../assets/images/svg/ClearCart-Icon.svg'
import CircledX_Grey from '../../assets/images/svg/CircledX-Grey.svg'
import OliverIconBaseBlue from '../../assets/images/svg/Oliver-Icon-BaseBlue.svg'
import SearchBaseBlue from '../../assets/images/svg/SearchBaseBlue.svg'
// import FilterArrowDown from '../../assets/images/svg/FilterArrowDown.svg'
// import FilterArrowUp from '../../assets/images/svg/FilterArrowUp.svg'
import DownArrowBlue from '../../assets/images/svg/DownArrowBlue.svg'
import AngledBracketBlueleft from '../../assets/images/svg/AngledBracket-Left-Blue.svg'
import AvatarIcon from '../../assets/images/svg/AvatarIcon.svg'
// import PlusSign from '../../assets/images/svg/PlusSign.svg'
import Pencil from '../../assets/images/svg/Pencil-Outline-Blue.svg'
import CircledPlus_Icon_Blue from '../../assets/images/svg/CircledPlus-Icon-Blue.svg'
import Pencil_Blue from '../../assets/images/svg/Pencil-Blue.svg';
import { useNavigate } from 'react-router-dom';
import { customergetDetail, getAllEvents, deleteCustomerNote, updateCustomerNote, updateCreditScore, customersave } from './CustomerSlice'
import { get_UDid } from '../common/localSettings';
import moment from 'moment';
import Config from '../../Config'
import STATUSES from "../../constants/apiStatus";
// import Customerlist from "./Customerlist";
// import { LoadingSmallModal } from '../common/commonComponents/LoadingSmallModal'
import { useIndexedDB } from 'react-indexed-db';
import AddCustomersNotepoup from "./AddCustomersNotepoup";
import AdjustCreditpopup from "./AdjustCreditpopup";
import CustomerCreate from './Customercreate';
import AppLauncher from "../common/commonComponents/AppLauncher";
import LocalizedLanguage from '../../settings/LocalizedLanguage';
import { FormateDateAndTime } from '../../settings/FormateDateAndTime';
import { LoadingModal } from "../common/commonComponents/LoadingModal";
import { cashRecords } from "../cashmanagement/CashmanagementSlice";
import { activityRecords, getDetail } from "../activity/ActivitySlice";
// import { NumericFormat } from 'react-number-format';
import { CommonModuleJS, NavigateComponent } from "../../settings/CommonModuleJS";
// import MsgPopup from "../common/commonComponents/MsgPopup";
// import { popupMessage } from "../common/commonAPIs/messageSlice";
import PermissionPopup from "../common/commonComponents/PermissionPopup";
import NoUserAccess from "../common/commonComponents/NoUserAccess";
import { v4 as uniqueKey } from 'uuid';
import { currecyFunction } from "../common/commonFunctions/currencyFunction";
import { GTM_ClientDetail } from "../common/CommonfunctionGTM";
import { checkBridgeVirson, ValidateComponent } from "../common/validate";
import { isMobileOnly } from "react-device-detect";
import { isAutoFocus } from "../../settings/AndroidIOSConnect";
import { useMemo } from "react";
import IframeWindow from "../dashboard/IframeWindow";
import { IsFreePlan } from "../../settings/CommonJS";


const CustomerView = () => {


  const [orderCount, setOrderCount] = useState([])
  const [orderAmount, setOrderAmount] = useState(0)

  var UID = get_UDid('UDID')
  // var pageSize = Config.key.CUSTOMER_PAGE_SIZE;
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { getAll } = useIndexedDB("customers");
  const [isSortWrapper, setSortWrapper] = useState(false)
  const [isCvmobile, setCvmobile] = useState(false)
  const [isShowAppLauncher, setisShowAppLauncher] = useState(false);
  const [isShowLinkLauncher, setisShowLinkLauncher] = useState(false);
  const [isShowiFrameWindow, setisShowiFrameWindow] = useState(false);
  const [isShowMobLeftNav, setisShowMobLeftNav] = useState(false);
  const [isShowNoteModel, setisShowNoteModel] = useState(false)
  const [isShowcreatecustomerToggle, setisShowcreatecustomerToggle] = useState(false)
  // const [isShowEditcustomer, setisShowEditcustomer] = useState(false)
  // const [isShowCreateCustomerModel, setisShowCreateCustomerModel] = useState(false)
  const [isShowCreditModel, setisShowCreditModel] = useState(false)
  const [customerlistdata, setcustomerlist] = useState([]);
  const [allCustomerList, setAllCustomerList] = useState([]);
  // const [isCustomerListLoaded, setisCustomerListLoaded] = useState(true)
  const [customerDetailData, setcustomerDetailData] = useState([])
  const [AllEvant, setcustomerAllEvant] = useState([])
  const [updateCustomerId, setupdateCustomerId] = useState('')
  const [CustomerAddress, setCustomerAddress] = useState([])
  const [isMobileNav, setisMobileNav] = useState(false);
  const [filterType, setFilterType] = useState('firstnameforward');
  const [FirstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('')
  const [Email, setEmail] = useState(sessionStorage.getItem("customerredirect") ? sessionStorage.getItem("customerredirect") : '')
  const [PhoneNumber, setPhoneNumber] = useState('')
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [toggleList, setToggleList] = useState(false)
  const [sortbyvaluename, SetSortByValueName] = useState(LocalizedLanguage.firstName + "(A-Z)")
  const [editcustomerparam, seteditcustomerparam] = useState('')
  // const [customerupdatedetails, setcustomerupdatedetails] = useState(false)
  const [updateCustomerState, setupdateCustomerState] = useState(false)
  // const [isShowMsg, setisShowMsg] = useState(false);
  // const [ClearSearchTrue, setClearSearchTrue] = useState(false)
  // const [msgTitle, setmsgTitle] = useState('');
  // const [msgBody, setmsgBody] = useState('');
  // const [warningImg, setWarningImg] = useState('');
  const [isShowPermissionPopup, setIsShowPermissionPopup] = useState(false);
  const [isCustomerEdit, setisCustomerEdit] = useState(false)
  const [isCreateCustomerTrue, setisCreateCustomerTrue] = useState(false)
  const [extApp, setExtApp] = useState('');
  // const [addCustomerBtn, setaddCustomeBtn] = useState(false)

  const [eventCollection, setEventCollection] = useState([])
  const [isFreePlan,setIsFreePlan]=useState(false);
  const navigate = useNavigate()

  ValidateComponent()


  const toggleAppLauncher = () => {
    setisShowAppLauncher(!isShowAppLauncher)
    setisShowLinkLauncher(false)
  }
  const toggleClickList = () => {
    setToggleList(!toggleList)
  }
  const toggleLinkLauncher = () => {
    setisShowLinkLauncher(!isShowLinkLauncher)
    setisShowAppLauncher(false)
  }

  const toggleiFrameWindow = (_exApp = null,appClose) => {
    if (_exApp != null && _exApp != "") { setExtApp(_exApp); //setisInit(false);
  }
    setisShowiFrameWindow(!isShowiFrameWindow)
  }
  
  function ToggleiFrameWindow(_exApp = null,appClose) {
    if (_exApp != null && _exApp != "") { setExtApp(_exApp); //setisInit(false);
     }
    if (isShowiFrameWindow === false) {
      //  UpdateRecentUsedApp(_exApp, true, 0, "Checkout")
    }
    if(appClose==true)
    {
         setisShowiFrameWindow(false)
    }
    else
    {
      setisShowiFrameWindow(!isShowiFrameWindow)
       
    }
  
  }
  


  const DisplayPluginPopup = useMemo(()=>{   
    return <IframeWindow exApp={extApp} isShow={isShowiFrameWindow} ToggleiFrameWindow={ToggleiFrameWindow}></IframeWindow>;
},[extApp]);

  const toggleNoteModel = () => {
    setisShowNoteModel(!isShowNoteModel)
  }
  const toggleCreditModel = () => {
    setisShowCreditModel(!isShowCreditModel)
    if (isShowCreditModel == true) {
      if (isAutoFocus() == true)
        document.getElementById("addCreditInput").focus()
    }
  }
  // const closeNotemodel = () => {
  //   setisShowNoteModel(false)
  //   setisShowCreditModel(false)
  // }
  const toggleCreateCustomer = () => {
    setisShowcreatecustomerToggle(!isShowcreatecustomerToggle)
    seteditcustomerparam('')
    setisCustomerEdit(true)
  }
  const toggleEditcustomer = (param) => {
    seteditcustomerparam(param)
    setisShowcreatecustomerToggle(!isShowcreatecustomerToggle)
    setisCustomerEdit(true)
  }

  const toggleMobileNav = () => {
    setisMobileNav(!isMobileNav)
    // props.toggleShowMobLeftNav();
    setisShowMobLeftNav(!isShowMobLeftNav)
  }
  const toggleSortWrapp = () => {
    setSortWrapper(!isSortWrapper)
    // setaddCustomeBtn(false)
  }
  const CustomerSearchMobi = () => {
    setCvmobile(!isCvmobile)
  }
  // const toggleMsgPopup = () => {
  //   setisShowMsg(!isShowMsg)
  // }
  const togglePermissionPopup = () => {
    setIsShowPermissionPopup(!isShowPermissionPopup);
  }


  //var eventCollection = [];
  
  useEffect(() => { 
    if(IsFreePlan()==true){
      setIsFreePlan(true);
    }
    //if (customerlistdata && customerlistdata.length > 0) { sortByList("firstnameforward", LocalizedLanguage.firstName + "(A-Z)") }

    //sessionStorage.removeItem("addcutomertosale");
    GTM_ClientDetail();
  }, []);

  let useCancelledTwo = false;
  useEffect(() => {
    // console.log("first")
    if (useCancelledTwo == false) {
      getCustomerFromIDB()
      // dispatch(getDetail(null));
      // dispatch(cashRecords(null));
      // dispatch(activityRecords(null));

      dispatch(getDetail("clear"));
      dispatch(cashRecords("clear"));
      dispatch(customersave("clear"));
      dispatch(activityRecords("clear"));
    }
    if (isShowcreatecustomerToggle == true) {
      setSortWrapper(false)
    }
    return () => {

      useCancelledTwo = true;
    }
  }, [updateCustomerState, isCreateCustomerTrue]);

  const sendCreateCustomerRes = () => {
    useCancelledTwo = true;
    setisCreateCustomerTrue(true)
  }


  /// Customer Page Data form  IndexDB
  const getCustomerFromIDB = (type = "") => {
    // var CustomerLoadIntrv = setInterval(() => {
    getAll().then((rows) => {
      var customerList = [];
      setAllCustomerList(rows);
      if (isCreateCustomerTrue == true) {
        customerList = rows.sort(function (a, b) {
          return b.WPId - a.WPId;
        })
      } else if (isCreateCustomerTrue == false) {
        // customerList = rows ? rows : [];
        customerList = rows?.sort(function (a, b) {
          if (a && b && a.FirstName.toLowerCase() < b.FirstName.toLowerCase()) { return -1; }
          if (a && b && a.FirstName.toLowerCase() > b.FirstName.toLowerCase()) { return 1; }
          return 0;
        });
        // customerList = customerList.filter((item) => (
        //   (item && item.FirstName && item.FirstName.toLowerCase())
        // ))
      }
      setcustomerlist(customerList);
      if (customerList && customerList.length > 0) {
        var id = customerList[0].WPId ? customerList[0].WPId : 0
        sessionStorage.setItem("CUSTOMER_ID", id);
       if (type == "clear-search" && id !== 0) {
          updateSomething(id, 'search');
        }
        if (isCustomerEdit == true) {
          // console.log("if")
        } else {
          // console.log("else")
          setupdateCustomerId(customerList[0].WPId ? customerList[0].WPId : 0)
        }
      }
      sortByList("firstnameforward", LocalizedLanguage.firstName + "(A-Z)")
      // setisCustomerListLoaded(false)
      //clearInterval(CustomerLoadIntrv);
    });
    //}, 200);
  }


  // First Time GetAllEvant and CustomerDetails API Call
  // let useCancelled1 = false;
  // useEffect(() => {
  //   // console.log("update and call useEffect")
  //   var UID = get_UDid('UDID');
  //   var CUSTOMER_ID = sessionStorage.getItem("CUSTOMER_ID") ? sessionStorage.getItem("CUSTOMER_ID") : '';
  //   if (useCancelled1 == false) {
  //     dispatch(customergetDetail(CUSTOMER_ID, UID));
  //     dispatch(getAllEvents(CUSTOMER_ID, UID));
  //   }
  //   return () => {
  //     useCancelled1 = true;
  //   }
  // }, [customerupdatedetails, ClearSearchTrue]);


  // // Response from customer getDetails
  const [customerAllDetails] = useSelector((state) => [state.customergetDetail])
  useEffect(() => {
    if (customerAllDetails && customerAllDetails.custsiglestatus == STATUSES.IDLE && customerAllDetails.custsigleis_success && customerAllDetails.custsigledata) {
      setcustomerDetailData(customerAllDetails?.custsigledata?.content?.customerDetails);
      sessionStorage.setItem("currentCustomer",JSON.stringify(customerAllDetails?.custsigledata?.content?.customerDetails));
      setCustomerAddress(customerAllDetails?.custsigledata?.content?.customerDetails?.customerAddress)
      dispatch(customergetDetail("clear"));
      //updateSomething(customerAllDetails?.custsigledata?.content?.customerDetails?.WPId,"refresh_customer")

      // if(addCustomerBtn==true){
      //   CustomerDetail(customerAllDetails?.custsigledata?.content?.customerDetails);
      // }
    }
  }, [customerAllDetails]);

  const addCustomerToSale = (cutomer_data) => {
    // dispatch(customergetDetail(cutomer_data.WPId));
    // setaddCustomeBtn(true)
    sessionStorage.setItem("addcutomertosale", true);
    //CustomerDetail(customerAllDetails?.custsigledata?.content?.customerDetails);
    CustomerDetail(cutomer_data);
  }
  const CustomerDetail = (cutomer_data) => {
    var data = cutomer_data;
    localStorage.setItem('AdCusDetail', JSON.stringify(data))
    var list = localStorage.getItem('CHECKLIST') !== null ? (typeof localStorage.getItem('CHECKLIST') !== 'undefined') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null : null;
    if (list !== null) {
      const CheckoutList = {
        ListItem: list.ListItem,
        customerDetail: data ? data : [],
        totalPrice: list.totalPrice,
        discountCalculated: list.discountCalculated,
        tax: list.tax,
        subTotal: list.subTotal,
        TaxId: list.TaxId,
        TaxRate: list.TaxRate,
        oliver_pos_receipt_id: list.oliver_pos_receipt_id,
        order_date: list.order_date,
        order_id: list.order_id,
        status: list.status,
        showTaxStaus: list.showTaxStaus,
        _wc_points_redeemed: list._wc_points_redeemed,
        _wc_amount_redeemed: list._wc_amount_redeemed,
        _wc_points_logged_redemption: list._wc_points_logged_redemption
      }
      localStorage.setItem('CHECKLIST', JSON.stringify(CheckoutList))
    }
    NavigateComponent(navigate,"home")
  }




  // Response ---Customer  getAllEvant 

  const [customerAllEvant] = useSelector((state) => [state.getAllEvents])
  useEffect(() => {
    if (customerAllEvant && customerAllEvant.status == STATUSES.IDLE && customerAllEvant.is_success && customerAllEvant.data) {
      setcustomerAllEvant(customerAllEvant.data.content);
      var _AllEvent = customerAllEvant.data.content;
      var _eventCollection = [];
      if (_AllEvent && _AllEvent.length > 0) {
        _AllEvent.map((event, index) => {
          var collectionItem = {
            eventtype: '', Id: '', amount: '', oliverPOSReciptId: '', datetime: '', status: '',
            Description: '', ShortDescription: '', location: ''
          };
          var time = FormateDateAndTime.formatDateWithTime(event.CreateDateUtc, event.time_zone);
          var jsonData = event.JsonData && JSON.parse(event.JsonData)

          var gmtDateTime = FormateDateAndTime.formatDateAndTime(event.CreateDateUtc, event.time_zone)
          //var time = FormateDateAndTime.formatDateWithTime(event.CreateDateUtc, event.time_zone);
          var time = moment.utc(event.CreateDateUtc).local().format('LT');
          collectionItem['eventtype'] = event.EventName;
          collectionItem['Id'] = event.Id;
          collectionItem['amount'] = jsonData && jsonData.AddPoint ? jsonData.AddPoint : event.Amount;
          collectionItem['DeductPoint'] = jsonData && jsonData.DeductPoint ? jsonData.DeductPoint : event.Amount;
          collectionItem['oliverPOSReciptId'] = '';
          collectionItem['datetime'] = gmtDateTime
          // moment.utc(event.CreateDateUtc).local().format(Config.key.TIMEDATE_FORMAT);//event.CreateDateUtc;
          collectionItem['status'] = event ? event.Status : '';
          collectionItem['Description'] = jsonData ? jsonData.Notes : event.Description;
          collectionItem['ShortDescription'] = event.ShortDescription;
          collectionItem['location'] = event ? event.Location : '';
          collectionItem['time'] = time ? time : '';
          _eventCollection.push(collectionItem)
        })
        var orderCount = _eventCollection.filter(x => x.eventtype == "New Order")
        //Order Total Amount 
        // console.log("eventCollection",eventCollection)
        var orderAmount = 0
        for (let index = 0; index < orderCount.length; index++) {
          if (orderCount[index].amount && orderCount[index].amount != 0) {
            orderAmount += parseInt(orderCount[index].amount++);
          }
        }
        setEventCollection(_eventCollection);
        setOrderCount(orderCount);
        setOrderAmount(orderAmount);
      }
      else
      {
        setEventCollection([]);
        setOrderCount(0);
        setOrderAmount(0);
      }
    }
    //dispatch(getAllEvents(null));
  }, [customerAllEvant.data]);
  // console.log("customerAllEvant",customerAllEvant)







  /// Customer render List Click Function
  const activeClass = (item, index) => {
    setSortWrapper(false)
    var UID = get_UDid('UDID');
    if (item && item.WPId !== '') {
      setupdateCustomerId(item.WPId)
      dispatch(customergetDetail(item.WPId, UID));
      dispatch(getAllEvents(item.WPId, UID));
      toggleClickList()
    }
    // setaddCustomeBtn(false)

  }

  const sortByList = (filterType, FilterValue) => {
    setFilterType(filterType);
    SetSortByValueName(FilterValue)
    setSortWrapper(false)
    // setaddCustomeBtn(false)
  }

  useEffect(() => {
    if (customerlistdata && customerlistdata.length > 0) { customerDataSearch(); }
  }, [filterType, customerlistdata]);

  const customerDataSearch = () => {

    // var scount = 0;
    var _filteredCustomer = customerlistdata
    // let _filteraftersort = []
    ///Sort By Customer 

    // latest list
    if (filterType == 'newestforward') {
      _filteredCustomer = _filteredCustomer.sort(function (a, b) {
        return b.WPId - a.WPId;
      })
      updateSomething(_filteredCustomer[0]?.WPId)
    }
    // oldest list
    else if (filterType == 'oldestbackward') {
      _filteredCustomer = _filteredCustomer.sort(function (a, b) {
        return a.WPId - b.WPId;
      })
      updateSomething(_filteredCustomer[0]?.WPId)
    }

    else if (filterType == 'emailforward') {
      _filteredCustomer = _filteredCustomer.sort(function (a, b) {
        if (a.Email.toLowerCase() < b.Email.toLowerCase()) { return -1; }
        if (a.Email.toLowerCase() > b.Email.toLowerCase()) { return 1; }
        return 0;
      });
      if (Email.trim() !== '') {
        _filteredCustomer = _filteredCustomer.filter((item) => (
          (item && item.Email && item.Email.toLowerCase().includes(Email.trim().toLowerCase()))
        ))
      }
      updateSomething(_filteredCustomer[0]?.WPId)
    }
    else if (filterType.toLowerCase() == 'emailbackward') {
      _filteredCustomer = _filteredCustomer.sort((a, b) => {
        if (a.Email.toLowerCase() > b.Email.toLowerCase())
          return -1;
        if (a.Email.toLowerCase() < b.Email.toLowerCase())
          return 1;
        return 0;
      });
      if (Email.trim() !== '') {
        _filteredCustomer = _filteredCustomer.filter((item) => (
          (item && item.Email && item.Email.toLowerCase().includes(Email.trim().toLowerCase()))
        ))
      }
      updateSomething(_filteredCustomer[0]?.WPId)
    }

    else if (filterType == 'firstnameforward') {

      _filteredCustomer = _filteredCustomer.sort(function (a, b) {
        if (a && b && a.FirstName.toLowerCase() < b.FirstName.toLowerCase()) { return -1; }
        if (a && b && a.FirstName.toLowerCase() > b.FirstName.toLowerCase()) { return 1; }
        return 0;
      });

      if (FirstName.trim() !== '') {
        _filteredCustomer = _filteredCustomer.filter((item) => (
          (item && item.FirstName && item.FirstName.toLowerCase().includes(FirstName.trim().toLowerCase()))
        ))
       // updateSomething(_filteredCustomer[0]?.WPId)
      }
    }
    else if (filterType.toLowerCase() == 'firstnamebackward') {
      _filteredCustomer = _filteredCustomer.sort((a, b) => {
        if (a && b && a.FirstName.toLowerCase() > b.FirstName.toLowerCase())
          return -1;
        if (a && b && a.FirstName.toLowerCase() < b.FirstName.toLowerCase())
          return 1;
        return 0;
      });

      if (FirstName.trim() !== '') {
        _filteredCustomer = _filteredCustomer.filter((item) => (
          (item && item.FirstName && item.FirstName.toLowerCase().includes(FirstName.trim().toLowerCase()))
        ))
      }
      //updateSomething(_filteredCustomer[0]?.WPId)
    }

    else if (filterType == 'lastnameforward') {
      _filteredCustomer = _filteredCustomer.sort(function (a, b) {
        if (a.LastName.toLowerCase() < b.LastName.toLowerCase()) { return -1; }
        if (a.LastName.toLowerCase() > b.LastName.toLowerCase()) { return 1; }
        return 0;
      });
      if (LastName.trim() !== '') {
        _filteredCustomer = _filteredCustomer.filter((item) => (
          (item.LastName && item.LastName.toLowerCase().includes(LastName.trim().toLowerCase()))
        ))
      }
      //updateSomething(_filteredCustomer[0]?.WPId)
    }
    else if (filterType.toLowerCase() == 'lastnamebackward') {
      _filteredCustomer = _filteredCustomer.sort((a, b) => {
        if (a.LastName.toLowerCase() > b.LastName.toLowerCase())
          return -1;
        if (a.LastName.toLowerCase() < b.LastName.toLowerCase())
          return 1;
        return 0;
      });
      if (LastName.trim() !== '') {
        _filteredCustomer = _filteredCustomer.filter((item) => (
          (item.LastName && item.LastName.toLowerCase().includes(LastName.trim().toLowerCase()))
        ))
      }
      //updateSomething(_filteredCustomer[0]?.WPId)
    }

    if (_filteredCustomer.length == 1) {
      setupdateCustomerId(_filteredCustomer[0]?.WPId)
    }
    //PUTTING ALL THE CUSTOMERS IN THE BOTTOM OF THE LIST, NOT HAVING FIRSTNAME AND LASTNAME
    if (filterType == 'firstnameforward' || filterType == 'firstnamebackward' || filterType == 'lastnameforward' || filterType == 'lastnamebackward') {
      var nameEmpty = [];
      var withName = [];
      switch (filterType) {
        case 'firstnameforward':
        case 'firstnamebackward':
          nameEmpty = _filteredCustomer.filter((item) => item.FirstName == null || item.FirstName == "");
          withName = _filteredCustomer.filter((item) => item.FirstName !== null && item.FirstName !== "");
          break;
        case 'lastnameforward':
        case 'lastnamebackward':
          nameEmpty = _filteredCustomer.filter((item) => item.LastName == null || !item.LastName == "");
          withName = _filteredCustomer.filter((item) => item.LastName !== null && !item.LastName == "");
          break;
        default:
          break;
      }
      _filteredCustomer = [...new Set([...withName, ...nameEmpty])];
      updateSomething(_filteredCustomer[0]?.WPId)
    }

    //_filteredCustomer = [...new Set([..._filteredCustomer, ...customerlistdata])];
    setFilteredCustomer(_filteredCustomer);
    //scount += _filteredCustomer.length;
    // console.log("_filteredCustomer", _filteredCustomer)
    //  console.log("updateCustomerId count", updateCustomerId)
  }


  const customerSearch = () => {
    if (FirstName !== '' || LastName !== '' || Email !== '' || PhoneNumber !== '') {
      CustomerSearchMobi()
      getAll().then((_allCustomerList) => {
        var _filteredCustomer = _allCustomerList;
        // Search in Customer
        if (FirstName.trim() !== '') {
          _filteredCustomer = _filteredCustomer.filter((item) => (
            (item && item.FirstName && item.FirstName.toLowerCase().includes(FirstName.trim().toLowerCase()))
          ))
          updateSomething(_filteredCustomer[0]?.WPId, "search")
        }
        if (PhoneNumber !== '') {
          _filteredCustomer = _filteredCustomer.filter((item) => (
            (item.Contact && item.Contact.toLowerCase().includes(PhoneNumber.toLowerCase()))
          ))
          updateSomething(_filteredCustomer[0]?.WPId, "search")
        }
        if (LastName.trim() !== '') {
          _filteredCustomer = _filteredCustomer.filter((item) => (
            (item.LastName && item.LastName.toLowerCase().includes(LastName.trim().toLowerCase()))
          ))
          updateSomething(_filteredCustomer[0]?.WPId, "search")
        }
        if (Email.trim() !== '') {
          _filteredCustomer = _filteredCustomer.filter((item) => (
            (item.Email && item.Email.toLowerCase().includes(Email.trim().toLowerCase()))
          ))
          updateSomething(_filteredCustomer[0]?.WPId, "search")
        }
        if (_filteredCustomer.length == 1) {
          setupdateCustomerId(_filteredCustomer[0]?.WPId)
        }
        setFilteredCustomer(_filteredCustomer);
        // scount += _filteredCustomer.length;
      })
    }

  }

  const updateSomething = (customer_Id, type = "") => {
    if (typeof customer_Id != "undefined" && customer_Id !== null) {
      setupdateCustomerId(customer_Id)
      sessionStorage.setItem("CUSTOMER_ID", customer_Id ? customer_Id : 0);
      var UID = get_UDid('UDID');
      if (type == "add_note" || type == "delete_note") {
        dispatch(getAllEvents(customer_Id, UID));
      }
      else if (type == "update_store_credit" || type == "refresh_customer" || type == "search") {
        dispatch(customergetDetail(customer_Id, UID));
        dispatch(getAllEvents(customer_Id, UID));
      }
      else {
        setTimeout(() => {
          dispatch(customergetDetail(customer_Id, UID));
        }, 100);
        setTimeout(() => {
          dispatch(getAllEvents(customer_Id, UID));
        }, 450);
      }
    }

  }









  const OpenTransactions = (customerDetailData) => {
    //console.log("customerDetailData",customerDetailData.Email)
    if (customerDetailData.Email !== '') {
      sessionStorage.setItem("transactionredirect", customerDetailData.Email ? customerDetailData.Email : "");
      sessionStorage.removeItem('notificationRedirect');
      navigate('/transactions')
    }
  }

  const clearSearch = () => {
    if (FirstName !== '' || LastName !== '' || Email !== '' || PhoneNumber !== '' || (filteredCustomer && filteredCustomer.length !== customerlistdata.length)) {
      getCustomerFromIDB("clear-search")
      setFirstName("")
      setLastName("")
      setEmail("")
      setPhoneNumber("")
      sessionStorage.removeItem('customerredirect');
      // setTimeout(() => {
      //   setClearSearchTrue(true)
      // }, 500);

    }
    // setaddCustomeBtn(false)
  }


  // Customer Edit  API response
  const [customereditsucc] = useSelector((state) => [state.customerupdate])
  useEffect(() => {
    if (customereditsucc && customereditsucc.status == STATUSES.IDLE && customereditsucc.is_success && customereditsucc.data) {
      // console.log("customereditsucc.data",customereditsucc.data.content.WPId)
      // setcustomerupdatedetails(true)
      updateSomething(customereditsucc?.data?.content?.WPId);
      setupdateCustomerId(customereditsucc?.data?.content?.WPId)

    }
  }, [customereditsucc]);


  const deleteNotes = (Id) => {
    if (Id !== "") {
      if (CommonModuleJS.permissionsForDeleteNotes() == false) {
        togglePermissionPopup();
        // toggleMsgPopup(true);
        // setmsgTitle("Message")
        // setmsgBody("This Feature Is Disabled From Admin Side.")

      } else {
        dispatch(deleteCustomerNote(Id))
        //updateSomething(updateCustomerId);
      }
    }
  }

  const [respDeleteCustomerNote] = useSelector((state) => [state.deleteCustomerNote])
  useEffect(() => {
    if (respDeleteCustomerNote && respDeleteCustomerNote.status == STATUSES.IDLE && respDeleteCustomerNote.is_success && respDeleteCustomerNote.data) {
      dispatch(deleteCustomerNote("clear"));
      updateSomething(updateCustomerId, "delete_note");
    }

  }, [respDeleteCustomerNote]);

  const [respUpdateCustomerNote] = useSelector((state) => [state.updateCustomerNote])
  useEffect(() => {
    if (respUpdateCustomerNote && respUpdateCustomerNote.status == STATUSES.IDLE && respUpdateCustomerNote.is_success && respUpdateCustomerNote.data) {
      updateCustomerNote("clear");
      updateSomething(updateCustomerId, "add_note");
    }
  }, [respUpdateCustomerNote]);

  const [respUpdateCreditScore] = useSelector((state) => [state.updateCreditScore])
  useEffect(() => {
    if (respUpdateCreditScore && respUpdateCreditScore.status == STATUSES.IDLE && respUpdateCreditScore.is_success && respUpdateCreditScore.data) {
      dispatch(updateCreditScore("clear"));
      updateSomething(updateCustomerId, "update_store_credit");
    }
  }, [respUpdateCreditScore]);

  //Resp Customer delete and  refresh state
  const [deleteCustomerRes] = useSelector((state) => [state.customerdelete])
  useEffect(() => {
    if (deleteCustomerRes && deleteCustomerRes.status == STATUSES.IDLE && deleteCustomerRes.is_success && deleteCustomerRes.data) {
      // setcustomerupdatedetails(true)
      setupdateCustomerState(true)
      setisCustomerEdit(false)
      getCustomerFromIDB();
    }
  }, [deleteCustomerRes]);


  const [customerres] = useSelector((state) => [state.customersave])
  useEffect(() => {
    if (customerres && customerres.status == STATUSES.IDLE && customerres.is_success && customerres.data) {
      setupdateCustomerState(true)
      updateSomething(customerres.data.content.WPId)

    }
  }, [customerres]);

  // Getting Common Message Responce
  // const [respopupMessage] = useSelector((state) => [state.popupMessage])
  // useEffect(() => {
  //     if (respopupMessage && respopupMessage.status == STATUSES.IDLE && respopupMessage.is_success && respopupMessage.data) {
  //        // toggleMsgPopup(true);
  //       //  setisShowMsg(true)
  //         setmsgBody(respopupMessage.data.msg);
  //         setmsgTitle(respopupMessage.data.title);
  //         setWarningImg(respopupMessage.data.img);
  //        // dispatch(popupMessage(null));
  //     }
  // }, [respopupMessage]);


  const handleKeyUp = (e) => {
    if (e.keyCode == 13) {
      customerSearch();
    }
  }
  const outSideClick = () => {
    setSortWrapper(false)
  }

  const editCustomerTrue = () => {
    setisCustomerEdit(true)
  }

  // create object and  render customer list using react-viewport-list
  const items = filteredCustomer.map((item, index) => ({
    intialsChar: item.FirstName.charAt(0) + item.LastName.charAt(0),
    CustomerId: item.WPId,
    FirstName: item.FirstName,
    LastName: item.LastName,
    PhoneNumber: item.Contact,
    Email: item.Email,
    userName: item.UserName,
    WPId: item.WPId,
  }));


  var noteslength = eventCollection;// && eventCollection.filter(i => i.eventtype.toLowerCase() == 'add new note')
  var showShippingAddress = checkBridgeVirson();


  // useEffect(() =>{
  if (sessionStorage.getItem('customerredirect')) {
    setTimeout(() => {
      customerSearch()
      // customerDataSearch();
      sessionStorage.removeItem('customerredirect')
    }, 400);
    // alert('exist')
  }
  // },[])
  
  return (
    <React.Fragment>
      {(customerAllEvant.status == STATUSES.LOADING
        || customereditsucc.status == STATUSES.LOADING
        || deleteCustomerRes.status == STATUSES.LOADING
        || respDeleteCustomerNote.status == STATUSES.LOADING
        || respUpdateCustomerNote.status == STATUSES.LOADING
        || respUpdateCreditScore.status == STATUSES.LOADING) && isFreePlan==false
        ? <LoadingModal></LoadingModal> : null}
      {/* {customereditsucc.status == STATUSES.LOADING ? <LoadingModal></LoadingModal> : null}
      {deleteCustomerRes.status == STATUSES.LOADING ? <LoadingModal></LoadingModal> : null} */}
      <div className="customer-view-wrapper">
        <LeftNavBar isShowMobLeftNav={isShowMobLeftNav} toggleLinkLauncher={toggleLinkLauncher} toggleAppLauncher={toggleAppLauncher} toggleiFrameWindow={toggleiFrameWindow} view="Customer View"></LeftNavBar>
        <AppLauncher isShow={isShowAppLauncher} toggleAppLauncher={toggleAppLauncher} toggleiFrameWindow={toggleiFrameWindow}></AppLauncher>

        <div id="navCover" className="nav-cover"></div>
        <div className="mobile-cv-header">
          <button id="mobileNavToggle" onClick={() => toggleMobileNav()} className={isMobileNav === true ? "opened" : ""} >
            <img src="" alt="" />
          </button>
          <p>{LocalizedLanguage.customers}</p>
          <button id="mobileCVSearchButton" onClick={CustomerSearchMobi}>
            <img src={SearchBaseBlue} alt="" />
          </button>
          <button id="mobileAppsButton" onClick={() => toggleAppLauncher()}>
            <img src={OliverIconBaseBlue}
              alt="" />
          </button>
        </div>
        <div id="CVSearch" className={isCvmobile === true ? "cv-search open" : "cv-search"} onClick={outSideClick}  >
          <div className="header">
            <p>{LocalizedLanguage.customers}</p>
            <button id="cvAddCustomer" onClick={() => toggleCreateCustomer()}>
              <img src={CircledPlus_Icon_Blue} alt="" />{LocalizedLanguage.AddNew}
            </button>
            <button id="mobileCVExitSearch" onClick={CustomerSearchMobi}>
              <img src={AngledBracketBlueleft} alt="" />
              {LocalizedLanguage.goBack}
            </button>
            {/* <p className="mobile-only">{LocalizedLanguage.searchforcustomer}</p> */}
          </div>
          <div className="body">
            <div className="row">
              <img src={SearchBaseBlue} alt="" />
              <p>{LocalizedLanguage.searchactivity}</p>
              <button id="customersClearSearch" onClick={clearSearch}>{LocalizedLanguage.clearsearch}</button>

            </div>
            <label htmlFor="fName">{LocalizedLanguage.firstName}</label>
            <input type="text" id="FirstName" placeholder={LocalizedLanguage.enterfirstname} value={FirstName} onChange={e => setFirstName(e.target.value)} onKeyUp={(e) => handleKeyUp(e)} />
            <label htmlFor="lName">{LocalizedLanguage.lastName}</label>
            <input type="text" id="LastName" placeholder={LocalizedLanguage.EnterLastName} value={LastName} onChange={e => setLastName(e.target.value)} onKeyUp={(e) => handleKeyUp(e)} />
            <label htmlFor="email">{LocalizedLanguage.Email}</label>
            <input type="email" id="Email" placeholder={LocalizedLanguage.EnterEmail} value={Email} onChange={e => setEmail(e.target.value)} onKeyUp={(e) => handleKeyUp(e)} />
            <label htmlFor="tel">{LocalizedLanguage.phoneNumber}</label>
            <input type="number" id="PhoneNumber" placeholder={LocalizedLanguage.EnterPhoneNumber} value={PhoneNumber} onChange={e => setPhoneNumber(e.target.value)} onKeyUp={(e) => handleKeyUp(e)} />
            <button id="searchCustomersButton" onClick={customerSearch}  >{LocalizedLanguage.searchactivity}</button>
          </div>
        </div>
        <div className="cv-list" >
          <div className="header">
            <p>{LocalizedLanguage.sortby}:</p>
            <div id="customerListSort" className={isSortWrapper === true ? "sort-wrapper open " : "sort-wrapper"}>
              <img src={DownArrowBlue} alt="" />
              <input type="text" id="filterType" value={sortbyvaluename} readOnly onClick={toggleSortWrapp} />
              {/* <img className="dropdown-arrow" src={DownArrowBlue} alt="" />
              <div id="sortCurrent" className="sort-current">
                <img src={filterType != "" && filterType.includes("forward") ? FilterArrowUp : FilterArrowDown} alt="" />
                <p>{sortbyvaluename}</p>
              </div> */}
              <div className="option-container" id="customerListSortOptionsContainer">
                <div className="option" onClick={(e) => sortByList("firstnameforward", LocalizedLanguage.firstName + "(A-Z)")}>{LocalizedLanguage.firstName}(A-Z)</div>
                <div className="option" onClick={(e) => sortByList("firstnamebackward", LocalizedLanguage.firstName + "(Z-A)")}>{LocalizedLanguage.firstName} (Z-A)</div>
                <div className="option" onClick={(e) => sortByList("lastnameforward", LocalizedLanguage.lastName + "(A-Z)")}>{LocalizedLanguage.lastName} (A-Z)</div>
                <div className="option" onClick={(e) => sortByList("lastnamebackward", LocalizedLanguage.lastName + "(Z-A)")}>{LocalizedLanguage.lastName} (Z-A)</div>
                <div className="option" onClick={(e) => sortByList("emailforward", LocalizedLanguage.Email + "(A-Z)")}>{LocalizedLanguage.Email} (A-Z)</div>
                <div className="option" onClick={(e) => sortByList("emailbackward", LocalizedLanguage.Email + "(Z-A)")} >{LocalizedLanguage.Email} (Z-A)</div>
              </div>
              {/* <div onClick={(e) => sortByList("newestforward", "Newest")} className="sort-option" >
                <img src={FilterArrowUp} alt="" />
                <p>Newest</p>
              </div>
              <div onClick={(e) => sortByList("oldestbackward", "Oldest")} className="sort-option" >
                <img src={FilterArrowDown} alt="" />
                <p>Oldest</p>
              </div>

              <div onClick={(e) => sortByList("firstnameforward", "FirstName")} className="sort-option" >
                <img src={FilterArrowUp} alt="" />
                <p>FirstName</p>
              </div>
              <div onClick={(e) => sortByList("firstnamebackward", "FirstName")} className="sort-option" >
                <img src={FilterArrowDown} alt="" />
                <p>FirstName</p>
              </div>
              <div onClick={(e) => sortByList("emailforward", "Email")} className="sort-option" >
                <img src={FilterArrowUp} alt="" />
                <p>Email</p>
              </div>
              <div onClick={(e) => sortByList("emailbackward", "Email")} className="sort-option">
                <img src={FilterArrowDown} alt="" />
                <p>Email</p>
              </div>
              <div onClick={(e) => sortByList("lastnameforward", "LastName")} className="sort-option" data-value="emailAsc">
                <img src={FilterArrowUp} alt="" />
                <p>LastName</p>
              </div>
              <div onClick={(e) => sortByList("lastnamebackward", "LastName")} className="sort-option">
                <img src={FilterArrowDown} alt="" />
                <p>lastName</p>
              </div> */}

            </div>
          </div>

          <div className="body" ref={ref}>
            {filteredCustomer && filteredCustomer.length > 0 ? <>  <ViewportList viewportRef={ref} items={items} itemMinSize={40} margin={8}>
              {(item) => {
                return (
                  <>
                    {
                      isMobileOnly === true ? <button onClick={(e) => activeClass(item)} id={item.FirstName} className="customer-card no-transform" key={uniqueKey()}>
                        <div className="avatar">{item.intialsChar ? item.intialsChar.toUpperCase() : <img src={AvatarIcon} alt="" />}</div>
                        <div className="text-group">
                          <p className="style1">{item.FirstName ? item.FirstName : item.Email}{" "}{item.LastName ? item.LastName : ''}</p>
                          <p className="style2">{item.FirstName ? item.Email : ''}</p>
                        </div>
                        {updateCustomerId === item.WPId ? <div className="selected-indicator" ></div> : ""}
                      </button> : <button onClick={(e) => activeClass(item)} id={item.FirstName} className={updateCustomerId === item.WPId ? "customer-card no-transform selected" : "customer-card no-transform"} key={uniqueKey()}>
                        <div className="avatar">{item.intialsChar ? item.intialsChar.toUpperCase() : <img src={AvatarIcon} alt="" />}</div>
                        <div className="text-group">
                          <p className="style1">{item.FirstName ? item.FirstName : item.Email}{" "}{item.LastName ? item.LastName : ''}</p>
                          <p className="style2">{item.FirstName ? item.Email : ''}</p>
                        </div>
                        {updateCustomerId === item.WPId ? <div className="selected-indicator" ></div> : ""}
                      </button>
                    }
                  </>
                  // <button onClick={(e) => activeClass(item)} id={item.FirstName} className={updateCustomerId === item.WPId ? "customer-card no-transform selected" : "customer-card no-transform"} key={uniqueKey()}>
                  //   <div className="avatar">{item.intialsChar ? item.intialsChar.toUpperCase() : <img src={AvatarIcon} alt="" />}</div>
                  //   <div className="text-group">
                  //     <p className="style1">{item.FirstName ? item.FirstName : item.Email}{" "}{item.LastName ? item.LastName : ''}</p>
                  //     <p className="style2">{item.FirstName ? item.Email : ''}</p>
                  //   </div>
                  //   {updateCustomerId === item.WPId ? <div className="selected-indicator" ></div> : ""}
                  // </button>
                )
              }}
            </ViewportList></> : <div className="no-results">
              <p className="style1">{LocalizedLanguage.Noresultsfound}</p>
              <p className="style2">{LocalizedLanguage.Sorryyousearchdidnot} <br />{LocalizedLanguage.matchanyresults}</p>
            </div>}

          </div>
          <div className="mobile-footer">
            <button id="mobileAddCustomerButton" onClick={() => toggleCreateCustomer()} >{LocalizedLanguage.CreateNew}</button>
          </div>
        </div>
        {filteredCustomer && filteredCustomer.length > 0 ? <div id="CVDetailed" onClick={outSideClick} className={toggleList === true ? "cv-detailed open " : "cv-detailed"}>
          <div className="mobile-back">
            <button id="exitCVDetailed" onClick={toggleClickList}>
              <img src={AngledBracketBlueleft} alt="" />
              {LocalizedLanguage.goBack}
            </button>
          </div>
          <div className="quick-info">
            <div className="avatar">
              {customerDetailData && (customerDetailData.FirstName || customerDetailData.LastName == '' ? customerDetailData.FirstName.charAt(0).toUpperCase() + customerDetailData.LastName.charAt(0).toUpperCase() : <img src={AvatarIcon} alt="" />)}

            </div>
            <div className="text-group">
              <p className="style1">{customerDetailData && customerDetailData.FirstName} {customerDetailData && customerDetailData.LastName}</p>
              <p className="style2">{customerDetailData && customerDetailData.Email}</p>
              <p className="style2">{LocalizedLanguage.phone} #: {customerDetailData && customerDetailData.Contact}</p>
            </div>
            <button id="editCustomerButton" onClick={() => toggleEditcustomer("editcustomer")}>
              <img src={Pencil} alt="" />
              {LocalizedLanguage.customerEdit}
            </button>
          </div>
          <div className="cust-totals">
            <div className="col">
              {/* <p className="style1">$<NumericFormat value={OrderAmount ? OrderAmount : 0} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></p> */}
              <p className="style1">{currecyFunction(orderAmount ? orderAmount : 0)}</p>
              <p className="style2">{LocalizedLanguage.TotalSpent}</p>
            </div>
            <div className="col">
              <p className="style1">{orderCount && orderCount.length ? orderCount.length : 0}</p>
              <p className="style2">{LocalizedLanguage.Order}</p>
            </div>
            <div className="col">
              {/* <p className="style1">${customerDetailData && <NumericFormat value={customerDetailData.store_credit} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />}</p> */}
              <p className="style1">{customerDetailData && currecyFunction(customerDetailData && customerDetailData.store_credit)}</p>
              <button id="adjustCreditSubwindowButton" onClick={toggleCreditModel}>{LocalizedLanguage.stroeCredit}
                <img src={Pencil_Blue} alt="" />
              </button>
            </div>
          </div>
          <div className="bill-ship-info">
            {CustomerAddress && CustomerAddress.map(item => {
              if (item.TypeName.toLowerCase() == "billing") {
                return (
                  <div className="col" key={uniqueKey()}>
                    <p className="style1">{LocalizedLanguage.billingInformation}</p>
                    <p className="style2">
                      {/* {item.Address1} <br />
                      {item.Address2} <br />
                      {item.City} <br />
                      {item.PostCode} <br />
                      {item.State}<br />
                      {item.Country} */}
                      {item.Address1} {item.Address1 && <br />}
                      {item.Address2} {item.Address2 && <br />}
                      {item.City} {item.city && <br />}
                      {item.PostCode} {item.PostCode && <br />}
                      {item.State} {item.State && <br />}
                      {item.Country}
                    </p>
                  </div>
                )
              } else if (item.TypeName.toLowerCase() == "shipping" && showShippingAddress == true) {
                return (
                  <div className="col" key={uniqueKey()}>
                    <p className="style1">{LocalizedLanguage.shippinginformation}</p>
                    <p className="style2">
                      {/* {item.Address1} <br />
                      {item.Address2} <br />
                      {item.City} <br />
                      {item.PostCode} <br />
                      {item.State}<br />
                      {item.Country} */}

                      {item.Address1} {item.Address1 && <br />}
                      {item.Address2} {item.Address2 && <br />}
                      {item.City} {item.city && <br />}
                      {item.PostCode} {item.PostCode && <br />}
                      {item.State} {item.State && <br />}
                      {item.Country}
                    </p>
                  </div>
                )
              }
            })}
          </div>
          <div className="cust-notes">
            <div className="header-row">
              <p>{LocalizedLanguage.CustomerNotes}</p>
              <button id="addCustNoteButton" onClick={toggleNoteModel} >{LocalizedLanguage.addNote}</button>
            </div>
            {eventCollection && eventCollection.length > 0 ? eventCollection.map((item, index) => {
              return (
                item.eventtype.toLowerCase() == 'add new note' ?
                  <div className="customer-note" key={uniqueKey()}>
                    <div className="row">
                      <p className="style1">{item.datetime}</p>
                      <p className="style2">{item.time}</p>
                      <button onClick={() => deleteNotes(item.Id)}>
                        <img src={CircledX_Grey} alt="" />
                      </button>
                    </div>
                    <p>{item.Description}</p>
                  </div>
                  : item.eventtype.toLowerCase() == "update customer" || item.eventtype.toLowerCase() == "save new customer" ?
                    <div className="customer-note" key={uniqueKey()}>
                      <div className="row">
                        <p className="style1">{item.datetime}</p>
                        <p className="style2">{item.time}</p>
                      </div>
                      <p>{item.Description}</p>
                    </div>
                    : item.eventtype == "Customer Store Credit" ?
                      item.Description != null && item.Description != "" ? <>
                        <div className="customer-note" key={uniqueKey()} style={{ "display": "block" }} >
                          <div className="row">
                            <p className="style1">{item.datetime}</p>
                            <p className="style2">{item.time}</p>
                            {/* <button onClick={() => deleteNotes(item.Id)}>
                              <img src={CircledX_Grey} alt="" />
                            </button> */}
                          </div>

                          {/* {item.amount ?
                            <p>{parseFloat(item.amount ? item.amount : 0)}
                              <br></br>
                              <span>{LocalizedLanguage.addCredit}</span>
                            </p> : ''
                          }
                          {item.DeductPoint ?
                            <p>
                              {parseFloat(item.DeductPoint ? item.DeductPoint : 0)}
                              <br></br>
                              <span>{LocalizedLanguage.deductCredit}</span>
                            </p> : ''
                          } */}
                          <p>{item.Description}</p>
                        </div>

                      </> : '' : ''
              )
            }) : ''}
            {noteslength.length == 0 ? <p style={{ color: "gray" }}>{LocalizedLanguage.Recordnotfound}</p> : ''}
          </div>
          <AddCustomersNotepoup updateSomething={updateSomething} isShow={isShowNoteModel} UID={UID} customerId={updateCustomerId} toggleNoteModel={toggleNoteModel} />
          <AdjustCreditpopup updateSomething={updateSomething} isShow={isShowCreditModel} toggleCreditModel={toggleCreditModel} details={customerDetailData} UID={UID} />

          <div className="footer">
            <button id="customerToTransactions" onClick={() => OpenTransactions(customerDetailData)}>{LocalizedLanguage.ViewTransactions}</button>
            <button id="addCustToSaleButton" onClick={() => addCustomerToSale(customerDetailData)}>{LocalizedLanguage.AddToSale}</button>
          </div>
        </div> : <><div id="CVDetailed" className="cv-detailed">
          <div className="no-search-results-detailed">
            <p className="style1">{LocalizedLanguage.Nocustomertodisplay}</p>
            <p className="style2">{LocalizedLanguage.Trysearchingforancustomeror} <br /> {LocalizedLanguage.selectfromlisttoview}</p>
          </div></div></>}
      </div>
      {
        isShowcreatecustomerToggle === true ?
          <CustomerCreate
            isShow={isShowcreatecustomerToggle}
            toggleCreateCustomer={toggleCreateCustomer}
            toggleEditcustomer={toggleEditcustomer}
            editcustomerparam={editcustomerparam}
            customerDetailData={customerDetailData ? customerDetailData : ""}
            CustomerAddress={CustomerAddress}
            getCustomerFromIDB={getCustomerFromIDB}
            sendCreateCustomerRes={sendCreateCustomerRes}
            updateSomething={updateSomething}
            togglePermissionPopup={togglePermissionPopup}
            editCustomerTrue={editCustomerTrue}
            searchSringCreate={""}
          /> : null
      }
        {isFreePlan==true &&
        <NoUserAccess isShow={isFreePlan} tab={LocalizedLanguage.customers}></NoUserAccess>
        }
      {/* <MsgPopup isShow={isShowMsg} toggleMsgPopup={toggleMsgPopup} msgTitle={msgTitle} msgBody={msgBody} img={warningImg}></MsgPopup> */}
      {isShowPermissionPopup === true ? <PermissionPopup isShow={isShowPermissionPopup} togglePermissionPopup={togglePermissionPopup}></PermissionPopup> : null}
      {isShowiFrameWindow == true ? DisplayPluginPopup: null}
    </React.Fragment>
  )
}

export default CustomerView

