// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile,isIOS,iPhone,iPad,iPod,IOS
// } from "react-device-detect";

export function showAndroidToast(udid, orderId, orderType) {
  // alert("AndroidFunctionCall ="+printBody )
  // console.log("Android Call");
  const Android = window.Android;
  if (typeof Android !== "undefined" && Android !== null) {
    // // console.log("Android Call")
    // Android.showToast(toast);
    Android.makePrint(udid, orderId, orderType)
  }
}
export function showAndroidReceipt(receipt, PrintReceiptData) {
  // alert("AndroidFunctionCall ="+printBody )
  // console.log("Android Call");
  // console.log("receipt", receipt, "PrintReceiptData", PrintReceiptData)
  const Android = window.Android;
  if (typeof Android !== "undefined" && Android !== null) {

    Android.generateReceipt(JSON.stringify(receipt), JSON.stringify(PrintReceiptData))
  }
}
export function setAndroidKeyboard(eventname) {
  const Android = window.Android;
  if (typeof Android !== "undefined" && Android !== null) {
    // var logout=localStorage.getItem("logoutclick");
    // alert(eventname);
    localStorage.setItem("env_type", "Android");
    Android.androidClickEvent(eventname)
  }

}


export function androidSearchClick() {
  const Android = window.Android;
  if (typeof Android !== "undefined" && Android !== null) {
    // var logout=localStorage.getItem("logoutclick");
    Android.searchClick('text')
  }
}

export function androidGetUser() {
  const Android = window.Android;
  if (typeof Android !== "undefined" && Android !== null) {
    // var logout=localStorage.getItem("logoutclick");
    Android.getuser()
  }
}
export function androidDisplayScreen(itemname, amount, total, mode) {
  const Android = window.Android;
  if ((typeof Android !== "undefined" && Android !== null) && (Android.getDatafromDevice("isWrapper") == true)) {
    // let fdata=localStorage.getItem('selected_ffdisplay');
    // if(typeof fdata!="undefined" && fdata=="yes")
    // {
    Android.displayScreen(itemname, amount, total, mode);
    // }
  }
  // else
  // {
  //   if(typeof Android !== "undefined" && Android !== null) {    
  //     Android.displayScreen(itemname,amount,total,mode)
  //   }
  // }

}
export function openCahsDrawer() {
  // console.log("openCahsDrawer");
  const Android = window.Android;
  if (typeof Android !== "undefined" && Android !== null) {
    Android.openCahsDrawer()
  }
}

export function AndroidExtentionFinished() {
  // console.log("extentionFinished");
  const Android = window.Android;
  if (typeof Android !== "undefined" && Android !== null) {
    var path = window.location.href
    // console.log('--------path--------------', path);
    Android.extentionFinished(path)
  }
}
export function openCashBox() {
  const Tizen = window.Tizen;
  const Android = window.Android;
  var isTizenWrapper = localStorage.getItem("isTizenWrapper");
  if (isTizenWrapper && isTizenWrapper != null && typeof isTizenWrapper != "undefined" && isTizenWrapper == "true") {
    if (Tizen && Tizen != null && typeof Tizen != "undefined") {
      Tizen.openCashBox();
    }
  }
  if ((typeof Android !== "undefined" && Android !== null) && (Android.getDatafromDevice("isWrapper") == true)) {
    Android.openCahsDrawer();
  }

  sendDataToWrapper("open_cashdrawer", "", { "version": "3.0" })
}


//for 3.0-------------------------------------
//03/02/2023
//function send data to client for in case for wrapper and they use that data to print receipt
export function wrapperReceiptData(PrintReceiptData) {
  // console.log("Android Call");
  // console.log("wrapperReceiptData", PrintReceiptData)
  const Android = window.Android;
  if (typeof Android !== "undefined" && Android !== null) {
    Android.ReceiptDetailsListener("", JSON.stringify(PrintReceiptData))
  }
}

export function sendDataToWrapper(method, misc, data) {
 // console.log("----" + method + "-----data-->" + misc + "--->" + JSON.stringify(data))
  const Wrapper = window.Wrapper;
  if (typeof Wrapper !== "undefined" && Wrapper !== null) {
    switch (method) {
      case "store_detail":
        Wrapper.StoreDetailsListener(misc, JSON.stringify(data))
        break;
      case "receipt_detail":
        Wrapper.ReceiptDetailsListener(misc, JSON.stringify(data))
        break;
      case "eod_detail":
        Wrapper.EndOfDayDetailsListener(misc, JSON.stringify(data))
        break;
      case "cart_activity_detial":
        Wrapper.CartActivityListener(misc, JSON.stringify(data))
        break;
      case "open_cashdrawer":
        Wrapper.openCashDrawerListener(JSON.stringify(data))
        break;
      // case "store_detail":
      //   Wrapper.StoreDetailsListener(JSON.stringify(data))
      //   break;
      case "switch_user":
        Wrapper.switchUsersListener(JSON.stringify(data))
        break;
      case "current_view":
        var _viewdata = {
          version: "3.0",
          data: {
            view: misc
          }
        }
        Wrapper.viewListener(JSON.stringify(_viewdata))
        break;
      case "customer_display":
        var _images = localStorage.getItem("AdvImages") ? JSON.parse(localStorage.getItem("AdvImages")) : [];
        var _viewdata = {
          version: "3.0",
          data: {
            images: _images
          }
        }
        Wrapper.CustomerDisplayListener(JSON.stringify(_viewdata))
        break;
      case "cash_ledger_receipt":
        Wrapper.CashLedgerListener(misc, JSON.stringify(data))
        break;

      default:
        break;
    }
  }
}
export function isAutoFocus() {
  var _autoFocus = true;
  const Wrapper = window.Wrapper;
  if (typeof Wrapper !== "undefined" && Wrapper !== null) {
    if (localStorage.getItem("autoselect") && localStorage.getItem("autoselect") == "0") {
      _autoFocus = false;
    }
    else if (localStorage.getItem("autoselect") && localStorage.getItem("autoselect") == "1") {
      _autoFocus = true;
    }
  }
  return _autoFocus;
}
// export function StoreDetailsListener(misc, data) {
//   const Android = window.Android;
//   if (typeof Android !== "undefined" && Android !== null) {
//     Android.StoreDetailsListener(JSON.stringify(PrintReceiptData))
//   }
// }
// export function ReceiptDetailsListener(misc, data) {
//   const Android = window.Android;
//   if (typeof Android !== "undefined" && Android !== null) {
//     Android.ReceiptDetailsListener(JSON.stringify(PrintReceiptData))
//   }
// }
// export function EndOfDayDetailsListener(misc, data) {
//   const Android = window.Android;
//   if (typeof Android !== "undefined" && Android !== null) {
//     Android.EndOfDayDetailsListener(JSON.stringify(PrintReceiptData))
//   }
// }
// export function CartActivityListener(misc, data) {
//   const Android = window.Android;
//   if (typeof Android !== "undefined" && Android !== null) {
//     Android.CartActivityListener(JSON.stringify(PrintReceiptData))
//   }
// }
// export function openCashDrawerListener() {
//   const Android = window.Android;
//   if (typeof Android !== "undefined" && Android !== null) {
//     Android.openCashDrawerListener(JSON.stringify(PrintReceiptData))
//   }
// }
