import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { useLoginMutation,useGetAllRegisterQuery } from '../../../components/login/loginService';
import { groupAPI } from './groupAPI';
import STATUSES from '../../../constants/apiStatus';


const initialState = {
  "status": STATUSES.IDLE,
  "data": "",
  "error": '',
  "is_success": false
};


export const group = createAsyncThunk(
  'group/groupAPI',
  async (parameter, { rejectWithValue }) => {

    try {
      const response = await groupAPI(parameter);
      // The value we return becomes the `fulfilled` action payload
      return response;
    } catch (err) {

      return rejectWithValue(err.response.data)
    }

  }
);
export const groupSlice = createSlice({
  name: 'group',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: () => {}
  extraReducers: (builder) => {
    builder
      .addCase(group.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(group.fulfilled, (state, action) => {
        state.status = action.payload && action.payload.is_success == true ? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload.is_success == true ? action.payload : "");
        state.error = action.payload && action.payload.is_success == true ? "" : (action.payload.hasOwnProperty('exceptions') ? action.payload.hasOwnProperty('exceptions').exceptions[0] : action.payload.message);
        state.is_success = action.payload && action.payload.is_success == true ? true : false;
      })
      .addCase(group.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});

//export const { loginPanding, loginSuccess, loginFail } = groupSlice.actions;


export default groupSlice;
