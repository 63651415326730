import React, { useEffect, useLayoutEffect } from "react";
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
import AngledBracket_Left_Blue from '../../../assets/images/svg/AngledBracket-Left-Blue.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { createPin, validatePin } from "../../pinPage/pinSlice"
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { useIdleTimer } from 'react-idle-timer';
import ActiveUser from '../../../settings/ActiveUser';
import Config from '../../../Config';
import { NavigateComponent } from "../../../settings";
const IdleScreen = (props) => {
    const navigate = useNavigate();

    const onPresenceChange = (presence) => {
        // Handle state changes in one function
    }

    const onPrompt = () => {
        console.log("propmt----");
        // Fire a Modal Prompt
    }

    const onIdle = () => {
        console.log("=======it's  idle--------")
        sessionStorage.setItem("lastUrl",window.location.pathname);
        localStorage.removeItem("user");
        //navigate('/pin');
        NavigateComponent(navigate,'pin');

        // Close Modal Prompt
        // Do some idle action like log out your user
    }

    const onActive = (event) => {
        // Close Modal Prompt
        // Do some active action
    }

    const onAction = (event) => {
        // Do something when a user triggers a watched event
    }
////Commented this on client request 27/10/2023
    // useIdleTimer({
    //     onPresenceChange,
    //     onPrompt,
    //     onIdle,
    //     onActive,
    //     onAction,
    //     timeout: Config.key.IDLE_SCREEN_TIME_OUT,
    //     // timeout: 5000,
    //     promptBeforeIdle: 0,
    //     events: [
    //         'mousemove',
    //         'keydown',
    //         'wheel',
    //         'DOMMouseScroll',
    //         'mousewheel',
    //         'mousedown',
    //         'touchstart',
    //         'touchmove',
    //         'MSPointerDown',
    //         'MSPointerMove',
    //         'visibilitychange',
    //         'focus'
    //     ],
    //     immediateEvents: [],
    //     debounce: 0,
    //     throttle: 0,
    //     eventsThrottle: 200,
    //     element: document,
    //     startOnMount: true,
    //     startManually: false,
    //     stopOnIdle: false,
    //     crossTab: false,
    //     name: 'idle-timer',
    //     syncTimers: 0,
    //     leaderElection: false
    // })
    return (<></>)
}

export default IdleScreen 