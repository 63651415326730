import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { useLoginMutation,useGetAllRegisterQuery } from '../../../components/login/loginService';
import {make_payconiq_paymentAPI,check_payconiq_pay_statusAPI,cancel_payconiq_paymentAPI } from './paymentAPI';
import STATUSES from '../../../../constants/apiStatus';


const initialState = {
  "status": STATUSES.IDLE,
  "data": "", 
  "error":'',
  "is_success":false
};


export const make_payconiq_payment = createAsyncThunk(
  'payment/make_payconiq_paymentAPI',
  async (parameter,{rejectWithValue}) => {   
   
   try {
     const response = await make_payconiq_paymentAPI(parameter);
          // The value we return becomes the `fulfilled` action payload
          return response;
   } catch (err) {
   
    return rejectWithValue(err.response.data)
  }
         
  }
);
export const make_payconiq_paymentSlice = createSlice({
  name: 'make_payconiq_payment',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: { 
    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
 // extraReducers: () => {}
  extraReducers: (builder) => {    
    builder     
      .addCase(make_payconiq_payment.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data="";
        state.error="";
        state.is_success=false;
      })
      .addCase(make_payconiq_payment.fulfilled, (state, action) => {       
          state.status = action.payload && action.payload.is_success==true? STATUSES.IDLE: STATUSES.ERROR;
          state.data=(action.payload && action.payload.is_success==true ?action.payload:"");  
          state.error=null;//action.payload && action.payload.is_success==true?"": action.payload.exceptions[0];
          state.is_success=action.payload && action.payload.is_success==true? true: false;      
      })
      .addCase(make_payconiq_payment.rejected, (state,action) => {
        state.status = STATUSES.IDLE;
        state.data="";
        state.error = action.error;
        state.is_success=false;
      });
  },
});

export const { } = make_payconiq_paymentSlice.actions;

//----
export const check_payconiq_pay_status = createAsyncThunk(
    'payment/check_payconiq_pay_statusAPI',
    async (parameter,{rejectWithValue}) => {   
     
     try {
       const response = await check_payconiq_pay_statusAPI(parameter);
            // The value we return becomes the `fulfilled` action payload
            return response;
     } catch (err) {
     
      return rejectWithValue(err.response.data)
    }
           
    }
  );
  export const check_payconiq_pay_statusSlice = createSlice({
    name: 'check_payconiq_pay_status',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: { 
      
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
   // extraReducers: () => {}
    extraReducers: (builder) => {    
      builder     
        .addCase(check_payconiq_pay_status.pending, (state) => {
          state.status = STATUSES.LOADING;
          state.data="";
          state.error="";
          state.is_success=false;
        })
        .addCase(check_payconiq_pay_status.fulfilled, (state, action) => {       
            state.status = action.payload && action.payload.is_success==true? STATUSES.IDLE: STATUSES.ERROR;
            state.data=(action.payload && action.payload.is_success==true ?action.payload:"");  
            state.error= null;//action.payload && action.payload.is_success==true?"": action.payload.exceptions[0];
            state.is_success=action.payload && action.payload.is_success==true? true: false;      
        })
        .addCase(check_payconiq_pay_status.rejected, (state,action) => {
          state.status = STATUSES.IDLE;
          state.data="";
          state.error = action.error;
          state.is_success=false;
        });
    },
  });
  
  export const { } = check_payconiq_pay_statusSlice.actions;
  
//----

//----
export const cancel_payconiq_payment = createAsyncThunk(
    'payment/cancel_payconiq_paymentAPI',
    async (parameter,{rejectWithValue}) => {   
     
     try {
       const response = await cancel_payconiq_paymentAPI(parameter);
            // The value we return becomes the `fulfilled` action payload
            return response;
     } catch (err) {
     
      return rejectWithValue(err.response.data)
    }
           
    }
  );
  export const cancel_payconiq_paymentSlice = createSlice({
    name: 'cancel_payconiq_payment',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: { 
      
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
   // extraReducers: () => {}
    extraReducers: (builder) => {    
      builder     
        .addCase(cancel_payconiq_payment.pending, (state) => {
          state.status = STATUSES.LOADING;
          state.data="";
          state.error="";
          state.is_success=false;
        })
        .addCase(cancel_payconiq_payment.fulfilled, (state, action) => {       
            state.status = action.payload && action.payload.is_success==true? STATUSES.IDLE: STATUSES.ERROR;
            state.data=(action.payload && action.payload.is_success==true ?action.payload:"");  
            state.error=null;//action.payload && action.payload.is_success==true?"": action.payload.exceptions[0];
            state.is_success=action.payload && action.payload.is_success==true? true: false;      
        })
        .addCase(cancel_payconiq_payment.rejected, (state,action) => {
          state.status = STATUSES.IDLE;
          state.data="";
          state.error = action.error;
          state.is_success=false;
        });
    },
  });
  
  export const { } = cancel_payconiq_paymentSlice.actions;
  
//----

export default {make_payconiq_paymentSlice,check_payconiq_pay_statusSlice,cancel_payconiq_paymentSlice};
