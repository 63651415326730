import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import X_Icon_DarkBlue from '../../assets/images/svg/X-Icon-DarkBlue.svg';
import down_angled_bracket from '../../assets/images/svg/down-angled-bracket.svg';
import BlueDot from '../../assets/images/svg/BlueDot.svg';
import ViewIcon from '../../assets/images/svg/ViewIcon.svg';
import Add_Icon_White from '../../assets/images/svg/Add-Icon-White.svg';
import Transactions_Icon_White from '../../assets/images/svg/Transactions-Icon-White.svg';
import CircledPlus_Icon_Blue from '../../assets/images/svg/CircledPlus-Icon-Blue.svg';
import Search_Icon_Blue from '../../assets/images/svg/Search-Icon-Blue.svg';
import AdvancedSearchCancelIcon from '../../assets/images/svg/AdvancedSearchCancelIcon.svg';

import { useIndexedDB } from 'react-indexed-db';
import { AddItemType } from "../common/EventFunctions";
// import { toggleSubwindow } from "../common/EventFunctions";
import { getTaxAllProduct } from '../common/TaxSetting'
import { addSimpleProducttoCart } from "./product/productLogic";
import { product } from "./product/productSlice";
import { getInventory } from "./slices/inventorySlice";
import CommonModuleJS from "../../settings/CommonModuleJS";
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { useNavigate } from "react-router-dom";
import { postMeta, getPostMeta } from "../common/commonAPIs/postMetaSlice";
import { popupMessage } from "../common/commonAPIs/messageSlice";
import { currecyFunction } from "../common/commonFunctions/currencyFunction";
import { addProductWithDefaultModifier, getProductModifiers } from "./product/productModifier";
import { customergetDetail } from "../customer/CustomerSlice";
import STATUSES from "../../constants/apiStatus";
import Config from '../../Config'
import { isAutoFocus } from "../../settings/AndroidIOSConnect";
const AdvancedSearch = (props) => {
    const refInputSearch = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getAll } = useIndexedDB("products");
    const { getAll: getAllModifier } = useIndexedDB("modifiers");
    const [allProductList, setAllProductList] = useState([]);
    const [allCustomerList, setAllCustomerList] = useState([]);
    const [allModifiersList, setAllModifiersList] = useState([]);
    const [allGroupList, setAllGroupList] = useState([]);

    const [filteredProductList, setFilteredProductList] = useState([]);
    const [filteredCustomer, setFilteredCustomer] = useState([]);
    const [filteredGroup, setFilteredGroup] = useState([]);

    const [filterType, setFilterType] = useState('all');
    const [serachString, setSerachString] = useState(props.barcodeSearchString ? props.barcodeSearchString : '');
    const [serachCount, setSerachCount] = useState(0);
    const [isShowDDNSearch, setisShowDDNSearch] = useState(false)
    const [searchHistory, setSearchHistory] = useState([])
    const [allowGroup, setAllowGroup] = useState(false)
    const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : "");
    const [respGroup] = useSelector((state) => [state.group])

    const myRef = useRef(null);
    const varDownUp = useRef(1)
    const varRightLeft = useRef(0)
    const [allTotalProductList, setAllTotalProductList] = useState([]);

    const limitArrayByNum = (arr, num) => {
        let _arr = arr;
        if (_arr && _arr.length > 10) { _arr = _arr.slice(0, num); }
        return _arr;
    }
    const toggleDDNSearch = () => {
        setisShowDDNSearch(!isShowDDNSearch)
    }
    const getProductFromIDB = () => {
        getAll().then((rows) => {
            var allProdcuts = getTaxAllProduct(rows);
            setAllTotalProductList(allProdcuts);
            allProdcuts = allProdcuts.filter(a => a.ParentId === 0);
            let _allp = allProdcuts;
            // if (_allp && _allp.length > 10) { _allp = _allp.slice(0, 10); }
            setAllProductList(_allp);
            //setFilteredProductList(_allp ? _allp : []);
            setSerachCount(_allp.length);
            setSerachCount(_allp.length + allCustomerList.length + filteredGroup.length);
        });
    }
    const handleSearch = (event) => {
        let value = event.target.value;
        if (value === "") {
            setFilteredCustomer([]);
            setFilteredProductList([]);
            setFilteredGroup([]);
        }
        if (value != " ") {
            setSerachString(value)

        }

        setTimeout(() => {
            if (myRef?.current !== null && serachCount !== 0 && myRef.current.children !== null && myRef.current.children[1] !== undefined) {
                varRightLeft.current = 0;
                varDownUp.current = 1;
                // myRef.current.children[1].children[1].children[varRightLeft.current].classList.add('add_border')
                // myRef.current.children[1].children[1].children[varRightLeft.current].style.outline ="1px solid #1583d3"
                // myRef.current.children[varDownUp.current].style.boxShadow = "none";
                // myRef.current.children[1].style.boxShadow = "0px 10px 12px -12px";
            }
        }, 1);
    }
    const SetFilter = (ftype) => {
        setisShowDDNSearch(false)
        setFilterType(ftype);
    }
    const GetCustomerFromIDB = () => {
        useIndexedDB("customers").getAll().then((rows) => {
            let _allc = rows;
            //if (_allc && _allc.length > 10) { _allc = _allc.slice(0, 10); }
            setAllCustomerList(_allc);
            setSerachCount(_allc.length + allProductList.length + filteredGroup.length);
        });

    }
    const GetModifiersFromIDB = () => {

        useIndexedDB("modifiers").getAll().then((rows) => {
            setAllModifiersList(rows);
        });

    }
    const resGetPostMeta = useSelector((state) => state.getPostMeta)
    if (resGetPostMeta && resGetPostMeta.is_success === true) {
        if (resGetPostMeta.data && resGetPostMeta.data.content && resGetPostMeta.data.content.Slug === user.user_id + "_searchHistory") {
            localStorage.setItem(user.user_id + "_searchHistory", resGetPostMeta.data.content.Value)
            setTimeout(() => {
                Search_History('');
            }, 100);
        }
    }

    let useCancelled = false;
    useEffect(() => {
        if (useCancelled === false) {
            getProductFromIDB()
            GetCustomerFromIDB()
            GetModifiersFromIDB()
            if (user !== "") {
                var parma = user.user_id + "_searchHistory";
                dispatch(getPostMeta(parma));
            }
            if (user.group_sales && user.group_sales !== null && user.group_sales !== "" && user.group_sales !== "undefined" && user.group_sales === true) {
                setAllowGroup(true);
            }
            else {
                setAllowGroup(false);
            }
        }
        return () => {
            useCancelled = true;
        }
    }, []);

    useEffect(() => {
        if (respGroup && respGroup.data && respGroup.data.content) {
            if (respGroup.data.is_success === true) {
                var _allg = respGroup.data.content;
                setAllGroupList(_allg);
                setSerachCount(allProductList.length + allCustomerList.length + _allg.length);
            }
        }
    }, [respGroup])

    var _SubCategory = [];
    const retrunItrateLoop = (found, filterCategoryCode) => {
        var setSubCategory = _SubCategory;
        filterCategoryCode.push(found.Code)
        if (found && found.Subcategories && found.Subcategories.length > 0) {
            found.Subcategories.map(element => {
                setSubCategory.push(element)
                filterCategoryCode.push(element.Code)
                if (element && element.Subcategories && element.Subcategories.length > 0) {
                    retrunItrateLoop(element, filterCategoryCode)
                }
            })
            const arrayUniqueByKeyArray = [...new Map(filterCategoryCode.map(item =>
                [item, item])).values()];
            _SubCategory = arrayUniqueByKeyArray;
            return arrayUniqueByKeyArray
        }
        return filterCategoryCode
    }

    useEffect(() => {
        productDataSearch(serachString.trim());
    }, [filterType]);

    useEffect(() => {
        if (serachString.trim() != "")
            productDataSearch(serachString.trim());
    }, [serachString]);

    useEffect(() => {
        if ((props.barcodeSearchString && props.barcodeSearchString !== '')) {
            setFilterType('product')
            productDataSearch(props.barcodeSearchString.trim());
        }
    }, [allProductList]);


    const Search_History = (e) => {
        var sArray = [];
        if (e !== "" && e.target.value.trim() !== "") {
            let _sValue = e.target.value.trim();
            if (localStorage.getItem(user.user_id + "_searchHistory")) {
                sArray = JSON.parse(localStorage.getItem(user.user_id + "_searchHistory"));
                let result = sArray.some(item => _sValue === item);
                if (result === false) {
                    if (sArray && sArray.length >= 10) {
                        sArray.pop();
                    }
                    sArray = [_sValue].concat(sArray)
                }
            }
            else {
                let result = sArray.some(item => _sValue === item);
                if (result === false) {
                    sArray = [_sValue].concat(sArray);
                }
            }

            setSearchHistory(sArray);
            if (user && user.user_id) {
                localStorage.setItem(user.user_id + "_searchHistory", JSON.stringify(sArray));
                var parma = { "Slug": user.user_id + "_searchHistory", "Value": JSON.stringify(sArray), "Id": 0, "IsDeleted": 0 };
                dispatch(postMeta(parma));
            }

        }
        else {
            if (localStorage.getItem(user.user_id + "_searchHistory")) {
                if (user && user.user_id) {
                    sArray = JSON.parse(localStorage.getItem(user.user_id + "_searchHistory"));
                    setSearchHistory(sArray);
                }
            }
        }
    }
    const productDataSearch = (item1) => {
        if (item1 === '' || item1.length < Config.key.SEARCH_START_LENGTH) {
            return;

        }
        var _filtered = [];
        var _filteredCustomer = [];
        var _filteredGroup = [];
        var value = item1;
        var scount = 0;
        if (filterType === "product" || filterType === "all") {
            // Search in Products
            var serchFromAll = [];
            value && value !== "" && value.split(" ").map(searchItem => {
                var result = allTotalProductList.filter((item) => (
                    // (item.Title && item.Title.toLowerCase().includes(value.toLowerCase()))
                    (item.Title && (item.Title.replace("-", " ").replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, ' ').toLowerCase().split(" ").some(m => { return m.startsWith(searchItem.toLowerCase()) }) || item.Title.toLowerCase().startsWith(searchItem.toLowerCase()))) //13-dec-2022 change "includes" to "some and startsWith", start searching from first letter
                    || (item.Barcode && item.Barcode.toString().toLowerCase().includes(value.toLowerCase()))
                    || (item.Sku && (item.Sku.toString().toLowerCase().split(" ").some(m => { return m.startsWith(searchItem.toLowerCase()) }) || item.Sku.toString().toLowerCase().startsWith(searchItem.toLowerCase())))//13-dec-2022 change "includes" to "some and startsWith", start searching from first letter
                    // ))
                    // var result = allProductList.filter((item) => (
                    || (item.Title && item.Title.toLowerCase().includes(value.toLowerCase()))
                    || (item.Barcode && item.Barcode.toString().toLowerCase().includes(value.toLowerCase()))
                    || (item.Sku && item.Sku.toString().toLowerCase().includes(value.toLowerCase()))
                ))
                if (value && value !== "") {
                    var result1 = allTotalProductList.filter((item) => (
                        (item.Title && item.Title.toLowerCase() == (value.toLowerCase()))
                        || (item.Barcode && item.Barcode.toString().toLowerCase() == (value.toLowerCase()))
                        || (item.Sku && item.Sku.toString().toLowerCase() == (value.toLowerCase()))
                    ))
                }
                // var completeMatch = allProductList.filter((item) => (
                //     (item.Title && item.Title.toLowerCase() == (value.toLowerCase()))
                //     || (item.Barcode && item.Barcode.toString().toLowerCase() == (value.toLowerCase()))
                //     || (item.Sku && item.Sku.toString().toLowerCase() == (value.toLowerCase()))
                // ))
                // if (typeof completeMatch != "undefined" && completeMatch !== null && completeMatch.length > 0) {
                //     result = completeMatch;
                // }
                // if (result && result.length > 0) {
                //     result.map(r => {
                //         serchFromAll.push(r);
                //     })
                // }
                serchFromAll = [...new Set([...result1, ...result])];
            })

            //-------//Filter child and parent-------------
            var parentArr = [];
            serchFromAll && serchFromAll.map(item => {
                if (item.ParentId !== 0) {
                    var parrentofChild = allProductList.find(function (element) {
                        return (element.WPID === item.ParentId)
                    });
                    if (parrentofChild)
                        parentArr.push(parrentofChild);
                }
            })
            serchFromAll = [...new Set([...serchFromAll, ...parentArr])];
            if (!serchFromAll || serchFromAll.length > 0) {
                var parentProduct = serchFromAll.filter(item => {
                    return (item.ParentId === 0)
                })
                parentProduct = parentProduct ? parentProduct : []
                _filtered = [...new Set([..._filtered, ...parentProduct])];

                if (_filtered && _filtered.length > 10) {
                    if (filterType === "all") {
                        _filtered = _filtered.slice(0, 10);
                    }
                }
            }
            scount += _filtered.length;
        }
        if (filterType === "customer" || filterType === "all") {
            // Search in Customer
            _filteredCustomer = allCustomerList.filter((item) => (
                (item.FirstName && item.LastName && (item.FirstName.toString().toLowerCase() + " " + item.LastName.toString().toLowerCase()).startsWith(value.toLowerCase())) ||
                (item.FirstName && item.FirstName.toLowerCase().startsWith(value.toLowerCase())) //13-dec-2022 change "includes" to "startsWith", start searching from first letter
                || (item.LastName && item.LastName.toString().toLowerCase().startsWith(value.toLowerCase())) //13-dec-2022 change "includes" to "startsWith", start searching from first letter
                || (item.Contact && item.Contact.toString().search(value.toLowerCase())>-1)
                || (item.Email && item.Email.toString().toLowerCase().search(value.toLowerCase())>-1) //13-dec-2022 change "includes" to "startsWith", start searching from first letter
            ))
            if (_filteredCustomer && _filteredCustomer.length > 10) {
                if (filterType === "all") { _filteredCustomer = _filteredCustomer.slice(0, 10); }
            }
            //setFilteredCustomer(_filteredCustomer);
            scount += _filteredCustomer.length;
        }
        if ((filterType === "group" || filterType === "all") && allowGroup === true) {
            _filteredGroup = allGroupList.filter((item) => (
                (item.Label && item.Label.toLowerCase().includes(value.toLowerCase()))))

            scount += _filteredGroup.length;
        }

        if (_filtered && _filtered.length > 0) {
            _filtered = AddItemType(_filtered, "product");
        }

        setFilteredCustomer(_filteredCustomer);
        setFilteredProductList(_filtered);
        setFilteredGroup(_filteredGroup);
        setSerachCount(scount);
    }
    // updated on 27sep2022: check permission for product x
    const addToCart = async (item) => {
        let type = item.Type;
        var taglist = item.Tags ? item.Tags !== "" ? item.Tags.split(",") : null : null;
        if (taglist && (taglist !== null && taglist.includes('oliver_produt_x') === true) &&
            (CommonModuleJS.showProductxModal() !== null && CommonModuleJS.showProductxModal()
                === true) && item !== null && item.ParamLink !== "" && item.ParamLink
            !== "False" && item.ParamLink !== null) {
            //console.log("product x with tag--" + item.ParamLink)
            props.toggleProductTypeNotSupported && props.toggleProductTypeNotSupported();
            // var data = { title: "", msg: LocalizedLanguage.productxOutOfStock, is_success: true }
            // dispatch(popupMessage(data));
            closePopUp();
            return
        }
        if ((type !== "simple" && type !== "variable" && type !== "variation") /*&& (CommonModuleJS.showProductxModal() !== null && CommonModuleJS.showProductxModal() === false)*/) {
            props.toggleProductTypeNotSupported && props.toggleProductTypeNotSupported();
        }
        else {
            if (item.ManagingStock === true && item.hasOwnProperty("WPID") && item.WPID != 0 && item.hasOwnProperty("Type") && item.Type == "simple") {
                dispatch(getInventory(item.WPID)); //call to get product warehouse quantity
            }
            // var result = addSimpleProducttoCart(item);
            // getModifiers(item)

            var result = addProductWithDefaultModifier(item, allModifiersList);
            //console.log("--product with default modifier---->" + result)
            if (result === 'outofstock') {
                props.toggleOutOfStock();
            }
            else {
                dispatch(product());
                setSerachString('');
                props.toggleAdvancedSearch();
            }
        }
    }

    //  Modfier product direct add to card from AdvanceSearch section
    // const getModifiers = (_product = null, isClear = false) => {
    //     getAllModifier().then((rows) => {
    //         getProductModifiers(rows, _product)
    //     });
    // }
    // careated by : 
    // description
    // update by: Nagendra Suryawanshi
    // updated description: call api to get warehouse quantity of the product
    // updated on 27sep2022: check permission for product x
    const viewProduct = (item) => {
        let type = item.Type;
        if ((type !== "simple" && type !== "variable" && type !== "variation") /*&& (CommonModuleJS.showProductxModal() !== null && CommonModuleJS.showProductxModal() === false)*/) {
            props.toggleProductTypeNotSupported && props.toggleProductTypeNotSupported();
        }
        else {
            if (item.ManagingStock === true) {
                dispatch(getInventory(item.WPID)); //call to get product warehouse quantity
            }
            setTimeout(() => {
                props.openPopUp(item);
                props.fatchUpdateInventory && props.fatchUpdateInventory()
            }, 100);

            setSerachString('');
            props.toggleAdvancedSearch();
        }
    }
    const addCustomerToSale = (cutomer_data) => {
        dispatch(customergetDetail(cutomer_data.WPId));
        setSerachString('');
        props.toggleAdvancedSearch();
        dispatch(product());
    }




    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            setSerachString('');
            props.toggleAdvancedSearch();
        }
    }
    const closePopUp = () => {
        setSerachString('');
        props.toggleAdvancedSearch();
    }
    const clearSearch = () => {
        setSerachString('');
        setFilteredCustomer([]);
        setFilteredProductList([]);
        setFilteredGroup([]);
        if (isAutoFocus() == true)
            refInputSearch && refInputSearch.current && refInputSearch.current.focus();

    }
    const viewCustomertransaction = (email) => {
        sessionStorage.setItem("transactionredirect", email);
        navigate('/transactions')
    }
    const viewCustomer = (email) => {
        if (email !== '') {
            sessionStorage.setItem("customerredirect", email ? email : "");
            navigate('/customers')
        }
    }


    const handleFocus = (e) => {
        if (myRef?.current !== null && serachCount !== 0 && myRef.current.children.length > 1) {
            if (e.key === "Enter") {
                myRef.current.children[1].children[1].children[varRightLeft.current].click()
            }
        }
        setTimeout(() => {
            if (e.key === 'Backspace' && myRef.current !== null) {
                if (myRef?.current.children.length > 1) {

                    varRightLeft.current = 0;
                    varDownUp.current = 1;
                    if (isAutoFocus() == true)
                        refInputSearch.current.focus()
                    myRef.current.children[1].children[1].children[varRightLeft.current].classList.add('add_border')
                }
            }
        }, 1);
    }


    const arrowKeyFunction = (e) => {
        if (myRef?.current && myRef.current && myRef.current !== null && serachCount !== 0 && myRef.current.children && myRef.current.children.length>1 ) {
            myRef?.current && myRef.current.children[1].children[1].children[0].classList.remove("add_border")
        }
        if (e.key === "ArrowDown") {
            varRightLeft.current = 0;
            varDownUp.current++;
            if (varDownUp.current >= myRef.current.children.length) {
                varDownUp.current = myRef.current.children.length - 1;
            }
            if (isAutoFocus() == true)
                myRef.current.children[varDownUp.current].children[1].children[varRightLeft.current].focus()
        } else if (e.key === "ArrowUp") {
            varRightLeft.current = 0;
            varDownUp.current--;
            if (varDownUp.current < 1) {
                varDownUp.current = 1;
            }
            if (isAutoFocus() == true)
                myRef.current.children[varDownUp.current].children[1].children[varRightLeft.current].focus()
        } else if (e.key === "ArrowRight") {
            varRightLeft.current++;
            if (varRightLeft.current >= myRef.current.children[varDownUp.current].children[1].children.length) {
                varRightLeft.current = myRef.current.children[varDownUp.current].children[1].children.length - 1;
            }
            if (isAutoFocus() == true)
                myRef.current.children[varDownUp.current].children[1].children[varRightLeft.current].focus()
        } else if (e.key === "ArrowLeft") {
            varRightLeft.current--;
            if (varRightLeft.current <= 0) {
                varRightLeft.current = 0;
            }
            if (isAutoFocus() == true)
                myRef.current.children[varDownUp.current].children[1].children[varRightLeft.current].focus()
        } else if (e.key === "Escape") {
            closePopUp()
        }


        if (myRef?.current && (varDownUp.current >= 2 || varRightLeft.current >= 1) && myRef.current.children && myRef.current.children.length>1 ) {
            myRef.current.children[1].children[1].children[varRightLeft.current].classList.remove('add_border')
            myRef.current.children[1].children[1].children[0].style.border = "none";
        }

        if (myRef?.current !== null && serachCount !== 0 && myRef.current.children.length > 1) {
            if (e.key === "Enter") {
                myRef.current.children[varDownUp.current].children[1].children[varRightLeft.current].click()
            }
        }
    }




    return <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onKeyDown={(e) => arrowKeyFunction(e)} onClick={(e) => outerClick(e)}><div className={props.isShow === true ? "subwindow advanced-search current" : "subwindow advanced-search"}>
        <div className="subwindow-header">
            <p>{LocalizedLanguage.advancedsearch}</p>
            <button className="close-subwindow" onClick={() => closePopUp()}>
                <img src={X_Icon_DarkBlue} alt="" />
            </button>
            <div className="input-wrapper" id="advSearchInputWrapper">
                <input ref={refInputSearch} type="text" id="advancedSearchBar" value={serachString} placeholder={LocalizedLanguage.starttosearch} onChange={e => handleSearch(e)} onBlur={e => Search_History(e)} onKeyDown={(e) => handleFocus(e)} autoComplete="off" autoFocus={true} />
                <img src={Search_Icon_Blue} alt="" id="advSearchInputIcon" />
                {serachString !== "" ? <button id="advSearchInputCancel" onClick={() => clearSearch()}>
                    <img src={AdvancedSearchCancelIcon} alt="" />
                </button> : null}
            </div>
        </div>
        <div className="subwindow-body">
            <div className="left-col">
                <p>{LocalizedLanguage.searchby}</p>
                <div className="radio-group">
                    <div id="mobileSearchModToggle" className={isShowDDNSearch === true ? "dropdown-input open" : "dropdown-input"} onClick={() => toggleDDNSearch()}>
                        <p><b>{LocalizedLanguage.searchfor}:</b> {LocalizedLanguage.allresults}</p>
                        <img src={down_angled_bracket} alt="" />
                    </div>
                    <label onClick={() => SetFilter('all')}>
                        <input type="radio" id="allResults" name="search_modifier" value="allResults" defaultChecked={filterType === 'all' ? true : false} />
                        <div className="custom-radio" >
                            <img src={BlueDot} alt="" />
                        </div>
                        <p>{LocalizedLanguage.allresults}</p>
                    </label>
                    <label onClick={() => SetFilter('product')}>
                        <input type="radio" id="products" name="search_modifier" value="products" defaultChecked={filterType === 'product' ? true : false} />
                        <div className="custom-radio" >
                            <img src={BlueDot} alt="" />
                        </div>
                        <p>{LocalizedLanguage.products}</p>
                    </label>
                    <label onClick={() => SetFilter('customer')}>
                        <input type="radio" id="customers" name="search_modifier" value="customers" defaultChecked={filterType === 'customer' ? true : false} />
                        <div className="custom-radio" >
                            <img src={BlueDot} alt="" />
                        </div>
                        <p>{LocalizedLanguage.customers}</p>
                    </label>
                    {allowGroup === true ?
                        <label onClick={() => SetFilter('group')}>
                            <input type="radio" id="groups" name="search_modifier" value="groups" defaultChecked={filterType === 'group' ? true : false} />
                            <div className="custom-radio" >
                                <img src={BlueDot} alt="" />
                            </div>
                            <p>{LocalizedLanguage.groups}</p>
                        </label> : null}
                </div>
                <p>{LocalizedLanguage.recentsearches}</p>
                <div className="recent-searches">
                    {searchHistory && searchHistory.map(s => {
                        return (<a key={s} href="#" onClick={() => setSerachString(s)}>{s}</a>)
                    })}

                </div>
            </div>
            <div className="right-col">
                {/* Will only appear if right col is empty besides start-searching element  */}
                {serachString.length < Config.key.SEARCH_START_LENGTH || (filteredCustomer.length === 0 && filteredGroup.length === 0 && filteredProductList.length === 0 && serachString === "") ?
                    <div className="start-searching display-flex">
                        <img src={Search_Icon_Blue} alt="" />
                        <p className="style1">{LocalizedLanguage.startdisplayresults}</p>
                        <p className="style2">{LocalizedLanguage.searchanycustomer} <br /> {LocalizedLanguage.grouptoresults}.</p>
                    </div> :
                    <React.Fragment>
                        <div className="header">
                            <p><b>{LocalizedLanguage.results}</b> ({serachCount} {LocalizedLanguage.searchresults})</p>
                        </div>
                        <div className="body" ref={myRef} id="selectDiv">
                            <div className="no-results">
                                <p className="style1">{LocalizedLanguage.Noresultsfound}</p>
                                <p className="style2">
                                    {LocalizedLanguage.Sorrysearchnotresults}. <br />
                                    {LocalizedLanguage.Trydoublcheck} <br />
                                    {LocalizedLanguage.searchforsimilar}
                                </p>
                                <div className="divider"></div>
                                <p className="style2">{LocalizedLanguage.customerfoundcustomer}:</p>
                                <button onClick={() => props.toggleCreateCustomer(serachString)}  >
                                    <img src={CircledPlus_Icon_Blue} alt="" />
                                    {LocalizedLanguage.createCustomer}
                                </button>
                            </div>


                            {
                                filteredProductList && filteredProductList.map((item, index) => {
                                    return <div className="search-result product" key={item.WPID}>
                                        <div className="col">

                                            <p className="style1">{LocalizedLanguage.product}</p>
                                            <p className="style2 search_title">{item.Title}</p>
                                            {/* <p className="style3">Funky Shoe Co.</p> */}
                                            {/* <p className="style3">${item.Price}</p> */}
                                            <p className="style3">{currecyFunction(item.Price)}</p>
                                            <p className="style3">SKU# {item.Sku}</p>
                                        </div>
                                        <div className="row">
                                            <button className="search-view" onClick={() => viewProduct(item)}>
                                                <img src={ViewIcon} alt="" />
                                                {LocalizedLanguage.view}
                                            </button>
                                            <button className={item.Type !== "simple" ? "search-add-to-sale btn-disable" : "search-add-to-sale"} onClick={() => item.Type !== "simple" ? viewProduct(item) : addToCart(item)} disabled={item.Type !== "simple" ? true : false}>
                                                <img src={Add_Icon_White} alt="" />
                                                {LocalizedLanguage.AddToSale}
                                            </button>
                                        </div>
                                    </div>
                                })
                            }
                            {
                                filteredCustomer && filteredCustomer.map((item, index) => {
                                    return <div className="search-result customer" key={item.Email + "_" + index}>
                                        <div className="col">
                                            <p className="style1">{LocalizedLanguage.customer}</p>
                                            <p className="style2">{item.FirstName + " " + item.LastName}</p>
                                            <p className="style3">{item.Email}</p>
                                            <p className="style3">{item.Contact}</p>
                                        </div>
                                        <div className="row">
                                            <button className="search-view" onClick={() => viewCustomer(item.Email)}>
                                                <img src={ViewIcon} alt="" />
                                                {LocalizedLanguage.view}
                                            </button>
                                            <button className="search-transactions" onClick={() => viewCustomertransaction(item.Email)} >
                                                <img src={Transactions_Icon_White} alt="" />
                                                {LocalizedLanguage.transactions}
                                            </button>
                                            <button className="search-add-to-sale" onClick={() => addCustomerToSale(item)}>
                                                <img src={Add_Icon_White} alt="" />
                                                {LocalizedLanguage.AddToSale}
                                            </button>
                                        </div>
                                    </div>
                                })}
                            {
                                filteredGroup && filteredGroup.map((item, index) => {
                                    return <div className="search-result group">
                                        <div className="col">
                                            <p className="style1">{item.GroupName}</p>
                                            <p className="style2">{item.Label}</p>
                                            <p className="style3">Party of 6</p>
                                            <p className="style3">Server: Freddy Mercury</p>
                                            <p className="style3">Order total: $223.45</p>
                                        </div>
                                        <div className="row">
                                            <button className="search-view">
                                                <img src={ViewIcon} alt="" />
                                                {LocalizedLanguage.view}
                                            </button>
                                            <button className="search-transactions">
                                                <img src={Transactions_Icon_White} alt="" />
                                                {LocalizedLanguage.transactions}
                                            </button>
                                            <button className="search-add-to-sale">
                                                <img src={Add_Icon_White} alt="" />
                                                {LocalizedLanguage.AddToSale}
                                            </button>
                                        </div>
                                    </div>
                                })}
                        </div>
                    </React.Fragment>}
            </div>
        </div>
    </div></div >
}

export default AdvancedSearch 