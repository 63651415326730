import { Markup } from "interweave";
export const encode_UDid = (udid) => {
  var UD_ID = btoa(udid)
  localStorage.setItem('UDID', UD_ID)
  return 'UDID';
}

/*------------------UDID Localstorage -----------------*/
export const get_UDid = (UDID) => {
  var decodedString = localStorage.getItem('UDID');
  var decod = decodedString ? window.atob(decodedString) : '';
  var getudid = decod;
  return getudid;
}

export const getShopName = () => {
  var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
  return <Markup content={client && client.subscription_detail ? client.subscription_detail.company_name : ""} noWrap={true}></Markup>
}
export const getCompanyName = () => {
  var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
  return client && client.subscription_detail ? client.subscription_detail.company_name : "";
}
export const getShopLogo = () => {
  var client = localStorage.getItem("orderreciept") ? JSON.parse(localStorage.getItem("orderreciept")) : null;
  return client && client.CompanyLogo ? client.CompanyLogo : '';
}
export const getUserShopLogo = () => {
  if (localStorage.getItem('user')) {
    var user = JSON.parse(localStorage.getItem('user'));
    if ( user !==null && typeof user.shop_logo != "undefined" && user.shop_logo != null) {
      return user.shop_logo;
    }
  }
  return '';
}
export const get_regName = () => {
  return <Markup content={localStorage.getItem('registerName') ? localStorage.getItem('registerName') : ''} noWrap={true}></Markup>
}
export const get_regId = () => {
  return localStorage.getItem('register') ? localStorage.getItem('register') : ''
}
export const get_locName = () => {
  return <Markup content={localStorage.getItem('LocationName') ? localStorage.getItem('LocationName') : ''} noWrap={true}></Markup>
}

export const get_userName = () => {
  var user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
  return user != "" && user != null ? user.display_name : '';
  //return localStorage.getItem('user_full_name') ? localStorage.getItem('user_full_name') : ''
}
export const get_employeeId = () => {
  var user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
  return user != "" && user !=null ? user.user_id : '';
  //return localStorage.getItem('user_full_name') ? localStorage.getItem('user_full_name') : ''
}
export const get_userName_Initial = () => {
  var user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
  if (user != "" && typeof user != "undefined" && user !== null) {
    var initial = user.display_name;
    initial = initial.split(' ');
    if (initial && initial.length > 1) {
      return initial[0].charAt(0).toUpperCase() + initial[1].charAt(0).toUpperCase()
    }
    else if (initial && initial.length == 1) {
      return initial[0].charAt(0).toUpperCase()
    }
    else {
      return '';
    }

  }

}
export const get_userId = () => {
  return localStorage.getItem('userId') ? localStorage.getItem('userId') : ''
}

export const get_customerName = () => {
  var localCustomer = localStorage.getItem('AdCusDetail');
  var customer = localCustomer && localCustomer !== "undefined" ? JSON.parse(localCustomer) : null;
  if (customer != null) {
    var name = customer.FirstName + " " + customer.LastName;
    if (name.trim() == "") { name = customer.Email }
    return { "Name": name, "Email": customer.Email }
  }
  else
    return null;
}
/*------------------ array ----------------------*/
export const chunkArray = (myArray, chunk_size) => {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];
  for (index = 0; index < arrayLength; index += chunk_size) {
    var myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }
  return tempArray;
}


export const plusIconSize = (length) => {
  var sizeArray = [];
  for (var i = 1; i <= length; i++) {
    sizeArray.push(i);
  }

  return sizeArray

}




