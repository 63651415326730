import React, { useEffect, useState } from "react";
import { getInclusiveTaxType, showTitle } from "../../settings";
import { addModiferTaxToLineItemTax, getCustomFee, getDiscountPerItem, getDiscountPerItemOnRefund, getModifierFromCustomFee, getTaxesPerItem, stripHtml } from "../../settings/CommonWork";
import FormateDateAndTime from "../../settings/FormateDateAndTime";
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { currecyFunction } from "../common/commonFunctions/currencyFunction";
import { RoundAmount } from "../common/TaxSetting";
import { v4 as uniqueKey } from 'uuid';
import moment from "moment/moment";
import NoImageAvailable from '../../assets/images/svg/NoImageAvailable.svg';
// import ActivityViewCustomFee from "./ActivityViewCustomFee";
const RefundDetail = (props) => {
    const [refundDetails, setRefundDetails] = useState([]);
    //const [taxInclusiveName, setTaxInclusiveName] = useState('');
    var taxInclusiveName = ''
    // if (props.activityOrderDetails.data.content && props.activityOrderDetails.data.content.meta_datas != null) {
    //     taxInclusiveName = getInclusiveTaxType(props.activityOrderDetails.data.content.meta_datas);

    // }
    var order_taxtype = '';
    // if (props.taxType && props.taxType.includes("incl") == true) {
    //     order_taxtype = "incl";
    // }
    const calculateTaxRate = (totalAmount, itemvalue) => {
        return Math.round(((itemvalue) * 100) / totalAmount, 0)
    }
    var order_reciept = localStorage.getItem('orderreciept') && localStorage.getItem('orderreciept') !== 'undefined' ? JSON.parse(localStorage.getItem('orderreciept')) : "";
    //var taxInclusiveName = "";
    const refundOrderDetail = (_activityOrderDetails) => {

        var refundHistoryArray = [];
        var labelsubTotal = '';

        var RefundItemLog = [];
        var refundLineItemDetail = [];
        var type = "activity";

        // var _taxInclusiveName = _activityOrderDetails ? getInclusiveTaxType(_activityOrderDetails.meta_datas) : "";
        // setTaxInclusiveName(_taxInclusiveName);
        // if (order_reciept && order_reciept.SubTotalDisplayLabel && order_reciept.SubTotalDisplayLabel !== '') {
        //     labelsubTotal = order_reciept.SubTotalDisplayLabel
        // } else {

        labelsubTotal = LocalizedLanguage.subTotal;
        //}
        var metaData = _activityOrderDetails && _activityOrderDetails.meta_datas ? _activityOrderDetails.meta_datas : [];

        taxInclusiveName = getInclusiveTaxType(metaData);
        if (taxInclusiveName && taxInclusiveName.toLowerCase().includes("incl") == true) {
            order_taxtype = "incl";
        }
        else {
            order_taxtype = "";
        }

        metaData && metaData.length > 0 && metaData.map((metaData, index) => {
            if (metaData.ItemName == '_order_oliverpos_refund_log') {
                RefundItemLog = metaData.ItemValue && metaData.ItemValue !== '' ? JSON.parse(metaData.ItemValue) : ''
            }
        })
        //======getting log data============================
        var logLineItemData = [];
        var getorderlist = "";
        var _data = _activityOrderDetails && _activityOrderDetails.meta_datas && _activityOrderDetails.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount');
        getorderlist = _data && _data.ItemValue && JSON.parse(_data.ItemValue);
        if (getorderlist) {
            getorderlist.map(prd => {
                if (prd.product_id || prd.variation_id) {
                    logLineItemData.push(prd) // ALL PRODUCTS HERE
                }
            })
        }
        //=========================================
        var _cartDiscount = 0.0;
        var lineitem_cartDiscount = 0;
        var lineItem_cartDiscountType = '';
        var Total_IndividualProductDiscount = 0.0;
        var Order_cartDiscount = 0;
        var line_items = _activityOrderDetails && _activityOrderDetails.line_items ? _activityOrderDetails.line_items : [];
        if (line_items.length > 0 && typeof _activityOrderDetails.meta_datas != "undefined") {
            line_items = getModifierFromCustomFee(line_items, _activityOrderDetails.meta_datas, _activityOrderDetails.order_custom_fee);
        }
        line_items.map(item => {
            var lineitem_taxType = [];
            var DisplayTotalSplitTax = getTaxesPerItem(order_reciept, 'activity', _activityOrderDetails, item);
            var lineitem_TotalQty = item.quantity;
            var itemName = stripHtml(item.name ? item.name : item.Title);

            var lineItem_DiscountDetail = getDiscountPerItemOnRefund(_activityOrderDetails, type, item.product_id, "", (Math.abs(item.quantity_refunded)));
            var logitem = logLineItemData && logLineItemData.find(litem => { return (litem.product_id == item.product_id || litem.variation_id == item.product_id) && ((litem.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? litem.modifiers_key == item.modifiers_key : true)) })
            //var lineitem_AcutalPrice = logitem ? logitem.old_price * item.quantity : 0;
            var lineitem_AcutalPrice = logitem ? logitem.old_price * item.quantity : (item.total + ((order_taxtype == "incl") ? item.total_tax : 0));
            var lineitem_Title = (type !== 'activity') ? itemName : showTitle(item) !== "" ? itemName : ''

            if (logitem == null || typeof logitem == "undefined") {
                lineitem_AcutalPrice = parseFloat(item.subtotal ? item.subtotal : item.subtotalPrice ? item.subtotalPrice : item.Price ? item.Price : 0) +
                    (order_taxtype !== '' ? type == 'activity' ? item.total_tax : (item.subtotaltax ? item.subtotaltax : 0) : 0)
            }
            var lineitem_Discount = lineItem_DiscountDetail && lineItem_DiscountDetail.discountAmount ? lineItem_DiscountDetail.discountAmount : 0;
            Total_IndividualProductDiscount += lineitem_Discount;
            if (lineItem_DiscountDetail && lineItem_DiscountDetail.discountCart) {
                lineitem_cartDiscount = parseFloat(lineItem_DiscountDetail.cartItemDiscount);
                _cartDiscount += lineitem_cartDiscount;
                Order_cartDiscount = lineItem_DiscountDetail.discountCart;
                lineItem_cartDiscountType = lineItem_DiscountDetail.discountCart ? '(' + parseFloat(lineItem_DiscountDetail.discountCart.discount_amount) + '%)' : ''
            }

            var _activitylineItemTotal = parseFloat(item.subtotal + (order_taxtype !== '' ? item.total_tax : 0) - lineitem_Discount - lineitem_cartDiscount).toFixed(2)
            var _lineitemTax = (order_taxtype !== '' || order_reciept.IndividualizedTaxAmountPerItem == true ? item.total_tax ? item.total_tax : item.subtotaltax ? item.subtotaltax : 0 : 0)

            var lineitem_Total = type == 'activity' ? _activitylineItemTotal
                :
                (item.product_id ? parseFloat(parseFloat(item.subtotalPrice ? item.subtotalPrice : 0) - parseFloat(lineitem_Discount) - lineitem_cartDiscount + (order_taxtype !== '' ? (item.totaltax ? parseFloat((_lineitemTax)) : 0) : 0))//(taxInclusiveName !==''? (item.totaltax?item.totaltax:0):0) ))
                    : item.Price ? parseFloat(parseFloat(item.Price) - parseFloat(lineitem_Discount) - lineitem_cartDiscount + (order_taxtype !== '' ? (item.totaltax ? parseFloat((_lineitemTax)) : 0) : 0))//(taxInclusiveName !==''? (item.totaltax?item.totaltax:0):0) ))
                        : 0
                )
            // only for exclusive tax------------
            lineitem_Total = parseFloat(lineitem_Total) + (order_taxtype == '' ?
                (order_reciept.IndividualizedTaxAmountPerItem == true || order_reciept.PercentageTaxPerItem == true) ? // added line item tax into product total only when setting is enable
                    ((item.totaltax || item.total_tax) ? parseFloat(_lineitemTax) : 0) : 0
                : 0)

            lineitem_Total = parseFloat(lineitem_Total).toFixed(2) == "0.01" ? 0.00 : lineitem_Total // to remove diffrence of .01
            var lineitem_Dis_Percent = lineItem_DiscountDetail && lineItem_DiscountDetail.discountApply && " (" + lineItem_DiscountDetail.discountApply + "%)";


            if (item.quantity_refunded < 0) {
                var _refundItemDetail = {}
                _refundItemDetail['productId'] = item.product_id;
                _refundItemDetail['line_item_id'] = item.line_item_id ? item.line_item_id : null;
                _refundItemDetail['title'] = lineitem_Title;
                _refundItemDetail['totalQuantity'] = lineitem_TotalQty;
                _refundItemDetail['pricePerQuantity'] = (lineitem_AcutalPrice / lineitem_TotalQty);
                _refundItemDetail['itemDiscountLabel'] = lineitem_Discount > 0 ? LocalizedLanguage.itemDiscount + lineitem_Dis_Percent/*(order_reciept.PercentageDiscountPerItem == true ? lineitem_Dis_Percent : '')*/ : '';
                var lblCartDiscount = LocalizedLanguage.cartdiscount;
                lblCartDiscount += order_reciept.PercentageDiscountPerItem == true ? lineItem_cartDiscountType : '';
                _refundItemDetail['CartDiscountLabel'] = lblCartDiscount;
                _refundItemDetail['itemDiscount'] = lineitem_Discount;
                _refundItemDetail['CartDiscount'] = lineitem_cartDiscount;
                _refundItemDetail['refundTaxType'] = lineitem_taxType;
                _refundItemDetail['refundedQuantity'] = item.quantity_refunded;
                _refundItemDetail['taxes'] = DisplayTotalSplitTax;
                if (item.hasOwnProperty("modifiers_Id")) {
                    _refundItemDetail['modifiers_Id'] = item.modifiers_Id;
                }
                if (item.hasOwnProperty("modifiers_key")) {
                    _refundItemDetail['modifiers_key'] = item.modifiers_key;
                }
                                refundLineItemDetail.push(_refundItemDetail)
            }
        })

        var isAnyRefund = _activityOrderDetails && _activityOrderDetails.line_items && _activityOrderDetails.line_items.filter(a => Math.abs(a.quantity_refunded) > 0);
        if (isAnyRefund !== null && typeof isAnyRefund !== "undefined" && (!RefundItemLog || RefundItemLog == '' || RefundItemLog.length == 0) && (typeof isAnyRefund !== "undefined" && isAnyRefund.length > 0)) {
            var refundSubTotal = 0;
            var discountAfterRefund = 0;
            var _refundHistorySale = {};
            var _refundHistorySaleItems = [];
            var RefundTotalTax = [];
            for (var key in refundLineItemDetail) {
                var _refundHistorySaleLineItem = {};
                // var item = refundLineItemDetail[key];
                // RefundItems && RefundItems.map(item => {
                var refund_lineitem_Title = '';
                var lineitem_pricePerQuantity = 0;
                var lineitem_TotalQty = 0;
                var itemDiscountLabel = '';
                var CartDiscountLabel = '';
                var itemDiscount = 0;
                var CartDiscount = 0;
                var refundItemTax = [];
                var refund_lineitem_Total = 0;
                var refundedQuantity = 0
                var item_tax = 0;
                // for (var key in refundLineItemDetail) {
                //console.log("key: " + key + " value: " + JSON.parse(refundLineItemDetail[key]) + "\n");

                var _refundItem = refundLineItemDetail[key];
                //if (_refundItem.productId ==item.product_id) {
                var _pricePerQuantity = _refundItem.pricePerQuantity;
                refundedQuantity = Math.abs(_refundItem.refundedQuantity);
                refund_lineitem_Title = _refundItem.title;
                lineitem_pricePerQuantity = _refundItem.pricePerQuantity;
                itemDiscountLabel = _refundItem.itemDiscountLabel;
                CartDiscountLabel = _refundItem.CartDiscountLabel;
                refundSubTotal += refundedQuantity * _refundItem.pricePerQuantity;
                refundItemTax = [];//= _refundItem.refundTaxType;
                lineitem_TotalQty = _refundItem.totalQuantity;
                itemDiscount = (_refundItem.itemDiscount / lineitem_TotalQty) * refundedQuantity;;
                CartDiscount = (_refundItem.CartDiscount / lineitem_TotalQty) * refundedQuantity; //Calculate discount for refunded item

                discountAfterRefund += itemDiscount + CartDiscount;
                refund_lineitem_Total = refundedQuantity * _refundItem.pricePerQuantity;
                refund_lineitem_Total -= itemDiscount;
                refund_lineitem_Total -= CartDiscount;
                var taxes = _refundItem.taxes;
                _refundItem.taxes && _refundItem.taxes.length > 0 && _refundItem.taxes.map(tkey => {
                    //_refundItem.taxes && _refundItem.taxes !== "" && Object.keys(_refundItem.taxes).map(tkey => {
                    var taxvalue = parseFloat(tkey.value);// _refundItem.taxes[tkey];
                    taxvalue = (taxvalue / _refundItem.totalQuantity) * (Math.abs(_refundItem.refundedQuantity))
                    _activityOrderDetails.order_taxes && _activityOrderDetails.order_taxes.map(tm => {
                        // taxRate && taxRate.map(tm=>{
                        // if (tm.RateId == tkey) {
                        // if (tm.Code == tkey.tax && taxvalue .toString() === tm.Refundad) {

                        if (tm.Code == tkey.tax && parseFloat(taxvalue).toFixed(2) === parseFloat(tm.Refundad).toFixed(2)) {
                            var taxname = tm.Title; //tm.TaxName;
                            var TaxRate = calculateTaxRate(parseFloat(lineitem_pricePerQuantity * refundedQuantity), parseFloat(taxvalue));
                            if (order_reciept) {
                                if (order_reciept.IndividualizedTaxAmountPerItem == true || order_reciept.PercentageTaxPerItem == true) {
                                    taxname += "(" + TaxRate + " %)";
                                }
                                refundItemTax.push({ "tax": taxname, "value": taxvalue, "id": tm.Code })
                            }
                            var found = false;
                            RefundTotalTax && RefundTotalTax.length > 0 && RefundTotalTax.map(tTax => {
                                if (tTax.tax == taxname) {
                                    tTax.value += parseFloat(taxvalue);
                                    found = true;
                                }
                            })
                            if (found == false) {
                                RefundTotalTax.push({ "tax": taxname, "value": taxvalue })
                            }
                            item_tax += parseFloat(taxvalue);
                            //refund_lineitem_Total += parseFloat(taxvalue);
                        }
                    })
                })
                // })

                // }
                var _refundHistorySale = {};
                var _payments = [];

                refund_lineitem_Total += order_taxtype != '' ? 0 : item_tax;
                //    console.log('refundItemTax', refundItemTax)
                var total_tax_modifier = 0;
                var modifers = line_items.filter(a =>
                    (a.hasOwnProperty("pid") && a.pid == _refundItem.productId && (a.hasOwnProperty("is_modi") && a.is_modi == true))
                    && ((a.hasOwnProperty("modifiers_key") && _refundItem.hasOwnProperty("modifiers_key") ? a.modifiers_key == _refundItem.modifiers_key : true)
                        //  || (!a.hasOwnProperty("modifiers_Id") && !_refundItem.hasOwnProperty("modifiers_Id"))
                    )
                );
                var _amount = 0
                modifers && modifers.map(t => {
                    if (t.amount_refunded != 0) {
                        _amount = parseFloat(t.amount);
                        _amount = _amount - t.amount_refunded;
                        refund_lineitem_Total += parseFloat(_amount);
                        if (order_taxtype !== "") {
                            _amount += t.total_tax;
                            refund_lineitem_Total += t.total_tax;
                            total_tax_modifier += t.total_tax;
                        }
                    }
                })
                //var _result = addModiferTaxToLineItemTax(refundItemTax, modifers);
                _refundHistorySaleLineItem["Title"] = refund_lineitem_Title;
                _refundHistorySaleLineItem["Quantity"] = Math.abs(refundedQuantity);
                _refundHistorySaleLineItem["Quantity_Price"] = "(" + Math.abs(refundedQuantity) + " x " + parseFloat(_pricePerQuantity).toFixed(2) + ") ";
                _refundHistorySaleLineItem["Price"] = (Math.abs(refundedQuantity) * _pricePerQuantity).toFixed(2);
                _refundHistorySaleLineItem["TaxList"] = refundItemTax;
                _refundHistorySaleLineItem["Total"] = parseFloat(refund_lineitem_Total/* * (Math.abs(refundedQuantity) > 0 ? Math.abs(refundedQuantity) : 1)*/).toFixed(2);
                _refundHistorySaleLineItem["LineItemDiscount"] = { "label": itemDiscountLabel, "value": parseFloat(itemDiscount).toFixed(2) };
                _refundHistorySaleLineItem["CartDiscount"] = { "label": CartDiscountLabel, "value": parseFloat(CartDiscount).toFixed(2) };
                _refundHistorySaleLineItem["modifers"] = modifers;
                                _refundHistorySaleItems.push(_refundHistorySaleLineItem);
            }
            _refundHistorySale["Refund_data_collection"] = _refundHistorySaleItems;
            // var displayRefundPayment = '';
            // var refundPay = '';
            var payment_TypeName = (typeof localStorage.getItem('PAYMENT_TYPE_NAME') !== 'undefined') ? JSON.parse(localStorage.getItem('PAYMENT_TYPE_NAME')) : null
            _refundHistorySale["SubTotal"] = { "label": (order_reciept.SubTotal ? order_reciept.SubTotal : labelsubTotal), "value": refundSubTotal.toFixed(2) };
            _refundHistorySale["RefundTotalTax"] = RefundTotalTax;
            _refundHistorySale["Discount"] = parseFloat(RoundAmount(discountAfterRefund)).toFixed(2);
            // var total_cashround = _activityOrderDetails.refund_cash_rounding_amount;
            _refundHistorySale["CashRound"] = _activityOrderDetails.refund_cash_rounding_amount;
            _refundHistorySale["RefundTotal"] = _activityOrderDetails.refunded_amount.toFixed(2);
            _activityOrderDetails && _activityOrderDetails.order_Refund_payments.map((item, index) => {

                var localDate = FormateDateAndTime.formatDateAndTime(item.payment_date, _activityOrderDetails.time_zone)
                var paytype = payment_TypeName && payment_TypeName.filter(itm => { return itm.Code == item.type })
                var paymentName = item.type !== "store-credit" ? paytype && paytype.length > 0 ? paytype[0].Name : item.type ? item.type : '' : 'store-credit';
                _payments.push({ "paymentName": paymentName + ' (' + localDate + ')', "amount": parseFloat(item.amount).toFixed(2) });
                return true;
            })
            _refundHistorySale["refund_payments"] = _payments;
            refundHistoryArray.push(_refundHistorySale);
            setRefundDetails(refundHistoryArray);
        }

        else
            if (RefundItemLog && RefundItemLog !== '' && RefundItemLog.length > 0) {
                ///****** Start refund Item ******************************* */
                // var all_modifier_taxes = '';
                // var _onlyCustomFee = [];
                // var _tipAmount = 0;
                // var _onlyCustomFee = [];
                // var _custFeeTaxTotal = 0;
                // var _custFeeTaxTotalOnly = 0;
                // var _totalcustomeFee = 0;
                // var feeList = [];
                RefundItemLog && RefundItemLog.map(refundlog => {
                    var all_modifier_taxes = '';
                    var _tipAmount = 0;
                    var _onlyCustomFee = [];
                    var _custFeeTaxTotal = 0;
                    var _custFeeTaxTotalOnly = 0;
                    var _totalcustomeFee = 0;
                    var feeList = [];

                    var refundDate = FormateDateAndTime.formatDateAndTime(refundlog.Date, refundlog.LocalTimeZoneType)
                    //   console.log("------refund date-----" + refundDate);
                    var _refundHistorySale = {};

                    var RefundTotalTax = [];
                    var _payments = [];
                    //refundItemDetail += `<table><tr><td><tfoot><h2 style="text-align: center; margin-top:20px">*** Refund ***</h2></tfoot></td></tr></table>`
                    //androidRefundHeaderTitle();
                    //refundItemDetail += refunHeaderDetailRender(refundlog);
                    var RefundItems = refundlog.Refund_data_collection && refundlog.Refund_data_collection.RefundItems;
                    RefundItems = refundlog.Refund_data_collection && refundlog.Refund_data_collection.RefundItems.filter(a => a.product_id != 0);


                    var _customFee = refundlog.Refund_data_collection && refundlog.Refund_data_collection.RefundItems.filter(a => a.product_id == 0).map(e => e.item_id);
                    if (_onlyCustomFee.length == 0) {
                        _onlyCustomFee = getCustomFee(_activityOrderDetails.meta_datas, _activityOrderDetails.order_custom_fee);
                        if (_onlyCustomFee && _onlyCustomFee.length == 0) {
                            _onlyCustomFee = _activityOrderDetails && _activityOrderDetails.order_custom_fee ? _activityOrderDetails.order_custom_fee : [];
                        }
                    }
                    _onlyCustomFee = _onlyCustomFee.filter(a => _customFee.includes(a.fee_id));
                    var total_amount = 0;
                    _onlyCustomFee && _onlyCustomFee.map((item_fee, index) => {
                        if (item_fee.hasOwnProperty("pid") == false || (item_fee.hasOwnProperty("pid") && (typeof item_fee.pid == "undefined" || item_fee.pid == 0))) {
                            if (item_fee.note && item_fee.note.includes('Tip')) {
                                //skip tip
                                _tipAmount += item_fee.amount ? parseFloat(item_fee.amount) : 0;
                            }
                            else {
                                if (item_fee.amount_refunded > 0 && item_fee.amount > 0) {
                                    var custFee = item_fee.amount ? item_fee.amount : item_fee.Price ? item_fee.Price : 0;
                                    var custFee_NoTax = custFee;
                                    total_amount += custFee;
                                    var custFeeTax = item_fee.total_tax ? item_fee.total_tax : 0;
                                    custFee += order_taxtype == '' ? custFeeTax : 0;
                                    _custFeeTaxTotalOnly += custFeeTax;
                                    _custFeeTaxTotal += custFee;

                                    var _refundedFee = RefundItems.filter(a => { return a.item_type == "customFee" && a.product_id != 0 });
                                    if (_refundedFee && _refundedFee.length > 0) {
                                        _refundedFee.map(r => {
                                            custFeeTax += r.tax;
                                            total_amount += r.amount;
                                            _custFeeTaxTotalOnly += custFeeTax;
                                            _custFeeTaxTotal += total_amount;
                                        });
                                    }
                                    //NOT TO ADD TAX IN NON TAXABLE CUSTOM FEE
                                    if (item_fee.hasOwnProperty("pid") && typeof item_fee.pid == "undefined") {
                                        var _custFee = item_fee.amount ? item_fee.amount : item_fee.Price ? item_fee.Price : 0;
                                        total_amount = _custFee;
                                    }
                                    feeList.push({ "title": item_fee.note ? item_fee.note : item_fee.Title ? item_fee.Title : '', "amount": total_amount, "tax": ("Tax " + taxInclusiveName + ""), "tax_value": custFeeTax });
                                    _totalcustomeFee += parseFloat(parseFloat(custFee));
                                }
                            }
                        }
                    })
                    ////custome fee



                    var refundProductTotal = 0;
                    var refundSubTotal = 0;
                    var discountAfterRefund = 0;
                    var _refundHistorySaleItems = [];
                    // var refundModifierTax = 0;
                    RefundItems && RefundItems.map(item => {
                        var _refundHistorySaleLineItem = {};
                        var refund_lineitem_Title = '';
                        var lineitem_pricePerQuantity = 0;
                        var lineitem_TotalQty = 0;
                        var itemDiscountLabel = '';
                        var CartDiscountLabel = '';
                        var itemDiscount = 0;
                        var CartDiscount = 0;
                        var refundItemTax = null;
                        var refund_lineitem_Total = 0;
                        var sku = '';

                        for (var key in refundLineItemDetail) {
                            //console.log("key: " + key + " value: " + JSON.parse(refundLineItemDetail[key]) + "\n");
                            var _refundItem = refundLineItemDetail[key];
                            if ((_refundItem.productId == item.product_id || _refundItem.line_item_id == item.item_id)
                                && (_refundItem.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? _refundItem.modifiers_key == item.modifiers_key : true)) {
                                refund_lineitem_Title = _refundItem.title;
                                sku = _refundItem.Sku;
                                lineitem_pricePerQuantity = _refundItem.pricePerQuantity;
                                itemDiscountLabel = _refundItem.itemDiscountLabel;
                                CartDiscountLabel = _refundItem.CartDiscountLabel;
                                if (item.item_type == "product") {
                                    refundSubTotal += item.Quantity * _refundItem.pricePerQuantity;
                                }
                                //ADDING MODIFIER AMOUNT TO SUBTOTAL WHICH IS REFUNDED WITH THE PRODUCT
                                if (item.item_type == "customFee" && item.hasOwnProperty("product_id") && item.product_id == _refundItem.productId) {
                                    refundSubTotal += item.amount + (typeof order_taxtype != "undefined" && order_taxtype !== "" && order_taxtype.toLowerCase() !== "tax" ? item.tax : 0);
                                }
                                refundItemTax = [];//= _refundItem.refundTaxType;
                                lineitem_TotalQty = _refundItem.totalQuantity;
                                itemDiscount = _refundItem.itemDiscount;// (_refundItem.itemDiscount / lineitem_TotalQty) * item.Quantity;;
                                CartDiscount = (_refundItem.CartDiscount / lineitem_TotalQty) * item.Quantity; //Calculate discount for refunded item
                                if (item.item_type == "product") {
                                    discountAfterRefund += itemDiscount + CartDiscount;
                                }
                                refund_lineitem_Total = item.Quantity * _refundItem.pricePerQuantity;
                                refund_lineitem_Total -= itemDiscount;
                                refund_lineitem_Total -= CartDiscount;
                                item.taxes && item.taxes.length > 0 && item.taxes.map(ltax => {
                                    ltax && ltax !== "" && Object.keys(ltax).map(tkey => {
                                        var taxvalue = ltax[tkey];
                                        _activityOrderDetails.order_taxes && _activityOrderDetails.order_taxes.map(tm => {
                                            // taxRate && taxRate.m ap(tm=>{
                                            if (tm.RateId == tkey) {
                                                //ADDING MODIFER TAX TO THE PRODUCT TAX ACCORDING TO THEIR CLASS --START 14022023
                                                var _taxvalue = 0;
                                                all_modifier_taxes && all_modifier_taxes !== "" && all_modifier_taxes.map(item1 => {
                                                    Object.keys(item1).map(key => {
                                                        if (tm.RateId == key) {
                                                            _taxvalue += item1[key];
                                                            //console.log("--taxvalue--" + _taxvalue);
                                                        }
                                                    })
                                                })
                                                //ADDING MODIFER TAX TO THE PRODUCT TAX ACCORDING TO THEIR CLASS --END 14022023
                                                var taxname = tm.Title; //tm.TaxName;
                                                var TaxRate = calculateTaxRate(parseFloat(lineitem_pricePerQuantity * item.Quantity)-(_refundItem.itemDiscount), parseFloat(taxvalue));
                                                if (order_reciept) {
                                                    if (order_reciept.IndividualizedTaxAmountPerItem == true || order_reciept.PercentageTaxPerItem == true) {
                                                        taxname += " (" + TaxRate + " %)";
                                                    }
                                                    refundItemTax.push({ "tax": taxname, "value": taxvalue + _taxvalue, "id": tm.RateId })
                                                }
                                                var found = false;
                                                //RefundTotalTax && RefundTotalTax.length > 0 && RefundTotalTax.map(tTax => {
                                                refundItemTax && refundItemTax.length > 0 && refundItemTax.map(tTax => {
                                                    if (tTax.tax == taxname) {
                                                        //ADDING MODIFER TAX TO THE PRODUCT TAX ACCORDING TO THEIR CLASS --START 14022023
                                                        var _taxvalue = 0;
                                                        all_modifier_taxes && all_modifier_taxes !== "" && all_modifier_taxes.map(item1 => {
                                                            Object.keys(item1).map(key => {
                                                                // if (tTax.RateId == key) {
                                                                    if (tTax.id == key) {
                                                                    _taxvalue += item1[key];
                                                                    //console.log("--REFUND TOTAL taxvalue--" + _taxvalue);
                                                                }
                                                            })
                                                        })
                                                        //ADDING MODIFER TAX TO THE PRODUCT TAX ACCORDING TO THEIR CLASS --END 14022023
                                                        tTax.value += parseFloat(_taxvalue);
                                                        found = true;
                                                    }
                                                })
                                                if (found == false) {
                                                    refundItemTax.push({ "tax": taxname, "value": taxvalue + _taxvalue, "id": tm.RateId })
                                                }
                                                if (order_taxtype == "") {
                                                    refund_lineitem_Total = refund_lineitem_Total + taxvalue;
                                                }
                                                //refund_lineitem_Total += taxInclusiveName != '' ? 0 : taxvalue;
                                                // refund_lineitem_Total += taxInclusiveName != '' ? taxvalue : 0;
                                                //refund_lineitem_Total += taxvalue;
                                            }
                                        })
                                    })
                                })

                            }
                        };
                        var refundItemPrice = item.Quantity * lineitem_pricePerQuantity;

                        var line_items_modi = line_items;
                        //var line_items_modi = getModifierFromCustomFee(line_items, _activityOrderDetails.meta_datas, _activityOrderDetails.order_custom_fee);

                        // var modifers = line_items_modi && line_items_modi.filter(a =>
                        //     (a.hasOwnProperty("pid") && (a.pid == item.product_id) && (a.hasOwnProperty("is_modi") && a.is_modi == true))
                        //     && (a.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? a.modifiers_key == item.modifiers_key : true)
                        // );
                        var modifers = line_items_modi && line_items_modi.filter(a => (a.pid == item.product_id || (item.hasOwnProperty("variation_id") && a.pid == item.variation_id)) && item.item_type == "product"
                            && ((a.hasOwnProperty("modifiers_Id") && item.hasOwnProperty("modifiers_Id") ? JSON.stringify(a.modifiers_Id) == JSON.stringify(item.modifiers_Id) : true)
                                && ((a.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? a.modifiers_key == item.modifiers_key : true)))
                        );
                        var total_refund_modifer_tax = 0;
                        var total_refund_modifer_discount = 0;
                        var _taxArray = [];
                        RefundItems && RefundItems.map(a => {
                            if (a.item_type == "customFee" && a.modifiers_key == item.modifiers_key) {
                                total_refund_modifer_tax += a.tax;
                                if (_taxArray && _taxArray.length > 0) {
                                    if (Array.isArray(a.taxes))
                                        _taxArray = [..._taxArray, ...a.taxes]
                                }
                                else { _taxArray = a.taxes; }

                                if (a.hasOwnProperty("cart_discount_amount") && a.cart_discount_amount != 0) {
                                    total_refund_modifer_discount += a.cart_discount_amount;
                                    discountAfterRefund += total_refund_modifer_discount;
                                }
                                if (a.hasOwnProperty("product_discount_amount") && a.product_discount_amount != 0) {
                                    total_refund_modifer_discount += a.product_discount_amount * (a.discount_type === "Number" ? 1 : a.quantity);
                                    discountAfterRefund += total_refund_modifer_discount;
                                }
                            }
                        });
                        var _amount = 0
                        if (order_taxtype == "") {
                            _amount += total_refund_modifer_tax;
                            refund_lineitem_Total += total_refund_modifer_tax;
                        }

                        //var _taxArray = [];
                        //
                        modifers && modifers.map(t => {
                            if (t.amount_refunded != 0) {
                                //_amount = parseFloat(t.amount);
                                //_amount = Math.abs(t.amount_refunded);
                                _amount = parseFloat(t.amount_refunded);
                                // refund_lineitem_Total += parseFloat(t.discount_amount);
                                var _price = t.amount / t.quantity;
                                if (t.discount_amount == 0) {
                                    refund_lineitem_Total += _price * item.Quantity;
                                }
                                else {
                                    refund_lineitem_Total += parseFloat(t.amount - t.discount_amount);
                                }
                                var _foundTaxInLog = RefundItems.find(a => a.item_id == t.fee_id && a.item_type == "customFee");
                                if (_foundTaxInLog && _foundTaxInLog != null) {
                                    t["total_taxes"] = _foundTaxInLog.taxes;
                                    t["total_tax"] = _foundTaxInLog.tax;
                                    // refundModifierTax += _foundTaxInLog.tax;
                                }
                                if (t.hasOwnProperty("cart_discount_amount") && t.cart_discount_amount != 0) {
                                    const _cartDiscount = (t.cart_discount_amount / t.quantity) * item.Quantity;
                                    CartDiscount += _cartDiscount;
                                    total_refund_modifer_discount += _cartDiscount;
                                    discountAfterRefund += _cartDiscount;
                                }
                                if (t.hasOwnProperty("product_discount_amount") && t.product_discount_amount != 0) {
                                    const _proDiscount = (t.product_discount_amount / t.quantity) * item.Quantity;
                                    itemDiscount += _proDiscount//* (t.discount_type === "Number" ? 1 : (t.quantity));
                                    total_refund_modifer_discount += _proDiscount;//* (t.discount_type === "Number" ? 1 : t.quantity);
                                    discountAfterRefund += _proDiscount;
                                }
                                // if (props.taxType && props.taxType.includes("incl") == false) {
                                //     _amount += t.total_tax;
                                //     refund_lineitem_Total += t.total_tax;
                                // }
                                // if (_taxArray.length > 0) {
                                //     _taxArray = [..._taxArray, ...t.total_taxes]
                                // }
                                // else { _taxArray = t.total_taxes; }
                            }
                        })


                        // var _refundedFee = RefundItems.filter(a => { return a.item_type == "customFee" && a.product_id != 0 });
                        //             if (_refundedFee && _refundedFee.length > 0) {
                        //                 _refundedFee.map(r => {
                        //                     custFeeTax += r.tax;
                        //                     total_amount += r.amount;
                        //                     _custFeeTaxTotalOnly += custFeeTax;
                        //                     _custFeeTaxTotal += total_amount;
                        //                 });
                        //             }

                        var _result = addModiferTaxToLineItemTax(refundItemTax, modifers);
                        if (item.hasOwnProperty("item_type") && item.item_type == "product") {
                            //console.log("-----result 558 if refund log--" + JSON.stringify(_result));
                            all_modifier_taxes = _taxArray;
                            _refundHistorySaleLineItem["modifers"] = modifers;
                                                        //  console.log("Product Total : " + parseFloat(refund_lineitem_Total).toFixed(2));
                            _refundHistorySaleLineItem["Title"] = refund_lineitem_Title;
                            _refundHistorySaleLineItem["Quantity"] = ((item.Quantity && lineitem_TotalQty >= 1) ? + item.Quantity : "");
                            _refundHistorySaleLineItem["Quantity_Price"] = ((item.Quantity && lineitem_TotalQty >= 1) ? "(" + item.Quantity + " x " + parseFloat(lineitem_pricePerQuantity).toFixed(2) + ")" : "");
                            _refundHistorySaleLineItem["Price"] = (refundItemPrice).toFixed(2);
                            // _refundHistorySaleLineItem["TaxList"] = refundItemTax;
                            _refundHistorySaleLineItem["TaxList"] = _result;
                            _refundHistorySaleLineItem["Total"] = parseFloat(refund_lineitem_Total).toFixed(2);
                            _refundHistorySaleLineItem["LineItemDiscount"] = { "label": itemDiscountLabel, "value": parseFloat(itemDiscount).toFixed(2) };
                            _refundHistorySaleLineItem["CartDiscount"] = { "label": CartDiscountLabel, "value": parseFloat(CartDiscount).toFixed(2) };
                            _refundHistorySaleLineItem["product_id"] = item.product_id ? item.product_id : (item.WPID ? item.WPID : item.Product_Id);
                            _refundHistorySaleItems.push(_refundHistorySaleLineItem);
                        }
                    })
                    _refundHistorySale["Date"] = refundDate;
                    _refundHistorySale["Refund_data_collection"] = _refundHistorySaleItems;
                    // var displayRefundPayment = '';
                    // var refundPay = '';
                    var payment_TypeName = (typeof localStorage.getItem('PAYMENT_TYPE_NAME') !== 'undefined') ? JSON.parse(localStorage.getItem('PAYMENT_TYPE_NAME')) : null
                    _refundHistorySale["SubTotal"] = { "label": (order_reciept.SubTotal ? order_reciept.SubTotal : labelsubTotal), "value": (refundSubTotal + total_amount).toFixed(2) };
                    _refundHistorySale["RefundTotalTax"] = RefundTotalTax;//+_custFeeTaxTotalOnly;
                    _refundHistorySale["Discount"] = parseFloat(RoundAmount(discountAfterRefund)).toFixed(2);
                    // var total_cashround = refundlog.Refund_data_collection.refund_cash_rounding_amount
                    _refundHistorySale["CashRound"] = refundlog.Refund_data_collection.refund_cash_rounding_amount;
                    _refundHistorySale["RefundTotal"] = refundlog.Refund_data_collection.refund_amount.toFixed(2);
                    _refundHistorySale["refundOrderNote"] = refundlog.Refund_data_collection.order_notes;

                    refundlog && refundlog.Refund_data_collection && refundlog.Refund_data_collection.order_refund_payments.map((item, index) => {
                        var refDate = moment(item.payment_date, "DD-MM-YYYY");
                        var localDate = FormateDateAndTime.formatDateAndTime(refDate, _activityOrderDetails.time_zone)
                        var paytype = payment_TypeName && payment_TypeName.filter(itm => { return itm.Code == item.type })
                        var paymentName = item.type !== "store-credit" ? paytype && paytype.length > 0 ? paytype[0].Name : item.type ? item.type : '' : 'store-credit';
                        _payments.push({ "paymentName": paymentName + ' (' + localDate + ')', "amount": parseFloat(item.amount).toFixed(2) });
                        return true;
                    })
                    _refundHistorySale["refund_payments"] = _payments;
                    _refundHistorySale["refund_tax"] = refundlog.Refund_data_collection.refund_tax;
                    _refundHistorySale["refundlog"] = refundlog;
                    _refundHistorySale["feeList"] = feeList;
                    refundHistoryArray.push(_refundHistorySale);
                })

                setRefundDetails(refundHistoryArray);
            }
    }

    useEffect(() => {
        if (props.activityOrderDetails.data && props.activityOrderDetails.data != "") {
            setRefundDetails([]);
            // setFeeList([]);
            refundOrderDetail(props.activityOrderDetails.data.content);
            //if (props.activityOrderDetails.data.content && props.activityOrderDetails.data.content.meta_datas != null) {
            //var _taxInclusiveName = getInclusiveTaxType(props.activityOrderDetails.data.content.meta_datas);
            //setTaxInclusiveName(_taxInclusiveName);
            // }
            //ActivityViewCustomFee(props.activityOrderDetails.data.content);
        }
    }, [props.activityOrderDetails.data]);
    
    return (
        <React.Fragment>
            {refundDetails && refundDetails.map(h => {
                //gmtDateTime = FormateDateAndTime.formatDateAndTime(DateTime.OrderDateTime, DateTime.time_zone)
                return (<React.Fragment><div className="refund-header" key={uniqueKey()}>
                    <p className="style1">{LocalizedLanguage.refunded.toUpperCase()}</p>
                    <p className="style2">{h && h.Date}</p>
                </div>
                    {h && h.Refund_data_collection.map(item => {
                        {
                            var productImage = NoImageAvailable;
                            var IndexImage = props.pImages.find(a => a.id == item.product_id);
                            if (typeof IndexImage != "undefined" && IndexImage != null) {
                                productImage =  IndexImage.image && IndexImage.image != "False" && IndexImage.image != "" ?  IndexImage.image: NoImageAvailable;

                            }
                        }

                        return (
                            !item.hasOwnProperty("is_modi") && item.hasOwnProperty("is_modi") == false ? <div className="item" key={uniqueKey()}>
                                <div className="img-container">
                                    <div className="quantity">
                                        <p>{item.Quantity}</p>
                                    </div>
                                    <img src={productImage} alt="" />
                                </div>
                                <div className="col">
                                    <div className="main-row">
                                        <p>{item.Title}</p>
                                        <p>
                                            {item.Quantity > 1 ? <small>{item.Quantity_Price}</small> : ""} {LocalizedLanguage.price}: <b>
                                                {currecyFunction(item.Price)}</b></p>
                                                                                </div>
                                    <div className="modifier-fields">
                                        {item.modifers && item.modifers.map(_item => {
                                            return <div className="row" key={uniqueKey()}>
                                                <p>{_item.note ? _item.note : ""}</p>
                                                {/* <p> &nbsp; {currecyFunction((item.amount_refunded + (order_taxtype != "" ? item.total_tax : 0)))}</p> */}
                                                <p> &nbsp; {currecyFunction((((_item.amount / _item.quantity) * item.Quantity)/* + (order_taxtype != "" ? _item.total_tax : 0)*/))}</p>
                                            </div>
                                        })}

                                    </div>
                                    <div className="product-total-fields">
                                        <div className="discounts">
                                            {
                                                item.LineItemDiscount && item.LineItemDiscount.value != 0 ?
                                                    <div className="row">
                                                        <p>{item.LineItemDiscount.label}</p>
                                                        <p>{currecyFunction(-item.LineItemDiscount.value)}</p>
                                                    </div> : ""}
                                            {
                                                item.CartDiscount && item.CartDiscount.value != 0 ?
                                                    <div className="row">
                                                        <p>{item.CartDiscount.label}</p>
                                                        <p>{currecyFunction(-item.CartDiscount.value)}</p>
                                                    </div> : ""}


                                        </div>

                                        {item && item.TaxList && item.TaxList.map(t => {
                                            return (<div className="taxes" key={uniqueKey()}>
                                                <div className="row">
                                                    <p>{t.tax} ({props.taxType}.)</p>
                                                    <p>{currecyFunction(t.value)}</p>
                                                </div>

                                            </div>)
                                        })}
                                        <div className="total">
                                            <div className="row">
                                                <p><b>{LocalizedLanguage.producttotal}</b></p>
                                                <p><b>{currecyFunction(item.Total)}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : "")
                    })}
                    {h.feeList && h.feeList.map((item_fee, index) => {
                        return (<React.Fragment>
                            <div className="item" key={uniqueKey()}>
                                <div className="img-container" style={{ height: "unset " }}>
                                </div>
                                <div className="col">
                                    <div className="main-row">
                                        <p>{item_fee.title}</p>
                                        <p>{currecyFunction(item_fee.amount)}</p>
                                    </div>
                                    {(order_reciept.IndividualizedTaxAmountPerItem == true || order_reciept.PercentageTaxPerItem == true) &&
                                        <div className="main-row">
                                            <p>{item_fee.tax}</p>
                                            <p>{currecyFunction(item_fee.tax_value)}</p>
                                        </div>}</div></div>


                        </React.Fragment>
                        )
                    })}
                    {/* <ActivityViewCustomFee data={props.activityOrderDetails.data.content} h={h} custFeeAmountTotal={custFeeAmountTotal}></ActivityViewCustomFee> */}
                    {/* <div>{GetCustomFeeHtml(props.activityOrderDetails.data.content)}</div> */}
                    {/* <GetCustomFeeHtml data={props.activityOrderDetails.data.content}></GetCustomFeeHtml> */}
                    {h.refundOrderNote ? h.refundOrderNote && h.refundOrderNote.map((item, index) => {
                        return item.note && item.note.toLowerCase().match(/payment done with:/) ? ("") : <div className="custom-fields" key={uniqueKey()}>
                            {item.note ? <p className="style2">{LocalizedLanguage.notes}: {item.note ? item.note : ""}</p> : ''}
                        </div>
                    })
                        : ""}

                    <div className="transaction-totals-wrapper">
                        <div className="transaction-totals">
                            {h && h.SubTotal ?
                                <div className="row">
                                    <p>{h.SubTotal.label}</p>
                                    <p>{currecyFunction(parseFloat(h.SubTotal.value))}</p>
                                    {/* <p>{currecyFunction(parseFloat(h.RefundTotal) - parseFloat((taxInclusiveName.toLowerCase().includes("incl") == false) ? h.refund_tax : 0))}</p> */}
                                </div> : ""}
                            {h && h.Discount && h.Discount !== "0.00" ?
                                <div className="row">
                                    <p>{LocalizedLanguage.discount} { }</p>
                                    <p>{currecyFunction(-h.Discount)}</p>
                                </div> : ""}
                            {/*(taxInclusiveName.toLowerCase().includes("incl") == false) &&*/ h && h.refund_tax >= 0 && (
                                <div className="row">
                                    <p>{"Tax"} ({props.taxType}.)</p>
                                    <p>{currecyFunction(h.refund_tax)}</p>
                                </div>)}
                            {/*(taxInclusiveName.toLowerCase().includes("incl") == false) &&*/ h && h.feeList && h.feeList.tax && (
                                <div className="row">
                                    <p>{"Tax"} ({props.taxType}.)</p>
                                    <p>{currecyFunction(h.feeList.tax)}</p>
                                </div>)}
                            {/*(taxInclusiveName.toLowerCase().includes("incl") == false) &&*/ h && h.RefundTotalTax && h.RefundTotalTax.map(item => {
                                return (<div className="row">
                                    <p>{item.tax} ({props.taxType}.)</p>
                                    <p>{currecyFunction(item.value)}</p>
                                </div>)
                            })}
                            {h && h.CashRound ?
                                <div className="row">
                                    <p>{LocalizedLanguage.cashRounding}</p>
                                    <p>{h.CashRound}</p>
                                </div> : ""}

                            {h && h.RefundTotal ?
                                <div className="row border-bottom">
                                    <p><b className="bold2">{LocalizedLanguage.total}</b></p>
                                    <p><b className="bold2">{currecyFunction(parseFloat(h.RefundTotal))}</b></p>
                                </div> : ""}
                            {/* {(taxInclusiveName.toLowerCase().includes("incl") == true) && h && h.refund_tax && (
                                <div className="row">
                                    <p>{"Tax"} ({props.taxType}.)</p>
                                    <p>{currecyFunction(h.refund_tax)}</p>
                                </div>)}
                            {(taxInclusiveName.toLowerCase().includes("incl") == true) && h && h.feeList && h.feeList.tax && (
                                <div className="row">
                                    <p>{"Tax"} ({props.taxType}.)</p>
                                    <p>{currecyFunction(h.feeList.tax)}</p>
                                </div>)}
                            {(taxInclusiveName.toLowerCase().includes("incl") == true) && h && h.RefundTotalTax && h.RefundTotalTax.map(item => {
                                return (<div className="row">
                                    <p>{item.tax} ({props.taxType}.)</p>
                                    <p>{currecyFunction(item.value)}</p>
                                </div>)
                            })} */}
                            <div className="row">
                                <p className="style2">{LocalizedLanguage.payments}</p>
                            </div>

                            {
                                h && h.refund_payments && h.refund_payments.map(item => {
                                    return (<div className="row" key={uniqueKey()}>
                                        <p>{item.paymentName}</p>
                                        <p><b>{currecyFunction(item.amount)}</b></p>
                                    </div>
                                    )
                                })
                            }
                            {props.shippingTotal && props.shippingTotal > 0 ?
                                <div className="row border-top">
                                    <p>{LocalizedLanguage.shippingAmount}</p>
                                    <p>{props.shippingTotal && currecyFunction(props.shippingTotal)}</p>
                                </div> : ""}

                            <div className="row border-top">
                                <p><b className="bold2">{LocalizedLanguage.balance}</b></p>
                                <p><b className="bold2">{currecyFunction(0)}</b></p>
                            </div>
                        </div>
                    </div>
                </React.Fragment>)
            })}

        </React.Fragment>
    )
}
export default RefundDetail