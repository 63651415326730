import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { useLoginMutation,useGetAllRegisterQuery } from '../../../components/login/loginService';changeReturnAmount
import { checkStockAPI, getPaymentTypeNameAPI ,getExtensionsAPI, getMakePaymentAPI,makeOnlinePaymentsAPI,saveAPI,paymentAmountAPI, changeReturnAmountAPI,checkTempOrderSyncAPI,checkTempOrderStatusAPI,orderToCancelSaleAPI,orderToVoidAPI} from './checkoutApi';
import STATUSES from '../../constants/apiStatus';


const initialState = {
  "status": STATUSES.IDLE,
  "data": "",
  "error": '',
  "is_success": false
};


export const checkStock = createAsyncThunk(
  'checkout/checkStockAPI',
  async (parameter, { rejectWithValue }) => {
    // const response =  loginAPI(parameter);
    // // The value we return becomes the `fulfilled` action payload
    // console.log("test",response.json())
    // return response.json();

    try {
      const response = await checkStockAPI(parameter);
      // The value we return becomes the `fulfilled` action payload
      return response;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data)
    }

  }
);
export const checkStockSlice = createSlice({
  name: 'checkStock',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // loginPanding:(state)=>{
    //   state.status=STATUSES.LOADING;
    //   state.data=""
    // },
    // loginSuccess:(state,action)=>{
    //   state.status=STATUSES.IDLE;
    //   state.error='';
    //   state.data=action.payload
    // },
    // loginFail:(state,action)=>{
    //   state.status=STATUSES.ERROR;    
    //   state.data="";
    //   state.error=action.payload;
    // }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: () => {}
  extraReducers: (builder) => {
    builder
      .addCase(checkStock.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(checkStock.fulfilled, (state, action) => {
        state.status = action.payload && action.payload.is_success == true ? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload.is_success == true ? action.payload : "");
        state.error = action.payload && action.payload.is_success == false ? action.payload.exceptions[0] : action.payload ? "Fail to fetch" : "";;
        state.is_success = action.payload && action.payload.is_success == true ? true : false;
      })
      .addCase(checkStock.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { loginPanding, loginSuccess, loginFail } = checkStockSlice.actions;

export const getPaymentTypeName = createAsyncThunk(
  'checkout/getPaymentTypeNameAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      const response = await getPaymentTypeNameAPI();
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
export const getPaymentTypeNameSlice = createSlice({
  name: 'getPaymentTypeName',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentTypeName.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(getPaymentTypeName.fulfilled, (state, action) => {
        state.status = action.payload && action.payload.is_success == true ? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload.is_success == true ? action.payload : "");
        state.error = action.payload && action.payload.is_success == false ? action.payload.exceptions[0] : action.payload ? "Fail to fetch" : "";;
        state.is_success = action.payload && action.payload.is_success == true ? true : false;
      })
      .addCase(getPaymentTypeName.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { } = getPaymentTypeNameSlice.actions;

export const getExtensions = createAsyncThunk(
  'checkout/getExtensionsAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      const response = await getExtensionsAPI(parameter);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
export const getExtensionsSlice = createSlice({
  name: 'getExtensions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExtensions.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(getExtensions.fulfilled, (state, action) => {
        state.status = action.payload && action.payload.is_success == true ? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload.is_success == true ? action.payload : "");
        state.error = action.payload && action.payload.is_success == false ? action.payload.exceptions[0] : action.payload ? "Fail to fetch" : "";;
        state.is_success = action.payload && action.payload.is_success == true ? true : false;
      })
      .addCase(getExtensions.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { } = getExtensionsSlice.actions;

//----
export const getMakePayment = createAsyncThunk(
  'checkout/getMakePaymentAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      const response = await getMakePaymentAPI(parameter);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
export const getMakePaymentSlice = createSlice({
  name: 'getMakePayment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMakePayment.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(getMakePayment.fulfilled, (state, action) => {
        state.status = action.payload && action.payload.is_success == true ? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload.is_success == true ? action.payload : "");
        state.error = action.payload && action.payload.is_success == false ? action.payload.exceptions[0] : action.payload ? "Fail to fetch" : "";;
        state.is_success = action.payload && action.payload.is_success == true ? true : false;
      })
      .addCase(getMakePayment.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { } = getMakePaymentSlice.actions;
//---

//----
export const makeOnlinePayments = createAsyncThunk(
  'checkout/makeOnlinePaymentsAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      if(parameter==="clear")
      {
        return initialState;  
      }
      const response = await makeOnlinePaymentsAPI(parameter);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
export const makeOnlinePaymentsSlice = createSlice({
  name: 'makeOnlinePayments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(makeOnlinePayments.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(makeOnlinePayments.fulfilled, (state, action) => {
        state.status = action.payload && action.payload.is_success == true ? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload.is_success == true ? action.payload : "");
        state.error = action.payload && action.payload.is_success == false ? action.payload.message: action.payload ? "Fail to fetch" : "";;
        state.is_success = action.payload && action.payload.is_success == true ? true : false;
      })
      .addCase(makeOnlinePayments.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { } = makeOnlinePaymentsSlice.actions;
//---


//----
export const save = createAsyncThunk(
  'checkout/saveAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      const response = await saveAPI(parameter);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
export const saveSlice = createSlice({
  name: 'save',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(save.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(save.fulfilled, (state, action) => {
        state.status = action.payload && action.payload.is_success == true ? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload.is_success == true ? action.payload : "");
        state.error = action.payload && action.payload.is_success == false ? action.payload.message : action.payload ? "Fail to fetch" : "";;
        state.is_success = action.payload && action.payload.is_success == true ? true : false;
      })
      .addCase(save.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { } = saveSlice.actions;
//---

//----
export const paymentAmount = createAsyncThunk(
  'checkout/paymentAmountPI',
  async (parameter, { rejectWithValue }) => {
    try {
      const response = await paymentAmountAPI(parameter);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
export const paymentAmountSlice = createSlice({
  name: 'save',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(paymentAmount.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(paymentAmount.fulfilled, (state, action) => {
        state.status = action.payload && action.payload? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload? action.payload : "");
        state.error = action.payload && action.payload? '' : "Fail to fetch" ;
        state.is_success = action.payload && action.payload? true : false;
      })
      .addCase(paymentAmount.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { } = paymentAmountSlice.actions;
//--

//----
export const changeReturnAmount = createAsyncThunk(
  'checkout/changeReturnAmountAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      const response = await changeReturnAmountAPI(parameter);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
export const changeReturnAmountSlice = createSlice({
  name: 'save',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeReturnAmount.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(changeReturnAmount.fulfilled, (state, action) => {
        state.status = action.payload && action.payload? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload? action.payload : "");
        state.error = action.payload && action.payload? '' : "Fail to fetch" ;
        state.is_success = action.payload && action.payload? true : false;
      })
      .addCase(changeReturnAmount.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { } = changeReturnAmountSlice.actions;
//--

//----
export const checkTempOrderSync = createAsyncThunk(
  'checkout/checkTempOrderSyncAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      const response = await checkTempOrderSyncAPI(parameter);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
export const checkTempOrderSyncSlice = createSlice({
  name: 'checkTempOrderSync',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkTempOrderSync.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(checkTempOrderSync.fulfilled, (state, action) => {
        state.status = action.payload && action.payload? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload? action.payload : "");
        state.error = action.payload && action.payload? '' : "Fail to fetch" ;
        state.is_success = action.payload && action.payload? true : false;
      })
      .addCase(checkTempOrderSync.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { } = checkTempOrderSyncSlice.actions;
//--

//----
export const checkTempOrderStatus = createAsyncThunk(
  'checkout/checkTempOrderStatusAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      const response = await checkTempOrderStatusAPI(parameter);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
export const checkTempOrderStatusSlice = createSlice({
  name: 'checkTempOrderStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkTempOrderStatus.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(checkTempOrderStatus.fulfilled, (state, action) => {
        state.status = action.payload && action.payload? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload? action.payload : "");
        state.error = action.payload && action.payload? '' : "Fail to fetch" ;
        state.is_success = action.payload && action.payload? true : false;
      })
      .addCase(checkTempOrderStatus.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { } = checkTempOrderStatusSlice.actions;
//--

//----
export const orderToCancelSale = createAsyncThunk(
  'checkout/orderToCancelSaleAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      const response = await orderToCancelSaleAPI(parameter.order_id,parameter.udid,parameter.WarehouseId);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
export const orderToCancelSaleSlice = createSlice({
  name: 'orderToCancelSale',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(orderToCancelSale.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(orderToCancelSale.fulfilled, (state, action) => {
        state.status = action.payload && action.payload? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload? action.payload : "");
        state.error = action.payload && action.payload? '' : "Fail to fetch" ;
        state.is_success = action.payload && action.payload? true : false;
      })
      .addCase(orderToCancelSale.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { } = orderToCancelSaleSlice.actions;
//--


//---- void Sale
export const orderToVoid = createAsyncThunk(
  'checkout/orderToVoidAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      if(parameter=="clear")
      {
        return initialState;
      }
      const response = await orderToVoidAPI(parameter.order_id,parameter.udid,parameter.WarehouseId);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);
export const orderToVoidSlice= createSlice({
  name: 'save',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(orderToVoid.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(orderToVoid.fulfilled, (state, action) => {
        state.status = action.payload && action.payload? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload? action.payload : "");
        state.error = action.payload && action.payload? '' : "Fail to fetch" ;
        state.is_success = action.payload && action.payload? true : false;
      })
      .addCase(orderToVoid.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});
export const { } = orderToVoidSlice.actions;
//--
export default { checkStockSlice, getPaymentTypeNameSlice,getExtensionsSlice,getMakePaymentSlice,makeOnlinePaymentsSlice,saveSlice,paymentAmountSlice ,changeReturnAmountSlice,checkTempOrderSyncSlice,checkTempOrderStatusSlice,orderToCancelSaleSlice,orderToVoidSlice};
