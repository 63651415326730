
exports.key = {  
    "": "All",
    completed:"Completed",  
    "on-hold":"Lay-Away",
    pending:"Pending",
    park_sale:"Parked",
    cancelled:"Voided", 
    refunded:"Refunded",
    processing:"Processing",
    cancelled_sale:"Voided", 
    lay_away:"Lay Away",
    void_sale:"Voided",
    failed: 'Failed'
}   

