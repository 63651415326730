import { serverRequest } from '../../../CommonServiceRequest/serverRequest'

export function getMenuAPI() {
  try {
      return serverRequest.clientServiceRequest('GET', `/wcbridge/getmenus`, '')
          .then(menu_list => {
              return menu_list;
          }).catch(error => console.log(error));
  }
  catch (error) {
      console.log(error);
      return error;
  }
}
