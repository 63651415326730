import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import LeftNavBar from "../common/commonComponents/LeftNavBar";
import ClearCart from '../../assets/images/svg/ClearCart-Icon.svg'
import OliverIconBaseBlue from '../../assets/images/svg/Oliver-Icon-BaseBlue.svg'
// import DropdownArrow from '../../assets/images/svg/DropdownArrow.svg'
import down_angled_bracket from '../../assets/images/svg/down-angled-bracket.svg';
import calendar from '../../assets/images/svg/calendar.svg'
//import Select from 'react-select'
import SearchBaseBlue from '../../assets/images/svg/SearchBaseBlue.svg'
import FilterArrowDown from '../../assets/images/svg/FilterArrowDown.svg'
import FilterArrowUp from '../../assets/images/svg/FilterArrowUp.svg'
import DownArrowBlue from '../../assets/images/svg/DownArrowBlue.svg'
import AngledBracketBlueleft from '../../assets/images/svg/AngledBracket-Left-Blue.svg'
import AvatarIcon from '../../assets/images/svg/AvatarIcon.svg'
import PlusSign from '../../assets/images/svg/PlusSign.svg'
import { useNavigate } from 'react-router-dom';
import { get_UDid } from '../common/localSettings';
import moment from 'moment';
import STATUSES from "../../constants/apiStatus";
import { LoadingSmallModal } from '../common/commonComponents/LoadingSmallModal'
import AppLauncher from "../common/commonComponents/AppLauncher";
import LocalizedLanguage from '../../settings/LocalizedLanguage';
import { LoadingModal } from "../common/commonComponents/LoadingModal";
import { activityRecords, getDetail, getFilteredActivities } from './ActivitySlice'
import Config from '../../Config'
import ActivityList from "./ActivityList";
import { FormateDateAndTime } from '../../settings/FormateDateAndTime';
import ActivityOrderDetail from "./ActivityOrderDetail";
import { ActivityFooter } from "./ActivityFooter";
import { cashRecords } from "../cashmanagement/CashmanagementSlice";
import { calenderInit } from "../common/commonFunctions/homeFn";
import { v4 as uniqueKey } from 'uuid';
import { GTM_ClientDetail } from "../common/CommonfunctionGTM";
import { ValidateComponent } from "../common/validate";
import { customergetDetail, customersave, customerupdate, updateCustomerNote } from "../customer/CustomerSlice";
import { IsFreePlan } from "../../settings/CommonJS";
import NoUserAccess from "../common/commonComponents/NoUserAccess";
const ActivityView = () => {
    const [defauldnumber, setDefauldNumber] = useState(1);
    const [AllActivityList, setAllActivityList] = useState([])
    const [updateActivityId, setupdateActivityId] = useState('')
    const [SelectedTypes, setSelectedTypes] = useState('')
    const [FilteredActivityList, setFilteredActivityList] = useState('')
    const [selectedOption, setSelectedOption] = useState('')
    const [sortbyvaluename, SetSortByValueName] = useState(LocalizedLanguage.datenewest)
    const [emailnamephone, setEmailNamePhone] = useState(sessionStorage.getItem("transactionredirect") ? sessionStorage.getItem("transactionredirect") : '')
    const [orderidsearch, setorderId] = useState(sessionStorage.getItem("notificationRedirect") ? sessionStorage.getItem("notificationRedirect") : '')
    const [pricefrom, setPriceFrom] = useState('')
    const [priceto, setPriceTo] = useState('')
    const [filterByPlatform, setFilterByPlatform] = useState('')
    const [filterByStatus, setFilterByStatus] = useState('')
    const [isloader, setSmallLoader] = useState(false)
    const [getPdfdateTime, setGetPdfdateTime] = useState('')
    const [filterByUser, setfilterByUser] = useState('')
    const [selectuserfilter, setSelectuserFilter] = useState('')
    // Toggle State------------
    const [isEmployeeWrapper, setEmployeeToggle] = useState(false)
    const [salepersonWrapper, setSalePersontoggle] = useState(false)
    const [isSelectStatus, setSelectStatus] = useState(false)
    const [isShowAppLauncher, setisShowAppLauncher] = useState(false);
    const [isShowLinkLauncher, setisShowLinkLauncher] = useState(false);
    const [isShowiFrameWindow, setisShowiFrameWindow] = useState(false);
    const [isCvmobile, setCvmobile] = useState(false)
    const [isShowMobLeftNav, setisShowMobLeftNav] = useState(false);
    const [isMobileNav, setisMobileNav] = useState(false);
    const [isSortWrapper, setSortWrapper] = useState(false)
    const [responsiveCusList, setResponsiveCusList] = useState(false)
    const [activityListcount, setactivityListcount] = useState([])
    const [activeDetailApi, setactiveDetailApi] = useState(true)
    const [ActivityOrderDetails, setActivityOrderDetails] = useState([])
    const [isDateFrom, setIsDateFrom] = useState(false)
    const [isDateTo, setIsDateTo] = useState(false)
    const [filterSearchActive, setFilterSearchActive] = useState(false)
    const [isWebShopOrder, setIsWebShopOrder] = useState(false)
    const [isFreePlan,setIsFreePlan]=useState(false);
    const [isReload,setIsReload]=useState(true);
    ValidateComponent()

    // All TOGGLE 
    const toggleAppLauncher = () => {
        setisShowAppLauncher(!isShowAppLauncher)
        setisShowLinkLauncher(false)
    }
    const toggleLinkLauncher = () => {
        setisShowLinkLauncher(!isShowLinkLauncher)
        setisShowAppLauncher(false)
    }
    const toggleiFrameWindow = () => {
        setisShowiFrameWindow(!isShowiFrameWindow)
    }
    const toggleMobileNav = () => {
        setisMobileNav(!isMobileNav)
        setisShowMobLeftNav(!isShowMobLeftNav)
    }
    const mobileTransactionsSearch = () => {
        setCvmobile(!isCvmobile)
    }

    const toggleSortWrapp = () => {
        setSortWrapper(!isSortWrapper)
        setIsDateFrom(false)
        setIsDateTo(false)
    }

    const toggleStatus = () => {
        setSelectStatus(!isSelectStatus)
        setEmployeeToggle(false)
        setSalePersontoggle(false)
        setIsDateFrom(false)
        setIsDateTo(false)
        setSortWrapper(false)
    }
    const toggleSaleperson = () => {
        setSalePersontoggle(!salepersonWrapper)
        setEmployeeToggle(false)
        setIsDateFrom(false)
        setIsDateTo(false)
        setSortWrapper(false)
    }
    const toggleEmployee = () => {
        setEmployeeToggle(!isEmployeeWrapper)
        setSelectStatus(false)
        setIsDateFrom(false)
        setIsDateTo(false)
        setSortWrapper(false)
    }
    const toggleResponsiveList = () => {
        setResponsiveCusList(!responsiveCusList)
    }
    const closeCalendra = (c) => {
        //console.log("---calendar---" + JSON.stringify(c));
    }
    const handleCalenderFrom = () => {
        setIsDateTo(false)
        setIsDateFrom(!isDateFrom)
        setEmployeeToggle(false)
        setSalePersontoggle(false)
        setSelectStatus(false)
        setSortWrapper(false)
    }
    const handleCalenderTo = () => {
        setIsDateFrom(false)
        setIsDateTo(!isDateTo)
        setEmployeeToggle(false)
        setSalePersontoggle(false)
        setSelectStatus(false)
        setSortWrapper(false)
    }
    const closeCalenderOnSelect = () => {
        setIsDateFrom(false)
        setIsDateTo(false)
        setEmployeeToggle(false)
        setSalePersontoggle(false)
        setSelectStatus(false)
        setSortWrapper(false)
    }
    // -------------------------------------------------------
    const dispatch = useDispatch();

    useEffect(() => {
        if(IsFreePlan()==true){
            setIsFreePlan(true);
        }
        document.getElementById("orderID").focus();
        setTimeout(() => {
            calenderInit(closeCalenderOnSelect);
        }, 1000);
    }, [])


    const hundleChange = (event) => {
        setEmailNamePhone(event.target.value)
    }
    const hundleChangeID = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setorderId(event.target.value)
        }
    }
    const hundleChangePriceFrom = (e) => {
        const amount = e.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
            setPriceFrom(e.target.value)
        }
    }
    const hundleChangePriceTo = (e) => {
        const amount = e.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
            setPriceTo(e.target.value)
        }
    }

    useEffect(() => {
        GTM_ClientDetail();
    }, []);

    /// GET ALL PAGE API FIRST TIME CALL___
    let useCancelled = false;
    useEffect(() => {
        if (useCancelled == false) {
            var transactionredirect = sessionStorage.getItem("transactionredirect") ? sessionStorage.getItem("transactionredirect") : '';
            var orderNo = sessionStorage.getItem("notificationRedirect") ? sessionStorage.getItem("notificationRedirect") : '';

            if (transactionredirect !== '') {
                applyServerFilter()
            }
            else if (orderNo != "") {
                setorderId(orderNo);
                applyServerFilter()
            }
            else {
                reload(1)
                dispatch(cashRecords(null));
            }
            if (localStorage.getItem("BACK_CHECKOUT") && localStorage.getItem("BACK_CHECKOUT") == "true") {
                localStorage.removeItem("BACK_CHECKOUT")
                //localStorage.removeItem("AdCusDetail")
            }

            dispatch(customersave('clear'));
            dispatch(customergetDetail('clear'));
            dispatch(customerupdate('clear'));
            dispatch(updateCustomerNote("clear"))
        }
        return () => {
            useCancelled = true;
        }
    }, []);

    const reload = (pagno,isfource) => {
       if(filterSearchActive==false  || isfource==true){
        var UID = get_UDid('UDID')
        var pageSize = Config.key.ACTIVITY_PAGE_SIZE;
        dispatch(activityRecords({ "UID": UID, "pageSize": pageSize, "pageNumber": pagno }));
      }else{
          if(isReload==true){
             pagno=(pagno==0?1:pagno);
            applyServerFilter(false,pagno);
          }
          
       }
      
    }


    // Getting Response  From Order Details
    const [respActivitygetdetails] = useSelector((state) => [state.activityGetDetail])
    useEffect(() => {
        if (respActivitygetdetails && respActivitygetdetails.status == STATUSES.IDLE && respActivitygetdetails.is_success && respActivitygetdetails.data) {
            setActivityOrderDetails(respActivitygetdetails && respActivitygetdetails.data && respActivitygetdetails.data.content);

        }
    }, [respActivitygetdetails,ActivityOrderDetails,updateActivityId]);

    // set all Activity List response from record Api
    const [activityAllDetails] = useSelector((state) => [state.activityRecords])
    useEffect(() => {        
        if (activityAllDetails && activityAllDetails.data && activityAllDetails.data.content && activityAllDetails.data.content.Records.length > 0) {
            var temState = [...AllActivityList, ...activityAllDetails.data && activityAllDetails.data.content && activityAllDetails.data.content.Records]

            temState = [...new Map(temState.map(item =>
                [item['order_id'], item])).values()];
            setAllActivityList(temState);
            setactivityListcount(activityAllDetails.data && activityAllDetails.data.content && activityAllDetails.data.content.TotalRecords)

        }
    }, [activityAllDetails]);


    // --Set Filter response from filter Api
    const [activityfilter] = useSelector((state) => [state.getFilteredActivities])
    useEffect(() => {        
        if (activityfilter && activityfilter.data.length > 0) {
            var temState = [...AllActivityList,...activityfilter.data];
            temState = [...new Map(temState.map(item =>
                [item['order_id'], item])).values()];
            setAllActivityList(temState);
            setSmallLoader(false)
        } else {
           // setAllActivityList([]);

            if (( activityfilter.status !== "loading" && activityfilter &&( activityfilter.data || activityfilter.data==""))) {
                if(activityfilter.data.length==0 && defauldnumber >1)
                {                
                 setIsReload(false);
                }
                setSmallLoader(false);
            }
           
        }
    }, [activityfilter]);





    useEffect(() => {
        ActivityDataSearch();
    }, [SelectedTypes, AllActivityList]);

    // Sort By-------
    const ActivityDataSearch = () => {
        var scount = 0;
        var _filteredActivity = AllActivityList;        
        // console.log("_filteredActivity",_filteredActivity)
        setFilteredActivityList(_filteredActivity);
        scount += _filteredActivity.length;
        // console.log("customer count", scount)
    }

    // Filter activity list Accourding To Date
    var getDistinctActivity = {};
    var _activity = FilteredActivityList;
    getDistinctActivity = useMemo(() => getActivityDateWise(FilteredActivityList), [FilteredActivityList]);
    //console.log("getDistinctActivity",getDistinctActivity)
    function getActivityDateWise(_activity) {
        // console.log("_activity",_activity)
        var getDistinctActivity = {};
        _activity && _activity.map(item => {
            //console.log("item", item)
            // var dateKey = FormateDateAndTime.formatDateAndTime(item.date_time && item.date_time !== undefined ? item.date_time : item.CreatedDate, item.time_zone);
            //var dateKey = FormateDateAndTime.formatDateAndTime(item.CreatedDate && item.CreatedDate !== undefined ? item.CreatedDate : item.date_time, item.time_zone);

            var dateKey = new Date(item.CreatedDate && item.CreatedDate !== undefined ? item.CreatedDate : item.date_time).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' });
            //  console.log("dateKey", dateKey)
            if (!getDistinctActivity.hasOwnProperty(dateKey)) {
                getDistinctActivity[dateKey] = new Array(item);
            } else {
                if (typeof getDistinctActivity[dateKey] !== 'undefined' && getDistinctActivity[dateKey].length > 0) {
                    //var prodcutDate = FormateDateAndTime.formatDateAndTime(item.CreatedDate && item.CreatedDate !== undefined ? item.CreatedDate : item.date_time, item.time_zone);
                    var prodcutDate = new Date(item.CreatedDate && item.CreatedDate !== undefined ? item.CreatedDate : item.date_time).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' });
                    //var arrFound=getDistinctActivity[dateKey].find(i=>i.Id==item.Id)
                    if (dateKey == prodcutDate) {
                        getDistinctActivity[dateKey].push(item)
                    }
                }
            }
        })
        return getDistinctActivity;
    }

    //---------------------------------------------------
    var ordersDate = new Array();
    if (typeof getDistinctActivity !== 'undefined') {
        for (const key in getDistinctActivity) {
            if (getDistinctActivity.hasOwnProperty(key)) {
                ordersDate.push(key)
            }
        }
    }

    const sortOrders = (orders) => {
        if (typeof orders !== 'undefined') {
            if (orders.length > 0) {
                if (SelectedTypes == '' || SelectedTypes == 'dateAsc' || SelectedTypes == 'dateDesc') {
                    orders.sort(function (a, b) {
                        var keyA = new Date(a.order_id),
                            keyB = new Date(b.order_id);
                        // Compare the 2 dates
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });
                }
                if (SelectedTypes == '' || SelectedTypes == 'dateAsc') {
                    orders.reverse();
                } else if (SelectedTypes == 'dateDesc') {

                }
                if (SelectedTypes == 'amountAsc') {
                    orders.sort(function (a, b) {
                        var keyA = new Date(a.total),
                            keyB = new Date(b.total);
                        // Compare the 2 dates
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });
                    orders.reverse();
                }
                if (SelectedTypes == 'amountDesc') {
                    orders.sort(function (a, b) {
                        var keyA = new Date(a.total),
                            keyB = new Date(b.total);
                        // Compare the 2 dates
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });

                }
            }
        }
        return orders;
    }

    /// Click By Activity List Components
    const activeClass = (item, index, isMobileClicked) => {
        if(item.hasOwnProperty("OliverReciptId")  && item.OliverReciptId=="")
        {
            setIsWebShopOrder(true);
        }
        else
        {
            setIsWebShopOrder(false);
        }
        setSortWrapper(false)
        setIsDateFrom(false)
        setIsDateTo(false)
        setEmployeeToggle(false)
        setSalePersontoggle(false)
        setSelectStatus(false)

        //var _item = JSON.stringify(item);
        if (isMobileClicked !== true && (item.order_id == localStorage.getItem("CUSTOMER_TO_OrderId") || item.order_id == localStorage.getItem("CUSTOMER_TO_ACTVITY"))) {
            //console.log(isMobileClicked, ">>>>")
        } else {
            localStorage.removeItem("CUSTOMER_TO_ACTVITY")
            localStorage.removeItem("CUSTOMER_TO_OrderId");
            var mydate = new Date(item.date);
            var getPdfdate = (mydate.getMonth() + 1) + '/' + mydate.getDate() + '/' + mydate.getFullYear() + ' ' + item.time;
            //var itemCreatedDate = FormateDateAndTime.formatDateAndTime(item.date_time, item.time_zone)
            setupdateActivityId(item.order_id)
            setGetPdfdateTime(getPdfdate)
            var UID = get_UDid('UDID');
            if (item.order_id) {
                dispatch(getDetail(item.order_id, UID));
            }
            setResponsiveCusList(!responsiveCusList)
        }
    }


    // First Time  activityDetails API Call
    let useCancelled1 = false;
    useEffect(() => {
        var UID = get_UDid('UDID');
        var customer_to_activity_id = (typeof localStorage.getItem("CUSTOMER_TO_ACTVITY") !== 'undefined' && localStorage.getItem("CUSTOMER_TO_ACTVITY") !== null) ? localStorage.getItem("CUSTOMER_TO_ACTVITY") : null;
       if(customer_to_activity_id==null){
            if(AllActivityList && AllActivityList.length>0)
            {
            var _orders=AllActivityList;
                _orders.sort(function (a, b) {
                    var keyA = new Date(a.order_id),
                        keyB = new Date(b.order_id);
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                })
                _orders.reverse();
                customer_to_activity_id=_orders && _orders[0].order_id;
            }
        }
        setupdateActivityId(customer_to_activity_id)
        if (useCancelled1 == false && activeDetailApi !== false) {
            if (customer_to_activity_id) {
                dispatch(getDetail(customer_to_activity_id, UID));
            }
            if (sessionStorage.getItem("notificationRedirect")) {
                setResponsiveCusList(true)
            }
        }
        if(AllActivityList && AllActivityList.length>0 && customer_to_activity_id!=null && customer_to_activity_id!==0)
        {
            var _first= AllActivityList.find(a=>a.order_id == customer_to_activity_id);
            if(typeof _first!=="undefined" && _first!==null)
            {
                if(_first.hasOwnProperty("OliverReciptId")  && _first.OliverReciptId=="")
                {
                    setIsWebShopOrder(true);
                }
                else
                {
                    setIsWebShopOrder(false);
                }
            }
        }
        if (isCvmobile === true) {
            // clearFilter();
        }
        return () => {
            useCancelled1 = true;
        }


    }, [AllActivityList]);



    const sortByList = (filterType, FilterValue) => {
        SetSortByValueName(FilterValue)
        setSelectedTypes(filterType);
        setupdateActivityId("")
        setSortWrapper(false)
    }

    // filter All Function 
    const SetFilterStatus = (filterType, FilterValue, label) => {
        if (filterType == 'status') {
            setFilterByStatus(FilterValue)
        }
    }
    const SetFilterPlatform = (filterType, FilterValue, label) => {
        if (filterType == 'platform') {
            setFilterByPlatform(FilterValue)
        }
    }
    const SetFilterUser = (filterType, FilterValue, label) => {
        if (filterType == 'user') {
            if (FilterValue !== "") {
                setfilterByUser(FilterValue)
                setSelectuserFilter(label)
            }

        }
    }
    //-----------------------



    // Filter Submit Btn
    const applyServerFilter = (isRefresh = false,pageno=0) => {              
        if(pageno==0){
            setDefauldNumber(1)            
            pageno++;
            setAllActivityList([])
        }
    
        var UID = get_UDid('UDID');
        var pagesize = Config.key.ACTIVITY_PAGE_SIZE
        var fromdate = document.getElementById("dateFrom").value;
        var txttodate = document.getElementById("dateTo").value;
        //var _startdate = fromdate && fromdate !== "" ? new Date(fromdate) : "";
        //var _enddate = txttodate && txttodate !== "" ? new Date(txttodate) : "";
        var s_dd = 0;
        var s_mm = 0;
        var s_yy = 0;
        var e_dd = 0;
        var e_mm = 0;
        var e_yy = 0;
        // if (_startdate && _startdate !== "") {
        //     s_dd = _startdate.getMonth() + 1;
        //     s_mm = _startdate.getDate();
        //     s_yy = _startdate.getFullYear();
        // }
        // if (_enddate && _enddate !== "") {
        //     e_dd = _enddate.getMonth() + 1;
        //     e_mm = _enddate.getDate();
        //     e_yy = _enddate.getFullYear();
        // }
        var _startdate = fromdate && fromdate !== "" ? fromdate.split('/') : "";
        var _enddate = txttodate && txttodate !== "" ? txttodate.split('/') : "";
        if (_startdate && _startdate !== "" && _startdate.length == 3) {
            s_dd = parseInt(_startdate[0]);
            s_mm = parseInt(_startdate[1]);
            s_yy = parseInt(_startdate[2]);
        }
        if (_enddate && _enddate !== "" && _startdate.length == 3) {
            e_dd = parseInt(_enddate[0]);
            e_mm = parseInt(_enddate[1]);
            e_yy = parseInt(_enddate[2]);
        }
        if (filterByUser.trim() !== "" || filterByStatus.trim() !== "" || filterByPlatform.trim() !== "" || emailnamephone.trim() !== "" ||
            selectuserfilter.trim() !== "" || orderidsearch.trim() !== "" || pricefrom.trim() !== "" || priceto.trim() !== "" || fromdate.trim() !== '' || txttodate.trim() !== '' ) { //

            var _filterParameter = {
                "PageSize": pagesize,
                "PageNumber": pageno,
                "isSearch": "true",
                "udid": UID,
                "plateform": filterByPlatform,
                "status": filterByStatus == "All" ? "" : filterByStatus,
                "userId": filterByUser == "All" ? "" : filterByUser,
                "SatrtDay": s_dd,
                "SatrtMonth": s_mm,
                "SatrtYear": s_yy,
                "EndDay": e_dd,
                "EndMonth": e_mm,
                "EndYear": e_yy,
                "searchVal": orderidsearch,
                //"groupSlug": this.state.filterByGroupList,
                "MinAmount": pricefrom,
                "MaxAmount": priceto,
                "customerInfo": emailnamephone ? emailnamephone : "",


            };
            setSmallLoader(true);
            //console.log("_filterParameter",_filterParameter)
            dispatch(getFilteredActivities(_filterParameter));
            // mobileTransactionsSearch()
            setFilterSearchActive(true)

        }




    }

    const clearFilter = () => {
        setFilterSearchActive(false);
        setIsDateFrom(false);
        setIsDateTo(false);
        setDefauldNumber(1);
        setActivityOrderDetails(null)
        var fromdate = document.getElementById("dateFrom").value;
        var txttodate = document.getElementById("dateTo").value;
        if (filterByUser !== "" || filterByStatus !== "" || filterByPlatform !== "" || emailnamephone !== "" ||
        selectuserfilter !== "" || orderidsearch !== "" || pricefrom !== "" || priceto !== "" || fromdate !== '' || txttodate !== '' ) { //|| filterSearchActive == true
            // selectuserfilter !== "" || orderidsearch !== "" || pricefrom !== "" || priceto !== "" || fromdate !== '' || txttodate !== '' || AllActivityList !== '') {

            setIsReload(true);
           
            setfilterByUser("")
            setFilterByStatus("")
            setFilterByPlatform("")
            setEmailNamePhone("")
            setSelectuserFilter('')
            setorderId('')
            setPriceFrom("")
            setPriceTo("")
            localStorage.removeItem("CUSTOMER_TO_ACTVITY");
            localStorage.removeItem('CUSTOMER_TO_OrderId');
            sessionStorage.removeItem('transactionredirect');
            sessionStorage.removeItem('notificationRedirect');
            document.getElementById('dateFrom').value = '';
            document.getElementById('dateTo').value = '';
            //if (filterSearchActive == true) {
                setupdateActivityId('')
                setAllActivityList([]);               
                     reload(1,true);               
                    setactiveDetailApi(true);
               
                // setAllActivityList("")
                
           // }
           
            mobileTransactionsSearch()
            document.getElementById("orderID").focus();
        }
        setIsDateFrom(false)
        setIsDateTo(false)
    }
    // transection dev click for close toggle 
    const hundleTransectionClick = () => {
        setSortWrapper(false)
        setIsDateFrom(false)
        setIsDateTo(false)
        setEmployeeToggle(false)
        setSalePersontoggle(false)
        setSelectStatus(false)
    }




    const _Useroptions = [];
    _Useroptions.push({ value: "All", label: "All" });
    var _userList = null
    _userList = localStorage.getItem('user_List') && localStorage.getItem('user_List') !== 'undefined' && typeof (localStorage.getItem('user_List')) !== undefined ? JSON.parse(localStorage.getItem('user_List')) : null;
    if (_userList !== null) {
        _userList.map((user) => {
            var option = { value: user.Id, label: user.Name };
            _Useroptions.push(option);
        })
    }
    var _platform = [{ key: "both", value: "Both" }, { key: "oliver-pos", value: "Oliver POS" }, { key: "web-shop", value: "Webshop" }];
    var _orderstatus = [{ key: "All", value: "All" }, { key: "pending", value: "Parked" }, { key: "on-hold", value: "Lay-Away" }, { key: "cancelled", value: "Voided" }, { key: "refunded", value: "Refunded" }, { key: "completed", value: "Completed" }, { key: "failed", value: "Failed" }];

    /// Scroll  then api call
    const updateSomething = () => {
        var transactionsRedirect = sessionStorage.getItem("transactionredirect") ? sessionStorage.getItem("transredirection") : '';
        setactiveDetailApi(false)
        setDefauldNumber(defauldnumber + 1)
        if (AllActivityList.length == activityListcount) {

        } else if (defauldnumber != 1 && transactionsRedirect == '' ) {//&& filterSearchActive !== true
            reload(defauldnumber)
        }
    }

    var subtotal = 0.0;
    if (ActivityOrderDetails) {
        subtotal = parseFloat(
            parseFloat(ActivityOrderDetails.total_amount - ActivityOrderDetails.refunded_amount) -
            parseFloat(ActivityOrderDetails.total_tax - ActivityOrderDetails.tax_refunded)
        ).toFixed(2);
    }

    const handleKeyUp = (e) => {
        if (e.keyCode == 13) {
            applyServerFilter();
        }
    }


    const outerClick = (e) => {
        // console.log("e",e)
        if (e && e.target && e.target.className && e.target.className === "date-selector" || e.target.className === "search-body") {
            closeCalenderOnSelect && closeCalenderOnSelect();
        }
    }

    // var iframe=  document.getElementById("iframeWrapper");
    //   if(iframe){
    //     iframe.src='http://localhost:3000/externalApp/3-0wrapper.html'
    // }
    var placeHolderStatus = ""
    _orderstatus && _orderstatus.length > 0 && _orderstatus.map((item, index) => {
        if (item.key == filterByStatus) {
            placeHolderStatus = item.value
        }
    })

    useEffect(() => {
        if (sessionStorage.getItem("transactionredirect")) {
            setTimeout(() => {
                applyServerFilter();
                sessionStorage.removeItem("transactionredirect")
            }, 50);
        }
    },[sessionStorage.getItem("transactionredirect")])
   
    return <>
        <div className="transactions-wrapper">
            {isFreePlan==false && activityAllDetails.status == STATUSES.LOADING? <LoadingModal></LoadingModal> : null}
            {respActivitygetdetails.status == STATUSES.LOADING && isFreePlan==false ? <LoadingModal></LoadingModal> : null}
            <LeftNavBar isShowMobLeftNav={isShowMobLeftNav} toggleLinkLauncher={toggleLinkLauncher} toggleAppLauncher={toggleAppLauncher} toggleiFrameWindow={toggleiFrameWindow} view="Activity View"></LeftNavBar>
            <AppLauncher isShow={isShowAppLauncher} toggleAppLauncher={toggleAppLauncher} toggleiFrameWindow={toggleiFrameWindow}></AppLauncher>
            <div id="navCover" className="nav-cover"></div>
            <div className="mobile-transactions-header">
                <button id="mobileNavToggle" onClick={() => toggleMobileNav()} className={isMobileNav === true ? "opened" : ""}>
                    <img src="" alt="" />
                </button>
                <p>{LocalizedLanguage.transactions}</p>
                <button id="mobileTransactionsSearchButton" onClick={mobileTransactionsSearch}>
                    <img src={SearchBaseBlue} alt="" />
                </button>
                <button id="mobileAppsButton" onClick={() => toggleAppLauncher()}>
                    <img src={OliverIconBaseBlue} alt="" />
                </button>
            </div>
            <div id="transactionsSearch" className={isCvmobile === true ? "transactions-search open" : "transactions-search"}>
                <div className="search-header">
                    <p>{LocalizedLanguage.transactions}</p>
                    <button id="clearSearchFields" onClick={() => clearFilter()}> {LocalizedLanguage.clear} </button>
                </div>
                <div className="search-header-mobile">
                    <button id="mobileSearchExit" onClick={mobileTransactionsSearch}>
                        <img src={AngledBracketBlueleft} alt="" />
                        {LocalizedLanguage.goBack}
                    </button>
                    <button id="mobileSearchFieldClear" onClick={() => clearFilter()} >{LocalizedLanguage.clear}</button>
                </div>
                <div className="search-body" onClick={(e) => outerClick(e)}>
                    <p className="mobile-only">{LocalizedLanguage.searchforOrder}r</p>
                    <label htmlFor="orderID">{LocalizedLanguage.orderid}</label>
                    <input type="number" id="orderID" placeholder={LocalizedLanguage.orderid} onKeyUp={(e) => handleKeyUp(e)} onChange={hundleChangeID} value={orderidsearch} />
                    <p>{LocalizedLanguage.youcanscantheorderidanytime}</p>
                    <div className="divider"></div>
                    <label htmlFor="custInfo">{LocalizedLanguage.shortCustomerInfo}</label>
                    <input type="text" id="custInfo" placeholder={LocalizedLanguage.placeholderSearchOrder} onKeyUp={(e) => handleKeyUp(e)} onChange={hundleChange} value={emailnamephone} />
                    <label htmlFor="orderStatus">{LocalizedLanguage.orderStatus}</label>
                    <div className={isSelectStatus === true ? "dropdown-wrapper open " : "dropdown-wrapper"} onClick={toggleStatus} >
                        <img src={down_angled_bracket} alt="" />
                        {/* <input type="text" id="orderStatus" className="textcapital" placeholder={placeHolderStatus == '' ? LocalizedLanguage.all : placeHolderStatus !== "" ? placeHolderStatus == "Parked" ? "pending and Parked" : placeHolderStatus : "Select Status"} onClick={toggleStatus} readOnly /> */}
                        <input type="text" id="orderStatus" className="textcapital" placeholder={LocalizedLanguage.selectstatus} onClick={toggleStatus} defaultValue={placeHolderStatus == "Parked" ? "pending and Parked" : placeHolderStatus} readOnly />

                        <div className="option-list">
                            {_orderstatus && _orderstatus.length > 0 && _orderstatus.map((item, index) => {
                                return (
                                    <div className="option" key={uniqueKey()} onClick={() => SetFilterStatus("status", item.key)}>
                                        <p className="textcapital">{item.value == "Parked" ? "pending and Parked" : item.value}</p>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className="input-row">
                        <div className="input-col">
                            <label htmlFor="dateFrom">{LocalizedLanguage.datefrom}</label>
                            <div className="date-selector-wrapper left ">
                                <input type="text" id="dateFrom" placeholder="dd/mm/yyyy" onChange={(e) => { closeCalendra(e) }} />
                                <button className="open-date-selector open" onClick={() => handleCalenderFrom()}>
                                    <img src={calendar} alt="" />
                                </button>
                                <div id="calFrom" className={isDateFrom == true ? "date-selector open" : "date-selector"} onClick={(e) => outerClick(e)}></div>
                            </div>
                        </div>
                        <div className="input-col">
                            <label htmlFor="dateTo">{LocalizedLanguage.dateto}</label>
                            <div className="date-selector-wrapper right">
                                <input type="text" id="dateTo" placeholder="dd/mm/yyyy" onChange={(e) => { closeCalendra(e) }} />
                                <button className="open-date-selector" onClick={() => handleCalenderTo()}>
                                    <img src={calendar} alt="" />
                                </button>
                                <div id="calTo" className={isDateTo == true ? "date-selector open" : "date-selector"}></div>
                            </div>
                        </div>
                    </div>
                    <label htmlFor="salesPlatform">{LocalizedLanguage.salesplatform}</label>
                    <div className={salepersonWrapper === true ? "dropdown-wrapper open " : "dropdown-wrapper"} onClick={toggleSaleperson} >
                        <img src={down_angled_bracket} alt="" />
                        <input type="text" id="salesPlatform" placeholder={LocalizedLanguage.allplatforms} defaultValue={filterByPlatform ? filterByPlatform : ""} onClick={toggleSaleperson} readOnly />
                        <div className="option-list">
                            {_platform && _platform.length > 0 && _platform.map((item, index) => {
                                return (
                                    <div className="option" key={uniqueKey()} onClick={() => SetFilterPlatform("platform", item.key)}>
                                        <p>{item.value}</p>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <label htmlFor="employee">{LocalizedLanguage.employee}</label>
                    <div className={isEmployeeWrapper === true ? "dropdown-wrapper open " : "dropdown-wrapper"} onClick={toggleEmployee}>
                        <img src={down_angled_bracket} alt="" />
                        <input type="text" id="employee" placeholder={LocalizedLanguage.selectemployee} defaultValue={selectuserfilter ? selectuserfilter : ""} onClick={toggleEmployee} readOnly />
                        <div className="option-list">
                            {_Useroptions && _Useroptions.length > 0 && _Useroptions.map((item, index) => {
                                return (
                                    <div className="option" key={uniqueKey()} onClick={() => SetFilterUser("user", item.value, item.label)}>
                                        <p>{item.label}</p>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className="input-row">
                        <div className="input-col">
                            <label htmlFor="priceFrom">{LocalizedLanguage.pricefrom}</label>
                            <input type="text" id="priceFrom" placeholder={LocalizedLanguage.price} onKeyUp={(e) => handleKeyUp(e)} onChange={hundleChangePriceFrom} value={pricefrom} />
                        </div>
                        <div className="input-col">
                            <label htmlFor="priceTo">{LocalizedLanguage.priceto}</label>
                            <input type="text" id="priceTo" placeholder={LocalizedLanguage.price} onKeyUp={(e) => handleKeyUp(e)} onChange={hundleChangePriceTo} value={priceto} />
                        </div>
                    </div>
                    <button id="searchTransactionButton" onClick={applyServerFilter}>{LocalizedLanguage.searchactivity}</button>
                </div>
            </div>

            <div className="transactions-list" >
                <div className="header" onClick={toggleSortWrapp}>
                    <p>{LocalizedLanguage.sortby}:</p>
                    <div id="customerListSort" className={isSortWrapper === true ? "sort-wrapper open " : "sort-wrapper"} >
                        {/* <!-- Hidden Input can be used to know what filter type to use (Other elements are purely visual) --> */}
                        {/* <input type="text" id="filterType" /> */}
                        <img className="dropdown-arrow" src={DownArrowBlue} alt="" />
                        <input type="text" id="filterType" value={sortbyvaluename} readOnly />
                        {/* <p>{sortbyvaluename}</p> */}
                        {/* <div id="sortCurrent" className="sort-current"  >
                        <img className="dropdown-arrow" src={DownArrowBlue} alt="" />
                            <img src={SelectedTypes != "" && SelectedTypes.includes("Asc") ? FilterArrowUp : FilterArrowDown} alt="" />
                            <p>{sortbyvaluename}</p>
                        </div> */}
                        <div className="option-container" id="transactionsListSortOptionsContainer">
                            <div className="option" onClick={(e) => sortByList("dateAsc", LocalizedLanguage.datenewest)}>{LocalizedLanguage.datenewest}</div>
                            <div className="option" onClick={(e) => sortByList("dateDesc", LocalizedLanguage.dateoldest)}>{LocalizedLanguage.dateoldest}</div>
                            <div className="option" onClick={(e) => sortByList("amountAsc", LocalizedLanguage.amountHighest)}>{LocalizedLanguage.amountHighest}</div>
                            <div className="option" onClick={(e) => sortByList("amountDesc", LocalizedLanguage.Amountlowest)}>{LocalizedLanguage.Amountlowest}</div>
                        </div>


                        {/* <div className="option" data-value="dateAsc" onClick={(e) => sortByList("dateAsc", "Date")} >
                            <img src={FilterArrowUp} alt="" />
                            <p>Date</p>
                        </div>
                        <div className="option" data-value="dateDesc" onClick={(e) => sortByList("dateDesc", "Date")}>
                            <img src={FilterArrowDown} alt="" />
                            <p>Date</p>
                        </div>
                        <div className="option" data-value="amountAsc" onClick={(e) => sortByList("amountAsc", "Amount")}>
                            <img src={FilterArrowUp} alt="" />
                            <p>Amount</p>
                        </div>
                        <div className="option" data-value="amountDesc" onClick={(e) => sortByList("amountDesc", "Amount")}>
                            <img src={FilterArrowDown} alt="" />
                            <p>Amount</p>
                        </div> */}

                    </div>
                </div>
                <ActivityList sortOrders={sortOrders} /*SelectedTypes={SelectedTypes}*/ orders={getDistinctActivity} click={activeClass} updateActivityId={updateActivityId} isloader={isloader} updateSomething={updateSomething} hundleTransectionClick={hundleTransectionClick} />
            </div>
            {_activity && _activity.length > 0 ? <div id="transactionsDetailed" className={responsiveCusList === true ? "transactions-detailed open" : " transactions-detailed"} onClick={(e) => hundleTransectionClick(e)}>
                <div className="detailed-header-mobile">
                    <button id="mobileDetailedExit" onClick={toggleResponsiveList}>
                        <img src={AngledBracketBlueleft} alt="" />
                        Go Back
                    </button>
                </div>
                <ActivityOrderDetail
                    Subtotal={subtotal ? subtotal : 0}
                    //  Discount={_discount}
                    TotalTax={ActivityOrderDetails && ActivityOrderDetails.total_tax}
                    tax_refunded={ActivityOrderDetails && ActivityOrderDetails.tax_refunded}
                    TotalAmount={ActivityOrderDetails && ActivityOrderDetails.total_amount}
                    refunded_amount={ActivityOrderDetails && ActivityOrderDetails.refunded_amount}
                    OrderPayment={ActivityOrderDetails && ActivityOrderDetails.order_payments}
                    refundPayments={ActivityOrderDetails && ActivityOrderDetails.order_Refund_payments}
                    cash_round={ActivityOrderDetails && ActivityOrderDetails.cash_rounding_amount}
                    balence={0}
                    TimeZone={ActivityOrderDetails && ActivityOrderDetails.time_zone}
                    refundCashRounding={ActivityOrderDetails && ActivityOrderDetails.refund_cash_rounding_amount}
                    redeemPointsToPrint={ActivityOrderDetails && ActivityOrderDetails.meta_datas ? ActivityOrderDetails && ActivityOrderDetails.meta_datas && ActivityOrderDetails && ActivityOrderDetails.meta_datas[1] ? ActivityOrderDetails && ActivityOrderDetails.meta_datas[1].ItemValue : 0 : 0}
                    orderMetaData={ActivityOrderDetails && ActivityOrderDetails.meta_datas}
                    applyServerFilter={applyServerFilter}
                    updateActivityId={updateActivityId}
                   // shippingLines = {ActivityOrderDetails && ActivityOrderDetails.shipping_lines}
                   shipping_tax={ActivityOrderDetails && ActivityOrderDetails.shipping_tax}
                   total_shipping={ActivityOrderDetails && ActivityOrderDetails.total_shipping}
                   shipping_method={ActivityOrderDetails && ActivityOrderDetails.shipping_method}
                   isWebShopOrder={isWebShopOrder}
                   isFreePlan={isFreePlan}
                />
                <ActivityFooter getPdfdateTime={getPdfdateTime} isWebShopOrder={isWebShopOrder}/>
            </div> : <><div id="CVDetailed" className="cv-detailed">
                <div className="no-search-results-detailed">
                    <p className="style1">{LocalizedLanguage.Notransactionstodisplay}</p>
                    <p className="style2">{LocalizedLanguage.Trysearchingforantransactionsor}<br /> {LocalizedLanguage.selectfromlisttoview}</p>
                </div></div></>}

               
        </div>
        <div className="subwindow-wrapper hidden"></div>
        {isFreePlan==true &&
        <NoUserAccess isShow={isFreePlan} tab={LocalizedLanguage.transactions}></NoUserAccess>
        }
    </>
}

export default ActivityView