
//************ Amelia Apps  handlers*/
import { postmessage } from '../commonAppHandler';
export const appreadywithtoken = (whereToview, isbackgroudApp) => {
    var clientJSON = {
        command: "appreadywithtoken",
        version: "3.0",
        method: "get",
        status: 200,
        data:
        {
          token:  btoa(localStorage.getItem("AUTH_KEY")).toString()
        },
        error: null
      }
      postmessage(clientJSON)
      //console.log("clientJSON from shopview", clientJSON)
}

export const getAmeliaMenu = (data, whereToView) => {
  if(data){
    localStorage.setItem("amelia_menu",JSON.stringify( data.data));
    // var mdata= JSON.parse(data).data;
     //console.log("",data);
   
  }
  
}