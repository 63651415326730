import React from "react";
import LockedIcon from '../../../assets/images/svg/CreditCard.svg';
import LocalizedLanguage from "../../../settings/LocalizedLanguage";

const NoUserAccess = (props) => {
  
    const closePopUp = () => {
       window.location="https://account.oliverpos.com/"
    }
    return (
        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} >
            <div className={props.isShow === true ? "subwindow no-permissions current" : "subwindow no-permissions"}>
                <div className="subwindow-body gap16">
                    <img className="no-access" src={LockedIcon} alt="" />
                    <p className="style1 nomargine">
                       <h1>{LocalizedLanguage.noaccesHeader}</h1>
                    </p>
                    <p className="style2 nomargine">
                       {LocalizedLanguage.noaccesLine1} <b>{props.tab}</b> {LocalizedLanguage.noaccesLine2}. 
                        {/* access this feature. Role permissions can <br class="mobile" />
                        be <br class="landscape" />
                        managed through the HUB settings. */}
                    </p>
                    <button className="margine16" id="noPermissionExit" onClick={()=>closePopUp()}>{LocalizedLanguage.noaccesHeader}</button>
                </div>
            </div>
        </div>)
}

export default NoUserAccess 