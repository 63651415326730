import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import X_Icon_DarkBlue from '../../assets/images/svg/X-Icon-DarkBlue.svg';
import down_angled_bracket from '../../assets/images/svg/down-angled-bracket.svg';
import Checkmark from '../../assets/images/svg/Checkmark.svg';
import RedLock from '../../assets/images/svg/RedLock.svg';
import { customerdelete, customergetPage, customersave, customerupdate, customergetDetail, getAllEvents } from './CustomerSlice'
import { get_UDid } from "../common/localSettings";
import STATUSES from "../../constants/apiStatus";
import { useIndexedDB } from 'react-indexed-db';
import Config, { key } from '../../Config'
import { LoadingModal } from "../common/commonComponents/LoadingModal";
import { CommonModuleJS, NavigateComponent } from "../../settings";
import MsgPopup from "../common/commonComponents/MsgPopup";
import ConfirmationPopup from "../common/commonComponents/ConfirmationPopup";
import { v4 as uniqueKey } from 'uuid';
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { checkBridgeVirson } from "../common/validate";
import { isAutoFocus } from "../../settings/AndroidIOSConnect";
import { popupMessage } from "../common/commonAPIs/messageSlice";

const CustomerCreate = (props) => {
    // console.log("editcustomerparam", props)
    const textInput = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    var UID = get_UDid('UDID');
    const initialValues = { fName: "", lName: "", tel: "", website: "", billingAddress1: "", billingAddress2: "", billingZipPostal: "", billingCity: "", billingState: "", billingCountry: "", shippingAddress1: "", shippingAddress2: "", shippingCity: "", email: props.searchSringCreate ? props.searchSringCreate : "", shippingZipPostal: "", shippingState: "", shippingCountry: "" };
    const [values, setValues] = useState(initialValues);
    const [toggleDrowpdown, settoggleDrowpdown] = useState(false)
    const [togglesecondDropdown, settogglesecondDropdown] = useState(false)
    const [togglethirdDropdown, settogglethirdDropdown] = useState(false)
    const [toggleFourDropdown, settoggleFourDropdown] = useState(false)
    const [toggleSameBilling, settoggleSameBilling] = useState(false)
    const [errors, setErrors] = useState({});
    const [allCustomerList, setAllCustomerList] = useState([])
    const [phone, setPhone] = useState();
    const [viewStateList, setviewStateList] = useState('')
    const [ShippingViewStateList, setShippingViewStateList] = useState('')
    const [country_name, setcountry_name] = useState('')
    const [Shippingcountry_name, setShippingCountryName] = useState('')
    const [shipping_state_name, setshippingstate_name] = useState('')
    const [state_name, setstate_name] = useState('')
    const [Cust_ID, setCust_ID] = useState('')
    const [isCusToSaveCart, setisCusToSaveCart] = useState(false)
    const [CountryList, setCountryList] = useState(localStorage.getItem('countrylist') !== null ? typeof (localStorage.getItem('countrylist')) !== undefined ? localStorage.getItem('countrylist') !== 'undefined' ?
        Array.isArray(JSON.parse(localStorage.getItem('countrylist'))) === true ? JSON.parse(localStorage.getItem('countrylist')) : '' : '' : '' : '')

    const [getStateList, setgetStateList] = useState(localStorage.getItem('statelist') && localStorage.getItem('statelist') !== null ? typeof (localStorage.getItem('statelist')) !== undefined ? localStorage.getItem('statelist') !== 'undefined' ?
        Array.isArray(JSON.parse(localStorage.getItem('statelist'))) === true ? JSON.parse(localStorage.getItem('statelist')) : '' : '' : '' : '')

    const [billingCountryCode, setbillingCountryCode] = useState('')
    const [billingStateCode, setbillingStateCode] = useState('')
    const [shippingCountryCode, setshippingCountryCode] = useState('')
    const [shippingStateCode, setshippingStateCode] = useState('')
    const [BillingSerachString, setBillingSerachString] = useState('');
    const [ShippingSearchCountry, setShippingSearchCountry] = useState('')
    const [RenderBillingCountry, setRenderBillingCountry] = useState('')
    const [RenderShippingCountry, setRenderShippingCountry] = useState('')
    const [RenderBillingState, setRenderBillingState] = useState("")
    const [RenderShippingState, setRenderShippingState] = useState('')
    const [HundleStateBilling, setHundleStateBilling] = useState('')
    const [HundleStateShipping, setHundleStateShipping] = useState('')
    const [AllreadyEmail, setAllreadyEmail] = useState(false)
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [emailformat, setEmailformat] = useState(false)
    const [isShowMsg, setisShowMsg] = useState(false);
    const [msgTitle, setmsgTitle] = useState('');
    const [msgBody, setmsgBody] = useState('');
    const [showConfirmpopup, setshowConfirmpopup] = useState(false);
    const [toggleCheckBtn, setToggleCheckBtn] = useState(false)

    // Toggle dropDown country and state
    const hundledropdown = () => {
        settoggleDrowpdown(!toggleDrowpdown)
    }
    const hundleseconddropdown = () => {
        settogglesecondDropdown(!togglesecondDropdown)
    }
    const hundleThirdDropdown = () => {
        settogglethirdDropdown(!togglethirdDropdown)
    }
    const hundleFourDropdown = () => {
        settoggleFourDropdown(!toggleFourDropdown)
    }
    const toggleMsgPopup = () => {
        setisShowMsg(!isShowMsg)
    }
    const toggleConfirmPopUp = () => {
        setshowConfirmpopup(!showConfirmpopup);
    }
    // Same As Billing
    const ClickSameBilling = () => {

        setToggleCheckBtn(!toggleCheckBtn)

        const { fName, lName, tel, website, billingAddress1, billingAddress2, billingZipPostal, billingCity, billingCountry, shippingAddress1, shippingAddress2, shippingCity, email, shippingZipPostal, billingState } = values
        settoggleSameBilling(!toggleSameBilling)
        setshippingstate_name(state_name)
        setShippingCountryName(country_name)
        setValues({
            WPId: "",
            fName: fName,
            lName: lName,
            tel: tel,
            website: website,
            billingAddress1: billingAddress1 ? billingAddress1 : '',
            billingAddress2: billingAddress2 ? billingAddress2 : "",
            billingZipPostal: billingZipPostal ? billingZipPostal : "",
            billingCity: billingCity ? billingCity : "",
            billingCountry: billingCountry ? billingCountry : '',
            billingState: billingState ? billingState : '',
            email: email,
            phone: phone,
            shippingAddress1: billingAddress1 ? billingAddress1 : '',
            shippingAddress2: billingAddress2 ? billingAddress2 : '',
            shippingZipPostal: billingZipPostal ? billingZipPostal : '',
            shippingCity: billingCity ? billingCity : '',
            shippingState: billingState ? billingState : '',
            shippingCountry: billingCountry ? billingCountry : '',

        })
        setshippingCountryCode(billingCountryCode)
        setshippingStateCode(billingStateCode)

        if (toggleCheckBtn === true) {
            setValues({
                WPId: "",
                fName: fName,
                lName: lName,
                tel: tel,
                website: website,
                billingAddress1: billingAddress1 ? billingAddress1 : '',
                billingAddress2: billingAddress2 ? billingAddress2 : "",
                billingZipPostal: billingZipPostal ? billingZipPostal : "",
                billingCity: billingCity ? billingCity : "",
                billingCountry: billingCountry ? billingCountry : '',
                billingState: billingState ? billingState : '',
                email: email,
                phone: phone,
                shippingAddress1: '',
                shippingAddress2: '',
                shippingZipPostal: '',
                shippingCity: '',
                shippingState: '',
                shippingCountry: '',

            })
        }
    }






    // Getting Customer from index DB
    const GetCustomerFromIDB = () => {
        useIndexedDB("customers").getAll().then((rows) => {
            setAllCustomerList(rows);
        });
    }

    let useCancelled = false;
    useEffect(() => {

        if (useCancelled == false) {
            GetCustomerFromIDB()
        }
        //  console.log("props",props)

        // Edit Customer setState Save From Props Data
        if (props.editcustomerparam == "editcustomer") {
            if (isAutoFocus() == true)
                textInput && textInput.current && textInput.current.focus();
            var WPId = '';
            var FirstName = '';
            var LastName = ''
            var Contact = ''
            var Email = ''
            var udid = ''
            var WebSite = ''
            var billingAddress1 = '';
            var billingAddress2 = '';
            var billingZipPostal = '';
            var billingCity = '';
            var billingState = '';
            var billingCountry = '';
            var shippingAddress1 = '';
            var shippingAddress2 = '';
            var shippingZipPostal = '';
            var shippingCity = '';
            var shippingState = '';
            var shippingCountry = '';
            var billingStateCode = '';
            var billingCountryCode = '';
            var shippingStateCode = '';
            var shippingCountryCode = '';
            props.CustomerAddress?.map(item => {
                CountryList && CountryList.map(param => {
                    getStateList && getStateList.map(res => {
                        if (item.Country == param.Code && item.TypeName == "billing") {
                            billingCountry = param.Name;
                        } else if (item.State == res.Code && item.Country == res.Country && item.TypeName == "billing") {
                            billingState = res.Name;
                        }
                        if (item.TypeName == "billing") {
                            billingAddress1 = item.Address1
                            billingAddress2 = item.Address2
                            billingCity = item.City
                            billingZipPostal = item.PostCode
                            billingStateCode = item.State
                            billingCountryCode = item.Country
                        }
                        if (item.Country == param.Code && item.TypeName == "shipping") {
                            shippingCountry = param.Name;
                        } else if (item.State == res.Code && item.Country == res.Country && item.TypeName == "shipping") {
                            shippingState = res.Name;
                        }
                        else if (item.TypeName == "shipping") {
                            shippingAddress1 = item.Address1
                            shippingAddress2 = item.Address2
                            shippingZipPostal = item.PostCode
                            shippingCity = item.City;
                            shippingStateCode = item.State
                            shippingCountryCode = item.Country
                        }                       
                    })                   
                })
            })
            setPhone(props.customerDetailData.Contact);
            setValues({
                WPId: "",
                email: props.customerDetailData.Email,
                fName: props.customerDetailData.FirstName,
                lName: props.customerDetailData.LastName,
                tel: "",
                website: "",
                billingAddress1: billingAddress1,
                billingAddress2: billingAddress2,
                billingZipPostal: billingZipPostal,
                billingCity: billingCity,
                billingState: billingState,
                billingCountry: billingCountry,
                shippingAddress1: shippingAddress1,
                shippingAddress2: shippingAddress2,
                shippingCity: shippingCity,
                shippingState: shippingState,
                shippingCountry: shippingCountry,
                shippingZipPostal: shippingZipPostal,
            })           
            setcountry_name(billingCountry)
            setstate_name(billingState)
            setShippingCountryName(shippingCountry)
            setshippingstate_name(shippingState)
            setCust_ID(props.customerDetailData.WPId)
            setbillingStateCode(billingStateCode)
            setbillingCountryCode(billingCountryCode)
            setshippingStateCode(shippingStateCode)
            setshippingCountryCode(shippingCountryCode)
            
            setBillingSerachString(billingCountry);
            setShippingSearchCountry(shippingCountry);
            setHundleStateBilling(billingState);
            setHundleStateShipping(shippingState);
            
        } else if (props.isShow === true) {
            if (isAutoFocus() == true)
                textInput && textInput.current && textInput.current.focus();
            clearInputFeild();

            var shopDetail = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
         console.log("shopDetail",shopDetail)
            const _initvalue = initialValues;
            if(shopDetail !==""){
                var _ctryName=shopDetail.shop_settings.shop_country && shopDetail.shop_settings.shop_country.includes(":") ? shopDetail.shop_settings.shop_country.substr(0,2):shopDetail.shop_settings.shop_country;
                
                _initvalue.billingCountry = shopDetail.shop_settings.shop_country_full_name;
                _initvalue.shippingCountry= shopDetail.shop_settings.shop_country_full_name;
                _initvalue.billingState= shopDetail.shop_settings.state_full_name;
                _initvalue.shippingState= shopDetail.shop_settings.state_full_name;
               setbillingCountryCode(_ctryName);
                setshippingCountryCode( _ctryName);
                setbillingStateCode( shopDetail.shop_settings.shop_state);               
                setshippingStateCode( shopDetail.shop_settings.shop_state);
                document.getElementById("sameAsBillingCheckbox").checked = true;  
                settoggleSameBilling(true);
            setBillingSerachString(shopDetail.shop_settings.shop_country_full_name);
            setShippingSearchCountry(shopDetail.shop_settings.shop_country_full_name);
            setHundleStateBilling(shopDetail.shop_settings.state_full_name);
            setHundleStateShipping(shopDetail.shop_settings.state_full_name);
            }  
            setValues(initialValues);
        }
        return () => {
            useCancelled = true;
        }
    }, [props.editcustomerparam == "editcustomer", props.isShow]);



    // hundle change phoneNumber
    const handleChangePhone = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPhone(value);
    };

    const handleChange = (name, value) => {
        setValues({
            ...values,
            [name]: value
        });
        validate({ [name]: value });
    }



    // Check data validation 
    const validate = (values) => {
        //    console.log("values validate", values)
        let temp = { ...errors }
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const alphabets = /^[a-zA-Z ]+$/
        const re = /^[0-9\b]+$/;
        /// Email
        if (!values.email && typeof values.email !== 'undefined') {
            temp.email = " Email is required!";
            setEmailInvalid(true)
        } else if (!regex.test(values.email) && typeof values.email !== 'undefined') {
            temp.email = "This is not a valid email format!";
            setEmailformat(true)
        } else {
            temp.email = "";
            setEmailformat(false)
            setEmailInvalid(false)
        }
        // // FIRST NAME
        // if (!values.fName && typeof values.fName !== 'undefined') {
        //     temp.fName =   "First name is required!";
        // } else if (!alphabets.test(values.fName) && typeof values.fName !== 'undefined') {
        //     temp.fName =   "Only alphabets allowed!";
        // } else {
        //     temp.fName = "";
        // }
        // // LAST NAME
        // if (!values.lName && typeof values.lName !== 'undefined') {
        //     temp.lName = "Last name is required!";
        // } else if (!alphabets.test(values.lName)  &&typeof values.lName !== 'undefined' ) {
        //     temp.lName = "Only alphabets allowed!";
        // } else {
        //     temp.lName = "";
        // }
        setErrors({
            ...temp
        })
        if (values) {
            return Object.values(temp).every(x => x == "")
        }
    };

    const handleSubmit = (addtocart) => {
        // disable create customer for demo user mode
        if (window.location.hostname === Config.key.DEMO_USER_URL) {
            var data = { title: "", msg: LocalizedLanguage.demoModeCreateCustomerMsg, is_success: true, img: "alert" }
            props.toggleEditcustomer();
            dispatch(popupMessage(data));
            return;
        }
        // e.preventDefault();
        const { fName, lName, tel, website, billingAddress1, billingAddress2, billingZipPostal, billingCity, billingCountry, shippingAddress1, shippingAddress2, shippingCity, email, shippingZipPostal } = values
        if (validate(values)) {
            var userExist = false;
            userExist = getExistingCustomerEmail(values.email);
            if (userExist == true) {
                // alert("Given email already exist! Please try another")
                setAllreadyEmail(true)

                // Create New Customer
            } else if (props.editcustomerparam !== "editcustomer" && Cust_ID == "" && addtocart !== "addtocart") {
                const save = {
                    WPId: "",
                    FirstName: fName,
                    LastName: lName,
                    Contact: phone,
                    startAmount: 0,
                    Email: email,
                    udid: UID,
                    notes: " ",
                    //Billing
                    StreetAddress: billingAddress1 ? billingAddress1 : "",
                    StreetAddress2: billingAddress2 ? billingAddress2 : "",
                    Pincode: billingZipPostal ? billingZipPostal : "",
                    City: billingCity ? billingCity : "",
                    Country: billingCountryCode ? billingCountryCode : "",
                    State: billingStateCode ? billingStateCode : "",
                    //Shippig
                    ShippingAddressLine1: toggleSameBilling==true && billingAddress1 ?billingAddress1: shippingAddress1 ? shippingAddress1 : "",
                    ShippingAddressLine2: toggleSameBilling==true && billingAddress2 ? billingAddress2: shippingAddress2 ? shippingAddress2 : "",
                    ShippingPincode: toggleSameBilling==true && billingZipPostal ? billingZipPostal :shippingZipPostal ? shippingZipPostal : "",
                    ShippingCity: toggleSameBilling==true && billingCity ?billingCity : shippingCity ? shippingCity : '',
                    ShippingCountry: toggleSameBilling==true && billingCountryCode ? billingCountryCode : shippingCountryCode ? shippingCountryCode : "",
                    ShippingState: toggleSameBilling==true && billingStateCode ? billingStateCode: shippingStateCode ? shippingStateCode : "",
                    WebSite: website ? website : "",
                    //fields for future---------
                    ShippingFirstName: fName,
                    ShippingLastName: lName,
                    ShippingEmail: email,
                    ShippingPhone: phone,

                }
                console.log("save", save)
                dispatch(customersave(save, 'create',));
                clearInputFeild()
                //props.toggleCreateCustomer()


                // Customer Create And  Add To Cart
            } else if (addtocart == "addtocart") {
                const save = {
                    WPId: "",
                    FirstName: fName,
                    LastName: lName,
                    Contact: phone,
                    //startAmount: 0,
                    Email: email,
                    udid: UID,
                    notes: " ",
                    //Billing
                    StreetAddress: billingAddress1 ? billingAddress1 : "",
                    StreetAddress2: billingAddress2 ? billingAddress2 : "",
                    Pincode: billingZipPostal ? billingZipPostal : "",
                    City: billingCity ? billingCity : "",
                    Country: billingCountryCode ? billingCountryCode : "",
                    State: billingStateCode ? billingStateCode : "",
                    //Shippig
                    ShippingAddressLine1: shippingAddress1 ? shippingAddress1 : "",
                    ShippingAddressLine2: shippingAddress2 ? shippingAddress2 : "",
                    ShippingPincode: shippingZipPostal ? shippingZipPostal : "",
                    ShippingCity: shippingCity ? shippingCity : '',
                    ShippingCountry: shippingCountryCode ? shippingCountryCode : "",
                    ShippingState: shippingStateCode ? shippingStateCode : "",
                    WebSite: website ? website : "",
                    //fields for future---------
                    ShippingFirstName: fName,
                    ShippingLastName: lName,
                    ShippingEmail: email,
                    ShippingPhone: phone,
                }
                setisCusToSaveCart(true)
                console.log("add to cart", save)
                dispatch(customersave(save, 'create',));
                clearInputFeild()
            }
        }
    };
    const updateHandleSubmit = () => {
        // disable update customer for demo user mode
        if (window.location.hostname === Config.key.DEMO_USER_URL) {
            var data = { title: "", msg: LocalizedLanguage.demoModeUpdateCustomerMsg, is_success: true, img: "alert" }
            props.toggleEditcustomer();
            dispatch(popupMessage(data));
            return;
        }
        const { fName, lName, tel, website, billingAddress1, billingAddress2, billingZipPostal, billingCity, billingCountry, shippingAddress1, shippingAddress2, shippingCity, email, shippingZipPostal } = values;
        if (validate(values)) {
            //   console.log("values",values)
            // Update and Edit Customer
            if (Cust_ID && Cust_ID != "") {
                const update = {
                    WPId: Cust_ID,
                    FirstName: fName,
                    LastName: lName,
                    Contact: phone,
                    startAmount: 0,
                    Email: email,
                    udid: UID,
                    notes: " ",
                    //Billing
                    StreetAddress: billingAddress1 ? billingAddress1 : "",
                    StreetAddress2: billingAddress2 ? billingAddress2 : "",
                    Pincode: billingZipPostal ? billingZipPostal : "",
                    City: billingCity ? billingCity : "",
                    Country: billingCountryCode ? billingCountryCode : "",
                    State: billingStateCode ? billingStateCode : "",
                    //Shippig
                    ShippingAddressLine1: shippingAddress1 ? shippingAddress1 : "",
                    ShippingAddressLine2: shippingAddress2 ? shippingAddress2 : "",
                    ShippingPincode: shippingZipPostal ? shippingZipPostal : "",
                    ShippingCity: shippingCity ? shippingCity : '',
                    ShippingCountry: shippingCountryCode ? shippingCountryCode : "",
                    ShippingState: shippingStateCode ? shippingStateCode : "",
                    WebSite: website ? website : "",
                    //fields for future---------
                    ShippingFirstName: fName,
                    ShippingLastName: lName,
                    ShippingEmail: email,
                    ShippingPhone: phone,

                }
                UpdateCustomerInIndexDB(UID, update)
                dispatch(customerupdate(update, 'update'));
                console.log("update", update)
                clearInputFeild()
                props.toggleEditcustomer()
                props.editCustomerTrue()
            }
        }
    }

    const HandleDeleteCus = () => {
        // disable delete customer for demo user mode
        if (window.location.hostname === Config.key.DEMO_USER_URL) {
            var data = { title: "", msg: LocalizedLanguage.demoModeDeleteCustomerMsg, is_success: true, img: "alert" }
            props.toggleEditcustomer();
            dispatch(popupMessage(data));
            return;
        }

        if (CommonModuleJS.permissionsForDeleteCustomer() == false) {
            props.toggleEditcustomer();
            props.togglePermissionPopup && props.togglePermissionPopup();
            // toggleMsgPopup(true);
            // setmsgTitle("Message")
            // setmsgBody("This Feature Is Disabled From Admin Side.")
        } else {
            setshowConfirmpopup(true)

        }
    }

    const customerDelete = () => {
        if (Cust_ID && Cust_ID != "") {
            setshowConfirmpopup(false)
            // props.toggleCreateCustomer()
            dispatch(customerdelete(Cust_ID, UID));
        }
    }








    const clearInputFeild = () => {
        setValues(initialValues);
        settoggleSameBilling(false)
        setPhone('');
        /// setValues({ email: "" })
        setcountry_name("")
        setstate_name("")
        setShippingCountryName("")
        setshippingstate_name("")
        setCust_ID("")
        setbillingCountryCode("")
        setbillingStateCode("")
        setshippingCountryCode("")
        setshippingStateCode("")
        setAllreadyEmail(false)
        setBillingSerachString("")
        setShippingSearchCountry("")
        setHundleStateBilling("")
        setHundleStateShipping("")
        setEmailInvalid(false)
        setEmailformat(false)
    }




    /// Customer add in Index DB
    const { add, update, getByID, getAll, deleteRecord } = useIndexedDB("customers");
    const AddCustomerInIndexDB = (UID, customerAdd) => {
        add(customerAdd).then(
            (key) => {
                console.log(" Add ID Generated: ", key);
                setTimeout(() => {
                    //   props.getCustomerFromIDB && props.getCustomerFromIDB()
                    props.sendCreateCustomerRes && props.sendCreateCustomerRes()
                }, 500);

            },
            (error) => {
                console.log(error);
            }
        )
    }

    const UpdateCustomerInIndexDB = (UID, customerAdd) => {
        update(customerAdd).then(
            (key) => {
                console.log("Update ID Generated: ", key);
                props.getCustomerFromIDB && props.getCustomerFromIDB()
            },
            (error) => {
                console.log(error);
            }
        )
    }


    /// Customer delete in Index DB
    const DeleteCustomerInIndexDB = (UID, customerdelete) => {
        deleteRecord(customerdelete).then(
            (key) => {
                console.log("Delete ID Generated : ", key);
                props.getCustomerFromIDB && props.getCustomerFromIDB()
                sessionStorage.removeItem("CUSTOMER_ID");
            },
            (error) => {
                console.log(error);
            }
        )
    }







    // Check Email already exists !! 
    const getExistingCustomerEmail = (email) => {
        var Exist = false;
        allCustomerList && allCustomerList.map(cust => {
            if (cust.Email == email)
                Exist = true;
        })
        return Exist;
    }



    // Close Button popup
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleCreateCustomer && props.toggleCreateCustomer();
            props.toggleEditcustomer && props.toggleEditcustomer();
        }
        else {
            e.stopPropagation();
        }
    }




    const billingHandleSearch = (event) => {
        let value = event.target.value;
        setBillingSerachString(value)
        if (props.editcustomerparam == "editcustomer") {
            setValues({
                ...values,           
            });
            // setValues({
            //     billingCountry: ''
            // })
        }
    }

    const HundleBillingState = (event) => {
        let value = event.target.value;
        setHundleStateBilling(value)

        if (props.editcustomerparam == "editcustomer") {
            setValues({
                ...values,
                ["billingCountry"]: ""
            });
        }

    }




    const shippingHandleSearch = (event) => {
        let value = event.target.value;
        setShippingSearchCountry(value)
        if (props.editcustomerparam == "editcustomer") {
            setValues({
                shippingState: ''
            })
        }
    }

    const HundleShippingState = (event) => {
        let value = event.target.value;
        setHundleStateShipping(value)
        if (props.editcustomerparam == "editcustomer") {
            // setValues({
            //     shippingCountry: ''
            // })
        }

    }


    useEffect(() => {
        BillingCountrySearch();
        ShillingCountrySearch();
        BillingStateSearch();
        ShippingStateSearch();



    }, [BillingSerachString, ShippingSearchCountry, HundleStateBilling, viewStateList, ShippingViewStateList, HundleStateShipping]);


    const BillingCountrySearch = () => {
        var _filteredCountry = [];
        if(BillingSerachString && BillingSerachString !==""){
             _filteredCountry = CountryList && CountryList.filter((item) => (
            (item.Name.toString().toLowerCase().includes(BillingSerachString.toLowerCase()))
            ))           
        }
        setRenderBillingCountry(_filteredCountry)
    }

    const ShillingCountrySearch = () => {
        var _filteredCountry = [];
        if(ShippingSearchCountry && ShippingSearchCountry !==""){
            _filteredCountry = CountryList && CountryList.filter((item) => (
            (item.Name.toString().toLowerCase().includes(ShippingSearchCountry.toLowerCase()))
        ))
        }
        setRenderShippingCountry(_filteredCountry)
    }
    const BillingStateSearch = () => {
        var _filterBillingState = []       
            _filterBillingState = viewStateList && viewStateList.filter((item) => (
                (item.Name.toString().toLowerCase().includes(HundleStateBilling.toLowerCase()))
            ))      
        setRenderBillingState(_filterBillingState)
    }

    const ShippingStateSearch = () => {
        var _filterBillingState = []
        _filterBillingState = ShippingViewStateList && ShippingViewStateList.filter((item) => (
            (item.Name.toString().toLowerCase().includes(HundleStateShipping.toLowerCase()))
        ))
        setRenderShippingState(_filterBillingState)
    }






    // Billing DropDown Click  
    const onChangeBillingStateList = (code, name) => {
        var finalStatelist = [];
        getStateList && getStateList.find(function (element) {
            if (element.Country == code) {
                finalStatelist.push(element)
            }
        })

        setbillingCountryCode(code)
        setviewStateList(finalStatelist)
        // setcountry_name(name)
        setBillingSerachString(name)
        setstate_name('')
        BillingStateSearch()
    }
    // Billing DropDown Click  

    const onChangeStateList = (code, name) => {
        // setstate_name(name.replace(/[^a-zA-Z]/g, ' '))
        setHundleStateBilling(name)
        setbillingStateCode(code)
    }


    // shipping DropDown Click  
    const shippCountryClick = (code, name) => {
        var finalStatelist = [];
        getStateList && getStateList.find(function (element) {
            if (element.Country == code) {
                finalStatelist.push(element)
            }
        })
        setshippingCountryCode(code)
        setShippingViewStateList(finalStatelist)
        //  setShippingCountryName(name)
        setShippingSearchCountry(name)
        setshippingstate_name('')
        ShippingStateSearch()

    }

    // shipping DropDown Click  

    const onChangeShipStateList = (code, name) => {
        //  setshippingstate_name(name.replace(/[^a-zA-Z]/g, ' '))
        setshippingStateCode(code)
        setHundleStateShipping(name)
    }








    // Customer create and Save API response
    const [resCustomerAdd] = useSelector((state) => [state.customersave])
    useEffect(() => {
        if (resCustomerAdd && resCustomerAdd.status == STATUSES.IDLE && resCustomerAdd.is_success && resCustomerAdd.data) {
            // console.log("customersave",resCustomerAdd)
            AddCustomerInIndexDB(UID, resCustomerAdd.data.content)
            if (isCusToSaveCart == true) {
                addCustomerToSale(resCustomerAdd.data.content)
            }
            dispatch(customersave("clear"));
            props.toggleCreateCustomer();
        }
    }, [resCustomerAdd, isCusToSaveCart]);


    // Customer Edit  API response
    const [resCustomerUpdate] = useSelector((state) => [state.customerupdate])
    useEffect(() => {
        if (resCustomerUpdate && resCustomerUpdate.status == STATUSES.IDLE && resCustomerUpdate.is_success && resCustomerUpdate.data) {
            // console.log("resCustomerUpdate",resCustomerUpdate)
            UpdateCustomerInIndexDB(UID, resCustomerUpdate.data.content)
            dispatch(customerupdate("clear"));

        }
    }, [resCustomerUpdate]);



    // Customer delete and Save API response
    const [resDeleteCustomer] = useSelector((state) => [state.customerdelete])
    useEffect(() => {
        if (resDeleteCustomer && resDeleteCustomer.status == STATUSES.IDLE && resDeleteCustomer.is_success && resDeleteCustomer.data) {
            if (Cust_ID != "") { DeleteCustomerInIndexDB(UID, Cust_ID) }
            dispatch(customerdelete("clear"));
            props.toggleCreateCustomer();
        }
    }, [resDeleteCustomer]);



    // Customer add to card
    const addCustomerToSale = (customerAdd) => {
        if (customerAdd) {
            localStorage.setItem('AdCusDetail', JSON.stringify(customerAdd))
            var list = localStorage.getItem('CHECKLIST') !== null ? (typeof localStorage.getItem('CHECKLIST') !== 'undefined') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null : null;
            if (list != null) {
                const CheckoutList = {
                    ListItem: list.ListItem,
                    customerDetail: customerAdd ? customerAdd : [],
                    totalPrice: list.totalPrice,
                    discountCalculated: list.discountCalculated,
                    tax: list.tax,
                    subTotal: list.subTotal,
                    TaxId: list.TaxId,
                    TaxRate: list.TaxRate,
                    oliver_pos_receipt_id: list.oliver_pos_receipt_id,
                    order_date: list.order_date,
                    order_id: list.order_id,
                    status: list.status,
                    showTaxStaus: list.showTaxStaus,
                    _wc_points_redeemed: list._wc_points_redeemed,
                    _wc_amount_redeemed: list._wc_amount_redeemed,
                    _wc_points_logged_redemption: list._wc_points_logged_redemption
                }
                localStorage.setItem('CHECKLIST', JSON.stringify(CheckoutList))
            }
            NavigateComponent(navigate, "home")
            if (location.pathname === "/home") {
                props?.toggleCreateCustomer()
                props?.toggleAdvancedSearch()
            }
        }
    }
    //set state drop down when click on that
    const reSetBillingState=()=>{
        var _filterBillingState = [];    
                getStateList && getStateList.find(function (element) {
                    if (element.Country == billingCountryCode) {
                        _filterBillingState.push(element)
                    }
                    
                })
                setRenderBillingState(_filterBillingState);           
    }
    const reSetShippingState=()=>{
        var _filterShippingState = []
                getStateList && getStateList.find(function (element) {              
                    if (element.Country == shippingCountryCode) {
                        _filterShippingState.push(element)
                    }
                })
                setRenderShippingState(_filterShippingState);
            
    }

    //Set country drop down when click on that
    const resetBillingCountry=()=>{     
        setRenderBillingCountry(CountryList)
    }
    const resetShippingCountry=()=>{       
        setRenderShippingCountry(CountryList)
    }                                               

   // console.log("billing state",HundleStateBilling, values.billingState);
    var showShippingAddress = checkBridgeVirson()
    return (
        <>
            {resCustomerAdd.status == STATUSES.LOADING ? <LoadingModal /> : null}
            <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
                <div className={props.isShow === true ? props.editcustomerparam == 'editcustomer' ? "subwindow edit-customer current" : "subwindow create-customer current" : "subwindow create-customer"}>
                    <div className="subwindow-header">
                        {props.editcustomerparam == 'editcustomer' ? <>
                            <p>{LocalizedLanguage.EditCustomer}</p> <button className="close-subwindow" onClick={() => props.toggleEditcustomer()} key="Editcustomer">
                                <img src={X_Icon_DarkBlue} alt="" />
                            </button>
                        </>
                            :
                            <> <p>{LocalizedLanguage.createcustomer}</p><button className="close-subwindow" onClick={() => props.toggleCreateCustomer()}>
                                <img src={X_Icon_DarkBlue} alt="" />
                            </button>
                            </>}


                    </div>
                    <div className="subwindow-body" key="subwindow-body">
                        <div id="custInfoSection" key="custInfoSection">
                            <p>{LocalizedLanguage.ContactInformation}</p>
                            <div className="input-row" key="custEmail">
                                <div className="input-col">
                                    <label htmlFor="newCustEmail">{LocalizedLanguage.Email}*</label>
                                    <input type="email" placeholder={LocalizedLanguage.EnterEmail} name='email'
                                        value={values.email} onChange={(e) => handleChange(e.target.name, e.target.value)} autoComplete='off' ref={textInput} className={emailInvalid == true ? "error required" : emailformat == true ? "error invalid-input" : ''} />
                                    <div className="error-wrapper"></div>
                                </div>

                                <div className="input-col">
                                    <label htmlFor="newCustPhone">{LocalizedLanguage.phoneNumber}</label>
                                    <input id="newCustPhone" type="text" pattern='[0-9]{0,5}' autoComplete='off' maxLength={13} placeholder={LocalizedLanguage.EnterPhoneNumber} name='tel' value={phone} onChange={handleChangePhone} />
                                    <div className="error-wrapper"></div>
                                </div>
                            </div>
                            <div className="input-row" key="custName">
                                <div className="input-col">
                                    <label htmlFor="newCustFirstName">{LocalizedLanguage.firstName}</label>
                                    <input type="text" id="newCustFirstName" value={values.fName} placeholder={LocalizedLanguage.enterfirstname} name='fName' onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                    <div className="error-wrapper"></div>
                                    {/* <p>{errors.fName}</p> */}
                                </div>

                                <div className="input-col">
                                    <label htmlFor="newCustLastName">{LocalizedLanguage.lastName}</label>
                                    <input type="text" id="newCustLastName" placeholder={LocalizedLanguage.EnterLastName} name='lName' onChange={(e) => handleChange(e.target.name, e.target.value)} value={values.lName} />
                                    <div className="error-wrapper"></div>
                                    {/* <p>{errors.lName}</p> */}
                                </div>
                            </div>
                            <div className="input-row" key="custWebSite">
                                <div className="input-col">
                                    <label htmlFor="newCustWebsite">{LocalizedLanguage.Website}</label>
                                    <input type="url" id="newCustWebsite" placeholder={LocalizedLanguage.EnterURL} name='website' value={values.website} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                    <div className="error-wrapper"></div>
                                </div>
                                <div className="input-col"></div>
                            </div>
                            {/* </section> */}
                        </div>
                        <div id="billingAddressSection" key="billingAddressSection">
                            <p>{LocalizedLanguage.BillingAddress}</p>
                            <div className="input-row" key="custAddress">
                                <div className="input-col">
                                    <label htmlFor="newCustAddress1Billing"> {LocalizedLanguage.addressOne}</label>
                                    <input type="text" id="newCustAddress1Billing" placeholder={LocalizedLanguage.EnterAddress1} name="billingAddress1" value={values.billingAddress1} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                    <div className="error-wrapper"></div>
                                </div>
                                <div className="input-col">
                                    <label htmlFor="newCustAddress2Billing">{LocalizedLanguage.addressTwo}</label>
                                    <input type="text" id="newCustAddress2Billing" placeholder={LocalizedLanguage.EnterAddress2} name="billingAddress2" value={values.billingAddress2} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                    <div className="error-wrapper"></div>
                                </div>
                            </div>
                            <div className="input-row" key="custPin">
                                <div className="input-col">
                                    <label htmlFor="newCustZipPCBilling">{LocalizedLanguage.zipcode}</label>
                                    <input type="text" id="newCustZipPCBilling" placeholder={LocalizedLanguage.zipcode} name="billingZipPostal" value={values.billingZipPostal} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                    <div className="error-wrapper"></div>
                                </div>
                                <div className="input-col">
                                    <label htmlFor="newCustCityBilling">{LocalizedLanguage.city}</label>
                                    <input type="text" id="newCustCityBilling" name="billingCity" placeholder={LocalizedLanguage.EnterCity} value={values.billingCity} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                    <div className="error-wrapper"></div>
                                </div>

                            </div>
                            <div className="input-row" key="custCountry">
                                <div className="input-col">
                                    <label htmlFor="newCustStateProvBilling">{LocalizedLanguage.country}</label>
                                    <div onClick={hundledropdown} className={toggleDrowpdown === true ? "dropdown-wrapper open " : "dropdown-wrapper"} >
                                        <input type="text" id="newCustStateProvBilling" placeholder={LocalizedLanguage.SelectCountry} value={BillingSerachString ? BillingSerachString?.replace(/[^a-zA-Z]/g, ' ') : ""} onChange={e => { billingHandleSearch(e); settoggleDrowpdown(true) }}  onClick={()=>resetBillingCountry()}/>
                                        <div className="error-wrapper" ></div>
                                        <img src={down_angled_bracket} alt="" />
                                        <div className="option-container"   >
                                            {RenderBillingCountry && RenderBillingCountry.map((item, index) => {
                                                return (
                                                    <div key={uniqueKey()} className="option" onClick={() => onChangeBillingStateList(item.Code, item.Name)} >
                                                        <p value={item.Code}>{item.Name.replace(/[^a-zA-Z]/g, ' ')}</p>
                                                    </div>)
                                            })}

                                        </div>
                                    </div>
                                </div>
                                <div className="input-col">
                                    <label htmlFor="newCustCountryBilling">{LocalizedLanguage.provinceState}</label>
                                    <div onClick={hundleseconddropdown} className={togglesecondDropdown === true ? "dropdown-wrapper open " : "dropdown-wrapper"}>
                                        <input type="text" id="newCustCountryBilling" placeholder={LocalizedLanguage.SelectState} value={HundleStateBilling ? HundleStateBilling?.replace(/[^a-zA-Z]/g, ' ') : ""} onChange={e => { HundleBillingState(e); settogglesecondDropdown(true) }} onClick={e=>reSetBillingState()} />
                                        <div className="error-wrapper"></div>
                                        <img src={down_angled_bracket} alt="" />
                                        <div className="option-container" >
                                            {RenderBillingState && RenderBillingState.map((item, index) => {
                                                return (
                                                    <div key={uniqueKey()} className="option" onClick={() => onChangeStateList(item.Code, item.Name)}>
                                                        <p value={item.Code} > {props.country_name !== '' ? item.Name.replace(/[^a-zA-Z]/g, ' ') : ''}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="shippingAddressSection" key="shippingAddressSection" hidden={!showShippingAddress}>
                            <div className="title-row" key="chkBiling">
                                <p>{LocalizedLanguage.ShippingAddress}</p>
                                <label className="custom-checkbox-wrapper">
                                    <input type="checkbox" id="sameAsBillingCheckbox" name="sameAsBillingCheckbox" onClick={ClickSameBilling} />
                                    <div className="custom-checkbox">
                                        <img src={Checkmark} alt="" />
                                    </div>
                                    {LocalizedLanguage.Sameasbilling}
                                </label>
                            </div>
                            <div className={toggleSameBilling === true ? "input-row hidden " : "input-row"} key="shippingAdd">
                                <div className="input-col">
                                    <label htmlFor="shippingAddress1">{LocalizedLanguage.addressOne}</label>
                                    <input type="text" id="shippingAddress1" placeholder={LocalizedLanguage.EnterAddress1} value={values.shippingAddress1} name='shippingAddress1' onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                </div>
                                <div className="input-col">
                                    <label htmlFor="shippingAddress2">{LocalizedLanguage.addressTwo}</label>
                                    <input type="text" id="shippingAddress2" name="shippingAddress2" value={values.shippingAddress2} placeholder={LocalizedLanguage.EnterAddress2} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                </div>
                            </div>
                            <div className={toggleSameBilling === true ? "input-row hidden " : "input-row"}>
                                <div className="input-col">
                                    <label htmlFor="shippingZipPostal">{LocalizedLanguage.zipcode}</label>
                                    <input type="text" id="shippingZipPostal" name='shippingZipPostal' value={values.shippingZipPostal} placeholder={LocalizedLanguage.zipcode} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                </div>
                                <div className="input-col">
                                    <label htmlFor="shippingCity">{LocalizedLanguage.city}</label>
                                    <input type="text" id="shippingCity" placeholder={LocalizedLanguage.EnterCity} name="shippingCity" value={values.shippingCity} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                                </div>
                            </div>
                            <div className={toggleSameBilling === true ? "input-row hidden " : "input-row"} key="shipCountry">
                                <div className="input-col">
                                    <label htmlFor="newCustStateProvShipping">{LocalizedLanguage.country}</label>
                                    <div onClick={hundleThirdDropdown} className={togglethirdDropdown === true ? "dropdown-wrapper open " : "dropdown-wrapper"}>
                                        <input type="text" id="newCustStateProvShipping" placeholder={LocalizedLanguage.SelectCountry} value={ShippingSearchCountry ? ShippingSearchCountry.replace(/[^a-zA-Z]/g, ' ') : ""} onChange={e => shippingHandleSearch(e)} onClick={()=>resetShippingCountry()}/>
                                        <div className="error-wrapper"></div>
                                        <img src={down_angled_bracket} alt="" />
                                        <div className="option-container">
                                            {RenderShippingCountry && RenderShippingCountry.map((item, index) => {
                                                return (
                                                    <div className="option" key={uniqueKey()} onClick={() => shippCountryClick(item.Code, item.Name)}>
                                                        <p value={item.Code}  >{item.Name.replace(/[^a-zA-Z]/g, ' ')}</p>
                                                    </div>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="input-col">
                                    <label htmlFor="newCustCountryShipping">{LocalizedLanguage.provinceState}</label>
                                    <div onClick={hundleFourDropdown} className={toggleFourDropdown === true ? "dropdown-wrapper open " : "dropdown-wrapper"}>
                                        <input type="text" id="newCustCountryShipping" placeholder={LocalizedLanguage.SelectState} value={HundleStateShipping ? HundleStateShipping.replace(/[^a-zA-Z]/g, ' ') : ""} onChange={e => HundleShippingState(e)} onClick={e=>reSetShippingState()}/>
                                        <div className="error-wrapper"></div>
                                        <img src={down_angled_bracket} alt="" />
                                        <div className="option-container">
                                            {RenderShippingState && RenderShippingState.map((item, index) => {
                                                return (
                                                    <div key={uniqueKey()} className="option" onClick={() => onChangeShipStateList(item.Code, item.Name)}>
                                                        <p value={item.Code} > {props.country_name !== '' ? item.Name.replace(/[^a-zA-Z]/g, ' ') : ''}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        {AllreadyEmail === true ? <p className="custom-error-message">{LocalizedLanguage.alreadyExistEmailMsg}</p> : ""}

                        {props.editcustomerparam == 'editcustomer' ? <div className="button-row">
                            {CommonModuleJS.permissionsForDeleteCustomer() == true ?
                                <button id="editCustomerDelete" onClick={HandleDeleteCus}>{LocalizedLanguage.customerDelete}</button>
                                :
                                <button id="editCustomerDelete" className="disabled" onClick={HandleDeleteCus}>
                                    <img src={RedLock} alt="" />
                                    {LocalizedLanguage.customerDelete}
                                </button>}
                            <button onClick={updateHandleSubmit}>{LocalizedLanguage.SaveUpdate}</button>
                        </div> : <div className="button-row">
                            <button onClick={handleSubmit}>{LocalizedLanguage.createcustomer}</button>
                            <button onClick={() => handleSubmit("addtocart")}>{LocalizedLanguage.CreateAddCart}</button>
                        </div>}
                    </div>
                </div>
            </div>
            {/* <MsgPopup isShow={isShowMsg} toggleMsgPopup={toggleMsgPopup} msgTitle={msgTitle} msgBody={msgBody}></MsgPopup> */}
            <ConfirmationPopup toggleConfirmPopUp={toggleConfirmPopUp} isShow={showConfirmpopup} customerDelete={customerDelete} />
        </>
    )
}

export default CustomerCreate 