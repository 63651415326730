import { NumericFormat } from "react-number-format";
import { currencyCode } from "../../../settings/CommonWork";


export const currecyFunction = (amount) => {
  if(localStorage.getItem('user')){
    var CurrencySymbol = localStorage.getItem('currecySymbole') ? localStorage.getItem('currecySymbole'):"$";
       // var currecyAmount = null;
    if(CurrencySymbol && CurrencySymbol !== undefined){
        // currecyAmount = () => {
            return <NumericFormat prefix={CurrencySymbol} value={amount} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />
        // }
    }else{
        // currecyAmount = () => {
            return <NumericFormat prefix={"$"} value={amount} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />
        // }
    }
            //   return CurrencySymbol.CurrencySymbol.replace(/\,/g,"");
// console.log(currecyAmount);
    //   return currecyAmount; 
}
}

