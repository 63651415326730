import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Rounded_Square_Plus_Icon_NewGrey from '../../../assets/images/svg/Rounded-Square-Plus-Icon-NewGrey.svg';
import STATUSES from "../../../constants/apiStatus";
import { AddAttribute, AddItemType } from "../../common/EventFunctions";
import { useIndexedDB } from 'react-indexed-db';
import { getTaxAllProduct } from '../../common/TaxSetting';
import { useLongPress } from 'use-long-press';
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
import { deleteTile } from '../tiles/tileSlice';
import { LoadingModal } from "../../common/commonComponents/LoadingModal";
import NoImageAvailable from '../../../assets/images/svg/NoImageAvailable.svg';
import ErrorIconBlue from '../../../assets/images/svg/ErrorIconBlue.svg';
import LockIcon from '../../../assets/images/svg/LockIcon.svg';
import { getInventory } from "../slices/inventorySlice";
import { addSimpleProducttoCart, addtoCartProduct } from "../product/productLogic";
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { CommonModuleJS } from "../../../settings";
import { product } from "../product/productSlice";
import { Markup } from "interweave";
import { popupMessage } from "../../common/commonAPIs/messageSlice";
import { getClosest } from "../../../settings/CommonWork";
import Minus_Blue from '../../../assets/images/svg/Minus-Blue.svg';
import Plus_Blue from '../../../assets/images/svg/Plus-Blue.svg';
// import { tile } from "../tiles/tileSlice";
import { v4 as uniqueKey } from 'uuid';
// import { tileCountInit } from "../../common/commonFunctions/homeFn";
// var AllProduct = [];
// var ParentProductList = [];
// var filtered = [];
import Config from '../../../Config'
import FormateDateAndTime from "../../../settings/FormateDateAndTime";
import { addModifierAsCustomFee, addProductWithDefaultModifier, getDefautModifiersId, getProductModifiers } from "../product/productModifier";
import { getPostMeta, postMeta } from "../../common/commonAPIs/postMetaSlice";
import { get_UDid } from "../../common/localSettings";
import { isMobile } from "react-device-detect";
import HomeIconWhite from '../../../assets/images/svg/HomeIconWhite.svg';
import CartSpeed from '../../../assets/images/svg/Cart_Speed.svg';


const TileList = (props) => {
    const { getAll, getByID } = useIndexedDB("products");
    const { getAll: getAllModifier } = useIndexedDB("modifiers");
    const dispatch = useDispatch();
    const [AllProduct, setAllProduct] = useState([]);
    const [AllModifiers, setAllModifiers] = useState([]);
    const [filtered, setfiltered] = useState([]);
    const [ParentProductList, setParentProductList] = useState([]);
    const [favArrayList, setfavArrayList] = useState([]);
    const [cat_breadcrumb, setCat_breadcrumb] = useState([]);
    const [sel_item, setSel_item] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [respAttribute, respCategory] = useSelector((state) => [state.attribute, state.category])
    const { status, data, error, is_success } = useSelector((state) => state.tile)
    const [tilesNo, setTilesNo] = useState(4);
    const [isTileDelete, setIsTileDelete] = useState(false);
    const [favIdsToDelete, setFavIdsToDelete] = useState([]);

    const closeDeleteTiles = () => {
        // setIsTileDelete(false);
    }
    const delete_tile = (id, item, order) => {
        var _favIdsToDelete = favIdsToDelete;
        _favIdsToDelete.push(id);

        if (favArrayList && favArrayList.length > 0) {
            var _favArrayList = favArrayList;
            var _delFav = favArrayList.find(a => a.id == id || a.Id == id);
            if (typeof _delFav != "undefined" && _delFav != null) {
                var _delFavIndex = favArrayList.indexOf(_delFav);
                _favArrayList[_delFavIndex] = { "TileColor": null, "type": "add-new", "Order": order };
            }
            setfavArrayList(_favArrayList);
        }

        //finalArray.push({ "TileColor": null, "type": "add-new", "Order": i });

        setFavIdsToDelete(_favIdsToDelete);
    }

    const save_update = () => {
        //DISABLE TILE RESIZING IN DEMO USER MODE
        if (window.location.hostname == Config.key.DEMO_USER_URL) {
            var data = { title: "", msg: LocalizedLanguage.demoModeTileDisableMsg, is_success: true, img: "alert" }
            dispatch(popupMessage(data));
            setTimeout(() => {
                setIsTileDelete(false);
                var count = 4;
                if (isMobile == true) { count = 2 }
                setTilesNo(count);
                adjustTileSize(count);
            }, 100);
            return;
        }

        if (favIdsToDelete && favIdsToDelete.length > 0) {
            favIdsToDelete.map(id => {
                var param = { "favid": id };
                setIsLoading(true);
                dispatch(deleteTile(param));
            });
        }
        else {
            setIsTileDelete(false);
        }

        if (localStorage.getItem("client_registerid")) {
            var parma = { "Slug": localStorage.getItem("client_registerid"), "Value": tilesNo, "Id": 0, "IsDeleted": 0 };
            dispatch(getPostMeta("clear"));
            localStorage.removeItem(get_UDid() + "_NumOfTiles");
            dispatch(postMeta(parma));

        }
        // adjustTileSize(4);
        // setTilesNo(4);
    }
    const callback = useCallback(e => {
        console.log('Long pressed!');
        // props.clearDeleteTileBtn(e);
        setIsTileDelete(true);
    }, []);

    let clickHoldTimer = null;
    var doDelete = false;
    const onStart = (event) => {
        // console.log('Press started' + event.target.classList)
        clickHoldTimer = setTimeout(() => {
            if (event.target.classList && !event.target.classList.contains("remove-cover")) {
                doDelete = false;
            }
            //Action to be performed after holding down mouse
        }, 1000); //Change 1000 to number of milliseconds required for mouse hold
    }
    const onFinish = () => {
        //console.log('Long press finished')
        doDelete = true;
        clearTimeout(clickHoldTimer);
    }
    const bind = useLongPress(true ? callback : null, {
        onStart: event => onStart(event),
        onFinish: event => onFinish(event),
        // onCancel: event => console.log('Press cancelled'),
        // onMove: event => console.log('Detected mouse or touch movement'),
        // filterEvents: event => true, // All events can potentially trigger long press
        threshold: 1000,
        // captureEvent: false,
        // cancelOnMovement: false,
        // detect: 'both',
    });

    useEffect(() => {
        adjustTileSize(tilesNo);
    }, [tilesNo]);
    const adjustTileSize = (rowSize) => {
        // var _number = 0;
        // if (localStorage.getItem(get_UDid() + "_NumOfTiles")) {
        //     _number = JSON.parse(localStorage.getItem(get_UDid() + "_NumOfTiles"));
        // }

        if (rowSize) {
            //console.log("---rowSize---" + rowSize);
            //let counter = 0;
            document.documentElement.style.setProperty('--oliver-tile-size', ((102 - 2 * rowSize) / rowSize) + "%");
            //document.documentElement.style.setProperty('--tile-width', ((102 - 2 * rowSize) / rowSize)+"%");
            //document.querySelectorAll("#productContainer > button").forEach((button) => {

            // if (button && button.style && button.style.display != "none") {
            //button.style.width = `${(102 - 2 * rowSize) / rowSize}%`;
            // if (button.id !== "add-tile-last") { button.style.marginRight = "2%"; }
            // counter++;
            // if (counter === tilesNo) {
            //     button.style.marginRight = "0%";
            //     counter = 0;
            // }
            // }
            //});
        }

    }
    const tilesCount = (action) => {
        var _tilesNo = tilesNo;
        if (action == "plus" && _tilesNo < 8) {
            _tilesNo = _tilesNo + 1;
        }
        else if (action == "minus" && _tilesNo > 1) {
            _tilesNo = _tilesNo - 1;
        }
        setTilesNo(_tilesNo);
        adjustTileSize(_tilesNo);
    }

    const resGetPostMeta = useSelector((state) => state.getPostMeta)
    useEffect(() => {
        if (resGetPostMeta && resGetPostMeta.is_success === true && localStorage.getItem("client_registerid")) {//&& window.location.hostname !== Config.key.DEMO_USER_URL
            if (resGetPostMeta.data && resGetPostMeta.data.content && resGetPostMeta.data.content.Slug === localStorage.getItem("client_registerid")) {
                var _number = JSON.parse(resGetPostMeta.data.content.Value)
                if (tilesNo !== _number) {
                    localStorage.setItem(get_UDid() + "_NumOfTiles", _number);
                    //document.documentElement.style.setProperty('--tile-width', 0);
                    adjustTileSize(_number);
                    setTilesNo(_number);
                }
            }
        }
    }, [resGetPostMeta]);


    const filterProductForAttribute = (item) => {
        var subAtt = [];
        var _attributeList=[];
        if (respAttribute.is_success === true && respAttribute.data && respAttribute.data.content != null) {
             _attributeList = respAttribute.data.content;
        }
        if(_attributeList.length==0 && localStorage.getItem("attributelist")){
            _attributeList=JSON.parse(localStorage.getItem("attributelist"))
        }
        if(_attributeList.length==0){
                 // setattributeList(_attributeList);
            subAtt = _attributeList.find(function (element) {
                return element.Id === item.attribute_id
            })
        }
        if (subAtt && subAtt.SubAttributes && subAtt.SubAttributes.length > 0) {
            var _SubAttributes = AddItemType(subAtt.SubAttributes, 'sub-attribute');
            if (typeof item.TileColor != "undefined" && item.TileColor != null) {
                _SubAttributes = AddAttribute(_SubAttributes, 'TileColor', item.TileColor);
            }
            _SubAttributes = _SubAttributes && _SubAttributes.map(element => { if (element["attribute_slug"] = element.Description) return element });
            if (_SubAttributes && _SubAttributes.length > 0)
                setfavArrayList(_SubAttributes);
            else
                setfavArrayList([]);
        }
        else
            setfavArrayList([]);
        productDataSearch(item.attribute_slug, 1)
    }

    const filterProductForSubAttribute = (item) => {
        if (item.SubAttributes && item.SubAttributes.length > 0) {
            var _SubAttributes = AddItemType(item.SubAttributes, 'sub-attribute');
            if (typeof item.TileColor != "undefined" && item.TileColor != null) {
                _SubAttributes = AddAttribute(_SubAttributes, 'TileColor', item.TileColor);
            }
            if (_SubAttributes && _SubAttributes.length > 0)
                setfavArrayList(_SubAttributes);
            else
                setfavArrayList([]);
        }
        else
            setfavArrayList([]);
        if (item.parent_attribute)
            productDataSearch(item, 3, item.parent_attribute ? item.parent_attribute.replace("pa_", "") : '');
        else {
            productDataSearch(item, 3, item.taxonomy.replace("pa_", ""));
        }

    }

    const filterProductForCateGory = (item) => {
        var subCat = [];
        var _categoryList=[]
        if (respCategory.is_success === true && respCategory.data && respCategory.data.content != null) {
            var _categoryList = respCategory.data.content;
        }
        if(_categoryList.length==0 && localStorage.getItem("category")){
            _categoryList=JSON.parse(localStorage.getItem("category"))
        }
        if(_categoryList.length>0){
            // setcategoryList(_categoryList)
            subCat = _categoryList.find(function (element) {
                return element.id === item.category_id
            })
        }

        if (subCat && subCat.Subcategories && subCat.Subcategories.length > 0) {
            var _Subcategories = AddItemType(subCat.Subcategories, 'sub-category');
            if (typeof item.TileColor != "undefined" && item.TileColor != null) {
                _Subcategories = AddAttribute(_Subcategories, 'TileColor', item.TileColor);
            }
            // favArrayList=_Subcategories;
            _Subcategories = _Subcategories.map(element => { if (element["name"] = element.Value) return element });
            if (_Subcategories && _Subcategories.length > 0)
                setfavArrayList(_Subcategories);
            else
                setfavArrayList([]);
        }
        else
            setfavArrayList([]);
        productDataSearch(item.category_slug, 2)
    }

    const filterProductForSubCateGory = (item) => {
        if (item.Subcategories && item.Subcategories.length > 0) {
            var _Subcategories = AddItemType(item.Subcategories, 'sub-category');
            if (typeof item.TileColor != "undefined" && item.TileColor != null) {
                _Subcategories = AddAttribute(_Subcategories, 'TileColor', item.TileColor);
            }
            if (_Subcategories && _Subcategories.length > 0)
                setfavArrayList(_Subcategories);
            else
                setfavArrayList([]);
        }
        else
            setfavArrayList([]);
        productDataSearch(item.category_slug ? item.category_slug : item.Code, 2)
    }
    var _SubCategory = [];
    const retrunItrateLoop = (found, filterCategoryCode) => {
        var setSubCategory = _SubCategory;// localStorage.getItem("setSubCategory") ? JSON.parse(localStorage.getItem("setSubCategory")) : [];
        filterCategoryCode.push(found.Code)
        if (found && found.Subcategories && found.Subcategories.length > 0) {
            found.Subcategories.map(element => {
                setSubCategory.push(element)
                filterCategoryCode.push(element.Code)
                if (element && element.Subcategories && element.Subcategories.length > 0) {
                    retrunItrateLoop(element, filterCategoryCode)
                }
            })
            // const arrayUniqueByKey = [...new Map(setSubCategory.map(item =>
            //     [item['Code'], item])).values()];
            const arrayUniqueByKeyArray = [...new Map(filterCategoryCode.map(item =>
                [item, item])).values()];
            _SubCategory = arrayUniqueByKeyArray;
            //localStorage.setItem("setSubCategory", JSON.stringify(arrayUniqueByKey))
            return arrayUniqueByKeyArray
        }
        return filterCategoryCode
    }

    const productDataSearch = (item1, index, parent) => {
        setfiltered([]);
        var _filtered = [];
        var value = item1;
        var parentAttribute = parent;
        // call loadingData when searchInput length 0
        if (value === '' || value === null) {
            index = null
        }
        if (index === 0) {//product
            var serchFromAll = AllProduct.filter((item) => (
                (item.Title && item.Title.toLowerCase().includes(value.toLowerCase()))
                || (item.Barcode && item.Barcode.toString().toLowerCase().includes(value.toLowerCase()))
                || (item.Sku && item.Sku.toString().toLowerCase().includes(value.toLowerCase()))
            ))
            //-------//Filter child and parent-------------
            var parentArr = [];
            serchFromAll && serchFromAll.map(item => {
                if (item.ParentId !== 0) {
                    var parrentofChild = AllProduct.find(function (element) {
                        return (element.WPID === item.ParentId)
                    });
                    if (parrentofChild)
                        parentArr.push(parrentofChild);
                }
            })
            serchFromAll = [...new Set([...serchFromAll, ...parentArr])];
            if (!serchFromAll || serchFromAll.length > 0) {
                var parentProduct = serchFromAll.filter(item => {
                    return (item.ParentId === 0)
                })
                parentProduct = parentProduct ? parentProduct : []
                _filtered = [...new Set([..._filtered, ...parentProduct])];
            }
        }
        if (index === 1) { //attribute
            ParentProductList && ParentProductList.map((item) => {
                item.ProductAttributes && item.ProductAttributes.map(attri => {
                    if (String(attri.Slug).toLowerCase().toString().indexOf(String(value).toLowerCase()) !== -1 ||
                        String(attri.Name).toLowerCase().toString().indexOf(String(value).toLowerCase()) !== -1) {
                        _filtered.push(item)
                    }
                })
            })
        }
        else if (index === 2) {


            // category
            ///------Get Subcategory Code------------------------------------------------ 
            var filterCategoryCode = []
            filterCategoryCode.push(value)

            var _categoryList = [];
            if (respCategory.is_success === true && respCategory.data && respCategory.data.content != null) {
                _categoryList = respCategory.data.content;
            }
            if(_categoryList.length==0 && localStorage.getItem("category")){
                _categoryList=JSON.parse(localStorage.getItem("category"))
            }

            if (_categoryList) {
                var category_list = _categoryList;
                var found;
                var setSubCategory = _SubCategory;// localStorage.getItem("setSubCategory") ? JSON.parse(localStorage.getItem("setSubCategory")) : [];
                if (category_list && category_list !== undefined && category_list.length > 0) {
                    found = category_list.find(item => value && item.Code && item.Code.replace(/-/g, "").toLowerCase() === value.replace(/-/g, "").toLowerCase());
                    if (!found && setSubCategory) {
                        found = setSubCategory && setSubCategory.find(item => value && item.Code && item.Code.replace(/-/g, "").toLowerCase() === value.replace(/-/g, "").toLowerCase());
                    }
                    if (found) {
                        filterCategoryCode = retrunItrateLoop(found, filterCategoryCode)
                    }
                }
            }
            ParentProductList && ParentProductList.map((item) => {
                item.Categories && item.Categories !== undefined && item.Categories.split(",").map(category => {
                    filterCategoryCode && filterCategoryCode !== undefined && filterCategoryCode.map(filterCode => {
                        var prod_Code = filterCode && filterCode.replace(/-/g, "");
                        category = category && category.replace(/-/g, "");
                        if (category && prod_Code && category.toUpperCase().toString() === prod_Code.toUpperCase()) {
                            if (_filtered.indexOf(item) === -1) {
                                _filtered.push(item)
                            }
                        }
                    })
                })
            })
        } else if (index === 3) {
            ///------Get attribute Code------------------------------------------------ 
            var filterAttribyteCode = []
            filterAttribyteCode.push(value.Value);
            if (item1 && item1.SubAttributes !== undefined && item1.SubAttributes.length > 0) {
                var found1 = item1.SubAttributes.find(function (element) {
                    return (element.Code.replace(/-/g, "").toLowerCase() === value.Value.replace(/-/g, "").toLowerCase())
                });
                if (found1) {
                    found1.SubAttributes.map(item => {
                        filterAttribyteCode.push(item.Code);
                    })
                }
            }
            ParentProductList && ParentProductList.map((item) => {
                item.ProductAttributes && item.ProductAttributes.map(proAtt => {
                    var dataSplitArycomma = proAtt.Option.split(',');
                    dataSplitArycomma && dataSplitArycomma !== undefined && dataSplitArycomma.map(opt => {
                        filterAttribyteCode !== undefined && filterAttribyteCode.map(filterAttribute => {
                            opt = opt && opt.replace(/-/g, "");
                            value = filterAttribute && filterAttribute.replace(/-/g, ""); // value.replace(/-/g, ""); 
                            if (opt.toString().toUpperCase() === String(value).toUpperCase() && String(proAtt.Slug).toUpperCase() === String(parentAttribute).toUpperCase()) {
                                if (_filtered.indexOf(item) === -1) {
                                    _filtered.push(item)
                                }
                            }
                        })
                    })
                })
            })
        } else if (index === 4) {
            ParentProductList && ParentProductList !== undefined && ParentProductList.map((item) => {
                item.ProductAttributes && item.ProductAttributes !== undefined && item.ProductAttributes.map(Opt => {
                    var dataSplitArycomma = Opt.Option.split(',');
                    dataSplitArycomma && dataSplitArycomma !== undefined && dataSplitArycomma.map(optValve => {
                        var itemCode = this.getAttributeCode(optValve, parent);
                        if (itemCode !== null && itemCode !== undefined && itemCode.toString().toUpperCase() === String(value).toUpperCase()) {
                            _filtered.push(item)
                        }
                    })
                })
            })
        }
        _filtered = _filtered.filter(item => {
            return (item.ParentId === 0)
        })
        setfiltered(_filtered);
    }


    // Modfier product direct add to card from tile section
    const getModifiers = () => {
        getAllModifier().then((rows) => {
            setAllModifiers(rows);
            //getProductModifiers(rows, _product, isClear, modifiers_key)
        });
    }


    const getProductDetail = async (item) => {
        if (item.Product_Id) {
            let product = await getByID(item.Product_Id)
            if (product && product.Tags && product.Tags !== null && product.Tags == "oliver_produt_x") {
                return true
            }
        }
        return false
    }


    const viewProduct = async (item) => {
        if(!item) return;
        let isXproduct = await getProductDetail(item);
        if (isXproduct == true) {
            props.toggleProductTypeNotSupported && props.toggleProductTypeNotSupported();
            return;
        }
        let type = item.Type;
        if ((type !== "simple" && type !== "variable" && type !== "variation") /*&& (CommonModuleJS.showProductxModal() !== null && CommonModuleJS.showProductxModal() === false)*/) {
            props.toggleProductTypeNotSupported && props.toggleProductTypeNotSupported();
        }
        else {
            if (item.ManagingStock === true) {
                dispatch(getInventory(item.WPID)); //call to get product warehouse quantity
            }
            setTimeout(() => {
                props.openPopUp(item);
                props.fatchUpdateInventory && props.fatchUpdateInventory()
            }, 100);

        }
    }

    const filterProductByTile = async (type, item, parent) => {
        if (doDelete === true)
            return;
        if (type !== "product")
            fillCategorySelection(item)
        switch (type) {
            case "attribute":
                filterProductForAttribute(item);
                break;
            case "sub-attribute":
                filterProductForSubAttribute(item);
                break;
            case "category":
                filterProductForCateGory(item);
                break;
            case "sub-category":
                filterProductForSubCateGory(item);
                break;
            case "product":
                if (item.ManagingStock === true && item.hasOwnProperty("Product_Id") && item.Product_Id != 0 && item.hasOwnProperty("Type") && item.Type == "simple") {
                    dispatch(getInventory(item.Product_Id)); //call to get product warehouse quantity
                }

                let type = item.Type;
                var id = item.product_id ? item.product_id : item.WPID ? item.WPID : item.Product_Id
                var _item = await getByID(id);
                var _product = getTaxAllProduct([_item])
                let findSingleProduct = _product[0]
                var taglist = findSingleProduct && findSingleProduct.Tags ? findSingleProduct.Tags !== "" ? findSingleProduct.Tags.split(",") : null : null;
                if (taglist && (taglist !== null && taglist.includes('oliver_produt_x') === true) &&
                    (CommonModuleJS.showProductxModal() !== null && CommonModuleJS.showProductxModal()
                        === true) && findSingleProduct !== null && findSingleProduct.ParamLink !== "" && findSingleProduct.ParamLink
                    !== "False" && findSingleProduct.ParamLink !== null) {
                    props.toggleProductTypeNotSupported && props.toggleProductTypeNotSupported();
                    props.closePopUp()
                    return
                }

                if ((type !== "simple" && type !== "variable" && type !== "variation")/* && (CommonModuleJS.showProductxModal() !== null && CommonModuleJS.showProductxModal() === false)*/) {
                    props.toggleProductTypeNotSupported && props.toggleProductTypeNotSupported();
                }
                else if (type === "simple") {
                    var id = item.product_id ? item.product_id : item.WPID ? item.WPID : item.Product_Id
                    //var _item = await getByID(id);
                    if (typeof _item != "undefined") {
                        // var _product = getTaxAllProduct([_item])
                        var result = '';
                        //var modifiers_key = uniqueKey();
                        if (typeof _product != "undefined") {
                            //var rows = await getAllModifier();
                            var rows = AllModifiers;
                            result = addProductWithDefaultModifier(_product[0], rows);
                            //console.log("--product with default modifier---->" + result)
                            if (result === 'outofstock') {
                                props.toggleOutOfStock && props.toggleOutOfStock(_product);
                            }
                        }
                    }

                }
                else {
                    props.openPopUp(item);
                }
                break;
            default:
                var ptype = (item.hasOwnProperty("Type") && item.Type == "simple" || item.Type == "variable" || item.Type == "variation") ? "product" : item.Type;
                if ((ptype !== "simple" && ptype !== "variable" && ptype !== "variation")/* && (CommonModuleJS.showProductxModal() !== null && CommonModuleJS.showProductxModal() === false)*/) {
                    props.toggleProductTypeNotSupported && props.toggleProductTypeNotSupported();
                }
                break;
        }
    }
    const fillFavourite = () => {
        var favTiles = [];
        var _favArrayList = [];
        favTiles = data && data.content;
        var FavProduct = [];// favTiles.FavProduct;
        var FavAttribute = favTiles.FavAttribute;
        var FavSubAttribute = favTiles.FavSubAttribute;
        var FavCategory = favTiles.FavCategory;
        var FavSubCategory = favTiles.FavSubCategory;

        getAll().then((rows) => { //Check product exist into index DB
            var _favProdct = [];
            var _FavProduct = AddItemType(favTiles.FavProduct, 'product');
            var _FavAttribute = AddItemType(favTiles.FavAttribute, 'attribute');
            var _FavSubAttribute = AddItemType(favTiles.FavSubAttribute, 'sub-attribute');
            var _FavCategory = AddItemType(favTiles.FavCategory, 'category');
            var _FavSubCategory = AddItemType(favTiles.FavSubCategory, 'sub-category');

            _favArrayList = [..._FavProduct, ..._FavAttribute, ..._FavSubAttribute, ..._FavCategory, ..._FavSubCategory];
            _favArrayList = [..._favArrayList].sort((a, b) => a.Order - b.Order);
            _favArrayList && _favArrayList.map((item) => {
                var _prd = rows.find(i => i.WPID == item.Product_Id);
                if (item.type == "product") {
                    if (_prd) {
                        item['Tags'] = _prd.Tags
                    }
                    _favProdct.push(item)
                } else {
                    if (_prd) {
                        item['Tags'] = _prd.Tags
                    }
                    _favProdct.push(item)
                }
            });

            // setfavArrayList(_favProdct);
        });

        FavProduct = AddItemType(favTiles.FavProduct, 'product');
        FavAttribute = AddItemType(favTiles.FavAttribute, 'attribute');
        FavSubAttribute = AddItemType(favTiles.FavSubAttribute, 'sub-attribute');
        FavCategory = AddItemType(favTiles.FavCategory, 'category');
        FavSubCategory = AddItemType(favTiles.FavSubCategory, 'sub-category');

        _favArrayList = [...FavProduct, ...FavAttribute, ...FavSubAttribute, ...FavCategory, ...FavSubCategory];
        _favArrayList = [..._favArrayList].sort((a, b) => a.Order - b.Order);

        //ARRAING THE TILES AS PER THE THEIR POSTION 20-JAN-2023 ---START
        const orderPositionIds = _favArrayList.map(object => {
            return object.Order;
        });
        if (typeof orderPositionIds !== "undefined" && orderPositionIds !== null && orderPositionIds.length > 0) {
            const maxOrderPositionNumber = Math.max.apply(null, orderPositionIds);
            var finalArray = [];
            var closest = getClosest(orderPositionIds, 24);
            if (typeof closest === "undefined" || closest === null || closest === "") {
                closest = _favArrayList.length - 1;
            }
            var loopingTill = maxOrderPositionNumber <= closest ? maxOrderPositionNumber : closest;

            for (let i = 1; i <= loopingTill; i++) {
                var item = _favArrayList.find(fav => fav.Order == i);
                var _prd = item && AllProduct.find(i => i.WPID == item.Product_Id);
                if (item) {
                    item['Tags'] = _prd && _prd.Tags && _prd.Tags;
                }
                if (typeof item !== "undefined" && item != null) {
                    finalArray.push(item);
                }
                else {
                    finalArray.push({ "TileColor": null, "type": "add-new", "Order": i });
                }
            }
            var otherFav = _favArrayList.filter(fav => fav.Order > loopingTill);
            if (typeof otherFav != "undefined" && typeof otherFav != null && otherFav.length > 0) {
                finalArray = ([...finalArray, ...otherFav]);
            }
            setfavArrayList(finalArray);
            if (finalArray) {
                localStorage.setItem(get_UDid() + "_FavList", JSON.stringify(finalArray.length));
            }
        }
        else {
            setfavArrayList(_favArrayList);
            if (finalArray) {
                localStorage.setItem(get_UDid() + "_FavList", JSON.stringify(finalArray.length));
            }
        }
        // if (localStorage.getItem(get_UDid() + "_NumOfTiles")) {
        //     var _number = JSON.parse(localStorage.getItem(get_UDid() + "_NumOfTiles"));
        //     adjustTileSize(_number);
        //     setTilesNo(_number);
        // }
        //ARRAING THE TILES AS PER THE THEIR POSTION 20-JAN-2023 ---END
    }
    useEffect(() => {
        closeDeleteTiles();
    }, [props.isOuterClick]);

    useEffect(() => {
        getAll().then((rows) => {
            var allProdcuts = getTaxAllProduct(rows)
            setAllProduct(allProdcuts);
            setParentProductList(allProdcuts);
            var testProdcut=allProdcuts.filter(item=>item.Categories !=="" && item.Categories.toLowerCase().includes("cloth"))
        });
        getModifiers();
        if (status === STATUSES.error) {
            //console.log(error)
        }
        if (status === STATUSES.IDLE && is_success && data != "") {
            fillFavourite();
        }
    }, [data]);

    const fillCategorySelection = (item) => {
        if (sel_item === null) {
            setCat_breadcrumb([]);
        }
        var catList = cat_breadcrumb;
        if (item) {
            catList.push(item)
            setCat_breadcrumb(catList);
        }
    }
    const BreadCumCategorySelection = (item) => {
        var tempItem = [];
        var index = -1;
        if (item === null) {
            setCat_breadcrumb([]);
        }
        var catList = cat_breadcrumb;
        if (catList.length > 0) {
            if (item.hasOwnProperty("id")) {
                index = catList.findIndex(a => a.id === item.id);
            }
            else if (item.hasOwnProperty("Id")) {
                index = catList.findIndex(a => a.Id === item.Id);
            }
            if (catList.length > 0) {
                tempItem = catList.slice(0, index + 1);
                filterProductByTile(item.type, item);
            }
            else {
            }
            setCat_breadcrumb(tempItem);
            setSel_item(item);
        }
        else {
        }
        showCategorySelection();
    }
    const showCategorySelection = () => {
        var _cat = [];
        var newStatuses = [];
        if (cat_breadcrumb && cat_breadcrumb.length > 0) {
            _cat = cat_breadcrumb.map(cat => {
                if (cat.Value || cat.attribute_slug || cat.name)
                    return <p onClick={() => BreadCumCategorySelection(cat)} key={uniqueKey()}>{(cat.Value ? cat.Value.replace("&amp;", "&") : cat.attribute_slug ? cat.attribute_slug.replace("&amp;", "&") : cat.name ? cat.name.replace("&amp;", "&") : '') + " > "}</p>
                else return ""
            })
            newStatuses = [<p onClick={() => goMainMenu()} key={uniqueKey()}>{LocalizedLanguage.allcategories}&nbsp;</p>, ..._cat]
        }
        return newStatuses;
    }
    const goMainMenu = () => {
        setCat_breadcrumb([]);
        setSel_item(null);

        showCategorySelection();
        fillFavourite();
        setfiltered([]);
    }
    // It is refreshing the tile list from server when a new tile is added
    const [resdeletTile] = useSelector((state) => [state.deletTile])
    useEffect(() => {
        if (isLoading === true && resdeletTile && resdeletTile.status === STATUSES.IDLE && (resdeletTile.is_success === true || resdeletTile.is_success === false)) {
            setIsLoading(false);
            setIsTileDelete(false);
            setFavIdsToDelete([]);
        }
    }, [resdeletTile]);

    var noTile = "";
    return (
        <div className={isTileDelete == true ? "products edit-screen" : "products"} onContextMenu={(e) => e.preventDefault()} id="tileItemsList">
            <div className="change-row-count">
                <button id="minusRow" className="dummy_minus" onClick={() => tilesCount("minus")}>
                    <img src={Minus_Blue} alt="" className="dummy_img" />
                </button>
                <p id="tileCount">{tilesNo} tiles per row</p>
                <button id="plusRow" onClick={() => tilesCount("plus")} className="dummy_plus">
                    <img src={Plus_Blue} alt="" className="dummy_img" />
                </button>
                <button id="exitEditing" onClick={() => save_update()}>{LocalizedLanguage.SaveUpdate}</button>
            </div>

            {cat_breadcrumb && cat_breadcrumb.length > 0 &&
                <div className="category-header">
                    {showCategorySelection()}
                    <button onClick={() => goMainMenu()}>
                        <img src={HomeIconWhite} alt="" />
                        {LocalizedLanguage.ReturnHome}
                    </button>
                </div>
            }
            <div className="products-container" id="productContainer">
                <div className="no-product-container">
                    <img src={ErrorIconBlue} alt="" />
                    <p>{LocalizedLanguage.noproducttodisplay}.</p>
                    {/* <button>{LocalizedLanguage.addaproduct}</button> */}
                </div>
                {/* {cat_breadcrumb && cat_breadcrumb.length > 0 && <div className="mod-product">
                    <div className="category-row">
                        {showCategorySelection()}
                    </div>
                </div>} */}
                {
                    favArrayList && favArrayList.map((item, index) => {
                        noTile = " custom-tile-size";
                        var tileColor = item.TileColor != null && item.TileColor != "" ? "var(--oliver-" + item.TileColor + ")" : "var(--oliver-blue)";
                        var txtColor = item.TileColor != null && item.TileColor != "" ? (item.TileColor === "lime" || item.TileColor === "yellow") ? "var(--oliver-base-blue)" : "#fff" : "#fff";
                        var mrRight = "";
                        if ((index + 1) % tilesNo === 0) {
                            mrRight = "mr-right "
                        }
                        else {
                            mrRight = "";
                        }
                        switch (item.type) {
                            case "product":
                                return <button className={mrRight + (isTileDelete == true ? "product custom-tile-size remove-state" : "product custom-tile-size")} key={"product_" + item.Id} id={"product_" + item.Id} onClick={() =>
                                    item.Type === 'simple' && isTileDelete === false ? viewProduct(item) : filterProductByTile(item.type, item, null)
                                } {...bind()} /*style={{backgroundColor:item.TileColor!=null && item.TileColor!=""?item.TileColor:"var(--oliver-blue)"}}*/>
                                    {
                                        item.Type === 'simple' && (
                                            <span className={isTileDelete === true && item.Type === 'simple' ? 'tile-cart-btn hide' : 'tile-cart-btn'}
                                                onClick={(e) => {
                                                    if (item.Type === 'simple' || isTileDelete === true) {
                                                        e.stopPropagation();
                                                        filterProductByTile(item.type, item, null);
                                                    } else {
                                                        // null
                                                    }
                                                }}
                                            >
                                                <img src={CartSpeed} alt='' />
                                            </span>
                                        )
                                    }

                                    {item.Tags == 'oliver_produt_x' || (item.Type != "simple" && item.Type != "variable" && item.Type != "variation") ?
                                        <div className="not-supported">
                                            <img src={LockIcon} alt="" />
                                        </div> : null}
                                    <div className="body">
                                        {item.Image != null && item.Image !=="False"?
                                            <img src={item.Image} alt="" /> :
                                            <img src={NoImageAvailable} alt="" />
                                        }
                                    </div>
                                    <div className="footer">
                                        <p>
                                            <Markup content={(item.Title).replace(" - ", "-")} />
                                        </p>
                                    </div>
                                    <div className={isTileDelete == true ? "remove-cover" : "remove-cover hide"}><div className="remove-button" onClick={() => delete_tile(item.Id, "product_" + item.Id, item.Order)}><img src={X_Icon_DarkBlue} alt="" /></div></div>

                                </button>
                            case "attribute":
                            case "sub-attribute":

                                return <button className={mrRight + (isTileDelete == true ? "category custom-tile-size remove-state" : "category custom-tile-size")} key={"attribute_" + index} id={"attribute_" + (item.id ? item.id : item.Id)} onClick={() => isTileDelete ? null : filterProductByTile(item.type, item, null)} {...bind()} style={{ backgroundColor: tileColor }}>


                                    <p className="sub" style={{ color: txtColor }}>{LocalizedLanguage.attribute}</p>
                                    <p className="main" style={{ color: txtColor }}>
                                        <Markup content={(item.attribute_slug).replace(" - ", "-")} />
                                    </p>
                                    {cat_breadcrumb.length == 0 ? <div className={isTileDelete == true ? "remove-cover" : "remove-cover hide"}><div className="remove-button" onClick={() => delete_tile(item.id, "attribute_" + item.id, item.Order)}><img src={X_Icon_DarkBlue} alt="" /></div></div> : null}
                                </button>
                            case "category":
                            case "sub-category":

                                return <button className={mrRight + (isTileDelete == true ? "category custom-tile-size remove-state" : "category custom-tile-size")} key={"category_" + index} id={"category_" + item.id} onClick={() => isTileDelete ? null : filterProductByTile(item.type, item, null)} {...bind()} style={{ backgroundColor: tileColor }}>



                                    <p className="sub" style={{ color: txtColor }}>{LocalizedLanguage.categories}</p>
                                    <p className="main" style={{ color: txtColor }}>
                                        <Markup content={(item.name ? item.name : item.Value).replace(" - ", "-")} />
                                    </p>
                                    {cat_breadcrumb.length == 0 ? <div className={isTileDelete == true ? "remove-cover" : "remove-cover hide"}><div className="remove-button" onClick={() => delete_tile(item.id, "category_" + item.id, item.Order)}><img src={X_Icon_DarkBlue} alt="" /></div></div> : null}
                                </button>
                            case "add-new":
                                return <button className={mrRight + "add-tile custom-tile-size"} onClick={() => isTileDelete ? null : props.toggleAddTitle(item.Order)} key={uniqueKey()}>
                                    <img src={Rounded_Square_Plus_Icon_NewGrey} alt="" />
                                    {LocalizedLanguage.addTitle}
                                </button>
                            default:
                                return ''
                        }

                    })
                }
                {
                    filtered && filtered.map((item, index) => {
                        var mrRight = "";
                        if ((index + 1) % tilesNo === 0) {
                            mrRight = "mr-right "
                        }
                        else {
                            mrRight = "";
                        }

                        var type = (item.hasOwnProperty("Type") && (item.Type == "simple" || item.Type == "variable" || item.Type == "variation")) ? "product" : item.Type;
                        return <button className={mrRight + "product custom-tile-size"} key={index} onClick={() => /*props.openPopUp(item)*/ item.Type === 'simple' && isTileDelete === false ? viewProduct(item) : filterProductByTile(type, item, null)} >
                            {
                                item.Type === 'simple' && (
                                    <span className={isTileDelete === true && item.Type === 'simple' ? 'tile-cart-btn hide' : 'tile-cart-btn'}
                                        onClick={(e) => {
                                            if (item.Type === 'simple' || isTileDelete === true) {
                                                e.stopPropagation();
                                                filterProductByTile(type, item, null);
                                            } else {
                                                //null
                                            }
                                        }}
                                    >
                                        <img src={CartSpeed} alt='' />
                                    </span>
                                )
                            }
                            {item.Tags == 'oliver_produt_x' || (item.Type != "simple" && item.Type != "variable" && item.Type != "variation") ?
                                <div className="not-supported">
                                    <img src={LockIcon} alt="" />
                                </div> : null}
                            <div className="body">
                                {item.ProductImage != null && item.ProductImage !== "False" ?
                                    <img src={item.ProductImage} alt="" /> :
                                    <img src={NoImageAvailable} alt="" />
                                }
                            </div>
                            <div className="footer">
                                <p>
                                    {item.Title}
                                </p>
                            </div>
                        </button>
                    })
                }
                {(!cat_breadcrumb || cat_breadcrumb.length == 0) &&
                    <button id="add-tile-last" className={"mr-right add-tile" + noTile} onClick={() => isTileDelete ? null : props.toggleAddTitle(favArrayList && favArrayList.length + 1)} >
                        <img src={Rounded_Square_Plus_Icon_NewGrey} alt="" />
                        {LocalizedLanguage.addTitle}
                    </button>
                }
                {isLoading === true || status === STATUSES.LOADING ? <LoadingModal></LoadingModal> : null}
            </div>
            <div style={{ display: "none" }}>
                {/* {setTimeout(() => {
                    tilesNo !== 0 && adjustTileSize(tilesNo);
                }, 1)} */}
            </div>
        </div>)
}

export default TileList 