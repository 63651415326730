import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { useLoginMutation,useGetAllRegisterQuery } from '../../../components/login/loginService';
import { GetUserProfile } from './loginAPI';
import STATUSES from '../../constants/apiStatus';

const initialState = {
  "g_status": STATUSES.IDLE,
  "g_data": "",
  "g_error": '',
  "g_is_success": false
};

export const GetUserProfileLogin = createAsyncThunk(
  'login/GetUserProfile',
  async (parameter, { rejectWithValue }) => {

    try {
      const response = await GetUserProfile(parameter);
      // The value we return becomes the `fulfilled` action payload
      return response;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data)
    }
  }
);
export const googleLoginSlice = createSlice({
  name: 'googleLogin',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },

  extraReducers: (builder) => {
    builder
      .addCase(GetUserProfileLogin.pending, (state) => {
        state.g_status = STATUSES.LOADING;
        state.g_data = "";
        state.g_error = "";
        state.g_is_success = false;
      })
      .addCase(GetUserProfileLogin.fulfilled, (state, action) => {
        state.g_status = action.payload && action.payload.status == 200 ? STATUSES.IDLE : STATUSES.ERROR;
        state.g_data = (action.payload && action.payload.status == 200 ? action.payload : "");
        state.g_error = action.payload && action.payload.status !== 200 ? "Fail to fetch" : "";;
        state.g_is_success = action.payload && action.payload.status == 200 ? true : false;
      })
      .addCase(GetUserProfileLogin.rejected, (state, action) => {
        state.g_status = STATUSES.IDLE;
        state.g_data = "";
        state.g_error = action.error;
        state.g_is_success = false;
      });

  },
});

export const { googleLoginPanding, googleLoginSuccess, googleLoginFail } = googleLoginSlice.actions;


export default googleLoginSlice;
