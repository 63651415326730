import React from 'react';
import GreyAlert from '../../../assets/images/svg/GreyAlert.svg';
import LocalizedLanguage from '../../../settings/LocalizedLanguage';
const RegisterTakeOver = (props) => {
    // const outerClick = (e) => {
    //     if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
    //         props.toggleRegTakeOver && props.toggleRegTakeOver();
    //     }
    // }
    return (
        // <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
        //     <div className={props.isShow === true ? "subwindow register-taken current" : "subwindow register-taken"}>
            <div className="subwindow-wrapper hidden" id="register-taken-parent">
            <div className="subwindow register-taken" id="register-taken">
                <div className="subwindow-header">
                    <p>{LocalizedLanguage.RegisterTakeOver}</p>
                </div>
                <div className="subwindow-body">
                    <div className="auto-margin-top"></div>
                    <img src={GreyAlert} alt="" />
                    <p>
                        {LocalizedLanguage.Thisregisterhasbeen} <br />
                        {LocalizedLanguage.takenoverbyanotheruser} <br /><br />
                        {LocalizedLanguage.Youwillbeloggedout}
                    </p>
                    <div className="auto-margin-bottom"></div>
                </div></div>
        </div>);
}
export default RegisterTakeOver;