import React, { useState } from 'react';
import LocalizedLanguage from '../../../settings/LocalizedLanguage';
import ClockBlue from '../../../assets/images/svg/ClockBlue.svg';
import { NavigateComponent } from '../../../settings';
import { useNavigate } from 'react-router';
import ActiveUser from '../../../settings/ActiveUser';
import Config from '../../../Config';
import moment from 'moment';
export const LoadingModal = (props) => {
    const [orderProcessingError, setOrderProcessingError] = useState(false);
    const navigate = useNavigate();
   var _interval= setInterval(() => { 
        if(props.startOrderProcessing){
            var d=new Date();
                var tm=d.getTime();
                var diff=tm - props.startOrderProcessing
          
                if( diff >20000 && orderProcessingError==false && props.loading==true){               
                    setOrderProcessingError(true);
                    var clientEmail= ActiveUser.key.Email;
                    var currentOrder=localStorage.getItem("GTM_ORDER") ? JSON.parse(localStorage.getItem("GTM_ORDER")) : "";
                    if(currentOrder && currentOrder !==""){                       
                        var TempOrders = [];
                        if (localStorage.getItem(`resync_orders_${clientEmail}`)) {
                            TempOrders = JSON.parse(localStorage.getItem(`resync_orders_${clientEmail}`));
                        }
                      var isFound=  TempOrders && TempOrders.length>0 && TempOrders.find(i=>i.oliver_pos_receipt_id ==currentOrder.oliver_pos_receipt_id);
                       if(!isFound){
                        {TempOrders.push(currentOrder)};
                        localStorage.setItem(`resync_orders_${clientEmail}`, JSON.stringify(TempOrders));


                          // added into temp order list to display into notification
                       var TempOrders = [];
                       if (localStorage.getItem(`TempOrders_${ActiveUser.key.Email}`)) {
                           TempOrders = JSON.parse(localStorage.getItem(`TempOrders_${ActiveUser.key.Email}`));
                       }
                       TempOrders.push({ "TempOrderID": currentOrder.oliver_pos_receipt_id, "Status": "false", "Index": TempOrders.length, "OrderID": 0, 'order_status': "incompleted", 'date': moment().format(Config.key.NOTIFICATION_FORMAT), 'Sync_Count': 0, 'new_customer_email': '', 'isCustomerEmail_send': false, 'order_status_DB': "resync" });  //order_status_DB is added for app command order status
                       localStorage.setItem(`TempOrders_${ActiveUser.key.Email}`, JSON.stringify(TempOrders));
                      
                       }
                       let clientDetail = localStorage.getItem('clientDetail') ? JSON.parse(localStorage.getItem('clientDetail')) : '';
                       var navigateSaleComplete = clientDetail && clientDetail !==""? clientDetail?.shop_settings?.display_sale_refund_complete_screen:true;
                        //-----------------------------------------------------------------------
                        clearInterval(_interval);
                        if(navigateSaleComplete==true){                            
                            NavigateComponent(navigate, "salecomplete");
                        }else{
                            clearData();
                            NavigateComponent(navigate, "home");
                        }
                    }
                  
                    clearInterval(_interval);                
                }
        }
    }, 1000);
   if(orderProcessingError==true || !props.startOrderProcessing){
    clearInterval(_interval);
   }
   const clearData = () => {
    localStorage.removeItem('CARD_PRODUCT_LIST');
    localStorage.removeItem('GTM_ORDER');
    localStorage.removeItem('ORDER_ID');
    localStorage.removeItem('CHECKLIST');
    localStorage.removeItem('oliver_order_payments');
    localStorage.removeItem('AdCusDetail');
    localStorage.removeItem("CART");
    localStorage.removeItem("SINGLE_PRODUCT");
    localStorage.removeItem("PRODUCT");
    localStorage.removeItem('PRODUCTX_DATA');
    localStorage.removeItem('PAYCONIQ_PAYMENT_RESPONSE');
    localStorage.removeItem('ONLINE_PAYMENT_RESPONSE');
    localStorage.removeItem('STRIPE_PAYMENT_RESPONSE');
    localStorage.removeItem('GLOBAL_PAYMENT_RESPONSE');
    localStorage.removeItem('PAYMENT_RESPONSE');
    localStorage.removeItem('PENDING_PAYMENTS');
    localStorage.setItem('DEFAULT_TAX_STATUS', 'true');
    localStorage.removeItem('PrintCHECKLIST');
    localStorage.removeItem("payType");
    localStorage.removeItem("CUSTOMER_TO_ACTVITY");
    localStorage.removeItem("tempOrder_Id");
}
  const handleErrorClick=()=>{
    clearInterval(_interval);
    // set custom data when bug submitted Marker------------------------------
    var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
            if(client){
            window.Marker.setCustomData({
                client: client.user_full_name,
                email: client.user_email  
            });
            }
       
        window.Marker.capture('fullscreen');
  }
  const resendRequest=()=>{
    props.handleResendReqest && props.handleResendReqest();
  }
    return (
        <div className='popup'>
            <div className="loaderThemeBg">
                <div className="preloaderbackground">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="120" height="120" viewBox="0 0 400 400" enableBackground="new 0 0 400 400">
                        <g>
                            <rect x="249.28" y="156.01" className="st0 ologo-1" width="103.9" height="103.9"></rect>
                            <path id="teal" className="st1 ologo-2" d="M249.28,363.81V259.91h103.9C353.17,317.29,306.66,363.81,249.28,363.81z"></path>
                            <rect id="cyan" x="145.38" y="259.91" className="st2 ologo-3" width="103.9" height="103.89"></rect>
                            <path id="blue" className="st3 ologo-4" d="M41.49,259.91L41.49,259.91h103.9v103.89C88,363.81,41.49,317.29,41.49,259.91z"></path>
                            <rect id="purple" x="41.49" y="156.01" className="st4 ologo-5" width="103.9" height="103.9"></rect>
                            <path id="red" className="st5 ologo-6" d="M41.49,156.01L41.49,156.01c0-57.38,46.52-103.9,103.9-103.9v103.9H41.49z"></path>
                            <rect id="orange" x="145.38" y="52.12" className="st6 ologo-7" width="103.9" height="103.9"></rect>
                            <path id="yellow" className="st7 ologo-8" d="M281.3,123.99V20.09c57.38,0,103.9,46.52,103.9,103.9H281.3z"></path>
                        </g>
                    </svg>
                    <div>
                        {orderProcessingError==true?
                        <div className='order-error'>
                          <div className='order-error-heading'>  <img  src={ClockBlue}></img>
                             <h3>{LocalizedLanguage.timeout}</h3></div>
                            <p>{LocalizedLanguage.ordernotdone}</p>
                            <button onClick={()=>handleErrorClick()}>{LocalizedLanguage.btnorderissue}</button>
                            {/* <button onClick={()=>resendRequest()}>Resend</button> */}
                            </div>
                        :
                            <span className="fn-poppins">{LocalizedLanguage.loading}</span>
                        }
                        
                    </div> 
                </div>
            </div>
        </div>
       
    );
}