import { postmessage_receipt } from "../commonWrapperAppHandler";
import { store } from "../../../../app/store";
import FormateDateAndTime from "../../../../settings/FormateDateAndTime";
import moment from "moment";
import ActiveUser from '../../../../settings/ActiveUser';
import { getInclusiveTaxType } from "../../../../settings";
import { sendDataToWrapper } from "../../../../settings/AndroidIOSConnect";
import { getPTaxable } from "../../../../settings/CommonWork";
import STATUSES from "../../../../constants/apiStatus";
import { textToBase64Barcode } from "./cartApp";
import { PrintPage } from "../../PrintPage";
import { getTotalTaxByName } from "../../TaxSetting";
import LocalizedLanguage from "../../../../settings/LocalizedLanguage";
//Print the app data on the exsting receit
function calculateTaxRate(totalAmount, itemvalue) {

  return Math.round(((itemvalue) * 100) / totalAmount, 0)
}
function getDiscountPerItem(data, type, productID) {
  var discountDetail;
  var getorderlist = "";
  var _data;
  if (type !== 'activity') {
    _data = data.meta_datas && data.meta_datas[0]
    getorderlist = _data && _data._order_oliverpos_product_discount_amount
  } else {
    _data = data.meta_datas && data.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount');
    getorderlist = _data && _data.ItemValue && JSON.parse(_data.ItemValue);
  }
  if (getorderlist) {
    getorderlist.filter(itm => itm.product_id && (itm.variation_id == productID || itm.product_id == productID)).map(prd => {
      // console.log("prd",prd)
      var typeRate = 0;
      if (prd.discount_type == "Number") {
        var disPerc = (prd.new_product_discount_amount * 100) / prd.Price
        typeRate = disPerc.toFixed(2)
      } else {
        typeRate = prd.new_product_discount_amount
      }
      discountDetail = {
        "discountApply": typeRate,
        "discountAmount": (prd.product_discount_amount * (prd.discount_type == "Number" ? 1 : prd.quantity)),
        "discounttype": prd.discount_type,
        "discountCart": prd.discountCart ? prd.discountCart : 0,
        "cartItemDiscount": prd.cart_discount_amount
      }
    })
  }
  return discountDetail

}
//export const WrapperReceipt = (RequestData, whereToview, isbackgroudApp) => {
export const WrapperReceipt = () => {
  var clientJSON = ""

  // var validationResponse = validateRequest(RequestData)

  // if (validationResponse.isValidationSuccess == false) {
  //     clientJSON = validationResponse.clientJSON;
  // }
  // else {

  var Data = {}

  const state = store.getState();
  //state.
  var refund_tax = 0.0;
  var single_Order_list = state.activityGetDetail ? state.activityGetDetail.data : null;
  if (single_Order_list && single_Order_list.content && single_Order_list.content.line_items && single_Order_list.content.line_items.length > 0) {
    var items = single_Order_list.content.line_items.map(item => {
      var _item = { ...item };
      //_item["isTaxable"] = getPTaxable(single_Order_list, item);
      if (_item.isTaxable == true && _item.quantity_refunded && Math.abs(_item.quantity_refunded) > 0) {
        refund_tax += (parseFloat(_item.total_tax / _item.quantity) * Math.abs(_item.quantity_refunded));
      }
      return _item;
    });
    single_Order_list = { ...single_Order_list.content, tax_refunded: refund_tax, line_items: items };
  }

  var manager = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  var order_reciept = localStorage.getItem('orderreciept') && localStorage.getItem('orderreciept') !== 'undefined' ? JSON.parse(localStorage.getItem('orderreciept')) : "";
  var selectedRegister = localStorage.getItem('selectedRegister') && localStorage.getItem('selectedRegister') !== 'undefined' ? JSON.parse(localStorage.getItem('selectedRegister')) : "";
  var baseurl = "";
  var shopName = "";
  var gmtDateTime = '';
  var time = "";
  var receiptId = "";
  var servedBy = "";
  var customershow_name = '';
  var CustomerAddress = "";
  var taxId = ""
  var registerName = ""
  var locationName = ""
  var customeText = ""
  var oprationalDate = null
  var orderPayments = []
  var data = single_Order_list;
  var type = window.location.pathname !== '/salecomplete' ? 'activity' : '';

  var tempOrderId = localStorage.getItem('tempOrder_Id') ? JSON.parse(localStorage.getItem('tempOrder_Id')) : '';
  receiptId = data.OliverReciptId ? data.OliverReciptId : data.order_id ? data.order_id : tempOrderId ? tempOrderId : "";
  taxId = order_reciept.TaxId ? order_reciept.TaxId : '';


  shopName = data && data.ShopName ? data.ShopName : manager && manager.shop_name;
  if (data !== null && data.date_time !== undefined && Reflect.has(data, data.date_time)) {
    oprationalDate = data.date_time;
  }
  else if (data.create_date) {
    oprationalDate = data.create_date;
  }
  else {
    oprationalDate = data._currentTime;
  }

  if (data.time_zone)
    gmtDateTime = FormateDateAndTime.formatDateAndTime(oprationalDate, data.time_zone);
  else
    gmtDateTime = FormateDateAndTime.formatDateAndTime(oprationalDate);


  gmtDateTime = moment(gmtDateTime).format(ActiveUser.key.orderRecieptDateFormate)

  var ckdateisValid = false
  if (gmtDateTime !== null && gmtDateTime !== undefined) {
    ckdateisValid = moment(gmtDateTime).isValid();
    if (ckdateisValid == false) {
      var currentdate = new Date();
      if (data.time_zone)
        gmtDateTime = FormateDateAndTime.formatDateAndTime(currentdate, data.time_zone);
      else
        gmtDateTime = FormateDateAndTime.formatDateAndTime(currentdate);
      gmtDateTime = moment(gmtDateTime).format(ActiveUser.key.orderRecieptDateFormate)
    }
  }
  servedBy = manager && manager.display_name ? manager.display_name : "";
  if (data.time_zone)
    time = FormateDateAndTime.formatDateWithTime(data.date_time, data.time_zone, order_reciept.TimeFormat.replace('tt', 'A'));  //tt not suported in moment
  else if (data.order_date && data.order_date !== "Invalid date")
    time = FormateDateAndTime.formatDateWithTime(data.order_date, null, order_reciept.TimeFormat.replace('tt', 'A'));//date_time
  else
    time = FormateDateAndTime.formatDateWithTime(data._currentTime, null, order_reciept.TimeFormat.replace('tt', 'A'));

  if (time == "Invalid date") {
    time = new Date().toLocaleString([], { hour: 'numeric', minute: 'numeric' });
  }

  if (data.orderCustomerInfo !== null && data.orderCustomerInfo !== undefined) // case of activity view
  {
    customershow_name = data.orderCustomerInfo.customer_name && data.orderCustomerInfo.customer_name !== undefined && data.orderCustomerInfo.customer_name.trim() !== "" ? data.orderCustomerInfo.customer_name
      : data.orderCustomerInfo.customer_email && data.orderCustomerInfo.customer_email.trim() !== "" ? data.orderCustomerInfo.customer_email : ''

  }
  else if (data.customerDetail !== null && data.customerDetail !== undefined && data.customerDetail.content && data.customerDetail.content !== undefined)// case of order create
  {
    customershow_name = data.customerDetail.content.FirstName && data.customerDetail.content.FirstName !== undefined ?
      data.customerDetail.content.FirstName + " " + data.customerDetail.content.LastName
      : data.customerDetail.content.Email !== null && data.customerDetail.content.Email !== undefined ? data.customerDetail.content.Email : ''
  }

  registerName = window.location.pathname !== '/salecomplete' ? data.RegisterName : selectedRegister.name;

  data.order_payments && data.order_payments.map(pay => {
    var _payment = {
      "method": pay.type,
      "amount": pay.amount
    }
    if (pay.description && pay.description != "") {
      _payment["emv"] = JSON.parse(pay.description);
    }
    orderPayments.push(_payment)
  })
  var lineItemDetail = []
  var taxInclusiveName = getInclusiveTaxType(data.meta_datas);

  data.line_items && data.line_items.map(item => {
    var arryDiscount = [];
    var lineitem_taxType = []
    /// tax calculatiomn--------------------------------              
    var itemvalue = item.Taxes && item.Taxes !== null && item.Taxes !== "" ? (type !== 'activity') ? item.Taxes : item.Taxes && item.Taxes !== "null" ? JSON.parse(item.Taxes).total : "" : ""
    var taxRate = JSON.parse(localStorage.getItem("SHOP_TAXRATE_LIST"))
    var TotalTax = 0
    if (type == 'activity') { // for activity
      itemvalue && itemvalue !== "" && Object.keys(itemvalue).map(key => {
        var taxvalue = itemvalue[key];
        data.order_taxes && data.order_taxes.map(tm => {
          // taxRate && taxRate.map(tm=>{
          if (tm.RateId == key) {
            var taxname = tm.Title; //tm.TaxName;
            var TaxRate = calculateTaxRate(parseFloat(item.price * item.quantity) - (taxInclusiveName !== '' ? parseFloat(taxvalue) : 0), parseFloat(taxvalue));
            if (order_reciept) {
              if (order_reciept.PercentageTaxPerItem == true) {
                // taxname += "(" + TaxRate + "%)";
              }
              TotalTax += parseFloat(TaxRate)
            }
            lineitem_taxType.push({ "name": taxname, 'percent': TaxRate, "amount": taxvalue })
          }
        })
      })
    } else {//for sale complete
      itemvalue !== "" && itemvalue.map(myObj => {
        var taxvalue = ""
        var key = ""
        for (const x in myObj) {
          key = x;
          taxvalue = myObj[x]
        }
        taxRate && taxRate.map(tm => {
          if (tm.TaxId == key) {
            var taxname = tm.TaxName;
            var rate = ''
            if (order_reciept && order_reciept.PercentageTaxPerItem == true) {
              rate = tm.TaxRate;
            }
            TotalTax += parseFloat(tm.TaxRate)

            lineitem_taxType.push({ "name": taxname, "percent": rate, "amount": taxvalue })
          }
        })
      })
    }
    ///-----------------------------------------------------------------------  
    //------------Discount-------------------------------
    var lineItem_DiscountDetail = getDiscountPerItem(data, type, item.product_id)
    var lineitem_cartDiscount = 0;
    var lineItem_cartDiscountType = '';
    var lineitem_Discount = lineItem_DiscountDetail && lineItem_DiscountDetail.discountAmount ? lineItem_DiscountDetail.discountAmount : 0;
    // Total_IndividualProductDiscount += lineitem_Discount;
    if (lineItem_DiscountDetail && lineItem_DiscountDetail.discountCart) {
      lineitem_cartDiscount = parseFloat(lineItem_DiscountDetail.cartItemDiscount);
      // _cartDiscount += lineitem_cartDiscount;
      // Order_cartDiscount = lineItem_DiscountDetail.discountCart;
      lineItem_cartDiscountType = lineItem_DiscountDetail.discountCart ? '(' + parseFloat(lineItem_DiscountDetail.discountCart.discount_amount) + '%)' : ''
    }
    //item.discount_amount && item.discount_amount !==0? (item.discount_amount).toFixed(2):undefined

    var lineitem_Dis_Percent = lineItem_DiscountDetail && lineItem_DiscountDetail.discountApply && "(" + lineItem_DiscountDetail.discountApply + "%)";
    if (lineitem_Discount > 0) {
      arryDiscount.push({ "name": LocalizedLanguage.itemDiscount, "percent": lineitem_Dis_Percent, "amount": lineitem_Discount });
    }
    if (lineitem_cartDiscount > 0) {
      arryDiscount.push({ "name": LocalizedLanguage.cartdiscount, "percent": lineItem_cartDiscountType, "amount": lineitem_cartDiscount });
    }

    //-----------------------------------------------------
    var itemDetail = {};
    itemDetail['name'] = item.name;
    itemDetail['price'] = item.total;
    itemDetail['sku'] = item.sku;
    itemDetail['descr'] = item.sortdescription;
    itemDetail['addons'] = item.addons_meta_data;
    itemDetail['discount'] = arryDiscount; //item.discount_amount;
    itemDetail['taxes'] = lineitem_taxType;
    itemDetail['total'] = item.total

    lineItemDetail.push(itemDetail)
  })


  //Set Data into object---------------------------
  Data['date'] = gmtDateTime;
  Data['time'] = time;
  Data['saleId'] = receiptId;
  Data['staffName'] = servedBy;
  Data['customerName'] = customershow_name;
  Data['taxId'] = taxId;
  Data['payments'] = orderPayments;
  Data['registerId'] = registerName;
  Data['tableNum'] = '';
  Data['items'] = data.line_items ? lineItemDetail : [];
  Data['tip'] = '';
  Data['total'] = data.total_amount;

  var clientJSON = {
    command: "getReceiptDetails",
    version: "3.0",
    data: JSON.stringify(data)
  }
  //postmessage_receipt(Data,true)
  sendDataToWrapper("receipt_detail", "", clientJSON)


}

//}
export const WrapperReceiptUpdated = async () => {
  const state = store.getState();
  var activitygetdetails = state.activityGetDetail ? state.activityGetDetail : null;
  if (activitygetdetails && activitygetdetails.status == STATUSES.IDLE && activitygetdetails.is_success && activitygetdetails.data) {
    var single_Order_list = { ...activitygetdetails.data };
    var refund_tax = 0.0;
    if (single_Order_list && single_Order_list.content && single_Order_list.content.line_items && single_Order_list.content.line_items.length > 0) {
      var items = single_Order_list.content.line_items.map(item => {
        var _item = { ...item };
        _item["isTaxable"] = getPTaxable(single_Order_list, item);
        if (_item.isTaxable == true && _item.quantity_refunded && Math.abs(_item.quantity_refunded) > 0) {
          refund_tax += (parseFloat(_item.total_tax / _item.quantity) * Math.abs(_item.quantity_refunded));
        }
        return _item;
      });
      single_Order_list = { ...single_Order_list.content, tax_refunded: refund_tax, line_items: items };
    }
    var mydate = new Date();
    var getPdfdate = (mydate.getMonth() + 1) + '/' + mydate.getDate() + '/' + mydate.getFullYear();
    var productxList = ''
    var AllProductList = ''
    var type = 'activity'
    var orderList = single_Order_list ? single_Order_list.order_payments : ''
    var cash_rounding_amount = single_Order_list.cash_rounding_amount
    var barCodeId = single_Order_list && single_Order_list.OliverReciptId
    var print_bar_code = await textToBase64Barcode(barCodeId)
    var order_reciept = localStorage.getItem('orderreciept') ? localStorage.getItem('orderreciept') === "undefined" ? null : JSON.parse(localStorage.getItem('orderreciept')) : "";
    var TotalTaxByName = (order_reciept && order_reciept.ShowCombinedTax == false) ? single_Order_list && single_Order_list.order_taxes && getTotalTaxByName(single_Order_list.order_taxes) : "";
    var redeemPointsToPrint = single_Order_list && single_Order_list.meta_datas && single_Order_list.meta_datas.find(item => item.ItemName == "_wc_points_logged_redemption")
    redeemPointsToPrint = redeemPointsToPrint && redeemPointsToPrint.ItemValue ? redeemPointsToPrint.ItemValue : 0

    var isTotalRefund = false;
    var Totalamount = single_Order_list ? single_Order_list.total_amount : 0
    var refunded_amount = single_Order_list ? single_Order_list.refunded_amount : 0
    if ((Totalamount - refunded_amount).toFixed(2) == '0.00') {
      isTotalRefund = true
    }
  }
  var _receiptAppData = {"command":"ReceiptDetail", "version": "2.0", "data":"" }
  PrintPage.PrintElem(single_Order_list, getPdfdate, isTotalRefund, cash_rounding_amount, print_bar_code, orderList, type, productxList, AllProductList, TotalTaxByName,null, null,false,_receiptAppData)
}

const validateRequest = (RequestData) => {

  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  var urlReg = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

  var isValidationSuccess = true;
  var clientJSON = {
    command: RequestData.command,
    version: RequestData.version,
    method: RequestData.method,
    status: 406,
  }
  if (RequestData.command.toLowerCase() === ('ReceiptDetail').toLowerCase()) {
    if (RequestData && (!RequestData.method || !RequestData.method == 'get')) { //missing attribut/invalid attribute name
      isValidationSuccess = false;
      clientJSON['error'] = "Invalid Attribute"
    }


  }
  else {// no command found
    isValidationSuccess = false;
    clientJSON['error'] = "Invalid Value" //GR[5]          
  }
  return { isValidationSuccess, clientJSON };
}