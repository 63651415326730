import React, { useEffect, useLayoutEffect } from "react";
import X_Icon_DarkBlue from '../../assets/images/svg/X-Icon-DarkBlue.svg';
import RefreshGrey from '../../assets/images/svg/RefreshGrey.svg';
import knowledgeBase_Icon from '../../assets/images/svg/KnowledgeBaseIcon.svg';
import LocalizedLanguage from "../../settings/LocalizedLanguage";
const KnowledgeBase = (props) => {
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleKnowledgeBase();
        }
    }
    const refreshPage = () => {
        document.getElementById('knowledgebaseIframe') && document.getElementById('knowledgebaseIframe').contentDocument && document.getElementById('linkLauncherIframe').contentDocument.location.reload(true);
    }
    var lang=localStorage.getItem("LANG")?localStorage.getItem("LANG"):"en";
    return (
        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)} >
            <div className={props.isShow === true ? "subwindow knowledge-base current" : "subwindow knowledge-base"}>
                <div className="subwindow-header">
                    <div className="icon-container">
                        <img src={knowledgeBase_Icon} alt="" />
                    </div>
                    <p>{LocalizedLanguage.KnowledgeBase}</p>
                    <button id="kBRefresh" onClick={() => refreshPage()}>
                        <img src={RefreshGrey} alt="" />
                        <p>{LocalizedLanguage.refreshpage}</p>
                    </button>
                    <button className="close-subwindow" onClick={() => props.toggleKnowledgeBase()}>
                        <img src={X_Icon_DarkBlue} alt="" />
                    </button>
                </div>
                <div className="subwindow-body">
                    <iframe src={"https://help.oliverpos.com/"+lang+"/"} frameborder="0" id="knowledgebaseIframe"></iframe>
                </div>
            </div></div>


    )
}
export default KnowledgeBase; 