import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import TagManager from 'react-gtm-module'
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import Config from './Config'
import { isIOS,isMobileOnly,isAndroid } from 'react-device-detect';

const container = document.getElementById('root');
const root = createRoot(container);
// if(process.env.NODE_ENV === 'production' && (window.location.hostname.toLocaleLowerCase().includes('sellproduction.azurewebsites.net') 
//                                           || window.location.hostname.toLocaleLowerCase().includes('sell1.oliverpos.com')) ){
  const tagManagerArgs = {
    gtmId: Config.key.GTM_ID
}
  TagManager.initialize(tagManagerArgs)
//}
//check environment/device type --------------------------
if (isIOS === true) {
  localStorage.setItem("env_type", "ios")
}
else if (isMobileOnly === true || isAndroid == true) {  
  localStorage.setItem("env_type", "Android")
} else {
  localStorage.removeItem("env_type");
}

var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
if(client && client !=="") {
  var subcriptionName="";
  if(client.subscription_detail && client.subscription_detail.SubscriptionLineItems.length>0){
    client.subscription_detail && client.subscription_detail.SubscriptionLineItems.map(i=>{
      subcriptionName = subcriptionName ==""?i.SubscriptionName: subcriptionName.concat(",", i.SubscriptionName); 
     });
  }else{
    subcriptionName=client.subscription_detail.subscription_name;
  }
//----------------------------------------
}
console.log("Register Version 3.0.10")
root.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
