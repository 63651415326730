// A mock function to mimic making an async request for data
import { serverRequest } from '../../CommonServiceRequest/serverRequest'
import { get_UDid } from '../common/localSettings';
import moment from 'moment';
import ActiveUser from '../../settings/ActiveUser';
import Config from '../../Config';
import { handleAppEvent } from '../common/AppHandeler/commonAppHandler';
import { postMeta } from "../common/commonAPIs/postMetaSlice";
import { addPaymentListLog } from '../../components/cashmanagement/CashmanagementSlice';
import { store } from '../../app/store';
import { UpdateProductInventoryDB } from '../loadProduct/loadProductSlice';
export function checkStockAPI(cartlist) {
    var items = [];
    var data;
    var udid = get_UDid('UDID');
    if (cartlist != null) {
        cartlist && cartlist.map(value => {
            items.push({
                ProductId: value.variation_id == 0 ? value.product_id : value.variation_id,
                Quantity: value.quantity,
                possition: 0,
                success: false
            })
        })
    }
    data = {
        udid: udid,
        productinfos: items,
        WarehouseId: localStorage.getItem("WarehouseId") ? parseInt(localStorage.getItem("WarehouseId")) : 0
    }
    return serverRequest.clientServiceRequest('POST', `/Product/CheckStock`, data)
        .then(result => {
            return result
        })
}

export function getPaymentTypeNameAPI() {
    let registerId = localStorage.getItem('register') ? localStorage.getItem('register') : '';
    return serverRequest.clientServiceRequest('GET', `/PaymentType/Get?RegisterId=${registerId}`, '')
        .then(res => {
            localStorage.setItem("PAYMENT_TYPE_NAME", JSON.stringify(res.content))
            return res
        }).catch(error => {
            return error
        });
}
export function getExtensionsAPI() {
    return serverRequest.clientServiceRequest('GET', `/Extensions/Get`, '')
        .then(result => {
            var _available_app = []
            if (result.content && result.content.length > 0) {
                _available_app = result.content.filter(a => a.removed_from_origin !== true);
            }
            localStorage.setItem('GET_EXTENTION_FIELD', JSON.stringify(_available_app))
            return result.content;
        })
        .catch(error => {
            return error
        });
}

export function getMakePaymentAPI(registerId, paycode, amount, command, transId) {

    var parma = { "registerId": registerId, "paycode": paycode, "amount": amount, "command": command, "refId": transId };
    return serverRequest.clientServiceRequest('POST', `/Payments/RunTransaction`, parma)
        .then(res => {
            return res
        })
        .catch(error => {
            return { "is_success": false, "message": error !== "" && error.Message ? error.Message : 'An error has occurred.', "content": null, "exceptions": null, "status_code": 202, "subscription_expired": false }
        })
}
// online payment service
export function makeOnlinePaymentsAPI(cardData) {
    return serverRequest.clientServiceRequest('POST', `/OnlinePayments/RunTransaction`, cardData)

        //  return serverRequest.clientServiceRequest('POST', `/OnlinePayments/ChargeCreditCard`,cardData)
        .then(res => {

            if (res.is_success == true) {
                var demoUser = localStorage.getItem("demoUser") ? localStorage.getItem("demoUser") : false;
                if (demoUser) {
                    //GTM_OliverDemoUser("ShopView: Add Tile to Favourite")
                }
                localStorage.setItem('ONLINE_PAYMENT_RESPONSE', JSON.stringify(res));
                localStorage.setItem('PAYMENT_RESPONSE', JSON.stringify(res));

                //dispatch(success(res));
            } else {

                //dispatch(failure(res.message));
            }

            return res
        })
}
export function saveAPI(shopOrder, path, updatedBy = "") {

    return serverRequest.clientServiceRequest('POST', `/orders/CreateOliverOrder`, shopOrder)
        .then(shop_order => {
            if (shop_order.is_success == true) {
                console.log("Order processed sucessfully");
                localStorage.removeItem('BACK_CHECKOUT');
                localStorage.removeItem('extensionUpdateCart');
                localStorage.removeItem('TIKERA_SELECTED_SEATS');
                localStorage.removeItem("TIKERA_SEAT_CHART");
                localStorage.removeItem("selectedGroupSale");
                localStorage.removeItem("SELECTED_MODIFIERS")
                var taxData = localStorage.getItem("SELECTED_TAX") && JSON.parse(localStorage.getItem("SELECTED_TAX"));
                if (taxData) {
                    localStorage.setItem("TAXT_RATE_LIST", JSON.stringify(taxData[0]));
                }
                var demoUser = localStorage.getItem("demoUser") ? localStorage.getItem("demoUser") : false;
                if (demoUser) {
                    //GTM_OliverDemoUser("CheckoutView: Order placed ")
                }
                //if parked by extension app, it is returning temp order id to the app 
                if (updatedBy == "byExtApp") {
                    handleAppEvent({ method: "post", command: "ParkSale", tempOrderId: shop_order.content ? shop_order.content.tempOrderId : 0 }, null);
                }

                //Saving post meta for Pay_by_Product
                if (localStorage.getItem("paybyproduct")) {
                    var _tempOrder_Id = shop_order.content ? shop_order.content.tempOrderId : 0
                    var parma = { "Slug": _tempOrder_Id + "_paybyproduct", "Value": localStorage.getItem("paybyproduct"), "Id": 0, "IsDeleted": 0 };
                    store.dispatch(postMeta(parma));
                    setTimeout(() => {
                        localStorage.removeItem("paybyproduct");
                        localStorage.removeItem("paybyproduct_unpaid");
                    }, 100);
                }

                //dispatch(success(shop_order));

                //Created By : Nagendra
                // Modified Date: 03/07/2020
                // Desc : Save the payment log 
                try {
                    var d = new Date();
                    var orderPayments = localStorage.getItem("oliver_order_payments") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : "";
                    var cashmanagementID = localStorage.getItem("Cash_Management_ID")
                    var dateStringWithTime = moment(d).format('YYYY-MM-DD HH:mm:ss Z');
                    var getLocalTimeZoneOffsetValue = d.getTimezoneOffset();
                    var localTimeZoneType = moment.tz.guess(true);
                    var user = JSON.parse(localStorage.getItem("user"));

                    var paymentLog = [];
                    if (orderPayments && orderPayments.length) {
                        orderPayments.map(item => {
                            if (item.Id == 0) { // only new payment should be log
                                paymentLog.push({
                                    CashManagementId: cashmanagementID,
                                    AmountIn: item.payment_amount,
                                    AmountOut: 0,
                                    LocalDateTime: dateStringWithTime,
                                    LocalTimeZoneType: localTimeZoneType,
                                    TimeZoneOffsetValue: getLocalTimeZoneOffsetValue,
                                    SalePersonId: user && user.user_id ? user.user_id : '',
                                    SalePersonName: user && user.display_name ? user.display_name : '',
                                    SalePersonEmail: user && user.user_email ? user.user_email : '',
                                    OliverPOSReciptId: shop_order.content && shop_order.content.tempOrderId && shop_order.content.tempOrderId,
                                    PaymentTypeName: item.payment_type,
                                    PaymentTypeSlug: item.payment_type,
                                    EODReconcilliation: true,
                                    Notes: item.description
                                })
                            }
                        })
                        if (paymentLog.length > 0) {
                            store.dispatch(addPaymentListLog(paymentLog));
                        }
                    }
                    //----------------------------------------------------------

                } catch (error) {
                    console.log("cashManagementLog Error", error)
                }



                //update Refund qty for product......................
                var ids = []
                shopOrder.line_items && shopOrder.line_items.map(value => {
                    var pid = value.variation_id == 0 ? value.product_id : value.variation_id ? value.variation_id : value.WPID
                    ids.push(pid);
                })
                if (ids.length > 0) { store.dispatch(UpdateProductInventoryDB(ids)) }
                //----------------------------------------------------  
                setTimeout(function () {
                    if (shop_order.content && shop_order.content.tempOrderId && shop_order.content.tempOrderId != '') {
                        localStorage.setItem('tempOrder_Id', JSON.stringify(shop_order.content.tempOrderId)); 
                        // inventory check lay-away and park sale
                        var pending_payments = localStorage.getItem('PENDING_PAYMENTS') ? JSON.parse(localStorage.getItem('PENDING_PAYMENTS')) : null
                        var checklist = localStorage.getItem('CHECKLIST') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null
                        var list_item = []
                        var itemExists = []
                        if (pending_payments && checklist && pending_payments.orderId == checklist.orderId) {
                            pending_payments && pending_payments.ListItem && pending_payments.ListItem.filter((pending) => {
                                return checklist && checklist.ListItem && checklist.ListItem.filter((check) => {
                                    if (pending.line_item_id == check.line_item_id) {
                                        itemExists.push(pending)
                                        return pending
                                    }
                                })
                            })
                            list_item = pending_payments && pending_payments.ListItem && pending_payments.ListItem.filter((item) => !itemExists.includes(item))

                            checklist && checklist.ListItem && checklist.ListItem.map((itm) => {
                                list_item && list_item.map((list) => {
                                    var checkProId = itm.variation_id == 0 ? itm.product_id : itm.variation_id ? itm.variation_id : itm.product_id
                                    var listProId = list.variation_id == 0 ? list.product_id : list.variation_id ? list.variation_id : list.product_id

                                    if (checkProId == listProId && list.quantity && itm.quantity && list.quantity > itm.quantity) {
                                        if (list.quantity > itm.quantity) {
                                            list.quantity = list.quantity - itm.quantity
                                        }
                                    }
                                })
                            })
                        }
                        //**************  ************//
                        var TempOrders = [];
                        if (localStorage.getItem(`TempOrders_${ActiveUser.key.Email}`)) {
                            TempOrders = JSON.parse(localStorage.getItem(`TempOrders_${ActiveUser.key.Email}`));
                        }
                        var resyncOrder=false;
                         if(TempOrders && TempOrders.length>0  ){
                             TempOrders.map(o=>{
                              if(o.TempOrderID==shop_order.content.tempOrderId && o.order_status=='incompleted' && o.order_status_DB=='resync'){
                                o.order_status='completed';
                                o.order_status_DB= shopOrder.status;
                                resyncOrder=true;
                              }
                            })
                         }
                         if(resyncOrder ==false){
                            TempOrders.push({ "TempOrderID": shop_order.content.tempOrderId, "Status": "false", "Index": TempOrders.length, "OrderID": 0, 'order_status': "completed", 'date': moment().format(Config.key.NOTIFICATION_FORMAT), 'Sync_Count': 0, 'new_customer_email': '', 'isCustomerEmail_send': false, 'order_status_DB': shopOrder.status });  //order_status_DB is added for app command order status
                        }
                        localStorage.setItem(`TempOrders_${ActiveUser.key.Email}`, JSON.stringify(TempOrders));
                        //-----------------------------------------------------------------------
                    }
                    // path = 1  is payment complete
                    // path = 2  is payments lay.park sale
                    if ( !path) { //for order re-process
                        // do nothing
                    }
                    if (path == 1) {
                        var OrderID;
                        //Call GTM Checkout---------------------------
                        if (process.env.NODE_ENV == 'production') {
                            //GTM_oliverOrderComplete()
                        }
                        //trackOliverOrderComplete();
                        //------------------------------------------------
                        localStorage.setItem("ORDER_ID", JSON.stringify(0));
                        if (JSON.parse(localStorage.getItem("user")).display_sale_refund_complete_screen == false) {
                            localStorage.removeItem('PRODUCT');
                            localStorage.removeItem("CART");
                            localStorage.removeItem('CHECKLIST');
                            localStorage.removeItem('AdCusDetail');
                            localStorage.removeItem('CARD_PRODUCT_LIST');
                            localStorage.removeItem("PRODUCTX_DATA");
                            localStorage.removeItem("PENDING_PAYMENTS");                           

                        } 
                      
                    } else {
                        localStorage.removeItem("PRODUCTX_DATA");
                        localStorage.removeItem('PRODUCT');
                        localStorage.removeItem("CART");
                        localStorage.removeItem('CHECKLIST');
                        localStorage.removeItem('AdCusDetail');
                        localStorage.removeItem('CARD_PRODUCT_LIST');
                      
                    }
                }, 500)                
            }
            console.log("order response",shop_order)
            return shop_order;
        });
}
export function paymentAmountAPI(amount) {
    return amount;
}
export function changeReturnAmountAPI(amount) {
    return amount;
}
export function checkTempOrderSyncAPI(tempOrderId) {
    if (tempOrderId == "clear") {
        return null;
    }
    var notificationLimit = Config.key.NOTIFICATION_LIMIT;
    return serverRequest.clientServiceRequest('GET', `/orders/IsSynced?OrderId=${tempOrderId}`, '')
        .then(order_status => {
            // Update Temp Order Status------------------------------------
            var TempOrders = localStorage.getItem(`TempOrders_${ActiveUser.key.Email}`) ? JSON.parse(localStorage.getItem(`TempOrders_${ActiveUser.key.Email}`)) : [];
            if (TempOrders && TempOrders.length > 0) {
                if (TempOrders.length > notificationLimit) {
                    TempOrders.splice(0, 1);
                }
                TempOrders.map(ele => {
                    if (ele.TempOrderID == tempOrderId) {
                        if (order_status && order_status.message == "Success") {

                            var placedOrderList = localStorage.getItem('placedOrderList') ? JSON.parse(localStorage.getItem('placedOrderList')) : "";
                            if (placedOrderList) {
                                //for order complete then call api for pruduct quantity update
                                localStorage.removeItem('placedOrderList')
                                var ids = []
                                placedOrderList.map(value => {
                                    var pid = value.variation_id == 0 ? value.product_id : value.variation_id ? value.variation_id : value.WPID
                                    ids.push(pid);
                                })
                                if (ids.length > 0) { store.dispatch(UpdateProductInventoryDB(ids)) }
                            }
                            ele.Status = "true";
                            if (order_status.content && order_status.content.hasOwnProperty('OrderNumber')) {
                                ele.OrderID = order_status.content.OrderNumber;
                            }
                            else if (order_status.content) {
                                ele.OrderID = order_status.content;
                            }

                            ele.Sync_Count = ele.Sync_Count + 1
                        }
                        else {
                            ele.Sync_Count = ele.Sync_Count + 1
                            if (ele.Sync_Count === Config.key.SYNC_COUNT_LIMIT)
                                ele.Status = "failed";
                            //  recheckTempOrderSync(udid,ele.TempOrderID)
                        }
                    }
                })
                localStorage.setItem(`TempOrders_${ActiveUser.key.Email}`, JSON.stringify(TempOrders))
            }
            //--------------------------------------------------------------
          return order_status;
        });

}
export function checkTempOrderStatusAPI(tempOrderId) {
    var notificationLimit = Config.key.NOTIFICATION_LIMIT;
    return serverRequest.clientServiceRequest('GET', `/orders/Status?OrderId=${tempOrderId}`, '')
        .then(order_status => {
            // Update Temp Order Status------------------------------------
            var TempOrders = localStorage.getItem(`TempOrders_${ActiveUser.key.Email}`) ? JSON.parse(localStorage.getItem(`TempOrders_${ActiveUser.key.Email}`)) : [];
            if (TempOrders && TempOrders.length > 0) {
                if (TempOrders.length > notificationLimit) {
                    TempOrders.splice(0, 1);
                }
                TempOrders.map(ele => {
                    if (ele.TempOrderID == tempOrderId) {
                        if (order_status && order_status.message == "Success") {

                            var placedOrderList = localStorage.getItem('placedOrderList') ? JSON.parse(localStorage.getItem('placedOrderList')) : "";
                            if (placedOrderList) {
                                //for order complete then call api for pruduct quantity update
                                localStorage.removeItem('placedOrderList')
                                var ids = []
                                placedOrderList.map(value => {
                                    var pid = value.variation_id == 0 ? value.product_id : value.variation_id ? value.variation_id : value.WPID
                                    ids.push(pid);
                                })
                                if (ids.length > 0) { store.dispatch(UpdateProductInventoryDB(ids)) }
                            }
                            ele.Status = "true";
                            ele.OrderID = order_status.content.OrderNumber;
                            console.log("OrderStatusSuccess", ele);
                            ele.Sync_Count = ele.Sync_Count + 1
                        }
                        else {
                            ele.Sync_Count = ele.Sync_Count + 1
                            if (ele.Sync_Count === Config.key.SYNC_COUNT_LIMIT)
                                ele.Status = "failed";
                            console.log("OrderStatusFailed", ele);
                            //  recheckTempOrderSync(udid,ele.TempOrderID)
                        }
                    }
                })
                localStorage.setItem(`TempOrders_${ActiveUser.key.Email}`, JSON.stringify(TempOrders))
            }
            //--------------------------------------------------------------
            console.log("orderStatus", order_status);
            return order_status;
        });

}

export function orderToVoidAPI(order_id, udid, WarehouseId) {
    return serverRequest.clientServiceRequest('GET', `/orders/VoidSale?udid=${udid}&OrderId=${order_id}&WarehouseId=${WarehouseId}`, '')
        .then(res => {
            localStorage.removeItem('PRODUCT');
            localStorage.removeItem("CART");
            localStorage.removeItem('CHECKLIST');
            localStorage.removeItem('AdCusDetail');
            localStorage.removeItem('CARD_PRODUCT_LIST');
            localStorage.removeItem('oliver_order_payments');
            return res
        })
}
export function orderToCancelSaleAPI(order_id, udid, WarehouseId) {
    return serverRequest.clientServiceRequest('GET', `/orders/CancelledSale?udid=${udid}&OrderId=${order_id}&WarehouseId=${WarehouseId}`, '')
        .then(res => {
            return res
        })
}