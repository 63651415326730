import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import LogOut_Icon_Blue from '../../../assets/images/svg/LogOut-Icon-Blue.svg';
import OliverIconError from '../../../assets/images/svg/OliverIconError.svg';
import { userLogin } from "../../login/loginSlice";
import { validatePin } from "../../pinPage/pinSlice";
import googlePlay from '../../../assets/images/svg/GooglePlay.svg';
import AppleStore from '../../../assets/images/svg/AppleStore.svg';

const EnableBrowserNotification = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [hasPermission, setHasPermission] = useState(false);
    const [step, setStep] = useState(1);
    const [page, setPage] = useState("");

    useEffect(() => {
        var queryParameters = new URLSearchParams(window.location.search);
        const _page = queryParameters.get("page");
        if (_page !== null) {
            setPage(_page);
            Notification.requestPermission().then(function (permission) {
                console.log('permiss', permission)
                if (permission == "denied") {
                    // setHasPermission(false)
                    localStorage.setItem("hasNotifyPermission", false)
                    // setTimeout(() => {
                     
                    //         setStep(3);
                        
                    // }, 500);
                }
                else if (permission == "granted") {
                    localStorage.setItem("hasNotifyPermission", true)
                    // setHasPermission(true)
                    setTimeout(() => {                      
                            navigate("/" + _page)                       
                    }, 500);
                }
            });
        }
    }, []);

    // const checkPermission = () => {
    //     Notification.requestPermission().then(function (permission) {
    //         console.log('permiss', permission)
    //         if (permission == "denied") {
    //             setHasPermission(false)
    //             localStorage.setItem("hasNotifyPermission", false)
    //         }
    //         else if (permission == "granted") {
    //             localStorage.setItem("hasNotifyPermission", true)
    //             setHasPermission(true)
    //         }
    //     });
    // }
    // function notifyMe() {
    //     if (!("Notification" in window)) {
    //         // Check if the browser supports notifications
    //         alert("This browser does not support desktop notification");
    //     } else if (Notification.permission === "granted") {
    //         // Check whether notification permissions have already been granted;
    //         // if so, create a notification
    //         const notification = new Notification("Hi there!");
    //         // …
    //     } else if (Notification.permission !== "denied") {
    //         // We need to ask the user for permission
    //         Notification.requestPermission().then((permission) => {
    //             // If the user accepts, let's create a notification
    //             if (permission === "granted") {
    //                 // const notification = new Notification("Hi there!");
    //                 const notification1 = new Notification('Notification title', {
    //                     icon: 'http://cdn.sstatic.net/stackexchange/img/logos/so/so-icon.png',
    //                     body: 'Hey there! You\'ve been notified!',
    //                 });
    //                 // …
    //             }
    //         });
    //     }

    //     // At last, if the user has denied notifications, and you
    //     // want to be respectful there is no need to bother them anymore.
    // }
    const logout = () => {
        var isDemoUser = localStorage.getItem('demoUser')
        var clientDetail = localStorage.getItem('clientDetail')
        if (isDemoUser == 'true' || !clientDetail) {
            //history.push('/login');
            const Android = window.Android;
            if ((typeof Android !== "undefined" && Android !== null) && (Android.getDatafromDevice("isWrapper") == true)) {
                Android.wrapperLogout();
            }
            else {
                // sessionStorage.removeItem("AUTH_KEY");
                localStorage.removeItem("AUTH_KEY")
                localStorage.removeItem("remember_data");
                navigate("/login");
            }
        } else {
            const Android = window.Android;
            if ((typeof Android !== "undefined" && Android !== null) && (Android.getDatafromDevice("isWrapper") == true)) {
                Android.wrapperLogout();
            }
            else {
                // sessionStorage.removeItem("AUTH_KEY");
                localStorage.removeItem("AUTH_KEY")
                localStorage.removeItem("remember_data");
                navigate("/login");
            }

        }
        dispatch(validatePin(null))
    }
    const goUnderstood = () => {
        setStep(2);

        Notification.requestPermission().then(function (permission) {
            console.log('permiss', permission)
            if (permission == "denied") {
                // setHasPermission(false)
                localStorage.setItem("hasNotifyPermission", false)
            }
            else if (permission == "granted") {
                localStorage.setItem("hasNotifyPermission", true)
                // setHasPermission(true)
            }
            setTimeout(() => {
                if (permission == "denied") {
                    setStep(3);
                }
                else {
                    navigate("/" + page)
                }
            }, 500);
        });

        // setTimeout(() => {
        //     console.log(localStorage.getItem("hasNotifyPermission"))
        //     if (!localStorage.getItem("hasNotifyPermission") || localStorage.getItem("hasNotifyPermission") == false)
        //         setStep(3)
        // }, 1000);
    }
    const reDirect = () => {
        setStep(1)
    }


    function isSafari() {
        // Get the user agent string
        const userAgent = window.navigator.userAgent.toLowerCase();
      
        // Check if "safari" is present in the user agent string, and it's not "chrome" (to avoid Chrome on iOS)
        return userAgent.includes('safari') && !userAgent.includes('chrome');
    }
    

    return <div className={"subwindow-wrapper"}>
        <div className={"subwindow logout-confirm current"}>
            <div className="idle-register-wrapper">
                <button id="logoutRegisterButton" onClick={() => logout()}>
                    <img src={LogOut_Icon_Blue} alt="" />
                    {LocalizedLanguage.logout}
                </button>
            </div>

            {
                isSafari() === true ? (
                    <div className="subwindow-body" id="notification-first">
                    <div className="auto-margin-top"></div>
                    <div className="error-404-wrapper">
                        <div className="error-404-container">
                            <img src={OliverIconError} alt="" />
                            <p className="style1 style1-md">Browser not supported</p>
                        <p className="style3">Please use the latest version of Chrome, Opera, Edge or Mozilla Firefox to use Oliver in <strong>browser mode.</strong></p>
                        
                        <p className="style4">For the best experience please download our app</p>
                    <div className="buttons-container">
                        <a href="https://apps.apple.com/it/app/oliver-pos-3-0/id6449044445" className="download-app-btn">
                            <span className="span--img">
                                    <img src={AppleStore } />
                            </span>
                            <span className="span-text-sm">Download on the</span>
                            <span className="span-text-lg">App Store</span>
                            
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.oliverpos.oliverpay&pcampaignid=web_share&hl=en&pli=1" className="download-app-btn">
                            <span className="span--img">
                                    <img src={ googlePlay} />
                            </span>
                            <span className="span-text-sm">Get it on</span>
                            <span className="span-text-lg">Google Play</span>
                            
                        </a>     
                    </div>    
                        </div>
                    </div>
                    <div className="auto-margin-bottom"></div>
                </div>
                ): step ==  1 &&
            
                <div className="subwindow-body" id="notification-first">
                    <div className="auto-margin-top"></div>
                    <p className="style1">You must enable notifications</p>
                    <p className="style2">
                        In order to use the Oliver Register, you need to enable
                    </p>
                    <p className="style2">
                        notifications in your browser. This allows Oliver POS to stay in-sync
                    </p>
                    <p className="style2">
                        with your WooCommerce store.
                    </p>
                    {
                        isSafari() === true && (
                            <p className="style2" style={{
                                marginTop:"16px"
                            }}>For better experiance use chrome, opera, edge or mozilla</p>
                        )
                    }
                    <button id="registerLogout" onClick={() => goUnderstood()}>Understood</button>
                    <div className="auto-margin-bottom"></div>
                </div>
                
            }
            
           
            {step == 2 &&
                <div className="subwindow-body" id="notification-second">
                    <div className="auto-margin-top"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" x={0} y={0} width={85} height={85} viewBox="0 0 400 400" enableBackground="new 0 0 400 400">
                        <g>
                            <rect x="249.28" y="156.01" className="st0 ologo-1" width="103.9" height="103.9" />
                            <path id="teal" className="st1 ologo-2" d="M249.28,363.81V259.91h103.9C353.17,317.29,306.66,363.81,249.28,363.81z" />
                            <rect id="cyan" x="145.38" y="259.91" className="st2 ologo-3" width="103.9" height="103.89" />
                            <path id="blue" className="st3 ologo-4" d="M41.49,259.91L41.49,259.91h103.9v103.89C88,363.81,41.49,317.29,41.49,259.91z" />
                            <rect id="purple" x="41.49" y="156.01" className="st4 ologo-5" width="103.9" height="103.9" />
                            <path id="red" className="st5 ologo-6" d="M41.49,156.01L41.49,156.01c0-57.38,46.52-103.9,103.9-103.9v103.9H41.49z" />
                            <rect id="orange" x="145.38" y="52.12" className="st6 ologo-7" width="103.9" height="103.9" />
                            <path id="yellow" className="st7 ologo-8" d="M281.3,123.99V20.09c57.38,0,103.9,46.52,103.9,103.9H281.3z" />
                        </g>
                    </svg>
                    <p className="style1">Checking to make sure<br></br> notifications are enabled...</p>
                    <div className="auto-margin-bottom"></div>
                </div>}
            {step == 3 &&
                <div className="subwindow-body" id="notification-third">
                    <div className="auto-margin-top"></div>
                    <div className="error-404-wrapper">
                        <div className="error-404-container">
                            <img src={OliverIconError} alt="" />
                            <p className="style1">Notications not enabled</p>
                            {/* <p className="style2">404 Error</p> */}
                            <p className="style3">{"If no pop-up appears, your browser may have blocked it. Please go to your browser settings to enable notifications."}</p>
                            <button onClick={() => reDirect()}>Go Back</button>
                            <div className="row">
                                <p>Having issues?</p>
                                <a href="https://oliverpos.com/contact-oliver-pos/">Contact us.</a>
                            </div>
                        </div>
                    </div>
                    <div className="auto-margin-bottom"></div>
                </div>}
        </div>
    </div>
}

export default EnableBrowserNotification 