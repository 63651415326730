import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { isAutoFocus } from "../../../settings/AndroidIOSConnect";
const ProductNote = (props) => {
    const refTextAreaNote = useRef();
    const [note, setNote] = useState('');

    const handleNote = () => {
        if (note != "" && props && props.addNote) {
            props.addNote(note);
            setNote('');
        }
    }

    useEffect(() => {
        if (refTextAreaNote) {
            if (isAutoFocus() == true)
            refTextAreaNote && refTextAreaNote.current && refTextAreaNote.current.focus();
        }
    }, [props])

    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleProductNote();
        }
    }
    return (
        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className={props.isShow === true ? "subwindow add-order-note current" : "subwindow add-order-note"}>
                <div className="subwindow-header">
                    <p>{LocalizedLanguage.addproductnote}</p>
                    <button className="close-subwindow" onClick={() => props.toggleProductNote()}>
                        <img src={X_Icon_DarkBlue} alt="" />
                    </button>
                </div>
                <div className="subwindow-body">
                    <div className="auto-margin-top"></div>
                    <label htmlFor="productNote">{LocalizedLanguage.enternoteproduct}:</label>
                    <textarea ref={refTextAreaNote} id="productNote" placeholder={LocalizedLanguage.adnoteproduct} value={note} onChange={(e) => setNote(e.target.value)}></textarea>
                    <button onClick={() => handleNote()}>{LocalizedLanguage.addNote}</button>
                    <div className="auto-margin-bottom"></div>
                </div>
            </div>
        </div>)
}

export default ProductNote 