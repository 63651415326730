import React, { useEffect, useLayoutEffect } from "react";
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
const VoidSaleConfirmationPopup = (props) => {
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleVoidSaleConfirmation();
        }
    }

    return (

        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className={props.isShow === true ? "subwindow delete-customer-confirm current" : "subwindow delete-customer-confirm"}>
                    <div className="subwindow-header">
                        <p>{LocalizedLanguage.voidSale}</p>
                        <button className="close-subwindow" onClick={() => props.toggleVoidSaleConfirmation()}>
                            <img src={X_Icon_DarkBlue} alt="" />
                        </button>
                    </div>
                    <div className="subwindow-body">
                        <div className="auto-margin-top" />
                        <p>{LocalizedLanguage.voidSaleMsg}</p>
                        <button id="deleteCustomer" onClick={() => props.voidSale()} >{LocalizedLanguage.voidSale}</button>
                        {/* <button id="cancelDeleteCustomer" onClick={() => props.toggleConfirmPopUp()}>Cancel</button> */}
                        <div className="auto-margin-bottom"/>
                    </div>
            </div></div >
    )
}

export default VoidSaleConfirmationPopup