import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { useLoginMutation,useGetAllRegisterQuery } from '../../../components/pin/loginService';
import { pinAPI, createPinAPI } from './pinAPI';
import STATUSES from '../../constants/apiStatus';


const initialState = {
  "status": STATUSES.IDLE,
  "data": "",
  "error": '',
  "is_success": false
};


export const validatePin = createAsyncThunk(
  'pin/pinAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      if (parameter == "clear" || parameter == null) {
        initialState['status'] = STATUSES.IDLE;
        return initialState;
      }
      const response = await pinAPI(parameter);
      // The value we return becomes the `fulfilled` action payload
      return response;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data)
    }
  }
);
export const createPin = createAsyncThunk(
  'pin/createPinAPI',
  async (parameter, { rejectWithValue }) => {
    try {
      const response = await createPinAPI(parameter);
      // The value we return becomes the `fulfilled` action payload
      return response;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data)
    }
  }
);
export const pinSlice = createSlice({
  name: 'pin',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // loginPanding: (state) => {
    //   state.status = STATUSES.LOADING;
    //   state.data = ""
    // },
    // loginSuccess: (state, action) => {
    //   state.status = STATUSES.IDLE;
    //   state.error = '';
    //   state.data = action.payload
    // },
    // loginFail: (state, action) => {
    //   state.status = STATUSES.ERROR;
    //   state.data = "";
    //   state.error = action.payload;
    // }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: () => {}
  extraReducers: (builder) => {
    builder
      .addCase(validatePin.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(validatePin.fulfilled, (state, action) => {
        state.status = action.payload && action.payload.is_success === true ? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload.is_success === true ? action.payload : "");
        state.error = action.payload && action.payload.is_success === true ? "" : action.payload && action.payload.message ? action.payload.message : '';
        state.is_success = action.payload && action.payload.is_success === true ? true : false;
      })
      .addCase(validatePin.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      })
      //Create Pin------------------------------
      .addCase(createPin.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.data = "";
        state.error = "";
        state.is_success = false;
      })
      .addCase(createPin.fulfilled, (state, action) => {
        state.status = action.payload && action.payload.is_success === true ? STATUSES.IDLE : STATUSES.ERROR;
        state.data = (action.payload && action.payload.is_success === true ? action.payload : "");
        state.error = action.payload && action.payload.is_success === false ? action.payload.exceptions[0] : action.payload ? "Fail to fetch" : "";;
        state.is_success = action.payload && action.payload.is_success === true ? true : false;
      })
      .addCase(createPin.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = "";
        state.error = action.error;
        state.is_success = false;
      });
  },
});

export const { loginPanding, loginSuccess, loginFail } = pinSlice.actions;

// // The function below is called a selector and allows us to select a value from
// // the state. Selectors can also be defined inline where they're used instead of
// // in the slice file. For example: `useSelector((state: RootState) => state.pin.value)`
// export const selectCount = (state) => state.pin.value;

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default pinSlice;
