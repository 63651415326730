// A mock function to mimic making an async request for data
import { serverRequest } from '../../CommonServiceRequest/serverRequest'
export function externalLoginAPI(parameter) {  

    return serverRequest.clientServiceRequest('GET', `/account/ExternalLogin?${parameter}`, '' )
        .then(userRes => {
            //var data = userRes.content;
            return userRes;
        }).catch(error => {
            return error
        });
}
