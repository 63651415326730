
import { useDispatch, useSelector } from 'react-redux';
import ActiveUser from '../../settings/ActiveUser';
import { save } from './checkoutSlice';
import STATUSES from '../../constants/apiStatus';
import { useEffect } from 'react';

const ResyncOrder=()=>{    
    const dispatch=useDispatch();
    var callOnce=false;
    useEffect(()=>{
        if(callOnce==false){     
        setTimeout(() => {
           if( window.location.pathname !=="/checkout" && window.location.pathname !=="/salecomplete" ){
              reSyncCall();   
             }
        }, 4000);     
                 
            callOnce=true;
    }
    },[])
    const reSyncCall=()=>{
        var clientEmail= ActiveUser.key.Email;
        var TempOrders = [];
        if (localStorage.getItem(`resync_orders_${clientEmail}`)) {
            TempOrders = JSON.parse(localStorage.getItem(`resync_orders_${clientEmail}`));
        }
        if(TempOrders.length>0){ console.log("resync order")
            dispatch(save(TempOrders[0]));
        }
    }
   
  //**CHECK STOCK IF THE SALE STATUS IS PENDING/PARK/ OR LAYAWAY 23-DEC-2022-----END--
  const [resSave] = useSelector((state) => [state.save])
    useEffect(() => {
      if (resSave && resSave.status == STATUSES.IDLE && resSave.is_success) {
        var clientEmail= ActiveUser.key.Email;
        var TempOrders = [];
        if (localStorage.getItem(`resync_orders_${clientEmail}`)) {
            TempOrders = JSON.parse(localStorage.getItem(`resync_orders_${clientEmail}`));
        }
        var isFound=  TempOrders && TempOrders.length>0 && TempOrders.find(i=>i.oliver_pos_receipt_id ==resSave.data.content.tempOrderId);
        if(isFound){           
            const index = TempOrders.indexOf(isFound);
            if (index > -1) { 
                TempOrders.splice(index, 1); // 2nd parameter means remove one item only
                localStorage.setItem(`resync_orders_${clientEmail}`, JSON.stringify(TempOrders)); 
                if(TempOrders.length>0){
                    setTimeout(() => {
                        reSyncCall();
                    }, 500);
                    
                }
               
            }
       
      }
      else if (resSave && resSave.status == STATUSES.ERROR && resSave.is_success === false) {
          //console.log("error message---" + resSave.data);
       
      }
    }
  }, [resSave]);   

return(
    <div style={{display:"none"}}></div>
)
}
export default ResyncOrder;