import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux';
import X_Icon_DarkBlue from '../../../../assets/images/svg/X-Icon-DarkBlue.svg';
import Checkout_Minus from '../../../../assets/images/svg/Checkout-Minus.svg';
import Checkout_Plus from '../../../../assets/images/svg/Checkout-Plus.svg';
import STATUSES from "../../../../constants/apiStatus";
import { typeOfTax } from "../../TaxSetting";
import { v4 as uuidv4 } from 'uuid';
import { NumericFormat } from "react-number-format";
import LocalizedLanguage from "../../../../settings/LocalizedLanguage";
import { currecyFunction } from "../../commonFunctions/currencyFunction";
const SplitByProduct = (props) => {
    const [listItem, setListItem] = useState(props.listItem);
    const [total, setTotal] = useState(0.00);
    const [resProduct] = useSelector((state) => [state.product])
    // const [getorder, setGetorder] = useState(localStorage.getItem("getorder") ? JSON.parse(localStorage.getItem("getorder")) : null);


    useEffect(() => {
        if (resProduct && resProduct.status === STATUSES.IDLE && resProduct.is_success) {
            var _list = [...resProduct.data];
            if (_list && _list.length > 0) {

                _list.map(item => {
                    if (localStorage.getItem('paybyproduct')) {
                        var paybyproduct = JSON.parse(localStorage.getItem('paybyproduct'));

                        // var _paid = paybyproduct.find(a => a.product_id === item.product_id);

                        const _paid = paybyproduct.find((i) => {
                            return i.product_id === item.product_id ? i.product_id === item.product_id : i.product_id === (item.pid + item.Title)
                        })

                        if (_paid) {
                            var _item = { ...item };
                            var _index = _list.indexOf(item, 0);
                            _item["paid_quantity"] = _item.quantity - _paid.quantity;
                            _item["unpaid_qty"] = -1;


                            if (_index >= 0) { _list[_index] = _item; }
                        }
                    }
                });
            }
            console.log(_list)
            setListItem(_list);

        }

    }, [resProduct]);



    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleSplitByProduct && props.toggleSplitByProduct();
        }
    }

    const updateQuantity = (item, type) => {
        var id = item.product_id;
        let _temp;

        var _listItem = [...listItem];
        var modifiers = _listItem.filter(a => ((item.hasOwnProperty("product_id") && a.pid == item.product_id) || (item.hasOwnProperty("variation_id") && a.pid == item.variation_id))
            && (a.hasOwnProperty("pid") && a.pid == 0 ? a.note == item.note : true)
            && ((a.hasOwnProperty("fee_id") && item.hasOwnProperty("fee_id")) ? a.fee_id == item.fee_id : true)
            && ((a.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key")) ? a.modifiers_key == item.modifiers_key : true)
        )
        var _modifiers = JSON.parse(JSON.stringify(modifiers));
        if (item.hasOwnProperty("fee_id")) {
            _modifiers = [];
        }
        if (_listItem) {
            _listItem.find((a) => {
                if (((item.hasOwnProperty("product_id") && a.product_id === id && ((a.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key")) ? a.modifiers_key == item.modifiers_key : true)) || (item.hasOwnProperty("pid") && a.pid === item.pid && a.Title === item.Title)) ||(!item.hasOwnProperty("pid") && !item.hasOwnProperty("modifiers_key") &&  item.hasOwnProperty("Price")  && a.Title === item.Title)) {
                    _temp = a
                }
            })

        }

        // var _temp = _listItem && _listItem.find(a =>
        //     (item.hasOwnProperty("product_id") && a.product_id === id) ||
        //     (item.hasOwnProperty("pid") && a.pid === item.pid && a.Title === item.Title) ||
        //     (!id && a.Title === item.Title));

        if (_temp && typeof _temp != "undefined") {
            var _index = _listItem.indexOf(_temp, 0);
            var _item = { ..._temp };
            if (_item.hasOwnProperty("quantity_to_pay")) {
                if (type === "inc") {
                    if (((_item.hasOwnProperty('paid_quantity') ? Math.abs(_item.paid_quantity) : _item.quantity)) > _item.quantity_to_pay) {
                        _item.quantity_to_pay = _item.quantity_to_pay + 1;
                        _item.unpaid_qty = _item.unpaid_qty + 1;

                        _modifiers.map(a => {
                            a.quantity_to_pay = a.quantity_to_pay + 1;
                            a.unpaid_qty = a.unpaid_qty + 1;
                        });
                    }

                }
                else if (type === "dec") {
                    if (_item.quantity_to_pay > 0) {
                        _item.quantity_to_pay = _item.quantity_to_pay - 1;
                         _item.unpaid_qty = _item.unpaid_qty - 1;
                        _modifiers.map(a => {
                            a.quantity_to_pay = a.quantity_to_pay - 1;
                            a.unpaid_qty = a.unpaid_qty - 1;
                        });

                    }
                    else { console.log("it can note be less than one") }
                }
            }
            else {
                if (type === "inc") {
                    if (_item.hasOwnProperty("paid_quantity") && _item.paid_quantity == 0) {
                        _item["quantity_to_pay"] = 0
                        _item["unpaid_qty"] = 0;

                        _modifiers.map(a => {
                            a["quantity_to_pay"] = 0;
                            a["unpaid_qty"] = 0;
                        });
                    }
                    else {
                        _item["quantity_to_pay"] = 1
                        _item["unpaid_qty"] = 1;

                        _modifiers.map(a => {
                            a["quantity_to_pay"] = 1;
                            a["unpaid_qty"] = 1;
                        });
                    }
                }
            }
            modifiers && modifiers.map((m, index) => {
                const pos = _listItem.indexOf(m);
                if (_modifiers && _modifiers.length > 0 && _modifiers.length > index) {
                    _listItem[pos] = _modifiers[index];
                   // console.log("-----modifier index position--->" + m.Title + "--->" + _modifiers[index]);
                }
            })
            if (_index >= 0) {
                _listItem[_index] = _item;
            }

        }

        calculateCart(_listItem);
    }

    const saveCount = () => {
        var taxInclusiveName = typeOfTax();
        var _paybyproduct = [];
        var paybyproduct = [];
        var checklist = localStorage.getItem("CHECKLIST") ? JSON.parse(localStorage.getItem("CHECKLIST")) : null;
        var total_amount = checklist && checklist.totalPrice;
        var cash_rounding_amount = checklist && checklist.cash_rounding_amount;
        var _listItem = checklist && checklist.ListItem;

        listItem && listItem.map((item, index) => {
            if (localStorage.getItem('paybyproduct')) {
                paybyproduct = JSON.parse(localStorage.getItem('paybyproduct'));


                if (item.quantity_to_pay != null && typeof item.quantity_to_pay != "undefined") {


                    var product_tax = 0.00;
                    var product_total = 0.00;
                    //--------
                    var product_subtotal = (item.Price / item.quantity) * item.quantity_to_pay;
                    var discount_amount = 0;
                    if (item.hasOwnProperty("discount_amount") && typeof item.discount_amount != "undefined") {
                        discount_amount = (item.discount_amount / item.quantity) * item.quantity_to_pay;
                        product_subtotal = product_subtotal - discount_amount;
                    }

                    if (taxInclusiveName === "incl") {
                        product_tax += (parseFloat(item.incl_tax / item.quantity) * item.quantity_to_pay);
                    }
                    else if (taxInclusiveName === "Tax") {
                        product_tax += (parseFloat(item.excl_tax / item.quantity) * item.quantity_to_pay);
                    }
                    if (taxInclusiveName && (taxInclusiveName !== "" && taxInclusiveName !== "incl")) { //in inclusive tax need to add tax intosub total
                        product_subtotal += product_tax;
                    }
                    product_total = (parseFloat(product_subtotal) + parseFloat(taxInclusiveName === "" ? product_tax : 0)); //added tax for exclusive tax
                    if (product_total + (cash_rounding_amount) === total_amount) {
                        product_total = product_total + (cash_rounding_amount)
                    }

                    var _paid = paybyproduct.find(a => a.product_id === item.product_id);


                    if (_paid) {
                        var _index = paybyproduct.indexOf(_paid, 0);
                        _paid.quantity = _paid.quantity + item.quantity_to_pay;
                        _paid["tax"] = product_tax;
                        _paid["total"] = product_total;
                        _paid["sub_total"] = product_subtotal;
                        _paid["unpaid_qty"] = item.quantity_to_pay;
                        paybyproduct[_index] = _paid;
                        _paybyproduct.push(_paid);

                    }
                    else {

                        // product_id: item.product_id ? item.product_id : (item.pid + item.Title)

                        _paybyproduct.push({ product_id: item.product_id ? item.product_id : (item.pid + item.Title), quantity: item.quantity_to_pay, tax: product_tax, total: product_total, sub_total: product_subtotal, unpaid_qty: item.quantity_to_pay });

                    }
                }
                else {
                    const _item = paybyproduct.find((i) => {
                        return i.product_id === item.product_id ? i.product_id === item.product_id : i.product_id === (item.pid + item.Title)
                    })

                    // product_id: item.product_id ? item.product_id : (item.pid + item.Title)
                    // quantity:_item.quantity

                    _paybyproduct.push({ product_id: item.product_id ? item.product_id : (item.pid + item.Title), quantity: _item.quantity, tax: 0, total: 0, sub_total: 0, unpaid_qty: -1 });
                }
            }
            else {
                //--------
                var product_subtotal = 0.00;
                var product_tax = 0.00;
                var product_total = 0.00;
                var qty = 0;
                var unpaidQty = -1;

                if (item.hasOwnProperty("quantity_to_pay")) {
                    product_subtotal = (item.Price / item.quantity) * item.quantity_to_pay
                    var discount_amount = 0;
                    if (item.hasOwnProperty("discount_amount") && typeof item.discount_amount != "undefined") {
                        discount_amount = (item.discount_amount / item.quantity) * item.quantity_to_pay;
                        product_subtotal = product_subtotal - discount_amount
                    }
                    qty = item.quantity_to_pay;
                    if (taxInclusiveName === "incl") {
                        product_tax += (parseFloat(item.incl_tax / item.quantity) * item.quantity_to_pay);
                    }
                    else if (taxInclusiveName === "Tax") {
                        product_tax += (parseFloat(item.excl_tax / item.quantity) * item.quantity_to_pay);
                    }
                    if (taxInclusiveName && (taxInclusiveName !== "" && taxInclusiveName !== "incl")) { //in inclusive tax need to add tax intosub total
                        product_subtotal += product_tax;
                    }
                    product_total = (parseFloat(product_subtotal) + parseFloat(taxInclusiveName === "" ? product_tax : 0)); //added tax for exclusive tax
                    if (product_total + (cash_rounding_amount) === total_amount) {
                        product_total = product_total + (cash_rounding_amount)
                    }
                    unpaidQty = item.hasOwnProperty("unpaid_qty") ? item.unpaid_qty : -1;
                }


                //product_id: item.product_id ? item.product_id : (item.pid + item.Title)

                _paybyproduct.push({ product_id: item.product_id ? item.product_id : (item.pid + item.Title), quantity: qty, tax: product_tax, total: product_total, sub_total: product_subtotal, unpaid_qty: unpaidQty,is_pid:item.hasOwnProperty("pid")?true:false });

            }
        });
        localStorage.setItem("paybyproduct_unpaid", JSON.stringify(_paybyproduct));
        props.pay_by_product && props.pay_by_product(total);
    }
    const calculateCart = (_listItem) => {
        var product_subtotal = 0.0;
        var product_total = 0.0;
        var product_tax = 0.0;
        var discount_amount = 0.0;
        var getorder = localStorage.getItem("CHECKLIST") ? JSON.parse(localStorage.getItem("CHECKLIST")) : null;
        var total_amount = getorder && getorder.totalPrice;
        var cash_rounding_amount = getorder && getorder.cash_rounding_amount;
        var taxInclusiveName = typeOfTax();

        _listItem && _listItem.map((item, index) => {
            if (item.hasOwnProperty("quantity_to_pay") && item.hasOwnProperty("Price") /*&& item.quantity_to_pay > 0*/) {
                discount_amount = (item.discount_amount / item.quantity) * item.unpaid_qty;
                product_subtotal = (item.Price / item.quantity) * item.unpaid_qty;
                product_subtotal = product_subtotal - discount_amount;
                //if(item.isTaxable===true)
                //{
                if (taxInclusiveName === "incl") {
                    //product_tax += (parseFloat(item.incl_tax / item.quantity) * item.unpaid_qty);
                }
                else if (taxInclusiveName === "Tax") {
                    product_tax += (parseFloat(item.excl_tax / item.quantity) * item.unpaid_qty);
                }

                //}
                if (taxInclusiveName && taxInclusiveName !== "") { //in inclusive tax need to add tax intosub total
                    product_subtotal += product_tax;
                }
                product_total = (parseFloat(product_subtotal) + parseFloat(taxInclusiveName === "" ? product_tax : 0)); //added tax for exclusive tax
                if (product_total + (cash_rounding_amount) === total_amount) {
                    product_total = product_total + (cash_rounding_amount)
                }
            }

        })
        product_total = product_total;
        setTotal(total+Math.abs(product_total) );
        setListItem(_listItem);
        //var _dis = _cartDiscountAmount > 0 ? RoundAmount(_cartDiscountAmount) : 0;
        //var refundlistItem = listItem && listItem.filter(a => a.hasOwnProperty("quantity_to_pay") && a.quantity_to_pay > 0);


        //props.setValues && props.setValues(product_subtotal, RoundAmount(product_tax), _dis, product_total,refundlistItem);
    }
    return (
        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className={props.isShow === true ? "subwindow split-by-product current" : "subwindow split-by-product"}>
                <div className="subwindow-header">
                    <p>{LocalizedLanguage.splitbyproduct}</p>
                    <button className="close-subwindow" onClick={() => props.toggleSplitByProduct()}>
                        <img src={X_Icon_DarkBlue} alt="" />
                    </button>
                </div>
                <div className="subwindow-body">
                    <div className="body">
                        {listItem && listItem.length > 0 && listItem.map(item => {
                            var modifers = listItem && listItem.filter(a => (a.pid == item.product_id || (item.hasOwnProperty("variation_id") && a.pid == item.variation_id)) && item.hasOwnProperty("product_id")
                                && ((a.hasOwnProperty("modifiers_Id") && item.hasOwnProperty("modifiers_Id") ? JSON.stringify(a.modifiers_Id) == JSON.stringify(item.modifiers_Id) : true)
                                    && ((a.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? a.modifiers_key == item.modifiers_key : true)))
                            );
                            if (!item.quantity)  //check for notes
                                return null
                            else if (item.hasOwnProperty("product_id") || !item.hasOwnProperty("pid")) {
                                return <div className="product-row" key={uuidv4()}>
                                    <div className="main-row">
                                        <div className="text-group">
                                            <p>{item.Title}</p>
                                            {item.product_discount_amount != 0 &&
                                                <p>
                                                    {item.hasOwnProperty("quantity_to_pay") ? currecyFunction(item.discount_type == "Number" ? ((item.Price / item.quantity) * item.quantity_to_pay) - (item.product_discount_amount) : ((item.Price / item.quantity) * item.quantity_to_pay) - (item.product_discount_amount * item.quantity_to_pay)) : currecyFunction("0.00")}
                                                </p>
                                            }
                                            {item.product_discount_amount == 0 && <p>{item.hasOwnProperty("quantity_to_pay") ? currecyFunction(parseFloat((item.Price / item.quantity) * item.quantity_to_pay).toFixed(2)) : currecyFunction("0.00")}</p>}
                                            {
                                                modifers.map(m => {
                                                    return (<React.Fragment><p>{m.Title}</p>
                                                        {
                                                            m.product_discount_amount != 0 &&
                                                            <p>
                                                                {m.hasOwnProperty("quantity_to_pay") ? currecyFunction(m.discount_type == "Number" ? ((m.Price / item.quantity) * item.quantity_to_pay) - (m.product_discount_amount) : ((m.Price / item.quantity) * item.quantity_to_pay) - (m.product_discount_amount * item.quantity_to_pay)) : currecyFunction("0.00")}
                                                            </p>
                                                        }
                                                        {m.product_discount_amount == 0 && <p>{m.hasOwnProperty("quantity_to_pay") ? currecyFunction(parseFloat((m.Price / item.quantity) * item.quantity_to_pay).toFixed(2)) : currecyFunction("0.00")}</p>}
                                                    </React.Fragment>)
                                                })
                                            }
                                        </div>
                                        <div className="increment-input">
                                            <button onClick={() => updateQuantity(item, 'dec')}>
                                                <img src={Checkout_Minus} alt="" />
                                            </button>
                                            <input type="text" readOnly value={(item.hasOwnProperty("quantity_to_pay") ? item.quantity_to_pay : "0") + "/" + (item.hasOwnProperty('paid_quantity') ? Math.abs(item.paid_quantity) : item.quantity)} />
                                            <button onClick={() => updateQuantity(item, 'inc')}>
                                                <img src={Checkout_Plus} alt="" />
                                            </button>
                                        </div>
                                    </div></div>
                            }
                            else
                                return ''
                        })}
                    </div>
                    <div className="footer">
                        <button onClick={() => saveCount()} disabled={total > 0 ? false : true} className={total > 0 ? "" : "btn-disable"}>{LocalizedLanguage.saveCount}</button>
                    </div>
                </div>
            </div></div>)
}
export default SplitByProduct 