import { serverRequest } from '../../../CommonServiceRequest/serverRequest';
export function updateOrderStatusAPI(shop_order) {
    if(shop_order=="clear")
    {
        return null;
    }

    return serverRequest.clientServiceRequest('POST', `/orders/ChangeStatus`, shop_order)
        .then(shop_order => {
            return shop_order;
        });
}