import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import IconDarkBlue from '../../assets/images/svg/X-Icon-DarkBlue.svg'
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { updateCreditScore } from './CustomerSlice'
import {popupMessage} from "../common/commonAPIs/messageSlice";
import MsgPopup from "../common/commonComponents/MsgPopup";
import { currecyFunction } from "../common/commonFunctions/currencyFunction";

const AdjustCreditpopup = (props) => {
    const dispatch = useDispatch();
    const [AddCreAmount, setAddCreAmount] = useState('')
    const [DeductCredAMount, setDeductCredAMount] = useState('')
    const [StatusFiled, setStatusFiled] = useState('Add')
    const [Notes, setNotes] = useState('')
    const [isShowMsg, setisShowMsg] = useState(false);
    const [msgTitle, setmsgTitle] = useState('');
    const [msgBody, setmsgBody] = useState('');
    const [warningImg, setWarningImg] = useState('');


    const toggleMsgPopup = () => {
        setisShowMsg(!isShowMsg)
      }


    const validateAddNumber = (e, type) => {
        const { value } = e.target;
        if (value === '' ||  value.match(/^\d{1,}(\.\d{0,4})?$/)) {
            if (type == 'add') {
                var addCreAmount = value ? value : ""
                setAddCreAmount(addCreAmount)
            }
            if (type == 'deduct') {
                var deductCredAMount = value ? value : ""
                setDeductCredAMount(deductCredAMount)
            }
        }
        if (type == 'notes') {
            var notes = value ? value : ''
            setNotes(notes)
        }
    }


    const handleSubmit = (customer_Id, UID) => {
        var msg = "";
        var title = "";
        if (DeductCredAMount && DeductCredAMount !=="" && props.details.store_credit < DeductCredAMount) {
            setmsgTitle(LocalizedLanguage.messageTitle)
            setmsgBody(LocalizedLanguage.cashAmountExceed)
            setisShowMsg(true)
             // title = LocalizedLanguage.messageTitle;
            // msg = LocalizedLanguage.cashAmountExceed;
            // var data = { title: title, msg: msg, is_success: true }
            // dispatch(popupMessage(data));
            return;
           
        }
        if (AddCreAmount || DeductCredAMount) {
            var addRemoveParm = {
                "CustomerWpid": customer_Id,
                "AddPoint": AddCreAmount,
                "DeductPoint": DeductCredAMount,
                "Notes": Notes,
                "Udid": UID,
            }
            dispatch(updateCreditScore(addRemoveParm));
            props.toggleCreditModel()
            setAddCreAmount("")
            setDeductCredAMount("")
            setNotes('')
            // setTimeout(() => {
            //  props.updateSomething(customer_Id)
            // }, 3000);
        }

    }

    const modelClose = () => {
        setAddCreAmount(0.00)
        setDeductCredAMount(0.00)
        setNotes('')
        props.toggleCreditModel()
    }

    const HundleCreditFiled =(statusFiled)=>{
        setStatusFiled(statusFiled)
        if (statusFiled == 'Add') {
         
            setDeductCredAMount('')
        }
        if (statusFiled == 'Remove') {          
            setAddCreAmount('')
        }
    }




    // Close Button popup
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleCreateCustomer && props.toggleCreateCustomer();
        }
        else {
            e.stopPropagation();
        }
    }
    return (
        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div  className={props.isShow === true ? "subwindow adjust-credit current" : "subwindow adjust-credit"}    >
                <div className="subwindow-header">
                    <p>{LocalizedLanguage.AdjustCredit}</p>
                    <button className="close-subwindow" onClick={props.toggleCreditModel}>
                        <img src={IconDarkBlue} alt="" />
                    </button>
                </div>
                <div className="subwindow-body">
                    <div className="auto-margin-top" />
                    <p>{LocalizedLanguage.Selectanoption}:</p>
                    <div className="toggle-wrapper" id="adjustCreditToggle">
                        <label>
                            <input
                                type="radio"
                                id="addCreditToggle"
                                name="add_remove_credit_toggle"
                                defaultChecked={true}
                            />
                            <div className="custom-radio" onClick={() => HundleCreditFiled('Add')}>{LocalizedLanguage.addCredit}</div>
                        </label>
                        <label>
                            <input
                                type="radio"
                                id="removeCreditToggle"
                                name="add_remove_credit_toggle"
                            />
                            <div className="custom-radio" onClick={() => HundleCreditFiled('Remove')}>{LocalizedLanguage.RemoveCredit}</div>
                        </label>
                    </div>
                    <div className="text-row">
                        <p className="style1">{LocalizedLanguage.CurrentCredit}</p>
                        {/* <p className="style2">{props.details?.store_credit ? props.details?.store_credit : 0.00}</p> */}
                        <p className="style2">{currecyFunction(props.details?.store_credit ? props.details?.store_credit : 0.00)}</p>
                    </div>
                    <div className={StatusFiled === "Add" ? "change-credit-row " : "change-credit-row hidden"} >
                        <p>{LocalizedLanguage.addCredit}:</p>
                        <input type="text" id="addCreditInput" placeholder={LocalizedLanguage.EnterAmount} value={AddCreAmount}  onChange={(e) => validateAddNumber(e, "add")} />
                    </div> 
                     <div className={StatusFiled === "Remove" ? "change-credit-row " : "change-credit-row hidden"}>
                        <p>{LocalizedLanguage.RemoveCredit}:</p>
                        <input type="text" id="removeCreditInput" placeholder={LocalizedLanguage.EnterAmount} onChange={(e) => validateAddNumber(e, "deduct")}  value={DeductCredAMount} />
                    </div>
                   
                    
                    <label htmlFor="adjustCreditAddNote">{LocalizedLanguage.addanote}:</label>
                    <textarea
                        id="adjustCreditAddNote"
                        placeholder={LocalizedLanguage.pleaseaddanotehere}
                        onChange={(e) => validateAddNumber(e, "notes")}
                        value={Notes}
                    />
                    <button onClick={() => handleSubmit(props.details.WPId, props.UID)}>{LocalizedLanguage.UpdateCredit}</button>
                    <div className="auto-margin-bottom" />
                </div>
            </div>
            <MsgPopup isShow={isShowMsg} toggleMsgPopup={toggleMsgPopup} msgTitle={msgTitle} msgBody={msgBody} img={warningImg}></MsgPopup>
        </div>
    )
}

export default AdjustCreditpopup