import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import STATUSES from "../../constants/apiStatus";
import moment from 'moment';
import Config from '../../Config'
import { LoadingModal } from "../common/commonComponents/LoadingModal";
import { NumericFormat } from "react-number-format";
import { v4 as uniqueKey } from 'uuid';
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { currecyFunction } from "../common/commonFunctions/currencyFunction";
const CashDrawerPaymentDetailList = (props) => {
    const { statusgetdetail, getdetail, errorgetdetail, is_successgetdetail } = useSelector((state) => state.cashmanagementgetdetail)
    var paymentList = [];
    if (statusgetdetail == STATUSES.IDLE && is_successgetdetail) {
        var CashDrawerPaymentDetail = getdetail && getdetail.content
        var _paymentList = CashDrawerPaymentDetail && CashDrawerPaymentDetail.CashRegisterlog ? CashDrawerPaymentDetail.CashRegisterlog : [];

        if (_paymentList && _paymentList.length > 0) {
            paymentList = [..._paymentList].reverse()

        }

    }

    var userName = CashDrawerPaymentDetail && CashDrawerPaymentDetail ? CashDrawerPaymentDetail.SalePersonName : '';
    var openingNote = CashDrawerPaymentDetail && CashDrawerPaymentDetail ? CashDrawerPaymentDetail.OpeningNotes : '';
    var openDateTime = CashDrawerPaymentDetail && CashDrawerPaymentDetail ? CashDrawerPaymentDetail.UtcOpenDateTime : "";
    var _openDateTime = moment.utc(openDateTime).local().format(Config.key.ONLY_TIME);
    var openingBal = CashDrawerPaymentDetail && CashDrawerPaymentDetail ? CashDrawerPaymentDetail.OpeningBalance : 0.00;
    var iscashDrawerClosed = CashDrawerPaymentDetail ? CashDrawerPaymentDetail.isClosed : false;
    var closeDateTime = CashDrawerPaymentDetail ? CashDrawerPaymentDetail.UtcClosedDateTime : "";
    var _closeDateTime = moment.utc(closeDateTime).local().format(Config.key.ONLY_TIME);
    var closingActualBal = CashDrawerPaymentDetail ? CashDrawerPaymentDetail.Actual : 0.00;
    var closingNote = CashDrawerPaymentDetail ? CashDrawerPaymentDetail.ClosingNotes : '';
    var closingExpectedBal = CashDrawerPaymentDetail ? CashDrawerPaymentDetail.Expected : 0.00;

    return (
        <>
            {props.isFreePlan==false && statusgetdetail == STATUSES.LOADING? <LoadingModal></LoadingModal> : null}
            {(iscashDrawerClosed == true) ? <>
                <div className="action">
                    <div className="header-row">
                        <p>{LocalizedLanguage.closingfloat}</p>
                        <p className="style2">{_closeDateTime}</p>
                    </div>
                    <div className="body-col">
                        <p>{LocalizedLanguage.user}: {userName}</p>
                    </div>
                    {
                        closingNote != '' ? <div className="body-col">
                        <p>{LocalizedLanguage.notes}: {closingNote}</p>
                        </div>:''
                    }
                    {/* <div className="body-col">
                        <p>{LocalizedLanguage.notes}: {closingNote}</p>
                    </div> */}
                    <div className="footer-col">
                        {/* <p><b>{LocalizedLanguage.expectedbalance}: </b>$<NumericFormat value={closingExpectedBal} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></p> */}
                        <p><b>{LocalizedLanguage.expectedbalance}: </b>{currecyFunction(closingExpectedBal)}</p>
                    </div>
                    <div className="footer-col">
                        {/* <p><b>{LocalizedLanguage.actualbalance}: </b>$<NumericFormat value={closingActualBal} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></p> */}
                        <p><b>{LocalizedLanguage.actualbalance}: </b>{currecyFunction(closingActualBal)}</p>
                    </div>
                </div>
            </> : null
            }
            <> {paymentList.filter(i => i.Description.toLowerCase() == "cash").map((item, index) => {
                return (
                    <div className="action" key={uniqueKey()}>
                        <div className="header-row">
                            <p className={item.IsManual == true ? "green" : item.Expected < 0 ? "red" : item.IsManual == false && item.Expected < 0 ? "" : "blue"}>
                                {item.IsManual == true ? <>{LocalizedLanguage.manualtransaction}</> : item.IsManual == false && item.Expected < 0 ? <>{LocalizedLanguage.refundTitle}</> : <>{LocalizedLanguage.cashsale}</>} </p>
                            <p className="style2">{moment.utc(item.TransactionDateOffset).local().format(Config.key.ONLY_TIME)}</p>
                        </div>
                        {item.Notes !== "" ? <div className="body-col">
                            <p>{LocalizedLanguage.notes}: {item.IsManual == true ? item.Notes : ''} </p>
                        </div> : ''}
                        <div className="footer-col">
                            {/* <p>{(item.Expected < 0 ? "-" : "+")} $<NumericFormat value={Math.abs(item.Expected)} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /> </p> */}
                            <p>{(item.Expected < 0 ? "-" : "+")} {currecyFunction(Math.abs(item.Expected))}</p>


                            {/* <p><b>{LocalizedLanguage.currentBalance}: </b>$<NumericFormat value={item.RemainingBalance} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></p> */}
                            <p><b>{LocalizedLanguage.currentBalance} </b>{currecyFunction(item.RemainingBalance)}</p>
                        </div>
                    </div>
                )
            })
            }</>


            <div className="action">
                <div className="header-row">
                    <p>{LocalizedLanguage.openingFloat}</p>
                    <p className="style2">{_openDateTime}</p>
                </div>
                <div className="body-col">
                    <p>{LocalizedLanguage.user}: {userName}</p>
                </div>
                {
                    openingNote !== "" ? <div className="body-col">
                        <p>{LocalizedLanguage.notes}: {openingNote}</p>
                    </div>:''
                }
                {/* <div className="body-col">
                    <p>{LocalizedLanguage.notes}: {openingNote}</p>
                </div> */}
                <div className="footer-col">
                    {/* <p><b>{LocalizedLanguage.openingBalacce}: </b>$<NumericFormat value={openingBal} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></p> */}
                    <p><b>{LocalizedLanguage.openingBalacce}: </b>{currecyFunction(openingBal)}</p>
                </div>
            </div>




        </>
    )
}

export default CashDrawerPaymentDetailList