import Config from '../Config'

export function getProductSummery(attributes, product) {
  let psummary = "";
  let items = product.combination.split("~");
  let i = 0;
  attributes && attributes.length > 0 && attributes.forEach(item => {
    if (items && items.length > 0) {
      psummary += item.Name + ": " + items[i];
      if (items.length > i + 1) {
        psummary += ", ";
      }
      i++;
    }
  });
  //text-transform: capitalize;
  //console.log("-----product summery---" + psummary);
  return psummary;
}
export function getModifierFromCustomFee_Updated(_ListItem, meta_datas, order_custom_fee) {
  var modifiersList = [];
  var ListItem = JSON.parse(JSON.stringify(_ListItem));
  const _data = get_order_oliverpos_product_discount_amount_meta_data(meta_datas, true);

  var indexCount = 0;
  var count = 0;
  _data && _data !== "" && _data.forEach((item, index) => {
    if (item.hasOwnProperty("product_id")) {
      var _pitem = ListItem[count];
      if (item.hasOwnProperty("modifiers_Id") && _pitem) {
        _pitem["modifiers_Id"] = item.modifiers_Id;
      }
      if (item.hasOwnProperty("modifiers_key") && _pitem) {
        _pitem["modifiers_key"] = item.modifiers_key;
      }
      count++;
    }
    if (item.order_custom_fee) {
      item.order_custom_fee.forEach(m => {
        var fee_id = 0;
        var amount_refunded = 0;
        var tax_refunded = 0;
        if (order_custom_fee && order_custom_fee.length > 0 && m.Title === order_custom_fee[indexCount]?.note
          && ((m.hasOwnProperty("modifiers_key") && order_custom_fee[indexCount].hasOwnProperty("modifiers_key")) ? m.modifiers_key == order_custom_fee[indexCount].modifiers_key : true)) {
          fee_id = order_custom_fee[indexCount].hasOwnProperty("fee_id") === true ? order_custom_fee[indexCount].fee_id : 0;
          amount_refunded = order_custom_fee[indexCount].hasOwnProperty("amount_refunded") === true ? order_custom_fee[indexCount].amount_refunded : 0;
          tax_refunded = order_custom_fee[indexCount].hasOwnProperty("tax_refunded") === true ? order_custom_fee[indexCount].tax_refunded : 0;
          //total_taxes = order_custom_fee[indexCount].hasOwnProperty("total_taxes") === true ? order_custom_fee[indexCount].total_taxes : 0;
        }
        // modifiersList.push({
        //   amount: m.Price, amount_refunded: amount_refunded, fee_id: fee_id, note: m.Title, subtotal_taxes: null,
        //   tax_refunded: tax_refunded, total_tax: m.subtotal_tax, total_taxes: m.total_taxes,
        //   pid: m.hasOwnProperty('pid') ? m.pid : 0, is_modi: m.hasOwnProperty('pid') ? true : false
        //   , modifiers_key: m.hasOwnProperty("modifiers_key") ? m.modifiers_key : 0
        // });
        var tax = m.subtotal_tax;
        tax_refunded = m.subtotal_tax;
        if ((amount_refunded + m.subtotal_tax + .01).toFixed(2) == m.Price.toFixed(2)) {
          tax = m.subtotal_tax + .01;
        }
        modifiersList.push({
          amount: m.Price, amount_refunded: amount_refunded, fee_id: fee_id, note: m.Title, subtotal_taxes: null,
          tax_refunded: tax_refunded, total_tax: tax, total_taxes: m.total_taxes, pid: m.pid, is_modi: true
          , modifiers_key: m.hasOwnProperty("modifiers_key") ? m.modifiers_key : 0
          , quantity: m.hasOwnProperty("quantity") ? m.quantity : 1,
          cart_after_discount: m.hasOwnProperty("cart_after_discount") ? m.cart_after_discount : 0,
          cart_discount_amount: m.hasOwnProperty("cart_discount_amount") ? m.cart_discount_amount : 0,
          discount_amount: m.hasOwnProperty("discount_amount") ? m.discount_amount : 0,
          discount_type: m.hasOwnProperty("discount_type") ? m.discount_type : "",
          new_product_discount_amount: m.hasOwnProperty("new_product_discount_amount") ? m.new_product_discount_amount : 0,
          old_price: m.hasOwnProperty("old_price") ? m.old_price : m.old_price,
          product_after_discount: m.hasOwnProperty("product_after_discount") ? m.product_after_discount : 0,
          product_discount_amount: m.hasOwnProperty("product_discount_amount") ? m.product_discount_amount * (m.discount_type === "Number" ? 1 : m.quantity) : 0
          // product_discount_amount: m.hasOwnProperty("product_discount_amount") ? (m.discount_type === "Number" ? m.product_discount_amount * m.quantity : 0) : 0
        });
        indexCount++;
      });
    }

  });

  var products = ListItem && ListItem.filter(a => a.hasOwnProperty("product_id") === true);
  var sortedProductArray = []
  if (products && products.length > 0) {
    products.forEach(p => {
      sortedProductArray.push(p); //Push parent product
      // var child = modifiersList.filter(l => l.hasOwnProperty("pid") === true && l.pid === p.product_id
      //   && ((l.hasOwnProperty("modifiers_Id") && p.hasOwnProperty("modifiers_Id") ? JSON.stringify(l.modifiers_Id) == JSON.stringify(p.modifiers_Id) : true)
      //     && ((l.hasOwnProperty("modifiers_key") && p.hasOwnProperty("modifiers_key") ? l.modifiers_key == p.modifiers_key : true)))
      // );
      var child = modifiersList.filter(l => l.hasOwnProperty("pid") === true && l.pid === p.product_id
        //&& (l.hasOwnProperty("modifiers_Id") && p.hasOwnProperty("modifiers_Id") ? JSON.stringify(l.modifiers_Id) == JSON.stringify(p.modifiers_Id) : true)
        && (l.hasOwnProperty("modifiers_key") && p.hasOwnProperty("modifiers_key") ? l.modifiers_key == p.modifiers_key : true)
      );
      child && child.forEach(c => {
        sortedProductArray.push(c); // push children(MODIFIERS) of that product
      })
    })
  }
  else {
    sortedProductArray = ListItem;
  }
  return sortedProductArray;
  //return ListItem;
}
export function getModifierFromCustomFee(_ListItem, meta_datas, order_custom_fee) {
  var modifiersList = [];
  if (typeof _ListItem == "undefined") {
    return [];
  }
  var ListItem = JSON.parse(JSON.stringify(_ListItem));
  const _data = get_order_oliverpos_product_discount_amount_meta_data(meta_datas, true);
  var indexCount = 0;
  var count = 0;
  _data && _data !== "" && _data.forEach((item, index) => {
    if (item.hasOwnProperty("product_id")) { //FIND ALL PRODUCTS FROM META DATA
      var _pitem = ListItem[count];
      if (item.hasOwnProperty("modifiers_Id") && _pitem) {
        _pitem["modifiers_Id"] = item.modifiers_Id;
      }
      if (item.hasOwnProperty("modifiers_key") && _pitem) {
        _pitem["modifiers_key"] = item.modifiers_key;
      }
      count++;
    }
    if (item.order_custom_fee) { //CUSTOM FEE ARRAY FROM META
      item.order_custom_fee.forEach(m => {
        var fee_id = 0;
        var amount_refunded = 0;
        var tax_refunded = 0;
        if (m.hasOwnProperty("pid") && typeof m.hasOwnProperty("pid") != "undefined" && m.pid != 0) {
          if (order_custom_fee && order_custom_fee.length > 0 && m.Title === order_custom_fee[indexCount]?.note
            && ((m.hasOwnProperty("modifiers_key") && order_custom_fee[indexCount].hasOwnProperty("modifiers_key")) ? m.modifiers_key == order_custom_fee[indexCount].modifiers_key : true)) {
            fee_id = order_custom_fee[indexCount].hasOwnProperty("fee_id") === true ? order_custom_fee[indexCount].fee_id : 0;
            amount_refunded = order_custom_fee[indexCount].hasOwnProperty("amount_refunded") === true ? order_custom_fee[indexCount].amount_refunded : 0;
            tax_refunded = order_custom_fee[indexCount].hasOwnProperty("tax_refunded") === true ? order_custom_fee[indexCount].tax_refunded : m.tax;
            //total_taxes = order_custom_fee[indexCount].hasOwnProperty("total_taxes") === true ? order_custom_fee[indexCount].total_taxes : 0;
          }
          //amount_refunded ALWAYS COMEING WITH TAX IN ANY CASE IF INCL/EXCL
          var tax = m.subtotal_tax;
          tax_refunded = m.subtotal_tax;
          if ((amount_refunded + m.subtotal_tax + .01).toFixed(2) == m.Price.toFixed(2)) {
            tax = m.subtotal_tax + .01;
          }
          // else {
          //   amount_refunded = amount_refunded + m.subtotal_tax;
          // }
          modifiersList.push({
            amount: m.Price, amount_refunded: amount_refunded, fee_id: fee_id, note: m.Title, subtotal_taxes: null,
            tax_refunded: tax_refunded, total_tax: tax, total_taxes: m.total_taxes, pid: m.pid, is_modi: true
            , modifiers_key: m.hasOwnProperty("modifiers_key") ? m.modifiers_key : 0
            , quantity: m.hasOwnProperty("quantity") ? m.quantity : 1,
            cart_after_discount: m.hasOwnProperty("cart_after_discount") ? m.cart_after_discount : 0,
            cart_discount_amount: m.hasOwnProperty("cart_discount_amount") ? m.cart_discount_amount : 0,
            discount_amount: m.hasOwnProperty("discount_amount") ? m.discount_amount : 0,
            discount_type: m.hasOwnProperty("discount_type") ? m.discount_type : "",
            new_product_discount_amount: m.hasOwnProperty("new_product_discount_amount") ? m.new_product_discount_amount : 0,
            old_price: m.hasOwnProperty("old_price") ? m.old_price : m.old_price,
            product_after_discount: m.hasOwnProperty("product_after_discount") ? m.product_after_discount : 0,
            product_discount_amount: m.hasOwnProperty("product_discount_amount") ? m.product_discount_amount * (m.discount_type === "Number" ? 1 : m.quantity) : 0
            // product_discount_amount: m.hasOwnProperty("product_discount_amount") ? (m.discount_type === "Number" ? m.product_discount_amount * m.quantity : 0) : 0
          });
          indexCount++;
        }
      });
      //modifiersList = item.order_custom_fee;
    }
  });
  var products = ListItem && ListItem.filter(a => a.hasOwnProperty("product_id") === true);
  var sortedProductArray = []
  if (products && products.length > 0) {
    products.forEach(p => {
      sortedProductArray.push(p); //Push parent product
      var child = modifiersList.filter(l => l.hasOwnProperty("pid") === true && l.pid === p.product_id
        && (l.hasOwnProperty("modifiers_key") && p.hasOwnProperty("modifiers_key") ? l.modifiers_key == p.modifiers_key : true)
      );
      child && child.forEach(c => {
        sortedProductArray.push(c); // push children(MODIFIERS) of that product
      })
    })
  }
  else {
    sortedProductArray = ListItem;
  }
  return sortedProductArray;
}
//tested done with common meta data method
export function getCustomFee(meta_datas, order_custom_fee) {
  var modifiersList = [];
  const _data = get_order_oliverpos_product_discount_amount_meta_data(meta_datas, true);
  _data && _data !== "" && _data.forEach((item, index) => {
    if (item.order_custom_fee) {
      var indexCount = 0;
      item.order_custom_fee.forEach(m => {
        var fee_id = 0;
        var amount_refunded = 0;
        var tax_refunded = 0;
        //var total_taxes = null;

        if (order_custom_fee && order_custom_fee.length > 0 && m.Title === order_custom_fee[indexCount]?.note) {
          fee_id = order_custom_fee[indexCount].hasOwnProperty("fee_id") === true ? order_custom_fee[indexCount].fee_id : 0;
          amount_refunded = order_custom_fee[indexCount].hasOwnProperty("amount_refunded") === true ? order_custom_fee[indexCount].amount_refunded : 0;
          tax_refunded = order_custom_fee[indexCount].hasOwnProperty("tax_refunded") === true ? order_custom_fee[indexCount].tax_refunded : m.tax;
          //total_taxes = order_custom_fee[indexCount].hasOwnProperty("total_taxes") === true ? order_custom_fee[indexCount].total_taxes : 0;
        }
        modifiersList.push({
          amount: m.Price, amount_refunded: amount_refunded, fee_id: fee_id, note: m.Title, subtotal_taxes: null,
          tax_refunded: tax_refunded, total_tax: m.subtotal_tax, total_taxes: m.total_taxes, pid: m.pid, is_modi: true
          , modifiers_key: m.hasOwnProperty("modifiers_key") ? m.modifiers_key : 0
          , quantity: m.hasOwnProperty("quantity") ? m.quantity : 0
        });
        indexCount++;
      });
    }
  });
  var cfee = modifiersList && modifiersList.filter(f => f.hasOwnProperty("pid") || (typeof f.pid == "undefined" || f.pid == 0));
  return cfee;
}
//tested done with common meta data method
export function getProductNotes(meta_datas, product, forAll = false) {
  var order_notes = [];

  const _data = get_order_oliverpos_product_discount_amount_meta_data(meta_datas, true);
  _data && _data !== "" && _data.forEach((item, index) => {
    if (item.hasOwnProperty("order_notes")) {
      item.order_notes.forEach(note_data => {
        if (note_data && typeof note_data != "undefined" && !note_data.note.toLowerCase().match(/payment done with:/)) {
          order_notes.push(note_data);
        }
      });
    }

  });
  if (forAll == false) {
    order_notes = order_notes ? order_notes.filter(a => a.pid == product.product_id && (a.hasOwnProperty("modifiers_key") && product.hasOwnProperty("modifiers_key") ? a.modifiers_key == product.modifiers_key : true)) : [];

  }
  return order_notes;
}
//tested done with common meta data method
export function getOrderNotes(meta_datas) {
  var order_notes = [];

  const _data = get_order_oliverpos_product_discount_amount_meta_data(meta_datas, true);
  _data && _data !== "" && _data.forEach((item, index) => {
    if (item.hasOwnProperty("order_notes")) {
      item.order_notes.forEach(note_data => {
        if (note_data && typeof note_data != "undefined" && ((!note_data.hasOwnProperty("pid") || note_data.pid == 0) && !note_data?.note !== "" && !note_data?.note.toLowerCase().match(/payment done with:/))) {
          order_notes.push(note_data);
        }
      });
    }

  });

  return order_notes;
}
export function getChangeAmount(meta_datas) {
  var cash_change = '';
  meta_datas?.map((item, index) => {
    if (item.hasOwnProperty("ItemName") && item.ItemName == "_order_oliverpos_cash_change" && item.hasOwnProperty("ItemValue")) {
      cash_change = item.ItemValue ? JSON.parse(item.ItemValue) : null;
    }
    return cash_change;
  });
  return cash_change;
}
//tested done with common meta data method
export function getDiscountPerItem(data, type, productID, item = "") {
  var discountDetail;
  const _data = get_order_oliverpos_product_discount_amount_meta_data(data.meta_datas, true);
  if (_data) {
    _data.filter(itm => itm.product_id && (itm.variation_id == productID || itm.product_id == productID)
      && ((itm.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? itm.modifiers_key == item.modifiers_key : true))
    ).map(prd => {
      // console.log("prd",prd)
      var typeRate = 0;
      if (prd.discount_type == "Number") {
        var disPerc = (prd.new_product_discount_amount * 100) / prd.Price
        typeRate = disPerc.toFixed(2)
      } else {
        typeRate = prd.new_product_discount_amount
      }
      discountDetail = {
        "discountApply": typeRate,
        "discountAmount": (prd.product_discount_amount * (prd.discount_type == "Number" ? 1 : prd.quantity)),
        "discounttype": prd.discount_type,
        "discountCart": prd.discountCart ? prd.discountCart : 0,
        "cartItemDiscount": prd.cart_discount_amount
      }
    })
  }
  return discountDetail

}
export function getDiscountPerItemOnRefund(data, type, productID, item = "", qty) {
  var discountDetail;
  const _data = get_order_oliverpos_product_discount_amount_meta_data(data.meta_datas, true);
  if (_data) {
    _data.filter(itm => itm.product_id && (itm.variation_id == productID || itm.product_id == productID)
      && ((itm.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? itm.modifiers_key == item.modifiers_key : true))
    ).map(prd => {
      // console.log("prd",prd)
      var typeRate = 0;
      if (prd.discount_type == "Number") {
        var disPerc = (prd.new_product_discount_amount * 100) / prd.Price
        typeRate = disPerc.toFixed(2)
      } else {
        typeRate = prd.hasOwnProperty("new_product_discount_amount") ? prd.new_product_discount_amount : 0;
      }
      discountDetail = {
        "discountApply": typeRate,
        "discountAmount": (prd.product_discount_amount * (prd.discount_type == "Number" ? 1 : qty)),
        "discounttype": prd.discount_type,
        "discountCart": prd.discountCart ? prd.discountCart : 0,
        "cartItemDiscount": prd.cart_discount_amount
      }
    })
  }
  return discountDetail

}
export function stripHtml(html) {
  // Create a new div element
  var temporalDivElement = document && document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return (temporalDivElement.textContent || temporalDivElement.innerText || "").replace(/^"(.*)"$/, '$1');

}
export function currencyCode(html) {
  // Create a new div element
  var temporalDivElement = document && document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return (temporalDivElement.textContent || temporalDivElement.innerText || "");

}
function calculateTaxRate(totalAmount, itemvalue) {
  return Math.round(((itemvalue) * 100) / totalAmount, 0)
}
export function getTaxesPerItem(order_reciept, type, data, item) {
  //var taxInclusiveName = getInclusiveTaxType(data.meta_datas);
  var DisplayTotalSplitTax = [];
  var lineitem_taxType = []
  var itemvalue = item.Taxes && item.Taxes !== null && item.Taxes !== "" ? (type !== 'activity') ? item.Taxes : item.Taxes && item.Taxes !== "null" ? JSON.parse(item.Taxes).total : "" : ""
  var taxRate = JSON.parse(localStorage.getItem("SHOP_TAXRATE_LIST"))
  //var TotalTax = 0
  if (type == 'activity') { // for activity
    itemvalue && itemvalue !== "" && Object.keys(itemvalue).map(key => {
      var taxvalue = itemvalue[key];
      data.order_taxes && data.order_taxes.map(tm => {
        // taxRate && taxRate.map(tm=>{
        if (tm.RateId == key) {
          var taxname = tm.Title; //tm.TaxName;

          var TaxRate = calculateTaxRate(parseFloat(item.price * item.quantity), parseFloat(taxvalue));
          if (item.price == 0) {
            TaxRate = 0;
          }
          if (order_reciept) {
            if (order_reciept.PercentageTaxPerItem == true) {
              taxname += "(" + TaxRate + "%)";
            }
            //TotalTax += parseFloat(TaxRate)
          }
          lineitem_taxType.push({ "tax": taxname, "value": taxvalue, "id": tm.RateId })
        }
      })
    })
  } else {//for sale complete
    itemvalue !== "" && itemvalue.map(myObj => {
      var taxvalue = ""
      var key = ""
      for (const x in myObj) {
        key = x;
        taxvalue = myObj[x]
      }
      taxRate && taxRate.map(tm => {
        if (tm.TaxId == key) {
          var taxname = tm.TaxName;
          if (order_reciept && order_reciept.PercentageTaxPerItem == true) {
            taxname += "(" + tm.TaxRate + ")"
          }
          // TotalTax += parseFloat(tm.TaxRate)

          lineitem_taxType.push({ "tax": taxname, "value": taxvalue, "id": tm.TaxId })
        }
      })
    })
  }
  //..............insert unique tax ...........................................
  lineitem_taxType && lineitem_taxType.map(taxItem => {     // '>1' to check if split tax inot more then 1 tax type
    if (DisplayTotalSplitTax.length == 0) {
      if (taxItem.value !== 0)
        DisplayTotalSplitTax.push(taxItem)
    }
    else {
      var checkTaxExist = false;
      DisplayTotalSplitTax.map(item => {
        if (item.tax == taxItem.tax && item.id == taxItem.id) {
          checkTaxExist = true;
          item.value = parseFloat(item.value) + parseFloat(taxItem.value)

        }
      })
      if (checkTaxExist == false) {
        if (taxItem.value !== 0)
          DisplayTotalSplitTax.push(taxItem)
      }
    }
  })
  return DisplayTotalSplitTax;
}
export function getClosest(array, value) {
  var closest;
  array.some(function (a) {
    if (a >= value) {
      return true;
    }
    closest = a;
  });
  return closest;
}

export function addModiferTaxToLineItemTax(DisplayTotalSplitTax, product_modifiers) {
  var _taxArray = [];
  product_modifiers && product_modifiers.map((item_fee, index) => {
    if (item_fee.hasOwnProperty("pid") == true) {
      if (_taxArray.length > 0 && Array.isArray(item_fee.total_taxes)) {
        _taxArray = [..._taxArray, ...item_fee.total_taxes]
      }
      else { _taxArray = item_fee.total_taxes; }
    }
  });
  var all_modifier_taxes = _taxArray;

  var result = [];
  var taxvalue = 0;
  DisplayTotalSplitTax?.length > 0 && DisplayTotalSplitTax?.map(item => {  //display split tax if tax is more then 1

    all_modifier_taxes && all_modifier_taxes !== "" && all_modifier_taxes.map(item1 => {
      Object.keys(item1).map(key => {
        if (item.id == key) {
          taxvalue += item1[key];
        }
      });
    });
    //result.push({ "taxname": item.tax, "taxamount": (parseFloat(item.value) + parseFloat(taxvalue)).toFixed(2) })
    result.push({ "tax": item.tax, "tax_name": item.hasOwnProperty("tax_name") ? item.tax_name : item.tax, "value": (parseFloat(item.value) + parseFloat(taxvalue)).toFixed(2), "id": item.hasOwnProperty("id") ? item.id : 0, "wpTaxRate": item.wpTaxRate })
  })
  return result;
}
export const getOrderListOnActivity = (activityOrderDetails) => {
  var ListItem = new Array();
  var _order_custom_fee = [];
  var single_Order_list = activityOrderDetails && JSON.parse(JSON.stringify(activityOrderDetails));
  if (single_Order_list && single_Order_list.line_items && single_Order_list.line_items.length > 0) {
    single_Order_list.line_items.map(item => {
      var _item = JSON.parse(JSON.stringify(item));
      if (!item.ProductSummery || item.ProductSummery.length == 0) {
        var psummary = getPSummary(single_Order_list, item)
        _item["ProductSummery"] = psummary;
      }
      _item["isTaxable"] = getPTaxable(single_Order_list, item);
      item = _item;
    });
    single_Order_list.order_custom_fee.map((item, index) => {
      item = getCustomFeeDetails(item, index, single_Order_list);
      _order_custom_fee.push(item);
    })
  }
  if (_order_custom_fee.length > 0) {
    single_Order_list.order_custom_fee = _order_custom_fee;
  }
  //----Same product with different modifiers
  var lineitems = single_Order_list && single_Order_list.line_items ? single_Order_list.line_items : [];
  if (lineitems.length > 0 && typeof single_Order_list.meta_datas != "undefined") {
    lineitems = getModifierFromCustomFee_Updated(lineitems, single_Order_list.meta_datas, single_Order_list.order_custom_fee);
  }
  single_Order_list.line_items = lineitems;
  //----end

  //------custom fee
  if (_order_custom_fee.length > 0) {
    //var products = single_Order_list.line_items;
    var products = lineitems;
    var sortedProductArray = []
    if (products.length > 0) {
      products.map(p => {
        if (p.hasOwnProperty("product_id")) {
          sortedProductArray.push(p); //Push parent product
        }
        var child = _order_custom_fee.filter(l => l.hasOwnProperty("pid") === true && l.pid === p.product_id
          && ((l.hasOwnProperty("modifiers_Id") && p.hasOwnProperty("modifiers_Id") ? JSON.stringify(l.modifiers_Id) == JSON.stringify(p.modifiers_Id) : true)
            && ((l.hasOwnProperty("modifiers_key") && p.hasOwnProperty("modifiers_key") ? l.modifiers_key == p.modifiers_key : true)))
        );
        child && child.map(c => {
          sortedProductArray.push(c); // push children of that product
        })
      })
    }
    else {
      sortedProductArray = ListItem;
    }
    single_Order_list.line_items = sortedProductArray;
  }
  localStorage.setItem("getorder", JSON.stringify(single_Order_list))
  localStorage.removeItem("oliver_refund_order_payments");
}
export const convertImageToBase64 = (imgUrl, callback, imgSec) => {
  const image = new Image();
  image.crossOrigin = 'Anonymous';
  image.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    ctx.drawImage(image, 0, 0);
    const dataUrl = canvas.toDataURL();
    if (imgSec != "") {
      localStorage.setItem(imgSec, dataUrl);
    }
    callback && callback(dataUrl)
  }
  image.src = imgUrl;
}
export const AddCustomerDetail = (cutomer_data) => {
  var data = cutomer_data;
  localStorage.setItem('AdCusDetail', JSON.stringify(data))

  var list = localStorage.getItem('CHECKLIST') !== null ? (typeof localStorage.getItem('CHECKLIST') !== 'undefined') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null : null;
  if (list !== null) {
    const CheckoutList = {
      ListItem: list.ListItem,
      customerDetail: data ? data : [],
      totalPrice: list.totalPrice,
      discountCalculated: list.discountCalculated,
      tax: list.tax,
      subTotal: list.subTotal,
      TaxId: list.TaxId,
      TaxRate: list.TaxRate,
      oliver_pos_receipt_id: list.oliver_pos_receipt_id,
      order_date: list.order_date,
      order_id: list.order_id,
      status: list.status,
      showTaxStaus: list.showTaxStaus,
      _wc_points_redeemed: list._wc_points_redeemed,
      _wc_amount_redeemed: list._wc_amount_redeemed,
      _wc_points_logged_redemption: list._wc_points_logged_redemption
    }
    localStorage.setItem('CHECKLIST', JSON.stringify(CheckoutList))
  }
}
export const getCustomFeeDetails = (_itemPara, _index, activityOrderDetails) => {
  var _item = { ..._itemPara };
  var getorderlist = get_order_oliverpos_product_discount_amount_meta_data(activityOrderDetails.meta_datas, true);
  if (getorderlist !== null) {
    getorderlist && getorderlist.map((item, index) => {
      item && item.order_custom_fee && item.order_custom_fee.map((fee, index) => {
        if (item.order_custom_fee != null && _item.note == fee.note && _index == index /*&& (_item.hasOwnProperty("fee_id") && fee.hasOwnProperty("fee_id") ? _item.fee_id == fee.fee_id : true)*/) {
          var _total_tax = {};
          fee.total_taxes && fee.total_taxes.map((item, index) => {
            Object.assign(_total_tax, item)
          });
          var _Tax = JSON.stringify({ "total": _total_tax, "subtotal": _total_tax });
          _item['price'] = (fee.incl_tax != 0 ? _item.amount - fee.total_tax : _item.amount);
          _item['line_item_id'] = _item.fee_id;
          _item['quantity'] = fee.quantity ? fee.quantity : 1; /** IT WILL SHOW THE ACTUAL QUANTITY OF THE MODIFIER */
          // _item['quantity'] = 1;
          _item['total'] = fee.incl_tax != 0 ? _item.amount - fee.total_tax : _item.amount;
          _item['subtotal'] = fee.incl_tax != 0 ? _item.amount - fee.subtotal_tax : item.amount;
          _item['name'] = _item.note;
          _item['is_ticket'] = false;
          _item['product_id'] = 0;
          _item['parent_id'] = 0;
          _item['Taxes'] = _Tax;
          _item['variation_id'] = 0;
          _item['amount_refunded'] = _item.amount_refunded;
          _item['quantity_refunded'] = _item.amount_refunded > 0 ? -1 : 0;
          _item['subtotal_tax'] = fee.subtotal_tax ? fee.subtotal_tax : 0;
          _item['subtotal_taxes'] = fee.subtotal_taxes ? fee.subtotal_taxes : [];
          _item['total_tax'] = fee.total_tax ? fee.total_tax : 0;
          _item['total_taxes'] = fee.total_taxes ? fee.total_taxes : [];
          _item['pid'] = fee.pid ? fee.pid : 0;

          if (fee.hasOwnProperty("modifiers_Id")) {
            _item["modifiers_Id"] = fee.modifiers_Id;
          }
          if (fee.hasOwnProperty("modifiers_key")) {
            _item["modifiers_key"] = fee.modifiers_key;
          }
          if (fee.hasOwnProperty("cart_discount_amount") && fee.cart_discount_amount != 0) {
            _item["cart_discount_amount"] = fee.cart_discount_amount;
          }
          if (fee.hasOwnProperty("product_discount_amount") && fee.product_discount_amount != 0) {
            _item["product_discount_amount"] = fee.product_discount_amount;
            _item['price'] -= fee.product_discount_amount * (fee.discount_type === "Number" ? 1 : fee.quantity);
          }
        }
      });
    })
  }
  return _item
}

export const getWCCustomFeeDetails = (_itemPara, isIncl) => {
  var _item = { ..._itemPara };
  var fee = _itemPara;
  if (_itemPara !== null) {
    var _total_tax = {};
    fee.total_taxes && fee.total_taxes.map((item, index) => {
      Object.assign(_total_tax, item)
    });
    var _Tax = JSON.stringify({ "total": _total_tax, "subtotal": _total_tax });
    _item['Price'] = isIncl == true ? _item.amount - fee.total_tax : _item.amount;
    _item['line_item_id'] = _item.fee_id;
    _item['quantity'] = 1;
    _item['total'] = isIncl == true ? _item.amount - fee.total_tax : _item.amount;
    _item['subtotal'] = isIncl == true ? _item.amount - fee.subtotal_tax : _item.amount;
    _item['name'] = _item.note;
    _item['is_ticket'] = false;
    _item['product_id'] = 0;
    _item['parent_id'] = 0;
    _item['Taxes'] = _Tax;

    _item['variation_id'] = 0;
    _item['amount_refunded'] = _item.amount_refunded;
    _item['quantity_refunded'] = _item.amount_refunded > 0 ? -1 : 0;
    _item['subtotal_tax'] = fee.subtotal_tax ? fee.subtotal_tax : 0;
    _item['subtotal_taxes'] = fee.subtotal_taxes ? fee.subtotal_taxes : [];
    _item['total_tax'] = fee.total_tax ? fee.total_tax : 0;
    _item['total_taxes'] = fee.total_taxes ? fee.total_taxes : [];
    _item['pid'] = fee.pid ? fee.pid : 0;
  }
  return _item
}
export const getPSummary = (single_Order_list, _item) => {
  var psummary = "";
  var getorderlist = get_order_oliverpos_product_discount_amount_meta_data(single_Order_list.content);
  //var getorderlist = single_Order_list && single_Order_list.content && single_Order_list.content.meta_datas && single_Order_list.content.meta_datas !== null ? single_Order_list.content.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount') : null;
  if (getorderlist !== null) {
    getorderlist = getorderlist && getorderlist.ItemValue && JSON.parse(getorderlist.ItemValue);
    getorderlist && getorderlist.map((item, index) => {
      if ((item.hasOwnProperty("product_id") && _item.product_id == item.product_id) || (item.hasOwnProperty("variation_id") && _item.product_id == item.variation_id)) {
        psummary = item.psummary && item.psummary != "" ? item.psummary : "";
      }
    })
  }
  return psummary;
}
export const getPTaxable = (single_Order_list, _item) => {
  var isTaxable = true;
  var getorderlist = [];
  if (single_Order_list && single_Order_list.hasOwnProperty("content")) {
    getorderlist = get_order_oliverpos_product_discount_amount_meta_data(single_Order_list.content)
    //getorderlist = single_Order_list && single_Order_list.content && single_Order_list.content.meta_datas && single_Order_list.content.meta_datas !== null ? single_Order_list.content.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount') : null;
  }
  else {
    getorderlist = get_order_oliverpos_product_discount_amount_meta_data(single_Order_list)
    //getorderlist = single_Order_list && single_Order_list.meta_datas && single_Order_list.meta_datas !== null ? single_Order_list.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount') : null;
  }

  if (getorderlist !== null) {
    getorderlist = getorderlist && getorderlist.ItemValue && JSON.parse(getorderlist.ItemValue);
    getorderlist && getorderlist.map((item, index) => {
      if ((item.hasOwnProperty("product_id") && _item.product_id == item.product_id) || (item.hasOwnProperty("variation_id") && _item.product_id == item.variation_id)) {
        if (typeof item.isTaxable !== 'undefined')
          isTaxable = item.isTaxable && item.isTaxable;
      }
    })
  }
  return isTaxable;
}
export const canManageInventory = () => {
  var _CanManageInventory = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).CanManageInventory : false;
  return _CanManageInventory;
}
export const allowCashManagement = () => {
  var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
  return client && client.subscription_permission && client.subscription_permission.AllowCashManagement;
}
export const getCustomFeeWC_Register = (single_Order_list, isIncl) => {
  var ListItem = [];
  //var isIncl = typeOfTax !== "" ? typeOfTax.toLocaleLowerCase().includes("incl") : false;
  var has_custom_fee_in_meta = false;
  //var getorderlist = single_Order_list && single_Order_list.meta_datas && single_Order_list.meta_datas !== null ? single_Order_list.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount') : null;
  var getorderlist = get_order_oliverpos_product_discount_amount_meta_data(single_Order_list);
  if (getorderlist !== null) {
    // getorderlist = getorderlist && getorderlist.ItemValue && JSON.parse(getorderlist.ItemValue);
    getorderlist && getorderlist.map((item, index) => {
      if (item.hasOwnProperty("order_custom_fee") == true) {
        has_custom_fee_in_meta = true;
      }
    });
  }
  // console.log("-----has_custom_fee_in_meta-----", has_custom_fee_in_meta);

  if (has_custom_fee_in_meta === false && typeof single_Order_list.order_custom_fee != "undefined" && single_Order_list.order_custom_fee != null && single_Order_list.order_custom_fee.length > 0) {
    single_Order_list.order_custom_fee.map(f => {
      if (f.hasOwnProperty("pid") && f.pid === 0) { ListItem.push(f); }
      else if (!f.hasOwnProperty("pid") && f.hasOwnProperty("fee_id") && f.fee_id !== 0) {
        var _f = getWCCustomFeeDetails(f, isIncl);
        if (_f) { ListItem.push(_f); }
      }
    })
  }
  else {
    if (typeof single_Order_list.order_custom_fee != "undefined" && single_Order_list.order_custom_fee != null && single_Order_list.order_custom_fee.length > 0) {
      single_Order_list.order_custom_fee.map(f => {
        if (f.hasOwnProperty("pid") && f.pid === 0)
          ListItem.push(f);
      })
    }
  }
  return ListItem;
}
export const getWCDiscount = (getorderlist, totalDiscount, taxType) => {
  var _totalDiscount = 0;
  if ( totalDiscount !== 0) {
    _totalDiscount = totalDiscount + (getorderlist.hasOwnProperty("CartDiscountTax") ? (taxType.toLowerCase().includes("incl") ? getorderlist.CartDiscountTax :0) : 0);
    var _point = _totalDiscount % 1;
    if (_point.toString().length >= 4) {
      var _decm = _point.toString().substring(3, 4);
      var _last = _point.toString().substring(4, _point.toString().length - 2);
      if (parseFloat("1." + _last) > 1 && _decm == 9) {
        _totalDiscount = _totalDiscount + 0.01;
        _totalDiscount = parseFloat(_totalDiscount.toString().substring(0, _totalDiscount.toString().length - 2));
      }
    }
  }

  return _totalDiscount;
}
export const checkNotifyPermission = () => {
  if (window.location.hostname !== Config.key.DEMO_USER_URL) {
    if (("Notification" in window)) {
      Notification.requestPermission().then(function (permission) {
        if (permission == "denied") {
          localStorage.setItem("hasNotifyPermission", false);
        }
        else if (permission == "granted") {
          localStorage.setItem("hasNotifyPermission", true)
        }
      });
    }
    else {
      // alert("This browser does not support desktop notification");
    }
  }
}
export const notificationStatus = () => {
  // return localStorage.getItem("hasNotifyPermission") ? localStorage.getItem("hasNotifyPermission") : 'false';
  if (window.location.hostname !== Config.key.DEMO_USER_URL) {
    if (("Notification" in window)) {
      Notification.requestPermission().then(function (permission) {
       // console.log('permiss', permission)
        if (permission == "denied") {
          localStorage.setItem("hasNotifyPermission", false);
          return false;
        }
        else if (permission == "granted") {
          localStorage.setItem("hasNotifyPermission", true)
          return true;
        }
      });
    }
  }

}
export const decodeHTML = (html) => {
  var temporalDivElement = document && document.createElement("div");
  temporalDivElement.innerHTML = html;
  return (temporalDivElement.textContent || temporalDivElement.innerText || "");
}
export const get_order_oliverpos_product_discount_amount_meta_data = (allMeta, isMeta = false) => {
  if (typeof allMeta == "undefined" || allMeta == null) {
    return null;
  }
  var allMetaData = isMeta == true ? allMeta : (allMeta && allMeta.meta_datas ? allMeta.meta_datas : null);

  if (allMetaData !== null && allMetaData.length > 0) {
    var _meta = "";
    if (allMetaData[0].hasOwnProperty("_order_oliverpos_product_discount_amount")) {
      _meta = allMetaData[0]._order_oliverpos_product_discount_amount;
      return _meta;
    }
    else {
      _meta = allMetaData.find(data => data.ItemName == '_order_oliverpos_product_discount_amount');
    }
    if (_meta !== null) {
      return _meta && _meta.ItemValue && JSON.parse(_meta.ItemValue);
    }
    return null;
  }
}
export const getModifierTotalAmount = (product) => {
  var modifier = [];
  var mamount = 0;
  var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
  if (cartlist.length > 0) {
    cartlist.map(findId => {
      if (findId.pid === product.WPID
        && (findId.hasOwnProperty("modifiers_key") && product.hasOwnProperty("modifiers_key") ? findId.modifiers_key == product.modifiers_key : true)
      ) {
        modifier.push(findId);
      }
    })
    modifier && modifier.map(a => { mamount = mamount + a.Price });
    //console.log("---amount=====" + mamount);
    return mamount;
  }
  else
  {
    return mamount;
  }
}