import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { sendOrderToCloudPrinter } from "../commonAPIs/cloudPrinterSlice";
import Checkmark from '../../../assets/images/svg/Checkmark.svg';
import { LoadingModal } from "./LoadingModal";
import STATUSES from "../../../constants/apiStatus";
const CloudPrinterListPopup = (props) => {
    const [respSendOrderToCloudPrinter] = useSelector((state) => [state.sendOrderToCloudPrinter]);
    const dispatch = useDispatch();
    const [printerList, setPrinterList] = useState(localStorage.getItem('cloudPrinters') ? JSON.parse(localStorage.getItem('cloudPrinters')) : []);
    // const [printerList, setPrinterList] = useState(  [{ "Id": 1, "Name": "Printer 1" }, { "Id": 2, "Name": "Printer 2" },
    // { "Id": 3, "Name": "Printer 3" }, { "Id": "localPrinter", "Name": "Local Printer" },
    // { "Id": 5, "Name": "Printer 5" }]);
  
    const [printerIds, setPrinterIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cloudPrinterErr,setCloudPrinterErr]=useState('');
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleCloudPrinter();
        }
        // else {
        //     e.stopPropagation();
        // }
    }
    useEffect(() => {
        const { status, data, error, is_success } = respSendOrderToCloudPrinter;
        if (status === STATUSES.error) {
            console.log(error);
            setIsLoading(false);
        }
        if (status === STATUSES.IDLE && is_success && data != "" && printerIds.length > 0) {
            console.log("---success---")
            setIsLoading(false);
            setPrinterIds([]);
            setTimeout(() => {
                props.toggleCloudPrinter();
            }, 200);
        }
    }, [respSendOrderToCloudPrinter]);
    const handleChange = (id) => {
        setCloudPrinterErr("");
        var _printerIds = printerIds;
        if (typeof _printerIds != "undefined" && _printerIds != null && _printerIds.length > 0) {
            var isId = _printerIds.indexOf(id);
            if (isId == -1) {
                _printerIds.push(id);
            }
            else {
                _printerIds = _printerIds.filter(a => a != id);
            }
        }
        else {
            _printerIds.push(id);
        }
        setPrinterIds(_printerIds);
        PrintClick();
       // console.log("--selected printer ids---" + JSON.stringify(_printerIds))
    }

    // var printerList = [{ "Id": 1, "Name": "Printer 1" }, { "Id": 2, "Name": "Printer 2" },
    // { "Id": 3, "Name": "Printer 3" }, { "Id": 4, "Name": "Printer 4" },
    // { "Id": 5, "Name": "Printer 5" }];
    //setPrinterId(d);
    const PrintClick = () => {
        if (printerIds && printerIds.length > 0) {
            var isLocalPrinterExist = printerIds.find(itm => itm == 'localPrinter')
            if (isLocalPrinterExist) {
                if (props.localPrinterPrint) {
                    props.localPrinterPrint();
                    props.toggleCloudPrinter();
                }
            } else if (printerIds.find(itm => itm !== 'localPrinter')) {
                var data = {
                    type: props.type,// type field will not send to API request, only use to check condition
                    printerId: printerIds,
                    orderId: props.order_id
                }
                setIsLoading(true);
                dispatch(sendOrderToCloudPrinter(data));
            }
        }
        else {
            setCloudPrinterErr(LocalizedLanguage.pleaseSelectPrinter);
            console.log("Please select printer");
        }

        // if (printerIds && printerIds.length > 0) {
        //     var data = {
        //         type: props.type,// 'activity'
        //         printerId: printerIds,
        //         orderId: props.order_id
        //     }
        //     setIsLoading(true);
        //     dispatch(sendOrderToCloudPrinter(data));
        // }
    }
    const LocalPrint = () => {
        if (props.localPrinterPrint) {
            props.localPrinterPrint();
            props.toggleCloudPrinter();
        }
    }
    return (
        <React.Fragment>
            {/* {loading === true ? <LoadingModal></LoadingModal> : null} */}
            <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
                <div className={props.isShow === true ? "subwindow receipt current" : "subwindow receipt"}>
                    <div className="subwindow-header">
                        <p>{LocalizedLanguage.sendReceiptToPrinter}</p>
                        <button className="close-subwindow" onClick={() => props.toggleCloudPrinter()}>
                            <img src={X_Icon_DarkBlue} alt="" />
                        </button>
                    </div>
                    {cloudPrinterErr!=""?<div className="error-message" style={{margin:"unset"}}>{cloudPrinterErr}</div>:""}
                    <div className="subwindow-body">
                        {/* <div className="receipt-options">
                                <button onClick={() => LocalPrint()}>Local printer</button>
                            </div> */}
                        {/* <label className="cloud-printer-wrapper" id="cloud-printer-wrapper">
                                <p>Apply taxes to custom fee</p>
                                <input type="checkbox"
                                    //  id="isCustomFeeTaxed" 
                                    value={true}
                                    // checked={true}
                                    //  checked={this.state.site === result.SITE_NAME}
                                    onChange={null} />
                                <div className="custom-toggle"></div>
                            </label> */}
                        {
                            printerList && printerList.map(item => {
                                return (

                                    // <label className="cloud-printer-wrapper" id="cloud-printer-wrapper" >
                                    //     <p>{item.Name}</p>
                                    //     <input type="checkbox"
                                    //         //  id="isCustomFeeTaxed" 
                                    //         value={true}
                                    //         // checked={true}
                                    //         //  checked={this.state.site === result.SITE_NAME}
                                    //         // onChange={null} 
                                    //         onChange={() => handleChange(item.Id)}
                                    //     />
                                    //     <div className="custom-toggle"></div>
                                    // </label>
                                    <div className="receipt-options">
                                        <button onClick={() => handleChange(item.Id)}>{item.Name}</button>
                                    </div>
                                )
                            })
                        }
                        <div className="receipt-options">
                            <button onClick={() => LocalPrint()}>{LocalizedLanguage.localPrinter}</button>
                            {/* <button onClick={() => PrintClick()}>{LocalizedLanguage.print}</button> */}
                        </div>
                    </div>
                </div></div>
            {isLoading === true && printerIds.length > 0 ? <LoadingModal></LoadingModal> : null}
        </React.Fragment>

    )
}

export default CloudPrinterListPopup