import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
import EmptyCart from '../../../assets/images/svg/EmptyCart.svg';
import CircledX_Grey from '../../../assets/images/svg/CircledX-Grey.svg';
import { deleteProduct } from './productLogic';
import { RoundAmount } from "../../common/TaxSetting";
import { product } from "./productSlice";
import { useNavigate } from "react-router-dom";
// import { checkStock } from "../../checkout/checkoutSlice";
import { typeOfTax } from "../../common/TaxSetting";
import STATUSES from "../../../constants/apiStatus";
import { LoadingModal } from "../../common/commonComponents/LoadingModal";
import { popupMessage } from "../../common/commonAPIs/messageSlice";
import { get_customerName } from "../../common/localSettings";
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { useIndexedDB } from 'react-indexed-db';
// import { NumericFormat } from 'react-number-format';
import { paymentAmount } from "../../checkout/checkoutSlice";
import { v4 as uniqueKey } from 'uuid';
import { Markup } from "interweave";
import { currecyFunction } from "../../common/commonFunctions/currencyFunction";
import keyDisplayOrderStatus from "../../../settings/KeysDisplayOrderStatus"
import { getNewReceiptId } from "../../../settings";
const CartList = (props) => {
    const cartScroll = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [subTotal, setSubTotal] = useState(0.00);
    const [taxes, setTaxes] = useState(0.00);
    const [discount, setDiscount] = useState(0.00);
    const [total, setTotal] = useState(0.00);
    const [taxRate, setTaxRate] = useState(0.00);
    const [checkoutData, setCheckoutData] = useState(false);
    const [updateProductStatus, setUpdateProductStatus] = useState(false)
    const [checkseatStatus, setCheckseatStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [totalItems, setTotalItems] = useState(0)
    const [isShowMobileCartList, setisShowMobileCartList] = useState(false)
    const [discountType, setDiscountType] = useState('');
    const [discountCalculated, setDiscountCalculated] = useState(0);
    const [showTaxStaus, setShowTaxStaus] = useState(false);
    const { getByID } = useIndexedDB("products");
    const toggleMobileCartList = () => {
        setisShowMobileCartList(!isShowMobileCartList)
    }
    useEffect(() => {
        getDiscountAmount_Type();
        calculateCart();

    }, [props.listItem]);


    const editPopUp = async (a, index) => {
        if (a && (a.Type === "variation" || a.Type === "variable" || a.Type == "")) {
            var _item = await getByID(a.product_id);
            _item["quantity"] = a.quantity;
            _item["old_price"] = a.old_price ? a.old_price : 0;
            _item["incl_tax"] = a.incl_tax ? a.incl_tax : 0;
            _item["excl_tax"] = a.excl_tax ? a.excl_tax : 0;
            _item['after_discount'] = a ? a.after_discount : 0;
            _item['discount_amount'] = a ? a.discount_amount : 0;
            _item['product_after_discount'] = a ? a.product_after_discount : 0;
            _item['product_discount_amount'] = a ? a.product_discount_amount : 0;
            _item['discount_type'] = a ? a.discount_type : "";
            _item['new_product_discount_amount'] = a ? a.new_product_discount_amount : 0;
            _item['cart_after_discount'] = a.cart_after_discount ? a.cart_after_discount : 0;
            _item['cart_discount_amount'] = a.cart_discount_amount ? a.cart_discount_amount : 0;
            if (a.hasOwnProperty("modifiers_Id")) {
                _item['modifiers_Id'] = a.modifiers_Id;
            }
            if (a.hasOwnProperty("modifiers_key")) {
                _item['modifiers_key'] = a.modifiers_key;
            }
            if (a && a.hasOwnProperty("combination") && a.combination != null) {
                if (_item.combination == null || (_item.combination != null && _item.combination.indexOf("*") > 0))
                    _item['combination'] = a.combination ? a.combination : '';
            }
            if (_item && _item.ParentId !== 0) {
                var _parent = await getByID(_item.ParentId);

                var allCombi = _item && _item.combination !== null && _item.combination !== undefined && _item.combination.split("~");
                allCombi = allCombi.map(a => { return a.replace(/\//g, "-").toLowerCase() });

                _parent["selectedOptions"] = allCombi;
                _parent["quantity"] = a.quantity;
                _parent["old_price"] = a.old_price ? a.old_price : 0;
                _parent["incl_tax"] = a.incl_tax ? a.incl_tax : 0;
                _parent["excl_tax"] = a.excl_tax ? a.excl_tax : 0;
                _parent['after_discount'] = a ? a.after_discount : 0;
                _parent['discount_amount'] = a ? a.discount_amount : 0;
                _parent['product_after_discount'] = a ? a.product_after_discount : 0;
                _parent['product_discount_amount'] = a ? a.product_discount_amount : 0;
                _parent['discount_type'] = a ? a.discount_type : "";
                _parent['new_product_discount_amount'] = a ? a.new_product_discount_amount : 0;
                _parent['cart_after_discount'] = a.cart_after_discount ? a.cart_after_discount : 0;
                _parent['cart_discount_amount'] = a.cart_discount_amount ? a.cart_discount_amount : 0;

                _parent['StockQuantity'] = _item.StockQuantity - a.quantity;
                _parent["quantity"] = a.quantity;
                _parent["SelVariationId"] = _item.WPID;

                if (a.hasOwnProperty("modifiers_Id")) {
                    _parent['modifiers_Id'] = a.modifiers_Id;
                }
                if (a.hasOwnProperty("modifiers_key")) {
                    _parent['modifiers_key'] = a.modifiers_key;
                }
                //---stock
                // _item["selectedOptions"] = allCombi;
                // _item['StockQuantity'] = _item.StockQuantity - a.quantity;
                //------

                props.updateVariationProduct(_item);
                // props.openPopUp(_parent, index);
                props.openPopUp(_parent, index);
            }
            else {
                props.updateVariationProduct(_item);
                props.openPopUp(_item, index);
            }
        }
        else {
            props.updateVariationProduct(null);
            props.openPopUp(a, index);
        }
    }
    const getDiscountAmount_Type = () => {
        var CurrencySymbol = localStorage.getItem('currecySymbole') ? localStorage.getItem('currecySymbole'):"$";
        if (localStorage.getItem("CART")) {
            let cart = JSON.parse(localStorage.getItem("CART"));
            let dtype = cart.discountType === "Percentage" ? '%' : CurrencySymbol;
            let damount = cart.discount_amount;

            // if (cart && cart.hasOwnProperty("real_type") && cart.real_type == "Number") {
            //     damount = parseFloat((cart.discount_amount * 100) / total).toFixed(2);
            // }
            if (cart.discountType === "Percentage") {
                setDiscountType(damount + dtype);
            }
            else {
                setDiscountType(dtype + "" + damount);
            }


        }
        else {
            setDiscountType('')
        }
    }
    const deleteItem = (item) => {
        if (item) {
            deleteProduct(item);
            //deleting the product note for the product
            var products = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
            if (products.length > 0) {
                var notes = products.filter(a => a.hasOwnProperty("pid") && !a.hasOwnProperty("product_id") && (!a.hasOwnProperty("Price") || a.Price === null) && a.pid === item.product_id);
                if (notes && notes.length > 0) {
                    notes.map(n => {
                        deleteProduct(n);
                    })
                }
            }
            dispatch(product());
        }
    }
    const doCheckout = () => {
        checkout(props.listItem);
        //navigate('/checkout');
    }

    const checkout = (ListItem) => {
        // ListItem && ListItem.length > 0 && ListItem.map(litem => {
        //     if (this.props.AllProductList) {
        //         var itemFound = this.props.AllProductList.find(item => item.WPID === litem.product_id);
        //         if (itemFound) {
        //             litem["ManagingStock"] = itemFound.ManagingStock;
        //         }
        //     }
        // })
        //this.setState({ isLoading: true })
        setIsLoading(true);
        localStorage.removeItem('RESERVED_SEATS');
        localStorage.removeItem("BOOKED_SEATS");
        localStorage.removeItem("CurrentTransactionStatus");

        var setting = localStorage.getItem('TickeraSetting') && typeof (localStorage.getItem('TickeraSetting')) !== 'undefined' && localStorage.getItem('TickeraSetting') !== 'undefined' ? JSON.parse(localStorage.getItem('TickeraSetting')) : '';
        //var selectedSeats = [];
        var tikeraSelectedSeats = localStorage.getItem('TIKERA_SELECTED_SEATS') ? JSON.parse(localStorage.getItem('TIKERA_SELECTED_SEATS')) : [];
        var getDistinctTicketSeat = {};
        tikeraSelectedSeats.length > 0 && tikeraSelectedSeats.map(item => {
            if (item.seat_check === "true") {
                var dateKey = item.product_id;
                if (!getDistinctTicketSeat.hasOwnProperty(dateKey)) {
                    getDistinctTicketSeat[dateKey] = new Array(item);
                } else {
                    if (typeof getDistinctTicketSeat[dateKey] !== 'undefined' && getDistinctTicketSeat[dateKey].length > 0) {
                        getDistinctTicketSeat[dateKey].push(item)
                    }
                }
            }
        })
        var productCount = 0;

        ListItem !== null && ListItem.length > 0 && ListItem.map(items => {
            if (items.product_id && items.product_id !== null) {
                productCount += 1;
                if (getDistinctTicketSeat) {
                    var getTicketSeats = getDistinctTicketSeat[items.product_id];
                    getTicketSeats && getTicketSeats.map((Tkt, indexing) => {
                        if (Tkt.product_id === items.product_id) {
                            items.ticket_info && items.ticket_info.map((match_index, index) => {
                                if (index === indexing) {
                                    match_index['seat_label'] = Tkt.seat_label;
                                    match_index['chart_id'] = Tkt.chart_id;
                                    match_index['seat_id'] = Tkt.seat_id;
                                }
                            })
                        }
                    })
                }
            }
        })
        var discountIs = 0;
        discountIs = discountCalculated;
        var _checklist = (typeof localStorage.getItem("CHECKLIST") !== 'undefined') ? JSON.parse(localStorage.getItem("CHECKLIST")) : null;
        // var taxratelist;
        var _TaxIs = [];
        var deafult_tax = localStorage.getItem('APPLY_DEFAULT_TAX') ? JSON.parse(localStorage.getItem("APPLY_DEFAULT_TAX")) : null;
        var selected_tax = localStorage.getItem('TAXT_RATE_LIST') ? JSON.parse(localStorage.getItem("TAXT_RATE_LIST")) : null;
        var apply_defult_tax = localStorage.getItem('DEFAULT_TAX_STATUS') ? localStorage.getItem('DEFAULT_TAX_STATUS').toString() : null;
        var TaxRate = apply_defult_tax === "true" ? deafult_tax : selected_tax;
        if (TaxRate && TaxRate.length > 0) {
            TaxRate.map(addTax => {
                if (addTax.check_is === true) {
                    if (apply_defult_tax === "true") {
                        _TaxIs.push({ [addTax.TaxId]: parseFloat(addTax.TaxRate) })
                    }
                    if (apply_defult_tax === "false") {
                        _TaxIs.push({ [addTax.TaxId]: parseFloat(addTax.TaxRate) })
                    }
                }
            })
        }
        // const { dispatch } = this.props;
        // const { addcust, taxRate } = this.state;
        var addcust = null
        var AdCusDetail = localStorage.getItem('AdCusDetail');
        if (typeof AdCusDetail != "undefined" && AdCusDetail !== null) {
            addcust = AdCusDetail && JSON.parse(AdCusDetail);
        }
        //Create new receipt Id-----------       
        const ReciptId = getNewReceiptId()

        // var taxRate = [];
        const CheckoutList = {
            ListItem: ListItem,
            customerDetail: addcust,
            totalPrice: total,
            discountCalculated: discountIs ? discountIs : 0,
            tax: taxes,
            subTotal: subTotal,
            TaxId: _TaxIs,
            TaxRate: taxRate,
            status: _checklist !== null ? _checklist.status : null,
            order_id: _checklist !== null ? _checklist.order_id : 0,
            order_date: _checklist !== null ? _checklist.order_date : null,
            oliver_pos_receipt_id: _checklist && _checklist !== null && _checklist.oliver_pos_receipt_id !== null ? _checklist.oliver_pos_receipt_id : ReciptId,
            showTaxStaus: showTaxStaus,
            //showTaxStaus: typeOfTax(),
            _wc_points_redeemed: 0,
            _wc_amount_redeemed: 0,
            _wc_points_logged_redemption: 0
        }

        if (addcust && addcust !== null) {
            sessionStorage.setItem("CUSTOMER_ID", addcust.UID ? addcust.UID : addcust.WPId);
        } else {
            sessionStorage.removeItem("CUSTOMER_ID");
        }
        if (ListItem.length === 0 || productCount === 0) {
            // alert("Please add at least one product in cart !");
            // dispatch(popupMessage({data:{title:"",msg:"Please add at least one product in cart !"},is_success:true}));
            var data = { title: LocalizedLanguage.warningTitle, msg: LocalizedLanguage.messageCartNoProduct, is_success: true, img: "stock" }
            dispatch(popupMessage(data));
            setIsLoading(false)
        } else {
            setUpdateProductStatus(true);
            var tickValiData = []
            var field = ''
            var staticField_value = ["first_name", "last_name", "owner_email"];
            var staticField = [];
            var quant = 0;
            ListItem.find(findId => {
                quant = findId.quantity;
                if (findId.ticket_status === true && (findId.ticket_info.length === 0 || quant !== findId.ticket_info.length)) {
                    staticField = staticField_value.filter(function (value, index, arr) {
                        return value === "first_name" && setting !== null && setting !== undefined && setting.show_attendee_first_and_last_name_fields === "yes" ||
                            value === "last_name" && setting !== null && setting !== undefined && setting.show_attendee_first_and_last_name_fields === "yes"
                            || value === "owner_email" && setting !== null && setting !== undefined && setting.show_owner_email_field === "yes";
                    });
                    tickValiData.push(findId.ticket_info);
                    field = findId.product_ticket ? JSON.stringify(findId.product_ticket.fields) : ''
                }

            })
            if (staticField.length === 0) {
                setCheckoutData(false);
            }
            if (tickValiData && tickValiData.length > 0) {
                var requiredDataIsNull = tickValiData.find(is_null => is_null.length === 0 || quant !== is_null.length);
                if (requiredDataIsNull) {
                    var requiredList = field && JSON.parse(field);
                    var a = requiredList ? requiredList.map(itm => {
                        var is_field = itm.field_info && JSON.parse(itm.field_info);
                        if (is_field.is_required === true || staticField !== '') {
                            setCheckoutData(true);
                        }
                    })
                        :
                        staticField !== ''
                    setCheckoutData(true);
                }
            }
            if (checkoutData === true) {
                setIsLoading(false)
                // this.setState({ isLoading: false })
                alert("Please fill the required fields of selected ticket.");
                //     if (isMobileOnly === true) {
                //         $('#common_msg_popup').addClass('show')
                //     }
                //    // $('#common_msg_popup').modal('show')
                //     showModal('common_msg_popup');
            }
            checkoutData === false && ListItem.map(findId => {
                if (findId.ticket_info && findId.ticket_info.length > 0 && findId.ticket_info !== '[]') {
                    findId.ticket_info.map(chart_Id => {
                        var chart_id = chart_Id && chart_Id.chart_id ? chart_Id.chart_id : null;
                        if (chart_id) {
                            setCheckseatStatus(true)
                            // ### this.setState({
                            //     isLoading: true,
                            //     checkseatStatus: true
                            // })
                            //### this.props.dispatch(cartProductActions.getReservedTikeraChartSeat(chart_id, 1));
                        }
                    })
                }
            })
            if (addcust && addcust.customerDetail && addcust.customerDetail.content) {
                var cust = addcust.customerDetail.content;
                sessionStorage.setItem("CUSTOMER_ID", cust.UID ? cust.UID : cust.WPId);
            }
            localStorage.setItem("CHECKLIST", JSON.stringify(CheckoutList))
            var demoUser = localStorage.getItem("demoUser") ? localStorage.getItem("demoUser") : false;
            if (demoUser === 'true') {
                localStorage.removeItem("BACK_CHECKOUT")
                navigate('/checkout')
                //window.location = '/checkout'
            } else {
                checkItemList(CheckoutList);
                //dispatch(checkoutActions.checkItemList(CheckoutList))
            }
        }
    }
    const checkStockFormIndexDb = async (cartlist) => {
        var items = [];
        var count = 0;
        if (cartlist != null) {
            cartlist && cartlist.map(async value => {
                var id = value.variation_id == 0 ? value.product_id : value.variation_id;              
                //var result = await getByID(id)
                var result = id && await getByID(id).then((row) => {
                    return row;
                });
                
                //Quantity Exceeded.
                //success: false
                if (result) {
                    if (result.StockQuantity >= value.quantity || result.ManagingStock == false || result.BackOrderd === "yes" || result.BackOrderd === 'notify') {
                        items.push({ "ProductId": id, "Quantity": value.quantity, "Message": "", "success": true });
                    }
                    else {
                        items.push({ "ProductId": id, "Quantity": result.StockQuantity, "Message": "Quantity Exceeded.", "success": false });
                    }
                }


                count++;
                if (cartlist.length == count) {
                    //console.log("--stock result--" + JSON.stringify(items));
                    setIsLoading(false);
                    var checkProductUpdate;
                    var IsExist = false;
                    var IsExsitTicket = false;
                    checkProductUpdate = items.find(item => (item.success === false || item.Quantity === 0));
                    if (checkProductUpdate && checkProductUpdate.ProductId !== 0) {
                        IsExist = false;
                        checkStockResult(items);
                    } else {
                        IsExist = true;
                        var CHECKLIST = localStorage.getItem("CHECKLIST") ? JSON.parse(localStorage.getItem("CHECKLIST")) : null;
                        var cartproductlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : null;
                        if (cartproductlist) {
                            cartproductlist && cartproductlist.map(ticketInfo => {
                                if (ticketInfo.ticket_info && ticketInfo.ticket_info.length > 0 && ticketInfo.ticket_info !== "[]") {
                                    CHECKLIST && CHECKLIST.ListItem.map(findId => {
                                        if (findId.ticket_info && findId.ticket_info.length > 0) {
                                            findId.ticket_info.map(chart_Id => {
                                                var chart_id = chart_Id && chart_Id.chart_id ? chart_Id.chart_id : null;
                                                if (chart_id) {
                                                    IsExsitTicket = true;
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }

                        if (IsExist === true && IsExsitTicket === false && checkseatStatus === false && checkoutData === false) {
                            localStorage.removeItem("oliver_order_payments");
                            localStorage.removeItem("VOID_SALE");
                            dispatch(paymentAmount(null));
                            navigate('/checkout');
                        }
                        setUpdateProductStatus(true);
                    }

                    //checkStockResult(items);
                    //return items;
                }
            })


            //console.log("--stock result--"+JSON.stringify(items));
        }
    }
    const checkItemList = (checkout_list) => {
        //var list_item = checkout_list ? checkout_list.ListItem : null;   
        const layaway_status = localStorage.getItem("CHECKLIST") && JSON.parse(localStorage.getItem("CHECKLIST"));
        var isLayAway = false;
        if (layaway_status && layaway_status.status != null) {
            if (layaway_status.status.toLowerCase() == "on-hold" || layaway_status.status.toLowerCase() == 'lay_away' || layaway_status.status.toLowerCase() == keyDisplayOrderStatus.key["on-hold"].toLowerCase()) {
                isLayAway = true;
            }
        }
        var list_item = checkout_list && checkout_list.ListItem ? checkout_list.ListItem.filter(item => { return (item.ManagingStock !== false) }) : null;
        if (!list_item || list_item === null || list_item.length === 0) {
            var _item = [];
            if (checkout_list && checkout_list.ListItem) {
                checkout_list.ListItem.map(item => {
                    if (item.hasOwnProperty("product_id") && !(item.BackOrderd == "notify" || item.BackOrderd == "yes")) {
                        if (isLayAway == true && item.line_item_id == 0) {
                            _item.push({ Message: "", ProductId: item.product_id, Quantity: item.quantity, Message: "", success: true, psummary: item.psummary });
                        }
                        else if (isLayAway == false) {
                            _item.push({ Message: "", ProductId: item.product_id, Quantity: item.quantity, Message: "", success: true, psummary: item.psummary });
                        }
                    }
                });
            }
            if (_item && _item.length > 0) {
                checkStockFormIndexDb(_item);
            }
            else {
                setIsLoading(false);
                localStorage.removeItem("oliver_order_payments");
                localStorage.removeItem("VOID_SALE");
                dispatch(paymentAmount(null));
                navigate('/checkout');
            }

            // var result = checkStockFormIndexDb(_item);
            // console.log("--stock result--" + JSON.stringify(result));
            //dispatch(checkStock(_item));
            //  dispatch(success( _item ));
            //return _item;
        } else {
            var demoUser = localStorage.getItem("demoUser") ? localStorage.getItem("demoUser") : false;
            if (demoUser === false) {
                var _item = [];
                if (list_item) {
                    list_item.map(item => {
                        if (item.hasOwnProperty("product_id") && !(item.BackOrderd == "notify" || item.BackOrderd == "yes")) {
                            if (isLayAway == true && item.line_item_id == 0) {
                                _item.push(item);
                            }
                            else if (isLayAway == false) {
                                _item.push(item);
                            }
                        }
                    });
                }
                if (_item && _item.length > 0) {
                    checkStockFormIndexDb(_item);
                }
                else {
                    setIsLoading(false);
                    localStorage.removeItem("oliver_order_payments");
                    localStorage.removeItem("VOID_SALE");
                    dispatch(paymentAmount(null));
                    navigate('/checkout');
                }
                //var result = checkStockFormIndexDb(_item);
                //console.log("--stock result--" + JSON.stringify(result));
                //dispatch(checkStock(_item));
            }
        }

    }
    const checkStockResult = (checkout_list) => {
        var msg = '';
        var title = '';
        var cartProductList = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : null;
        var blank_quntity = []
        var new_data = [];
        checkout_list && checkout_list.map(checkfalse => {
            if ((checkfalse.success === false || checkfalse.Quantity === 0)) {
                new_data.push(checkfalse)
            }
        })

        if (cartProductList && new_data) {
            new_data.map(isExsit => {
                cartProductList.map(idExsit => {
                    if (idExsit.variation_id === 0 ? idExsit.product_id === isExsit.ProductId : idExsit.variation_id === isExsit.ProductId) {
                        blank_quntity.push(idExsit.Title)
                    }
                })
            })
        }
        if (blank_quntity.length > 0) {
            title = LocalizedLanguage.messageCartProductNotAvailable;

            msg += blank_quntity.map(name => {
                return (
                    blank_quntity.length === 1 ? name : msg === "" ? name : ", " + name
                )
            })
        }
        else {
            msg = LocalizedLanguage.messageCartNoProduct;
        }
        //show message popup here

        var data = { title: title, msg: msg, is_success: true }
        dispatch(popupMessage(data));
        //alert(msg);
        setIsLoading(false)
    }
    const calculateCart = () => {
        var _subtotal = 0.0;
        var _total = 0.0;
        var _taxAmount = 0.0;
        var _totalDiscountedAmount = 0.0;
        var _customFee = 0.0;
        var _exclTax = 0;
        var _inclTax = 0;
        var _taxId = [];
        var _taxRate = [];
        var TaxIs = [];
        var _subtotalPrice = 0.00;
        var _subtotalDiscount = 0.00;
        var _cartDiscountAmount = 0.00;
        var _productDiscountAmount = 0.00;
        var _seprateDiscountAmount = 0.00;
        var taxratelist = [];
        if ((typeof localStorage.getItem('TAXT_RATE_LIST') !== 'undefined') && localStorage.getItem('TAXT_RATE_LIST') !== null) {
            taxratelist = localStorage.getItem('TAXT_RATE_LIST') && JSON.parse(localStorage.getItem('TAXT_RATE_LIST'));
        }
        if (taxratelist && taxratelist !== null && taxratelist !== "undefined") {
            taxratelist && taxratelist.length > 0 && taxratelist.map(tax => {
                _taxId.push(tax.TaxId);
                _taxRate.push(tax.TaxRate);
                if (tax.check_is === true) {
                    TaxIs.push({ [tax.TaxId]: parseFloat(tax.TaxRate) })
                }
            })
            // this.setState({
            //     isChecked: _taxId,
            //     TaxId: _taxId,
            //     taxRate: _taxRate,
            //     TaxIs: TaxIs
            // })
            setTaxRate(_taxRate);
        }
        _taxRate = taxRate;
        props.listItem && props.listItem.map((item, index) => {
            if (item.Price) {
                _subtotalPrice += item.Price
                _subtotalDiscount += parseFloat(item.discount_amount === null || isNaN(item.discount_amount) === true ? 0 : item.discount_amount)
                //ADDED CODE TO MAKE CONDITION TRUE FOR MODIFIER
                if (item.product_id || (item.hasOwnProperty("pid") && item.hasOwnProperty("modifiers_key") && item.hasOwnProperty("Price"))) {//donothing  
                    var isProdAddonsType = "";//CommonJs.checkForProductXAddons(item.product_id);// check for productX is Addons type products                  
                    _exclTax += item.excl_tax ? item.excl_tax : 0;
                    _inclTax += item.incl_tax ? item.incl_tax : 0;
                    _cartDiscountAmount += item.cart_discount_amount;
                    // _productDiscountAmount += item.discount_type === "Number" ? item.product_discount_amount:item.product_discount_amount; // quantity commment for addons
                    _productDiscountAmount += item.discount_type === "Number" ? item.product_discount_amount : item.product_discount_amount * (isProdAddonsType && isProdAddonsType === true ? 1 : item.quantity);
                }
                else {
                    _customFee += item.Price;
                    _exclTax += item.excl_tax ? item.excl_tax : 0;
                    _inclTax += item.incl_tax ? item.incl_tax : 0;
                }
            }
        })

        //total count of the prodcuts in the cart
        setTotalItems(0)
        if (props.listItem && props.listItem.length > 0) {
            var qty = 0;
            props.listItem.map(item => {
                if (item && item.Price && item.Price !== "" && typeof item.product_id !== "undefined") {
                    qty += item.quantity;
                }
            })
            if (qty !== 0) {
                setTotalItems(qty)
            }

        }

        _seprateDiscountAmount = _subtotalPrice - _subtotalDiscount;
        _subtotal = _subtotalPrice - _productDiscountAmount;
        _totalDiscountedAmount = _subtotalDiscount;
        if (_taxRate) {
            _taxAmount = parseFloat(_exclTax) + parseFloat(_inclTax);
        }
        _total = parseFloat(_seprateDiscountAmount) + parseFloat(_exclTax);
        setSubTotal(RoundAmount(_subtotal));
        setTotal(RoundAmount(_total));
        setDiscount(_cartDiscountAmount > 0 ? RoundAmount(_cartDiscountAmount) : 0);
        setTaxes(RoundAmount(_taxAmount));
        setDiscountCalculated(_totalDiscountedAmount > 0 ? RoundAmount(_totalDiscountedAmount) : 0);
        setShowTaxStaus(typeOfTax() === 'incl' ? LocalizedLanguage.inclTax : LocalizedLanguage.exclTax);
        //    this.setState({
        //         subTotal: RoundAmount(_subtotal),
        //         totalAmount: RoundAmount(_total),// parseFloat(_subtotal) - parseFloat(nextProps.discountAmount),           
        //         discountAmount: nextProps.discountAmount,
        //         discountType: nextProps.discountType,
        //         taxAmount: RoundAmount(_taxAmount), //(( parseFloat(_subtotal) - parseFloat(nextProps.discountAmount))% parseFloat(this.state.taxRate))*100.0           
        //         discountCalculated: _totalDiscountedAmount > 0 ? RoundAmount(_totalDiscountedAmount) : 0,
        //         showTaxStaus: typeOfTax() === 'incl' ? LocalizedLanguage.inclTax : LocalizedLanguage.exclTax,
        //         cartDiscountAmount : _cartDiscountAmount
        //     })  
    }
    // const [resCheckStock] = useSelector((state) => [state.checkStock])
    // useEffect(() => {
    //     if (resCheckStock && resCheckStock.status === STATUSES.IDLE && resCheckStock.is_success) {
    //         return;
    //     }
    // }, [resCheckStock]);

    const showTaxListIfSubscription = () => {
        if (typeOfTax() !== "incl") {
            var subscriptionClientDetail = localStorage.getItem('clientDetail') ? JSON.parse(localStorage.getItem('clientDetail')) : '';
            if (subscriptionClientDetail && subscriptionClientDetail.subscription_detail && subscriptionClientDetail.subscription_detail.subscription_type === "oliverpos-free") {
                var data = { title: LocalizedLanguage.subscriptionFreeParaOne, msg: LocalizedLanguage.subscriptionFreeParatwo, is_success: true }
                // var data = { title: "This “Feature” is not included in your plan!", msg: 'In order to upgrade please go to the Oliver HUB', is_success: true }
                dispatch(popupMessage(data));
            }
            else {
                props.toggleTaxList()
            }
        }
    }
    const RemoveCustomer = () => {
        localStorage.removeItem('AdCusDetail');
        sessionStorage.removeItem("CUSTOMER_ID");
        sessionStorage.removeItem("addcutomertosale");
        var list = localStorage.getItem('CHECKLIST') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null;
        if (list !== null) {
            var _wc_amount_redeemed = list._wc_amount_redeemed ? parseFloat(list._wc_amount_redeemed) : 0
            const CheckoutList = {
                ListItem: list.ListItem,
                customerDetail: null,
                totalPrice: parseFloat(list.totalPrice) + _wc_amount_redeemed,
                discountCalculated: parseFloat(list.discountCalculated) - _wc_amount_redeemed,
                tax: list.tax,
                subTotal: list.subTotal,
                TaxId: list.TaxId,
                showTaxStaus: list.showTaxStaus,
                TaxRate: list.TaxRate,
                order_id: list.order_id,
                oliver_pos_receipt_id: list.oliver_pos_receipt_id,
                order_date: list.order_date,
                // order_id: list.order_id,
                status: list.status,
                _wc_points_redeemed: 0,
                _wc_amount_redeemed: 0,
                _wc_points_logged_redemption: 0
            }
            localStorage.setItem('CHECKLIST', JSON.stringify(CheckoutList))
        }
        dispatch(product());
    }

    // var deafult_tax = localStorage.getItem('APPLY_DEFAULT_TAX') ? JSON.parse(localStorage.getItem("APPLY_DEFAULT_TAX")) : null;
    var selected_tax = localStorage.getItem('TAXT_RATE_LIST') ? JSON.parse(localStorage.getItem("TAXT_RATE_LIST")) : null;
    var apply_defult_tax = localStorage.getItem('DEFAULT_TAX_STATUS') ? localStorage.getItem('DEFAULT_TAX_STATUS').toString() : null;
    var taxRates = ''
    var TaxRate = selected_tax//apply_defult_tax === "true" ? deafult_tax : selected_tax;
    // var Price = RoundAmount(price);
    //var Price = parseFloat(price);

    if (TaxRate && TaxRate.length > 0) {
        TaxRate.map(addTax => {
            if (apply_defult_tax === "true" && addTax.TaxRate !== "" && addTax.TaxClass === "") {// for default tax
                taxRates = taxRates + (taxRates !== "" ? "," : "") + addTax.TaxRate;
            } else if (apply_defult_tax === "false" && addTax.check_is === true && addTax.TaxRate !== "") {
                taxRates = taxRates + (taxRates !== "" ? "," : "") + addTax.TaxRate;

            }
        })
    }
    const goToCustomer = (email) => {
        if (email !== '') {
            sessionStorage.setItem("customerredirect", email);
            navigate('/customers')
        }
    }
    useEffect(() => {
        window.scrollBy(0, 200);
        cartScroll.current.scrollBy(0, cartScroll.current.scrollHeight)
    }, [props.listItem])
    //console.log("taxRates", taxRates)
    return (
        <React.Fragment>
            {isLoading ? <LoadingModal></LoadingModal> : null}
            <div className={isShowMobileCartList === true ? "cart open" : "cart"}>
                <div className="mobile-header">
                    <p>{LocalizedLanguage.cart}</p>
                    <button id="exitCart" onClick={() => toggleMobileCartList()}>
                        <img src={X_Icon_DarkBlue} alt="" />
                    </button>
                </div>
                <div className="body" ref={cartScroll}>
                    <img src={EmptyCart} alt="" />

                    {get_customerName() !== null && <div className="cart-item">
                        <div className="main-row aligned">
                            <div className="tag customer" >{LocalizedLanguage.customer}</div>
                            <div className="content-style trim-long-email" onClick={() => goToCustomer(get_customerName().Email)}>{get_customerName().Name}</div>
                            <button className="remove-cart-item" onClick={() => RemoveCustomer()}>
                                <img src={CircledX_Grey} alt="" />
                            </button>
                        </div>
                    </div>}
                    {props && props.listItem && props.listItem.length > 0 && props.listItem.map((a, index) => {

                        var notes = props.listItem.find(b => b.hasOwnProperty('pid') && a.hasOwnProperty('product_id') && (b.pid === a.product_id /*&& b.vid === a.variation_id*/) && (!b.hasOwnProperty("check_modifer") && b.hasOwnProperty("modifiers_key") && a.hasOwnProperty("modifiers_key") && a.modifiers_key == b.modifiers_key));
                        var modifirList = props.listItem.filter(b => b.hasOwnProperty('pid') && (b.pid === a.product_id) && (b.hasOwnProperty("check_modifer")) && b.hasOwnProperty("modifiers_key") && a.hasOwnProperty("modifiers_key") && a.modifiers_key == b.modifiers_key);
                        var item_type = "";
                        if ((!a.hasOwnProperty('Price') || a.Price === null) && !a.hasOwnProperty('product_id')) { item_type = "no_note"; }
                        else if (a.hasOwnProperty('product_id') && a.product_id !== 0) { item_type = "product"; }
                        else if (a.hasOwnProperty('Price') && !a.hasOwnProperty('product_id') && a.hasOwnProperty("check_modifer") && a.check_modifer == true) { item_type = "modifier"; }
                        // else if (a.hasOwnProperty('Price') && !a.hasOwnProperty('product_id')) { item_type = "custom_fee"; }
                        else if ((a.hasOwnProperty('Price') && !a.hasOwnProperty('pid')) || (a.hasOwnProperty('Price') && a.hasOwnProperty('pid') && a.pid == 0)) { item_type = "custom_fee"; }
                        if ((!a.hasOwnProperty('Price') || a.Price === null) && !a.hasOwnProperty('product_id') && (!a.hasOwnProperty('pid')|| (a.hasOwnProperty('pid') && a.pid==0))) { item_type = "note"; }

                        switch (item_type) {
                            case "product":
                                return <div className="cart-item" key={uniqueKey()}>
                                    <div className="main-row" >
                                        <p className="quantity" onClick={() => editPopUp(a, index)}>{a.quantity && a.quantity}</p>
                                        <p className="content-style" onClick={() => editPopUp(a, index)}>{a.Title && a.Title}</p>
                                        {/* <p className="price" onClick={() => editPopUp(a, index)}>
                                            $<NumericFormat className={a.product_discount_amount !== 0 ? "strike-through" : ""} value={a.Price} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />
                                        </p> */}
                                        <p className={a.product_discount_amount !== 0 ? "strike-through price" : "price"} onClick={() => editPopUp(a, index)}>
                                            {currecyFunction(a.Price)}
                                        </p>
                                        &nbsp;
                                        {a.product_discount_amount !== 0 && <p className="price" onClick={() => editPopUp(a, index)}>
                                            {/* $<NumericFormat value={a.discount_type === "Number" ? a.Price - (a.product_discount_amount) : a.Price - (a.product_discount_amount * a.quantity)} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /> */}
                                            {currecyFunction(a.discount_type === "Number" ? a.Price - (a.product_discount_amount) : a.Price - (a.product_discount_amount * a.quantity))}
                                            {/* <NumericFormat   value={a.Price - a.product_discount_amount} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /> */}
                                        </p>}
                                        <button className="remove-cart-item" onClick={() => deleteItem(a)}>
                                            <img src={CircledX_Grey} alt="" />
                                        </button>
                                    </div>
                                    {
                                        //((typeof a.psummary !== "undefined" && a.psummary !== "") || (typeof notes !== "undefined" && notes !== "")) &&
                                        <div className="secondary-col" onClick={() => editPopUp(a, index)}>
                                            {typeof a.psummary !== "undefined" && a.psummary !== "" && <Markup content={(a.psummary)} noWrap={true} />}
                                            {typeof notes !== "undefined" && notes !== "" && <Markup content={("<b>**" + LocalizedLanguage.note + ": </b>" + notes.Title)} tagName="p" />}
                                            {modifirList && modifirList !== null && modifirList.map(item => {
                                                return <div className="row" key={uniqueKey()}>
                                                    <p>{item.Title?item.Title.replace(/_/g, " "):""}</p>
                                                    {/* <p>({currecyFunction(item.Price)})</p> */}
                                                    <p className={item.product_discount_amount !== 0 ? "strike-through" : ""} onClick={() => editPopUp(a, index)}>
                                                        {(currecyFunction(item.Price))}
                                                    </p>
                                                    {item.product_discount_amount !== 0 && <p onClick={() => editPopUp(a, index)}>
                                                        {(currecyFunction(item.discount_type === "Number" ? item.Price - (item.product_discount_amount) : item.Price - (item.product_discount_amount * item.quantity)))}
                                                    </p>}
                                                </div>

                                            })}
                                        </div>}
                                </div>
                            case "note":
                                return <div className="cart-item" key={uniqueKey()}>
                                    <div className="main-row aligned">
                                        <div className="tag cart-note">{LocalizedLanguage.note}</div>
                                        <p className="content-style line-capped" onClick={() => props.editNote && props.editNote(a, index)}>
                                            {a.Title && a.Title}
                                        </p>
                                        {
                                            !a.hasOwnProperty("pid") &&
                                            <button className="remove-cart-item" onClick={() => deleteItem(a)}>
                                                <img src={CircledX_Grey} alt="" />
                                            </button>
                                        }
                                    </div>
                                </div>
                            case "custom_fee":
                                return <div className="cart-item" key={uniqueKey()}>
                                    <div className="main-row aligned">
                                        <div className="tag custom-fee">{LocalizedLanguage.customFee}</div>
                                        <div className="content-style">{a.Title && a.Title}</div>
                                        <div className="price">
                                            {/* $<NumericFormat value={a.Price} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /> */}
                                            {currecyFunction(a.Price)}
                                        </div>
                                        <button className="remove-cart-item" onClick={() => deleteItem(a)}>
                                            <img src={CircledX_Grey} alt="" />
                                        </button>
                                    </div>
                                </div>
                            // case "modifier":

                            //     return <div className="cart-item" key={uniqueKey()}>
                            //         <div className="secondary-col">
                            //             <div className="row">
                            //                 <p>*{a.Title && a.Title}</p>
                            //                 <p>(<NumericFormat prefix="$" value={a.Price} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />)</p> 
                            //             </div>
                            //         </div>
                            //     </div>


                            // return <div className="cart-item" key={uniqueKey()}>
                            //     <div className="main-row aligned">
                            //         <div className="tag custom-fee">Modifier</div>
                            //         <div className="content-style modifier">{a.Title && a.Title}</div>
                            //         <div className="price"><NumericFormat prefix="$" value={a.Price} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /></div>
                            //         <button className="remove-cart-item" onClick={() => deleteItem(a)}>
                            //             <img src={CircledX_Grey} alt="" />
                            //         </button>
                            //     </div>
                            // </div>
                            // case "customer":
                            //     return <div className="cart-item">
                            //         <div className="main-row aligned">
                            //             <div className="tag customer">Customer</div>
                            //             <div className="content-style">{get_customerName().Name}</div>
                            //             <button className="remove-cart-item" onClick={() => deleteItem(a)}>
                            //                 <img src={CircledX_Grey} alt="" />
                            //             </button>
                            //         </div>
                            //     </div>
                            case "group":
                                return <div className="cart-item" key={uniqueKey()}>
                                    <div className="main-row aligned">
                                        <div className="tag group">Group</div>
                                        <p className="content-style">Table 1</p>
                                        <button className="remove-cart-item" onClick={() => deleteItem(a)}>
                                            <img src={CircledX_Grey} alt="" />
                                        </button>
                                    </div>
                                </div>
                            default:
                                return null;
                        }


                    })}

                </div>
                <div className="footer">
                    <div className="totals">
                        <div className="row">
                            <p>{LocalizedLanguage.subTotal}</p>
                            {/* <p><b>${<NumericFormat value={subTotal} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />}</b></p> */}
                            <p><b>{currecyFunction(subTotal)}</b></p>
                        </div>
                        {discountType !== "" ?
                            <div className="row">
                                <p>{LocalizedLanguage.cartdiscount} ({discountType})</p>
                                <button id="editCartDiscount" onClick={() => props.toggleEditCartDiscount()}>{LocalizedLanguage.edit}</button>
                                {/* <p><b>- ${<NumericFormat value={discount} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />}</b></p> */}
                                <p><b>- {currecyFunction(discount)}</b></p>
                            </div> : null}
                        <div className="row">
                            <p>{LocalizedLanguage.taxes} &nbsp;{typeOfTax() === 'incl' ? "Incl." : ""}</p> &nbsp;
                            {typeOfTax() !== "incl" ? <button id="taxesButton" onClick={() => showTaxListIfSubscription()}>{LocalizedLanguage.edit}</button> : ""}
                            {/* <p><b>${<NumericFormat value={taxes} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />}</b></p> */}
                            <p><b>{currecyFunction(taxes)}</b></p>
                        </div>


                    </div>
                    <div className="checkout-container">
                        <button id="checkoutButton" onClick={() => doCheckout()}>
                            {/* <p>{LocalizedLanguage.checkout} (${<NumericFormat value={total} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />})</p> */}
                            <p>{LocalizedLanguage.checkout} ({currecyFunction(total)})</p>
                        </button>
                    </div>
                </div>
            </div>
            <div className="mobile-homepage-footer">
                {/* <button id="openMobileCart" onClick={() => toggleMobileCartList()}>{LocalizedLanguage.viewCart} {totalItems !== 0 ? ("(" + totalItems + ")") : ""}  ${<NumericFormat value={total} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />}</button> */}
                <button id="openMobileCart" onClick={() => toggleMobileCartList()}>{LocalizedLanguage.viewCart} {totalItems !== 0 ? ("(" + totalItems + ")") : ""} {currecyFunction(total)}</button>
            </div>
        </React.Fragment>)
}

export default CartList 