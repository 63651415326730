import React, { useEffect, useLayoutEffect } from "react";
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
const ConfirmationPopup = (props) => {
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleConfirmPopUp();
        }
        else {
            e.stopPropagation();
        }
    }

    return (

        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)} style={{ zIndex: 9 }}>
            <div className={props.isShow === true ? "subwindow delete-customer-confirm current" : "subwindow delete-customer-confirm"}>
                    <div className="subwindow-header">
                        <p>Delete Customer</p>
                        <button className="close-subwindow" onClick={() => props.toggleConfirmPopUp()}>
                            <img src={X_Icon_DarkBlue} alt="" />
                        </button>
                    </div>
                    <div className="subwindow-body">
                        <div className="auto-margin-top" />
                        <p>Are you sure you want <br />to delete this customer?</p>
                        <button id="deleteCustomer" onClick={() => props.customerDelete()} >Delete</button>
                        <button id="cancelDeleteCustomer" onClick={() => props.toggleConfirmPopUp()}>Cancel</button>
                        <div className="auto-margin-bottom"/>
                    </div>
            </div></div >
    )
}

export default ConfirmationPopup