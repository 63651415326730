// A mock function to mimic making an async request for data
import { serverRequest } from '../../../CommonServiceRequest/serverRequest'
export function attributeAPI(parameter) {
  return serverRequest.clientServiceRequest('GET', `/Attributes/Get`, '')
    .then(attribute => {
      if (attribute.is_success === true && attribute.content !== null) {
        localStorage.setItem("attribute", JSON.stringify(attribute.content));
      }

      return attribute;
    }).catch(error => {
      return error
    });
}
