export function productAPI() {

    if (localStorage.getItem("SELECTED_MODIFIERS")) {
        var list = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
        var products = list.filter(a => a.hasOwnProperty("product_id") == true /*|| a.hasOwnProperty("after_discount") == true*/ || a.hasOwnProperty("pid") == false ); //after_discount used to display the notes and custome fee
        var sortedArray = []
        if (products.length > 0) {
            products.map(p => {
                sortedArray.push(p); //Push parent product
                //var child = list.filter(l => l.hasOwnProperty("pid") == true && l.pid == p.product_id);
                var child = list.filter(l => l.hasOwnProperty("pid") === true && l.pid === p.product_id
                    && ((l.hasOwnProperty("modifiers_Id") && p.hasOwnProperty("modifiers_Id") ? JSON.stringify(l.modifiers_Id) == JSON.stringify(p.modifiers_Id) : true)
                        && ((l.hasOwnProperty("modifiers_key") && p.hasOwnProperty("modifiers_key") ? l.modifiers_key == p.modifiers_key : true)))
                );
                child && child.map(c => {
                    sortedArray.push(c); // push children of that product
                })
            })
        }
        localStorage.setItem("CARD_PRODUCT_LIST", JSON.stringify(sortedArray));
        if (localStorage.getItem('CHECKLIST')) {
            var checklist = JSON.parse(localStorage.getItem('CHECKLIST'));
            checklist.ListItem = sortedArray;
            localStorage.setItem("CHECKLIST", JSON.stringify(checklist));
        }
        return sortedArray;
    }
    else {
        return localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
    }
    // return localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
}

