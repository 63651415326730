import { sendDataToWrapper, setAndroidKeyboard, showAndroidReceipt, wrapperReceiptData } from "../../settings/AndroidIOSConnect";
import moment from 'moment';
import Config from '../../Config'
import { pdfFormate, recieptDateFormate, recieptTimeFormate, companyLogo } from '../../settings/ReceiptSetting'
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import FormateDateAndTime from "../../settings/FormateDateAndTime";
import { postmessage } from "./AppHandeler/commonAppHandler";
import { isSafari } from "react-device-detect";
import { currecyFunction } from './commonFunctions/currencyFunction';


export const EodPrint = {
  PrintEodEle
};


export function formatAmount(amount) {
  const CurrencySymbol = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).CurrencySymbol : "$";


  const parsedAmount = parseFloat(amount);

  if (isNaN(parsedAmount)) {
    return "Invalid amount";
  }

  if (parsedAmount >= 0) {
    return CurrencySymbol + parsedAmount.toFixed(2);
  } else {
    const formattedAmount = Math.abs(parsedAmount).toFixed(2);
    return "-" + CurrencySymbol + formattedAmount;
  }
}

function PrintEodEle(data, notes = "") {

  //eod app variables STARTS HEHE

  var eodData = {};
  var tenders = {};
  var notes = [];
  var staff = [];
  var taxes = {};
  var taxes_summary = [];
  var reasons = {};
  var reasons_summary = [];
  var discounts = {};
  var discounts_summary = [];
  var orderTaxes = [];
  var summary = [];
  //eod app variables ENDS HEHE


  var _closeRegister = data

  console.log('_closeRegister', _closeRegister)
  const pageSize = pdfFormate();
  console.log("==-------pageSize----", pageSize)
  var _openingBalance = _closeRegister && _closeRegister.OpeningBalance;
  var _totalDiff = _closeRegister && _closeRegister.Actual - _closeRegister.Expected;
  var _totalActual = _closeRegister && _closeRegister.Actual;
  var _totalExpected = _closeRegister && _closeRegister.Expected;
  var closePersonName = _closeRegister && _closeRegister.ClosingByName && _closeRegister.ClosingByName.trim() !== '' ? _closeRegister.ClosingByName : _closeRegister.ClosingByEmail;
  var SalePersonName = _closeRegister && _closeRegister.SalePersonName && _closeRegister.SalePersonName.trim() !== '' ? _closeRegister.SalePersonName : _closeRegister.SalePersonEmail;
  var otherPayments = ''
  var finaltotal = _closeRegister.Expected;
  var finalactual = _closeRegister.Actual;
  _closeRegister && _closeRegister.PaymentSummery && _closeRegister.PaymentSummery.map(item => {
    _totalDiff = _totalDiff + (item.Actual - item.Expected);
    _totalActual = _totalActual + item.Actual;
    _totalExpected = _totalExpected + item.Expected;

    finaltotal += item.Expected
    finalactual += item.Actual
    summary.push({ "type": item.Name, "expected": parseFloat(item.Expected).toFixed(2), "actual": parseFloat(item.Actual).toFixed(2), "difference": parseFloat(item.Actual - item.Expected).toFixed(2) }); // eod app
    otherPayments = otherPayments +
      `<tr>
                                          <td class="font-bold" colspan="2">${item.Name}</td>
                                      </tr>
                                      <tr>
                                      <td>${LocalizedLanguage.expected} </td>
                                      <td>${LocalizedLanguage.actual} </td>
                                      <td style="text-align: right;">${LocalizedLanguage.Difference} </td>
                                      </tr>
                  <tr>
                                                  <td>${parseFloat(item.Expected).toFixed(2)}</td>
                                                  <td>${parseFloat(item.Actual).toFixed(2)}</td>
                                                  <td style="text-align: right;">${parseFloat(item.Actual - item.Expected).toFixed(2) < 0 ? "" : "+"}${parseFloat(item.Actual - item.Expected).toFixed(2)}</td>
                                              </tr>`;
    //tenders.push({ "type": LocalizedLanguage.total, "expected": parseFloat(item.Expected).toFixed(2), "actual": parseFloat(item.Actual).toFixed(2), "difference": parseFloat(item.Actual - item.Expected).toFixed(2) }); // eod app
  })
  var tenders_total = ({ "expected": parseFloat(finaltotal).toFixed(2), "actual": parseFloat(finalactual).toFixed(2), "difference": parseFloat(finalactual - finaltotal).toFixed(2) }); // eod app
  var locationName = localStorage.getItem('LocationName');
  var registerName = localStorage.getItem('registerName');
  const orderReceipt = localStorage.getItem('orderreciept') ? JSON.parse(localStorage.getItem('orderreciept')) : '';
  var user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
  var clientTaxId =''
  var shopName = user && user.shop_name ? user.shop_name : '';
  var shopWebsite = user && user.website ? user.website : '';
  
  const userLocations = localStorage.getItem('UserLocations') && JSON.parse(localStorage.getItem('UserLocations')) ;
  const locationIndex = localStorage.getItem("Location") && JSON.parse(localStorage.getItem('Location'));

  let shopaddress;
  const add = userLocations.find((a) => a.id === locationIndex);


  if (userLocations && locationIndex && add) {
    clientTaxId=add.TaxId?add.TaxId:"";
    const address1 = add.address_1 && add.address_1 !== '' ? add.address_1 : '';
    const address2 = add.address_2 && add.address_2 !== '' ? ',' + ' ' + add.address_2 : ''; 
    const addressCity = add.city && add.city !== '' ? ','+ ' ' + add.city : '';
    const addressCountry = user.shop_country_full_Name && user.shop_country_full_Name !== '' ? ',' + ' ' + user.shop_country_full_Name : '';

    shopaddress = address1 + address2 + addressCity + addressCountry;
    
  } else {
    const address1 = user.shop_address1 && user.shop_address1 !== '' ? user.shop_address1 : '';
    const address2 = user.shop_address2 && user.shop_address2 !== '' ? ',' + ' ' + user.shop_address2 : '';
    const addressCity = user.shop_city && user.shop_city !== '' ? ',' + ' ' + user.shop_city : '';
    const addressCountry = user.shop_country_full_Name && user.shop_country_full_Name !== '' ? ',' + ' ' + user.shop_country_full_Name : '';

    shopaddress = address1 + address2 + addressCity + addressCountry;
  }

  // var shopaddress = user && user.shop_address1 ? user.shop_address1 : '';

  var manualTransac = ''
  let initialValue = 0;
  let staffTotal = _closeRegister.CashRegisterlog.reduce((totalvalue, currentValue) => {
    return totalvalue + currentValue.Expected
  }, 0)
  var _manualTransac = [];
  var totalManual = 0;
  var totalCashSale=0;
  _closeRegister && _closeRegister.CashRegisterlog && _closeRegister.CashRegisterlog.map(item => {
  if (item.IsManual == false && item.Description=='cash') {
    totalCashSale +=item.Expected;
  }
    
    if (item.IsManual == true) {

      totalManual += item.Expected;
      manualTransac = manualTransac + `<tr>
                                                          <td>${item.SalePersonName}</td>
                                                          <td>${item.TransactionTime}</td>
                                                          <td style="text-align: right;">${(parseFloat(item.Expected).toFixed(2) > 0 ? "+" : "") + parseFloat(item.Expected).toFixed(2)}</td>                                                    
                                                      </tr> `
      if (item.Notes && item.Notes !== "") {
        manualTransac = manualTransac + `<tr><td colspan="3">${LocalizedLanguage.note}: ${item.Notes}</td> </tr> `

        notes.push({ "description": item.Notes }); //--eod app
      }

    }
  })
  manualTransac = manualTransac !== "" ?
    `<div>
                  <h1 class="section-heading">
                      ${LocalizedLanguage.addRemoveCash}
                  </h1>
                  <div class="category-list">
                      <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
                          <tbody>
                              <tr>
                              <td>${LocalizedLanguage.name}</td> 
                              <td>${LocalizedLanguage.time}</td>  
                              <td style="text-align: right;">${LocalizedLanguage.amount}</td>   
                              </tr>                        
                              ${manualTransac}  
                      <tr>
                          <td class="font-bold" colspan="2" style="border-top: 1px solid;">${LocalizedLanguage.total}</td>
                          <td style="text-align: right;border-top: 1px solid;">${totalManual.toFixed(2)} </td>   
                      </tr> 
                  </tbody>                    
                      </table>
                  </div>
              </div>`: ``



  var totalTax = 0.0;
  var taxDetail = '';
  var orderTaxes = [];
  var totalTaxRatePerc = 0;
  var taxGrossAmount = 0.0;
  var taxNetAmount = 0;
  var totalRefund = 0.0;
  var totalRefundCount = 0;
  var totallDiscount = 0.0;
  var totalCanceled = 0.0;
  var totalCanceledCount = 0;
  var totalTransaction = 0;
  var toaltRevenue = 0;
  var totalDiscountCount = 0;
  _closeRegister && _closeRegister.orders && _closeRegister.orders.map(item => {
    //var taxInclusiveName = getInclusiveTaxType(item.order_meta_lines);
    // var arrItem = orderTaxes[item.order_taxes.RateId]
    if (item.order_status.toLowerCase() == "cancelled" || item.order_status == "void_sale") {
      totalCanceled += item.total_amount;
      totalCanceledCount += 1;



    } else if (item.order_status.toLowerCase() !== "pending" && item.order_status.toLowerCase() !== "park_sale") {
      toaltRevenue += item.total_amount;
      var existInArry = false;
      // var isCash=false;
      var totalGrossAmount = 0
      item.line_items.forEach(function (litem) {
        //if item is not taxable so we do not include the amount into gross total, check litem.total_tax==0
        totalGrossAmount += (litem.total_tax !== 0 ? parseFloat(litem.total) : 0) + parseFloat(litem.total_tax) //(taxInclusiveName == "" ? parseFloat(litem.total_tax) : 0)
          //- parseFloat(litem.amount_refunded);
          - (litem.amount_refunded > 0 ?
            (parseFloat(litem.price) * (litem.quantity_refunded * -1) + parseFloat(litem.total_tax)
            ) : 0)
        //we are not using the field item.amount_refunded  due to .01 difference
      })
      //refunded Custome Fee Amount deduct from the gross
      var customefeeRefundAmount = 0
      item.order_custom_fee.forEach(function (Ritem) {
        //totalGrossAmount -= parseFloat(Ritem.amount_refunded)
        customefeeRefundAmount += parseFloat(Ritem.amount_refunded)
      })

      if (item.order_meta_lines) {// add taxable custom fee amount into gross total
        var _data = item.order_meta_lines.find(data => data.ItemName == '_order_oliverpos_product_discount_amount');
        if (_data && _data.CustomFees) {
          _data.CustomFees.forEach(function (fItem) {
            if (fItem.isTaxable && fItem.isTaxable == true) {
              totalGrossAmount += fItem.amount //- fItem.incl_tax;

            }
          })
        }
      }
      totalGrossAmount -= customefeeRefundAmount;
      item.line_items.forEach(function (lineItem) {

        if (lineItem.Taxes) {
          taxGrossAmount +=parseFloat(lineItem.total)+parseFloat(lineItem.total_tax);
          var lineItemTaxRates = lineItem.Taxes && lineItem.Taxes != "null" && JSON.parse(lineItem.Taxes).total;
          lineItemTaxRates && lineItemTaxRates != "" && Object.keys(lineItemTaxRates).map(key => {
            existInArry = false;
            var _lineItemRefund = 0
            var _lineItemRefundTax = 0
            var taxvalue = lineItemTaxRates[key];
            // calucate the refund -------------------
            if (lineItem.quantity_refunded) {
              _lineItemRefund = ((lineItem.total + parseFloat(taxvalue)) / lineItem.quantity) * lineItem.quantity_refunded
              _lineItemRefundTax = (parseFloat(taxvalue) / lineItem.quantity) * lineItem.quantity_refunded
            }
            //-----------------------------------------
            orderTaxes.forEach(function (arrItem) {
              if (key == arrItem.RateId) {
                arrItem["Total"] = arrItem.Total + parseFloat(taxvalue) //+ _lineItemRefundTax//- ordtax.RefundadInDecimal                               
                arrItem["OrderAmount"] = parseFloat(arrItem.OrderAmount) + parseFloat(lineItem.total) + parseFloat(lineItem.total_tax) //+ _lineItemRefund//tax sholud be added only for exclusive
                //(taxInclusiveName == "" ? parseFloat(lineItem.total_tax) : 0)
                existInArry = true;
              }
            })
            if (existInArry == false) {
              var ordtax1 = item.order_taxes.find(oTax => oTax.RateId == key)
              
              if (ordtax1) {
                var ordtax = {...ordtax1, OrderAmount: 0, Total: 0};
            
                ordtax["OrderAmount"] = parseFloat(lineItem.total) + parseFloat(lineItem.total_tax) //+ _lineItemRefund; //tax sholud be added only for exclusive
                //(taxInclusiveName == "" ? parseFloat(lineItem.total_tax) : 0)
                ordtax["Total"] = parseFloat(taxvalue) //+ _lineItemRefundTax //parseFloat(ordtax.Total) - ordtax.RefundadInDecimal
                orderTaxes.push(ordtax);

                //taxes_summary.push({ "name": "", "gross": ordtax["Total"], "net": "", "difference": "", }); //eod app
              }

            }
          })
        }
      })

      if (item.refunded_amount) {
        totalRefund += parseFloat(item.refunded_amount)
        totalRefundCount += 1;
      }

      if (item.discount) {
        totallDiscount += parseFloat(item.discount);
        totalDiscountCount += 1;
        discounts_summary.push({ "name": "", "count": "", "amount": item.discount });
      }
    }
  })
  //discounts_summary.push({ "name": LocalizedLanguage.discount, "count": totalDiscountCount, "amount": parseFloat(totallDiscount).toFixed(2) }); //--eod app
  var discounts_total = ({ "count": totalDiscountCount, "amount": parseFloat(totallDiscount).toFixed(2) })
  console.log("totalTransaction", totalTransaction)
  var ratelist = localStorage.getItem('SHOP_TAXRATE_LIST') && JSON.parse(localStorage.getItem('SHOP_TAXRATE_LIST'));


  orderTaxes.forEach(function (arrItem) {
    var ratePerc = ""
    ratelist && ratelist.map(item => {
      if (item.TaxId == arrItem.RateId) {
        ratePerc = item.TaxRate
        totalTaxRatePerc += parseFloat(item.TaxRate.replace('%', ''))
      }
    })
    //  taxGrossAmount += parseFloat(arrItem.OrderAmount);
    taxNetAmount += parseFloat(arrItem.OrderAmount) - parseFloat(arrItem.Total)
    //totalTax +=arrItem.Total;

    taxDetail += `<tr><td class="font-bold" colspan="2"> ${arrItem.Title} (${ratePerc}) </td></tr>`
    taxDetail += `<tr><td>Gross </td><td style="text-align: right;">Tax </td></tr>`
    //taxDetail += `<tr><td>Gross </td><td style="text-align: right;">Tax </td></tr>`
    taxDetail += `<tr><td>${arrItem.OrderAmount.toFixed(2)} </td><td style="text-align: right;">${parseFloat(arrItem.Total).toFixed(2)}</td></tr>`
    //taxDetail += `<tr><td>${arrItem.OrderAmount.toFixed(2)} </td><td style="text-align: right;">${parseFloat(arrItem.Total).toFixed(2)}</td></tr>`
    totalTax += parseFloat(arrItem.Total);
    //taxGrossAmount += parseFloat(arrItem.OrderAmount)

    taxes_summary.push({ "name": arrItem.Title, "gross": parseFloat(arrItem.OrderAmount).toFixed(2), "net": (arrItem.OrderAmount - parseFloat(arrItem.Total)).toFixed(2), "difference": parseFloat(arrItem.Total).toFixed(2) }); //--eod app
  });
  var taxes_total = ({ "gross": parseFloat(taxGrossAmount).toFixed(2), "actual": (totalTax).toFixed(2), "difference": parseFloat(taxGrossAmount - totalTax).toFixed(2) }); //--eod app

  console.log("orderTaxes", orderTaxes)
  var openingDate = _closeRegister && _closeRegister.UtcOpenDateTime ? _closeRegister.UtcOpenDateTime : ''
  var openingDateTime = moment.utc(openingDate).local().format(Config.key.DATETIME_FORMAT);

  var closingDate = _closeRegister && _closeRegister.UtcClosedDateTime ? _closeRegister.UtcClosedDateTime : ''
  var closingDateTime = moment.utc(closingDate).local().format(Config.key.DATETIME_FORMAT);
  
  var now = moment.utc(_closeRegister.UtcClosedDateTime); //todays date
  var end = moment.utc(_closeRegister.UtcOpenDateTime); // another date
  var duration = moment.duration(now.diff(end));
  console.log(now.diff(end, 'hours')) // 745
  // var duration = moment.duration(closingDateTime.diff(closingDate));
  var hours = duration.asHours().toFixed(2);
  //var hours=0
  var _tip;
 
  var html = (`
              <html lang="en">
              <head>
                  <meta charset="UTF-8">
                  <meta http-equiv="X-UA-Compatible" content="IE=edge">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>End of Day / Z report</title>
                  <style>
                  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                  body {
                      font-family: 'Poppins', sans-serif;
                      font-size: 14px;
                      line-height: 19px;
                  }
                  body, h1,h2,h3,h4,h5,h6 {
                      margin: 0;
                      padding: 0;
                  }
                  .table-common {
                      margin-bottom: 30px;
                  }
                  .table-common td {
                      padding-top: 5px;
                      padding-bottom: 5px;
                  }
                  .table-common tr:first-child td {
                      padding-top: 6px;
                  }
                  .table-common thead th{
                      border-bottom: 1px solid #050505;
                      padding-bottom: 10px;
                  }
                  .table-common tr:last-child td {
                      padding-bottom: 8px;
                      border-bottom: 1px solid #050505;
                  }
                  .font-bold {
                      font-weight: 600;
                  }
                  
                  .section-heading{
                      font-weight: 500;
                      border-bottom: 1px solid #050505;
                      padding-bottom: 10px;
                      font-size: 14px;
                  }
                  .opening-balance{
                    font-weight: 500;
                    font-size: 14px;
                    padding-bottom: 5px;
                   }
                  /* .table-common2 thead tr:first-child th{
                      font-weight: 500;
                      border-bottom: 1px solid #050505;
                      padding-bottom: 10px;
                      font-size: 14px;
                  } */
                  .category-list tfoot tr:last-child td {
                      border-bottom: 0;
                  }
                  .pagesize{
                      width:${pageSize.width}; overflow:hidden;
                        margin: 0 auto;
                    }
                  </style>
              </head>
      <body>
      <div style='padding:${pageSize.width == '80mm' ? "20px" : (pageSize.width == '52mm' || pageSize.width == '58mm') ? "10px" : "40px"};'   class='pagesize'>
      <div style="text-align: center; padding-bottom: 30px;">
          <h4 style="margin-bottom: 8px;">End of Day / Z report</h4>
          <address>
              ${shopaddress}
             
          </address>
      </div>
      <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
          <thead>
              <th>${LocalizedLanguage.endofdayreport} #</th>
              <th style="text-align: right;">${_closeRegister && _closeRegister.CashManagementId}</th>
          </thead>
          <tbody>
              <tr>
                  <td colspan="2">${LocalizedLanguage.reportfrom}: ${openingDateTime} </td>
              </tr>
              <tr>
                  <td colspan="2">${LocalizedLanguage.openedby}: ${SalePersonName} </td>
              </tr>
              <tr>
                  <td colspan="2">${LocalizedLanguage.reportuntil}:  ${closingDateTime}</td>
              </tr>
              <tr>
                  <td colspan="2">${LocalizedLanguage.closedby}: ${closePersonName} </td>
              </tr>
              <tr>
                  <td colspan="2">${LocalizedLanguage.duration}: ${hours} hr</td>
              </tr>
              <tr>
                  <td colspan="2">${LocalizedLanguage.register}: ${registerName} </td>
              </tr>        
              <tr>
              <td colspan="2">${LocalizedLanguage.shopName}: ${shopName}${clientTaxId && clientTaxId !==""?"(#"+clientTaxId+")":""} </td>
          </tr> 
          </tbody>
      </table>
      <div>
      <h1 class="opening-balance">
      ${LocalizedLanguage.openingBalacce} :${formatAmount(parseFloat(_openingBalance).toFixed(2))}
      </h1>
          <h1 class="section-heading">
              ${LocalizedLanguage.tendersummary}
          </h1>
          <div class="category-list">
              <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
                  <tbody>
                      <tr>
                          <td class="font-bold" colspan="2">${LocalizedLanguage.cashInTill} (Cash Sales= ${totalCashSale.toFixed(2)})</td>
                      </tr>
                      <tr>
                          <td>${LocalizedLanguage.expected} </td>
                          <td>${LocalizedLanguage.actual}</td>
                          <td style="text-align: right;">${LocalizedLanguage.Difference} </td>
                      </tr>
      
                      <tr>
                      <td>${_closeRegister && parseFloat(_closeRegister.Expected).toFixed(2)}</td>
                      <td>${_closeRegister && parseFloat(_closeRegister.Actual).toFixed(2)}</td>
                      <td style="text-align: right;">${parseFloat(_closeRegister && (_closeRegister.Actual - _closeRegister.Expected)) < 0 ? "" : "+"}${parseFloat(_closeRegister && (_closeRegister.Actual - _closeRegister.Expected)).toFixed(2)}</td>
                      </tr>
                      
                      ${otherPayments}
                     
                      <tr style="border-top: 1px solid;">
                          <td class="font-bold" colspan="3">${LocalizedLanguage.total}</td>
                      </tr>
                      <tr>
                      <td>${LocalizedLanguage.expected} </td>
                      <td>${LocalizedLanguage.actual}</td>
                      <td style="text-align: right;">${LocalizedLanguage.Difference} </td>
                      <tr>
                          <td>${finaltotal.toFixed(2)} </td>
                          <td>${finalactual.toFixed(2)} </td>
                          <td style="text-align: right;">${parseFloat(_totalActual - _totalExpected).toFixed(2)} </td>
                      </tr>
                   </tbody>
                
              </table>
             
          </div>
      </div>
      
      <div>
          <h1 class="section-heading">
              ${LocalizedLanguage.revenue}
          </h1>
          <div class="category-list">
              <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
                  <tbody>
                      <tr>
                          <td>${LocalizedLanguage.total}(${LocalizedLanguage.exluOpening} ${formatAmount(parseFloat(_openingBalance).toFixed(2))})</td>
                          <td style="text-align: right;">${(toaltRevenue - totalRefund).toFixed(2)}  </td>
                      </tr>
                     
                      ${_tip ? `<tr>
                          <td>${LocalizedLanguage.tips}</td>
                          <td style="text-align: right;">0.00</td>
                      </tr>`: ''}
                  </tbody>
              </table>
          </div>
      </div>
      ${manualTransac}
      <div>
          <h1 class="section-heading">
              ${LocalizedLanguage.exclTax}
          </h1>
          <div class="category-list">
              <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
                  <tbody>                
                      ${taxDetail}
                 
                      <tr style="border-top: 1px solid;">
                          <td class="font-bold" colspan="2">${LocalizedLanguage.total}</td>
                      </tr>
                      <tr>
                          <td>${LocalizedLanguage.gross} </td>
                          
                          <td style="text-align: right;">${LocalizedLanguage.exclTax} </td>
                      </tr>
                          <td>${taxGrossAmount.toFixed(2)} </td>
                          
                          <td style="text-align: right;">${totalTax.toFixed(2)}</td>
                      </tr> 
                  </tbody>
                 
              </table>
             
          </div>
      </div>
      
      
      <div>
          <h1 class="section-heading">
         ${LocalizedLanguage.voidedrefunded}
          </h1>
          <div class="category-list">
              <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
                  <tbody>               
                      <tr>
                          <td>${LocalizedLanguage.reason} </td>
                          <td>${LocalizedLanguage.num} </td>
                          <td style="text-align: right;">${LocalizedLanguage.amount} </td>
                      </tr>
                      <tr>
                          <td>${LocalizedLanguage.voided}</td>
                          <td>${totalCanceledCount}</td>
                          <td style="text-align: right;">${totalCanceled.toFixed(2)}</td>
                      </tr>
                      <tr>
                      <td>${LocalizedLanguage.refunded}</td>
                      <td>${totalRefundCount}</td>
                      <td style="text-align: right;">${totalRefund.toFixed(2)}</td>
                  </tr>
                       <tr>
                          <td class="font-bold" style="border-top: 1px solid;">${LocalizedLanguage.refunded}</td>
                          <td style="border-top: 1px solid;">${(totalCanceledCount + totalRefundCount).toFixed(2)}</td>
                          <td style="text-align: right;border-top: 1px solid;">${(totalRefund + totalCanceled).toFixed(2)}</td>
                      </tr>
                  </tbody>
              </table>
             
          </div>
      </div>
      <div>
          <h1 class="section-heading">
              ${LocalizedLanguage.discount}
          </h1>
          <div class="category-list">
              <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
                  <tbody>
                      <tr>
                          <td>${LocalizedLanguage.reason}</td>
                          <td>${LocalizedLanguage.num}</td>
                          <td style="text-align: right;">${LocalizedLanguage.amount}</td>
                      </tr>
                      <tr>
                          <td> ${LocalizedLanguage.discount}</td>
                          <td>${totalDiscountCount}</td>
                          <td style="text-align: right;">${totallDiscount.toFixed(2)}</td>
                      </tr>
                
                      <tr style="border-top: 1px solid;">
                          <td>${LocalizedLanguage.total}</td>
                          <td></td>
                          <td style="text-align: right;">${totallDiscount.toFixed(2)}</td>
                      </tr>
                   </tbody>
              </table>
          </div>
      </div>
      <div>
          <h1 class="section-heading">
              ${LocalizedLanguage.total} # of ${LocalizedLanguage.transactions}
          </h1>
          <div class="category-list">
              <table class="table-common" style="width: 100%;text-align: left;border-collapse: collapse;">
                      <tr>
                          <td>${LocalizedLanguage.total}</td>
                          <td style="text-align: right;">${_closeRegister.CashRegisterlog.length}</td>
                      </tr>
              </table>
          </div>
      </div>
      ${_closeRegister.OpeningNotes && _closeRegister.OpeningNotes !== "" ?
      `<div>
          <h1 class="section-heading">
              ${LocalizedLanguage.openingnotes}
          </h1>
          <div class="category-list">
              <div class="category-list">
              <br> ${_closeRegister.OpeningNotes} </br></br>
              </div>
             
          </div>
      </div>` : ``
    }
              ${(_closeRegister.ClosingNotes && _closeRegister.ClosingNotes !== "") ?
      `<div>
          <h1 class="section-heading">
         ${LocalizedLanguage.closingnotes}
          </h1>
          <div class="category-list">
              <div class="category-list">
              ${_closeRegister.ClosingNotes !== "" ? `${_closeRegister.ClosingNotes}` : ``}
              </div>
             
          </div>
      </div>`
      : ``
    }
      </div>`
  );

  html += '</body ></html>';

  //---eod app data--

  var selectedRegister = localStorage.getItem('selectedRegister') ? JSON.parse(localStorage.getItem('selectedRegister')) : 0;
  var store = {
    "name": user && user.shop_name ? user.shop_name : '',
    "address": {
      "lineOne": user.shop_address1 ? user.shop_address1 : "",
      "lineTwo": user.shop_address2 ? user.shop_address2 : "",
      "zip": user.shop_postcode ? user.shop_postcode : "",
      "country": user.shop_country_full_Name ? user.shop_country_full_Name : ""
    }
  };
  reasons_summary.push({ "type": LocalizedLanguage.refunded, "count": totalRefundCount, "amount": totalRefund.toFixed(2) }); //eod app
  reasons_summary.push({ "type": LocalizedLanguage.voided, "count": totalCanceledCount, "amount": totalCanceled.toFixed(2) }); //eod app
  var reasons_total = ({ "count": totalRefundCount + totalCanceledCount, "amount": totalRefund + totalCanceled.toFixed(2) }); //eod app
  //reasons.push({ "type": LocalizedLanguage.total, "count": totalRefundCount + totalCanceledCount, "amount": (totalRefund + totalCanceled).toFixed(2) }); //eod app
  summary.push({ "type": LocalizedLanguage.cashInTill, "expected": _closeRegister && parseFloat(_closeRegister.Expected).toFixed(2), "actual": _closeRegister && parseFloat(_closeRegister.Actual).toFixed(2), "difference": (parseFloat(_closeRegister.Actual - _closeRegister.Expected).toFixed(2)) });
  var _odate = FormateDateAndTime.formatDateAndTime(openingDate, data.time_zone);
  var _cdate = FormateDateAndTime.formatDateAndTime(closingDateTime, data.time_zone);
  var _otime = FormateDateAndTime.formatDateWithTime(data.UtcOpenDateTime, data.TimeZoneType, Config.key.ONLY_TIME)
  var _ctime = FormateDateAndTime.formatDateWithTime(data.UtcClosedDateTime, data.TimeZoneType, Config.key.ONLY_TIME)
  //console.log("----date----" + _odate + "..." + _cdate + "..." + _otime + "..." + _ctime);
  tenders["summary"] = summary;
  tenders["total"] = tenders_total;
  taxes["summary"] = taxes_summary;
  taxes["total"] = taxes_total;
  reasons["summary"] = reasons_summary;
  reasons["total"] = reasons_total;

  discounts["summary"] = discounts_summary
  discounts["total"] = discounts_total;
  eodData["store"] = store;
  eodData["reportStart"] = { "date": _odate, "time": _otime, "staffName": _closeRegister?.SalePersonName };;
  eodData["reportEnd"] = { "date": _cdate, "time": _ctime, "staffName": _closeRegister?.ClosingByName };
  eodData["duration"] = hours;
  eodData["registerId"] = selectedRegister != 0 ? selectedRegister.id : 0;
  eodData["tenders"] = tenders;
  eodData["notes"] = notes;
  eodData["revenue"] = { "total": (toaltRevenue - totalRefund).toFixed(2), "expenses": "", "tips": _tip ? _tip : "","revenueTotalLabel":LocalizedLanguage.exluOpening +"("+_openingBalance+")" };
  eodData["staff"] = staff;
  eodData["taxes"] = taxes;
  eodData["reasons"] = reasons;
  eodData["discounts"] = discounts;
  eodData["transactionCount"] = _closeRegister.CashRegisterlog.length;
  eodData["shopName"] =  shopName;
  eodData["taxId"] = clientTaxId; 
  var label = {};
  label["shopName"] = LocalizedLanguage.shopName;
  label["taxId"] = LocalizedLanguage.taxId;  
  label["endOfDayReport"] = LocalizedLanguage.endOfDayReport;
  label["zReport"] = LocalizedLanguage.zIndex;
  label["reportFrom"] = LocalizedLanguage.reportfrom;
  label["openedBy"] = LocalizedLanguage.openedBy;
  label["reportUntil"] = LocalizedLanguage.reportuntil;
  label["closedBy"] = LocalizedLanguage.closedby;
  label["duration"] = LocalizedLanguage.duration;
  label["register"] = LocalizedLanguage.register;
  label["tenderSummary"] = LocalizedLanguage.tendersummary;
  label["expected"] = LocalizedLanguage.expected;
  label["actual"] = LocalizedLanguage.actual;
  label["difference"] = LocalizedLanguage.Difference;
  label["total"] = LocalizedLanguage.total;
  label["notes"] = LocalizedLanguage.notes;
  label["description"] = LocalizedLanguage.description;
  label["revenue"] = LocalizedLanguage.revenue;
  label["excludingExpenses"] = LocalizedLanguage.excludingExpenses;
  label["tips"] = LocalizedLanguage.tips;
  label["staff"] = LocalizedLanguage.staff;
  label["name"] = LocalizedLanguage.name;
  label["amount"] = LocalizedLanguage.amount;
  label["tax"] = LocalizedLanguage.tax;
  label["grossTax"] = LocalizedLanguage.grossTax;
  label["netTax"] = LocalizedLanguage.netTax;
  label["voided"] = LocalizedLanguage.voided;
  label["cancelled"] = LocalizedLanguage.cancelled;
  label["refunded"] = LocalizedLanguage.refunded;
  label["reason"] = LocalizedLanguage.reason;
  label["number"] = LocalizedLanguage.number;
  label["discount"] = LocalizedLanguage.discount;
  label["totalTransactions"] = LocalizedLanguage.total + " # of " + LocalizedLanguage.transactions;
  eodData["label"] = label;
  //console.log("----eod------" + JSON.stringify(eodData));

  var clientJSON = {
    // command: "getEndOfDayDetails",
    version: "3.0",
    // method: "get",
    // status: 200,
    data: eodData
  }

  sendDataToWrapper("eod_detail", "", clientJSON);
  //return eodData;
  var a = window.open('#', '', 'width=400', 'A2');
  a && a.document && a.document.write(html);
  a && a.print();
  if (!isSafari) {
    a && a.close();
  }
  return true;

  // var clientJSON = "";
  // clientJSON = {
  //   command: "EOD",
  //   version: "3.0",
  //   method: "get",
  //   data: JSON.stringify(eodData)
  // }
  // postmessage(eodData);
}