import React from "react";
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';

const ViewRecieptCashLedger = (props) => {
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleViewReceipt() && props.toggleViewReceipt();
        }
    }
    var printExcel = true;
    return (
        <React.Fragment>
            <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
                <div className={props.isShow === true ? "subwindow receipt current" : "subwindow receipt"}>
                    <div className="subwindow-header">
                        <p>{LocalizedLanguage.print} {LocalizedLanguage.option}</p>
                        <button className="close-subwindow" onClick={() => props.toggleViewReceipt()}>
                            <img src={X_Icon_DarkBlue} alt="" />
                        </button>
                    </div>
                    <div className="subwindow-body">
                        <div className="auto-margin-top" />
                        <div className="receipt-options">
                            <p>{LocalizedLanguage.selectPrintBtn}:</p>
                            <button onClick={() => props.PrintClick()}>{LocalizedLanguage.PrintEOD}</button>
                            <button id="receiptChooseEmail" onClick={() => props.Printledger()}>{LocalizedLanguage.PrintCashLedger}</button>
                            {/* <button onClick={() => props.Printledger(printExcel)}>Print To Excel</button> */}
                        </div>
                        <div className="auto-margin-bottom" />
                    </div>
                </div></div>
        </React.Fragment>
    )
}

export default ViewRecieptCashLedger
