import React, { useState } from "react";
import OliverIconError from '../assets/images/svg/OliverIconError.svg';
// import { useNavigate } from "react-router-dom";
const NoPage = () => {
  // const navigate = useNavigate()
  // return <h1>404</h1>;
  const reDirect = () => {

    const Android = window.Android;
    if ((typeof Android !== "undefined" && Android !== null) && (Android.getDatafromDevice("isWrapper") == true)) {
      Android.wrapperLogout();
    }
    else {
      if (window !== window.parent) {
        //console.log("The page is in an iframe");
        window.parent.location.href = window.parent.location.pathname;
      }
      else {
        //console.log("The page is not in an iframe");
        window.location.href = "/login";
      }
    }
    // if (window.location.pathname == "/home") {
    //   window.location.href = "/home";
    // }
    // else { window.location.href = "/login"; }

  }
  return (
    <div className="error-404-wrapper">
      <div className="error-404-container">
        <img src={OliverIconError} alt="" />
        <p className="style1">Oh no!</p>
        <p className="style2">404 Error</p>
        <p className="style3">{"We can’t find the page you’re looking for."}</p>
        <button onClick={() => reDirect()}>Go Back</button>
        <div className="row">
          <p>Still having issues?</p>
          <a href="https://oliverpos.com/contact-oliver-pos/">Contact us.</a>
        </div>
      </div>
    </div>
  )
}

export default NoPage;