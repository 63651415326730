import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { validatePin } from "../../pinPage/pinSlice";
const LogoutConfirm = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logout = () => {
        var isDemoUser = localStorage.getItem('demoUser')
        var clientDetail = localStorage.getItem('clientDetail')
        if (isDemoUser == 'true' || !clientDetail) {
            //history.push('/login');
            const Android = window.Android;
            if ((typeof Android !== "undefined" && Android !== null) && (Android.getDatafromDevice("isWrapper") == true)) {
                Android.wrapperLogout();
            }
            else {
                // sessionStorage.removeItem("AUTH_KEY");
                localStorage.removeItem("AUTH_KEY")
                localStorage.removeItem("remember_data");
                navigate("/login");
            }
        } else {
            const Android = window.Android;
            if ((typeof Android !== "undefined" && Android !== null) && (Android.getDatafromDevice("isWrapper") == true)) {
                Android.wrapperLogout();
            }
            else {
                // sessionStorage.removeItem("AUTH_KEY");
                localStorage.removeItem("AUTH_KEY")
                localStorage.removeItem("remember_data");
                navigate("/login");
            }

        }
        dispatch(validatePin(null))
    }

    return <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"}>
        <div className={props.isShow === true ? "subwindow logout-confirm current" : "subwindow logout-confirm"}>
            <div className="subwindow-body">
                <div className="auto-margin-top"></div>
                <p className="style1">{LocalizedLanguage.accountlogoutconfirmation}</p>
                <p className="style2">
                    {LocalizedLanguage.areyousureyouwanttologout}<br />
                    {LocalizedLanguage.oftheOliverPOSapp}
                </p>
                <p className="style2">
                    {LocalizedLanguage.youwillneedtheaccountusernameand}<br />
                    {LocalizedLanguage.passwordtologbackin}
                </p>
                <button id="registerLogout" onClick={() => logout()}>{LocalizedLanguage.logOut}</button>
                <button id="cancelRegisterLogout" onClick={() => props.toggleLogoutConfirm()}>{LocalizedLanguage.cancel}</button>
                <div className="auto-margin-bottom"></div>
            </div>
        </div>
    </div>
}

export default LogoutConfirm 