import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { SaveClosingNote, openRegister } from './CashmanagementSlice'
import { useNavigate } from "react-router-dom";
import ActiveUser from '../../settings/ActiveUser';
import moment from 'moment';
import Config from '../../Config'
import { createPin, validatePin } from "../pinPage/pinSlice"
import { setAndroidKeyboard, showAndroidReceipt } from "../../settings/AndroidIOSConnect";
import { EodPrint } from "../common/EodPrint";
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { get_locName, get_regName, get_userName, getShopName } from "../common/localSettings"
import { LoadingModal } from "../common/commonComponents/LoadingModal";

const Closeregistertwo = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [enterNotes, setenterNotes] = useState('')
    const [isloading, setIsLoading] = useState(false);
    const enterNote = (e) => {
        const { value } = e.target;
        setenterNotes(value)
    }


    const CloseRegister = (event) => {
        var cashManagementID = sessionStorage.getItem('Cash_Management_ID');
        var saveCountParm = { "CashManagementId": cashManagementID, "Note": enterNotes }
        // console.log("saveCountParm", saveCountParm)
        event.currentTarget.disabled = true
        setIsLoading(true);
        dispatch(SaveClosingNote(saveCountParm))

        sessionStorage.removeItem('Cash_Management_ID');
        localStorage.removeItem("CUSTOMER_TO_OrderId")
        localStorage.removeItem('CASH_ROUNDING');
        localStorage.setItem("IsCashDrawerOpen", "false");
        localStorage.removeItem('Cash_Management_ID');
        //Webview Android keyboard setting.................... 
        localStorage.setItem('logoutclick', "true");
        setAndroidKeyboard('logout');
        //--------------------------------------------------------
        //this.props.dispatch(userActions.logout())
        // redirectToURL()
        // history.push('/loginpin');
        dispatch(validatePin(null))
        var open_register_param = {
            "RegisterId": 0,
            "RegisterName": 0,
            "LocationId": 0,
            "LocationName": "",
            "LocalDateTime": null,
            "LocalTimeZoneType": null,
            "SalePersonId": '',
            "SalePersonName": '',
            "SalePersonEmail": '',
            "ActualOpeningBalance": 0,
            "OpeningNote": '',
            "LocalTimeZoneOffsetValue": ''
        }
        dispatch(openRegister());
        localStorage.removeItem('user');
        setTimeout(() => {
            setIsLoading(false);
            navigate('/openregister')
        }, 500);

    }

    const EodprintHtml = (_closeRegister) => {
        var close_Register = { ..._closeRegister }
        if (enterNotes != "") {
            close_Register["ClosingNotes"] = enterNotes;
        }
        EodPrint.PrintEodEle(close_Register, enterNotes);
    }


    var closeRegister = props.closeregisterPaymentDetail;
    var _closeRegister = null;
    if (closeRegister && closeRegister)
        _closeRegister = closeRegister && closeRegister;
    var _totalDiff = _closeRegister && _closeRegister.Actual - _closeRegister.Expected
    _closeRegister && _closeRegister.PaymentSummery && _closeRegister.PaymentSummery.map(item => {
        // _totalDiff = _totalDiff + (item.DiffrenceAmount);
        _totalDiff = _totalDiff + (item.Actual - item.Expected);
    })
    var cashRegisterLog = closeRegister ? closeRegister.CashRegisterlog : [];


    return (
        <>
        {isloading==true  ?<LoadingModal></LoadingModal> : null}
        
          <p className="style1">{LocalizedLanguage.closeRegister}</p>
            <p className="style2">
                <b>{LocalizedLanguage.loggedUser}:</b>{get_userName()}
            </p>
            <label>{LocalizedLanguage.estimatedcashandtransactiontotals}</label>
            <div className="closing-totals">
                <p className="style1">{LocalizedLanguage.estimatedtotals}</p>
                <div className="tablet-row">
                    <p>{LocalizedLanguage.payment}</p>
                    <p>{LocalizedLanguage.expected}</p>
                    <p>{LocalizedLanguage.actual}</p>
                    <p>{LocalizedLanguage.Difference}</p>
                </div>
                <div className="col">
                    <p className="style2">{LocalizedLanguage.cashInTill}</p>
                    <div className="divider" />
                    <div className="row">
                        <p className="style3">{LocalizedLanguage.expected}:</p>
                        <p className="style3">{_closeRegister && _closeRegister.Expected}</p>
                    </div>
                    <div className="row">
                        <p className="style3">{LocalizedLanguage.actual}:</p>
                        <p className="style3">{_closeRegister && _closeRegister.Actual}</p>
                    </div>
                    <div className="row">
                        <p className="style3">{LocalizedLanguage.Difference}:</p>
                        <p className="style3">{_closeRegister && (_closeRegister.Actual - _closeRegister.Expected).toFixed(2)} </p>
                    </div>
                </div>
                {_closeRegister && _closeRegister.PaymentSummery && _closeRegister.PaymentSummery.map((item, index) => {
                    return (<div className="col">
                        <p className="style2">{item.Name}</p>
                        <div className="divider" />
                        <div className="row">
                            <p className="style3">{LocalizedLanguage.expected}:</p>
                            <p className="style3">{item.Expected}</p>
                        </div>
                        <div className="row">
                            <p className="style3">{LocalizedLanguage.actual}:</p>
                            <p className="style3">{item.Actual}</p>
                        </div>
                        <div className="row">
                            <p className="style3">{LocalizedLanguage.Difference}:</p>
                            <p className="style3">{(item.Actual - item.Expected).toFixed(2)}</p>
                        </div>
                    </div>);
                })
                }

                <div className="tablet-divider" />
                <div className="col total">
                    <p className="style2">{LocalizedLanguage.totalDifference}</p>
                    <div className="divider" />
                    <div className="row">
                        <p className="style3">{_totalDiff && _totalDiff !== null ? _totalDiff.toFixed(2) : "0.00"}</p>
                    </div>
                </div>
            </div>
            <label htmlFor="closingNote">{LocalizedLanguage.addNote}</label>
            <textarea
                name="closingNote"
                id="closingNote"
                placeholder="Please add a note here"
                defaultValue={""}
                onChange={(e) => enterNote(e)}
            />
            <button id="printReport" onClick={() => EodprintHtml(_closeRegister)}>{LocalizedLanguage.printReport}</button>
            <button id="closeRegister" onClick={(e) => CloseRegister(e)}>{LocalizedLanguage.closeRegister}</button></>
    )
}

export default Closeregistertwo